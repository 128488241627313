import { CompanyMessageDto } from 'Api/Features/Messages/Dtos/CompanyMessageDto';
import { MessageEntityTypeDto } from 'Api/Features/Messages/Dtos/MessageEntityTypeDto';
import { NotificationTypeDto } from 'Api/Features/Notifications/Dtos/NotificationTypeDto';
import { CompanyMessageUserDto } from 'Api/Features/Messages/Dtos/CompanyMessageUserDto';
import { User } from 'Models/Users/User';
import { UserDto } from 'Api/Features/Users/Dtos/UserDto';

export class CompanyMessage implements CompanyMessageDto {
    constructor(dto: CompanyMessageDto) {
        Object.assign(this, dto);
    }

    id = '';
    entityType?: MessageEntityTypeDto | null = null;
    entityId?: string | null;
    type?: NotificationTypeDto | null;
    title?: string | null;
    body?: string | null;
    date?: string;
    createdByUser?: CompanyMessageUserDto | null;

    private _creator: User | null = null;

    public getConsoleUrl(companyId: string): string {
        return `/companies/${companyId}/messages/${this.id}`;
    }

    get from(): User | null {
        if (this.createdByUser && !this._creator) {
            this._creator = new User({ ...this.createdByUser } as UserDto);
        }

        return this._creator;
    }

    get fromName(): string {
        const fromUser = this.from;
        return (fromUser) ? fromUser.name || '' : '';
    }
}
