import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import AppStore from 'Assets/Images/common/app-store.svg';
import PlayStore from 'Assets/Images/common/google-play.svg';
import {
    STORE_URL_HEYDAY_APPLE,
    STORE_URL_HEYDAY_GOOGLE,
    STORE_URL_PASSPORT_APPLE,
    STORE_URL_PASSPORT_GOOGLE,
} from 'Models/Constants';
import './index.less';

const HIDInvitation: FunctionComponent = () => {
    const { t } = useTranslation();
    const variant = process.env.REACT_APP_VARIANT;

    const getPlayStoreUrl = (): string => {
        return variant === 'heyday'
            ? STORE_URL_HEYDAY_GOOGLE
            : STORE_URL_PASSPORT_GOOGLE;
    };

    const getAppStoreUrl = (): string => {
        return variant === 'heyday'
            ? STORE_URL_HEYDAY_APPLE
            : STORE_URL_PASSPORT_APPLE;
    };

    return (
        <div className="HIDInvitation">
            <div className="content">
                <div className="text-1">
                    <span>{t('AccessControl.deep_link_landing_page_1')}</span>
                </div>

                <div className="text-2">
                    <span className="app">{t('heyday')} </span>
                    <span>{t('AccessControl.deep_link_landing_page_2')}</span>
                </div>

                <Row gutter={[40, 24]} className="stores">
                    <Col xs={24} md={12} className="store google">
                        <a href={getPlayStoreUrl()} target="_blank" rel="noopener noreferrer">
                            <img src={PlayStore} alt={t('play_store')} />
                        </a>
                    </Col>
                    <Col xs={24} md={12} className="store apple">
                        <a href={getAppStoreUrl()} target="_blank" rel="noopener noreferrer">
                            <img src={AppStore} alt={t('app_store')} />
                        </a>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default HIDInvitation;
