import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ActionMessage, ActionTitle } from 'Components/webapp-actions-layout';
import AppStore from 'Assets/Images/common/app-store.svg';
import PlayStore from 'Assets/Images/common/google-play.svg';
import { Row, Col, Button } from 'antd';
import {
    STORE_URL_HEYDAY_APPLE,
    STORE_URL_HEYDAY_GOOGLE,
    STORE_URL_PASSPORT_APPLE,
    STORE_URL_PASSPORT_GOOGLE,
} from 'Models/Constants';
import './confirm-account-success.less';

const ConfirmAccountSuccess: FunctionComponent = () => {
    const { t } = useTranslation();
    const variant = process.env.REACT_APP_VARIANT;
    const history = useHistory();

    const onButtonClick = (): void => {
        history.push('/');
    };

    const getPlayStoreUrl = (): string => {
        return variant === 'heyday'
            ? STORE_URL_HEYDAY_GOOGLE
            : STORE_URL_PASSPORT_GOOGLE;
    };

    const getAppStoreUrl = (): string => {
        return variant === 'heyday'
            ? STORE_URL_HEYDAY_APPLE
            : STORE_URL_PASSPORT_APPLE;
    };

    return (
        <div className="ConfirmAccountSuccess">
            <ActionTitle>{t('User.user_welcome_title')}</ActionTitle>
            <ActionMessage>
                <div className="text-1">
                    <span>{t('AccessControl.download_link_landing_page_1', { param1: t('brand_name') } )}</span>
                </div>

                <div className="text-2">
                    <span className="app">{t('app_name')} </span>
                    <span>{t('AccessControl.download_link_landing_page_2')}</span>
                </div>
            </ActionMessage>
            <div style={{ display: 'inline-block' }} />
            <Row gutter={[40, 24]} className="stores">
                <Col xs={24} md={12} className="store google">
                    <a href={getPlayStoreUrl()} target="_blank" rel="noopener noreferrer">
                        <img src={PlayStore} alt={t('play_store')} />
                    </a>
                </Col>
                <Col xs={24} md={12} className="store apple">
                    <a href={getAppStoreUrl()} target="_blank" rel="noopener noreferrer">
                        <img src={AppStore} alt={t('app_store')} />
                    </a>
                </Col>
            </Row>
            <div className="action">
                <Button type="primary" onClick={onButtonClick} className="login-btn">
                    {t('Account.login_with_web')}
                </Button>
            </div>
        </div>
    );
};

export default ConfirmAccountSuccess;
