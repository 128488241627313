import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Waiver: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 18 22" fill="none" {...props}>
                <path
                    d="M1.125 22H16.875C17.4953 22 18 21.5373 18 20.9688V6.53125C18 6.51956 17.994 6.50994 17.9932 6.49894C17.9917 6.48313 17.9873 6.46937 17.9835 6.45425C17.9717 6.40649 17.9487 6.36167 17.916 6.32294C17.9107 6.31675 17.9115 6.30919 17.9062 6.303L11.9062 0.1155C11.904 0.113437 11.901 0.113438 11.8988 0.111375C11.853 0.0675196 11.7952 0.0357081 11.7315 0.01925C11.7172 0.015125 11.7045 0.01375 11.6895 0.0116875C11.6678 0.00825 11.6475 0 11.625 0H1.125C0.50475 0 0 0.462687 0 1.03125V20.9688C0 21.5373 0.50475 22 1.125 22ZM12 1.24781L16.7902 6.1875H12.375C12.21 6.1875 12 5.89875 12 5.67188V1.24781ZM0.75 1.03125C0.75 0.940082 0.789509 0.852648 0.859835 0.788182C0.930161 0.723716 1.02554 0.6875 1.125 0.6875H11.25V5.67188C11.25 6.22806 11.7413 6.875 12.375 6.875H17.25V20.9688C17.25 21.0599 17.2105 21.1474 17.1402 21.2118C17.0698 21.2763 16.9745 21.3125 16.875 21.3125H1.125C0.915 21.3125 0.75 21.1612 0.75 20.9688V1.03125Z"
                    fill={fill}
                />
                <path
                    d="M4.125 9.625H13.875C13.9745 9.625 14.0698 9.58878 14.1402 9.52432C14.2105 9.45985 14.25 9.37242 14.25 9.28125C14.25 9.19008 14.2105 9.10265 14.1402 9.03818C14.0698 8.97372 13.9745 8.9375 13.875 8.9375H4.125C4.02554 8.9375 3.93016 8.97372 3.85984 9.03818C3.78951 9.10265 3.75 9.19008 3.75 9.28125C3.75 9.37242 3.78951 9.45985 3.85984 9.52432C3.93016 9.58878 4.02554 9.625 4.125 9.625Z"
                    fill={fill}
                />
                <path
                    d="M4.125 12.375H13.875C13.9745 12.375 14.0698 12.3388 14.1402 12.2743C14.2105 12.2099 14.25 12.1224 14.25 12.0312C14.25 11.9401 14.2105 11.8526 14.1402 11.7882C14.0698 11.7237 13.9745 11.6875 13.875 11.6875H4.125C4.02554 11.6875 3.93016 11.7237 3.85984 11.7882C3.78951 11.8526 3.75 11.9401 3.75 12.0312C3.75 12.1224 3.78951 12.2099 3.85984 12.2743C3.93016 12.3388 4.02554 12.375 4.125 12.375Z"
                    fill={fill}
                />
                <path
                    d="M4.125 6.875H8.625C8.72446 6.875 8.81984 6.83878 8.89017 6.77432C8.96049 6.70985 9 6.62242 9 6.53125C9 6.44008 8.96049 6.35265 8.89017 6.28818C8.81984 6.22372 8.72446 6.1875 8.625 6.1875H4.125C4.02554 6.1875 3.93016 6.22372 3.85984 6.28818C3.78951 6.35265 3.75 6.44008 3.75 6.53125C3.75 6.62242 3.78951 6.70985 3.85984 6.77432C3.93016 6.83878 4.02554 6.875 4.125 6.875Z"
                    fill={fill}
                />
                <path
                    d="M4.125 15.125H13.875C13.9745 15.125 14.0698 15.0888 14.1402 15.0243C14.2105 14.9599 14.25 14.8724 14.25 14.7812C14.25 14.6901 14.2105 14.6026 14.1402 14.5382C14.0698 14.4737 13.9745 14.4375 13.875 14.4375H4.125C4.02554 14.4375 3.93016 14.4737 3.85984 14.5382C3.78951 14.6026 3.75 14.6901 3.75 14.7812C3.75 14.8724 3.78951 14.9599 3.85984 15.0243C3.93016 15.0888 4.02554 15.125 4.125 15.125Z"
                    fill={fill}
                />
                <path
                    d="M4.125 17.875H13.875C13.9745 17.875 14.0698 17.8388 14.1402 17.7743C14.2105 17.7099 14.25 17.6224 14.25 17.5312C14.25 17.4401 14.2105 17.3526 14.1402 17.2882C14.0698 17.2237 13.9745 17.1875 13.875 17.1875H4.125C4.02554 17.1875 3.93016 17.2237 3.85984 17.2882C3.78951 17.3526 3.75 17.4401 3.75 17.5312C3.75 17.6224 3.78951 17.7099 3.85984 17.7743C3.93016 17.8388 4.02554 17.875 4.125 17.875Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Waiver;
