import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../Hooks';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import './global-loading-container.less';
import { theme } from 'variant';
import { useTranslation } from 'react-i18next';

const GlobalLoadingContainer: FunctionComponent = observer(() => {
    const { globalLoadingStore } = useStores();
    const { t } = useTranslation();
    return (
        <div className={`GlobalLoadingContainer ${globalLoadingStore.isLoading ? 'loading' : ''}`}>
            {globalLoadingStore.isLoading && (
                <Spin
                    indicator={
                        <LoadingOutlined
                            style={{ fontSize: 50, color: theme['loading-color'] }}
                            spin
                        />
                    }
                    size="large"
                />
            )}
            {globalLoadingStore.useExplanation && <div className="explanation">{t('Loading.loading_explanation')}</div>}
        </div>
    );
});

export default GlobalLoadingContainer;
