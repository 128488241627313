import { observable, action } from 'mobx';

interface ToastConfig {
    type: 'info' | 'success' | 'error' | 'warning';
    message?: string;
    messageKey?: string;
}

class ToastStore {
    @observable currentToast: ToastConfig | null = null;

    @action
    genericError(): void {
        this.currentToast = {
            type: 'error',
            messageKey: 'Errors.unexpected_error',
        };
    }

    @action
    displayError(error: any, server = false): void {
        // This method with attempt to display any error with some level of details,
        // in order to reduce the occurence of cryptic unexpected_error messages.
        //
        // Human readable message should be prioritized over code,
        // but anything that can offer clue on the error nature is welcome.
        //
        // This version will check for: error.message, error.response.data.title, error.response.status,
        // … error.response.data.errorDescription, error.response.data.error
        // … and error.response.data.errorCode

        let error_description:string | null = null;

        if (error?.response?.data?.title) {
            error_description = error.response.data.title;
        } else if(error?.message) {
            error_description = error.message;
        } else if (error?.response?.data?.errorDescription) {
            error_description = error.response.data.errorDescription;
        } else if (error?.response?.statusText) {
            error_description = error.response.statusText;
        }

        if(error_description) {
            // Attempt to get backend specific error descriptions, if any
            if(error?.response?.data?.errors) {
                for(const [, errors_list] of Object.entries(error.response.data.errors)) {
                    if(Array.isArray(errors_list)) {
                        for(let idx=0; idx<errors_list.length; idx++) {
                            for(const [key, text] of Object.entries(errors_list[idx])) {
                                if(key==='description') {
                                    error_description += ' | ' +  text;
                                }
                            }
                        }
                    }
                }
            }

            let error_code:string | null = null;
            if(error?.response?.data?.errorCode) {
                error_code = error.response.data.errorCode;
            } else if (error?.response?.data?.error) {
                error_code = error.response.data.error;
            } else if (error?.response?.status >= 400) {
                error_code = error.response.status;
            }

            this.toast({
                type: 'error',
                message: error_description + ((error_code) ? ' [code/status: ' + error_code + ']' : '')
            })
        } else if(server) {
            this.serverError();
        } else {
            this.genericError();
        }
    }

    @action
    serverError(): void {
        this.currentToast = {
            type: 'error',
            messageKey: 'Errors.server_error',
        };
    }

    @action
    toast(config: ToastConfig): void {
        this.currentToast = config;
    }
}

export default ToastStore;
