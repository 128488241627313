import React, { FunctionComponent, useCallback, useEffect, useState, useRef } from 'react';
import { Modal, Form, Row, Col, Input, Button, List } from 'antd';
import {
    Booking,
    Calendar,
    Close,
    Amenity as AmenityIcon,
    User as UserIcon,
    Company,
} from 'Components/icons';
import { useTranslation } from 'react-i18next';
import { useFormValidation, useService, useStores } from 'Hooks';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { CreateBookingSchema } from 'Schemas';
import { Store } from 'antd/es/form/interface';
import { ValidatedFormItem } from 'Components/validated-form-item';
import './booking-modal.less';
import { CreateBookingRequestDto } from 'Api/Features/Bookings/Dtos/CreateBookingRequestDto';
import { SimpleList } from 'Components/simple-list';
import ImageWithPlaceholder from 'Components/image-with-placeholder/image-with-placeholder';
import { MemberService } from 'Services/MemberService';
import { BookingService } from 'Services/BookingService';
import { AmenityService } from 'Services/AmenityService';
import { CompanyService } from 'Services/CompanyService';
import momentTimezone from 'moment-timezone';
import { Gutter } from 'antd/es/grid/row';
import { theme } from 'variant';
import { SelectCustom } from 'Components/select-custom';
import { SelectCustomOption } from 'Components/select-custom/select-custom';
import debounce from 'lodash.debounce';
import moment from 'moment';
import { AmenityDto } from 'Api/Features/Amenities/Dtos/AmenityDto';
import FlexibleBooking from 'Components/booking/flexible-booking/flexible-booking';
import { Company as CompanyModel } from 'Models/Companies/Company';
import { CompanyDto } from 'Api/Features/Companies/Dtos/CompanyDto';
import { User } from 'Models/Users/User';
import { DEBOUNCE_DELAY } from 'Models/Constants';
import Icon from 'Components/icons/Icon';
import { GetBookingRecurrencePresetsRequestDto } from 'Api/Features/Bookings/Dtos/GetBookingRecurrencePresetsRequestDto';
import { BookingRecurrencePresetDto } from 'Api/Features/Bookings/Dtos/BookingRecurrencePresetDto';
import { CreateBookingRecurrenceInfoDto } from 'Api/Features/Bookings/Dtos/CreateBookingRecurrenceInfoDto';
import BookingConflictModal from './booking-conflicts-modal';
import { BookingPreviewDto } from 'Api/Features/Bookings/Dtos/BookingPreviewDto';
import { CreateBookingRecurrenceExceptionDto } from 'Api/Features/Bookings/Dtos/CreateBookingRecurrenceExceptionDto';
import { DailyBookingSummaryPeriodUnavailabilityReasonDto } from 'Api/Features/Bookings/Dtos/DailyBookingSummaryPeriodUnavailabilityReasonDto';
import { EditBookingSchema } from 'Schemas/CreateBookingSchema';
import { mergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';

const formGutter: [Gutter, Gutter] = [20, 0];

interface BookingModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    booking?: any;
    newBookingInfo?: any;
    resource: any;
    resourcePeriod?: any;
    timezone: any;
    selectedDate: moment.Moment;
    isEditing: boolean;
    locationId: string;
}

const BookingModal: FunctionComponent<BookingModalProps> = ({
    visible,
    onComplete,
    booking,
    newBookingInfo,
    resource,
    resourcePeriod,
    timezone,
    selectedDate,
    isEditing,
    locationId,
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(
        isEditing ? EditBookingSchema : CreateBookingSchema,
        form
    );
    const pageSize = 25;
    const memberService = useService(MemberService);
    const companyService = useService(CompanyService);
    const bookingService = useService(BookingService);
    const amenityService = useService(AmenityService);
    const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
    const [conferenceRoom, setConferenceRoom] = useState<any>(false);
    const [date, setDate] = useState('');
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [currentBooking, setCurrentBooking] = useState(newBookingInfo);

    const [companyCurrentPage, setCompanyCurrentPage] = useState(0);
    const [companySearchResults, setCompanySearchResults] = useState<CompanyDto[]>([]);
    const [companySearchTerm, setCompanySearchTerm] = useState('');
    const [companyMaxResults, setCompanyMaxResults] = useState(false);
    const [companyOptions, setCompanyOptions] = useState<SelectCustomOption[]>([]);
    const [selectedCompanyOption, setSelectedCompanyOption] = useState<SelectCustomOption[]>([]);
    const [selectedCompanyId, setSelectedCompanyId] = useState<string>('');

    const [userOptions, setUserOptions] = useState<SelectCustomOption[]>([]);
    const [selectedUserOption, setSelectedUserOption] = useState<SelectCustomOption[]>([]);
    const [selectedUserId, setSelectedUserId] = useState<string>('');
    const [userCurrentPage, setUserCurrentPage] = useState(0);
    const [userSearchResults, setUserSearchResults] = useState<User[]>([]);
    const [userSearchTerm, setUserSearchTerm] = useState('');
    const [userMaxResults, setUserMaxResults] = useState(false);

    const [amenityDetails, setAmenityDetails] = useState<AmenityDto | null>();
    const [selectedCompanyIsCoworking, setSelectedCompanyIsCoworking] = useState(false);

    const [currentBookingIsErrored, setCurrentBookingIsErrored] = useState<boolean>(false);
    //booking recurrence
    const [fetchedRecurrencePresets, setFetchedRecurrencePresets] = useState(false);
    const [selectedRecurrenceTypeIndex, setSelectedRecurrenceTypeIndex] = useState<string>(
        t('Booking.does_not_repeat')
    );
    const [availableRecurrenceTypeOptions, setAvailableRecurrenceTypeOptions] = useState<
        SelectCustomOption[]
    >([]);
    const [availableBookingRecurrencePresets, setAvailableBookingRecurrencePresets] = useState<
        BookingRecurrencePresetDto[] | null
    >(null);
    const [bookingRecurrenceDurationOptions, setBookingRecurrenceDurationOptions] = useState<
        SelectCustomOption[]
    >([]);
    const [selectedRecurrenceDuration, setSelectedRecurrenceDuration] = useState<string>();
    const [selectedCustomNumberOfOccurences, setSelectedCustomNumberOfOccurences] = useState<
        string
    >();
    const [customNumberOfOccurencesOptions, setCustomNumberOfOccurencesOptions] = useState<
        SelectCustomOption[]
    >([]);

    const [conflictModalVisible, setConflictModalVisible] = useState(false);
    const [bookingsWithRecurrenceErrors, setBookingsWithRecurrenceErrors] = useState<
        BookingPreviewDto[]
    >([]);
    const [recurringBookingExceptions, setRecurringBookingExceptions] = useState<
        CreateBookingRecurrenceExceptionDto[] | null
    >(null);
    const [recurrenceBlocksResize, setRecurrenceBlocksResize] = useState(false);

    const resetUserSearch = (): void => {
        setUserCurrentPage(0);
        setUserSearchResults([]);
        setUserSearchTerm('');
        setUserMaxResults(false);
    };

    const handleCompanySelect = (option: SelectCustomOption): void => {
        setSelectedCompanyId(option.value);
        setSelectedCompanyOption([option]);
        const companyDetails = companySearchResults.find((company) => company.id === option.value);
        setSelectedCompanyIsCoworking(companyDetails ? companyDetails.isCoworking ?? false : false);
        setSelectedUserId('');
        resetUserSearch();
    };

    useEffect(() => {
        const fetchAmenityData = async () => {
            const data = await amenityService.getAmenity(resource[0].id);
            setAmenityDetails(data);
        };
        fetchAmenityData();
        if (!isEditing && newBookingInfo) {
            setStartDate(newBookingInfo.bookingStart);
            setEndDate(newBookingInfo.bookingEnd);
            setConferenceRoom(newBookingInfo.bookingAmenity[0]);
            setCurrentBooking(newBookingInfo);
        } else if (booking) {
            setCurrentBooking({
                bookingId: booking?.id,
                bookingStart: booking?.periodStart,
                bookingEnd: booking?.periodEnd,
                conferenceRoomId: booking?.amenity?.id,
                ...booking,
            });
            setStartDate(booking.periodStart);
            setEndDate(booking.periodEnd);
            setConferenceRoom(booking.bookingAmenity[0]);
            setSelectedCompanyId(booking.company?.id ?? '');
            setSelectedUserId(booking.organizer?.id ?? '');
        }
        setDate(selectedDate.format('dddd MMMM D, YYYY'));
    }, [newBookingInfo, booking, amenityService, isEditing, resource, selectedDate]);

    const resetCompanySearch = (): void => {
        setCompanyCurrentPage(0);
        setCompanySearchResults([]);
        setCompanySearchTerm('');
        setCompanyMaxResults(false);
    };

    const searchCompanies = async (page: number, searchTerm: string): Promise<CompanyModel[]> => {
        const args = {
            pageSize: pageSize,
            page: page,
            searchTerm: searchTerm,
            isTownhall: resource[0].isTownhallExclusive ? true : null,
            locationIds: resource[0].isTownhallExclusive ? [locationId] : null,
        };

        const [results, totalItemCount] = await companyService.getCompanies(args);

        if (results.length + pageSize * page >= totalItemCount) {
            setCompanyMaxResults(true);
        }

        return results;
    };

    const searchUsers = async (
        page: number,
        searchTerm: string,
        companyId: string
    ): Promise<User[]> => {
        const args = {
            pageSize: pageSize,
            page: page,
            searchTerm: searchTerm,
            companyIds: companyId ? [companyId] : undefined,
            hasManagementRole: false,
        };

        const [items, totalItemCount] = await memberService.getMembers(args);

        if (items.length + pageSize * page >= totalItemCount) {
            setUserMaxResults(true);
        }

        return items;
    };

    const debounceCompanySearch = useRef(
        debounce((page: number, searchTerm: string) => {
            searchCompanies(page, searchTerm).then((results) => {
                setCompanySearchResults((prevResults) => [...prevResults, ...results]);
            });
        }, DEBOUNCE_DELAY)
    );

    const debounceUserSearch = useRef(
        debounce((page: number, searchTerm: string, companyId: string) => {
            searchUsers(page, searchTerm, companyId).then((results) => {
                setUserSearchResults((prevResults) => [...prevResults, ...results]);
            });
        }, DEBOUNCE_DELAY)
    );

    useEffect(() => {
        debounceCompanySearch.current(companyCurrentPage, companySearchTerm);
    }, [companySearchTerm, companyCurrentPage]);

    useEffect(() => {
        debounceUserSearch.current(userCurrentPage, userSearchTerm, selectedCompanyId);
    }, [userSearchTerm, userCurrentPage, selectedCompanyId]);

    const handleCompanyKeywordsChange = useCallback((value: string): void => {
        resetCompanySearch();
        setCompanySearchTerm(value);
    }, []);

    const handleUserKeywordsChange = useCallback((value: string): void => {
        resetUserSearch();
        setUserSearchTerm(value);
    }, []);

    const handleCompanyMenuScrollToBottom = () => {
        if (!companyMaxResults) {
            setCompanyCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handleUserMenuScrollToBottom = () => {
        if (!userMaxResults) {
            setUserCurrentPage((prevPage) => prevPage + 1);
        }
    };

    useEffect(() => {
        const searchResults = companySearchResults?.map(
            (x) =>
                ({
                    value: x?.id,
                    label: x?.name,
                    imageUrl: x?.imageUrl,
                    badge: undefined,
                } as SelectCustomOption)
        );
        const merged = mergeSelectedOptionsWithSearchResults(searchResults, selectedCompanyOption);
        setCompanyOptions(merged);
    }, [companySearchResults, selectedCompanyOption]);

    useEffect(() => {
        const searchResults = userSearchResults?.map(
            (x) =>
                ({
                    value: x?.id,
                    label: `${x?.firstName} ${x?.lastName}`,
                    content: x?.contactInfo?.email,
                    imageUrl: x?.imageUrl,
                    badge: undefined,
                } as SelectCustomOption)
        );
        const merged = mergeSelectedOptionsWithSearchResults(searchResults, selectedUserOption);
        setUserOptions(merged);
    }, [userSearchResults, selectedUserOption]);

    const handleNoteChange = (value: string): void => {
        form.setFieldsValue({ note: value });
    };

    const confirmRecurrenceReset = async (): Promise<boolean> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <AmenityIcon />,
                title: t(`Booking.current_recurrence_confirm_title`),
                message: t(`Booking.current_recurrence_confirm_message`),
                positiveText: t('yes'),
                negativeText: t('no'),
            }))
        )
            return false;
        return true;
    };

    const resetRecurrence = (): void => {
        setSelectedRecurrenceTypeIndex(t('Booking.does_not_repeat'));
        setSelectedRecurrenceDuration(undefined);
        setSelectedCustomNumberOfOccurences(undefined);
    };

    const onRecurrenceBlockedResize = async (): Promise<void> => {
        if (await confirmRecurrenceReset()) {
            setRecurrenceBlocksResize(false);
            resetRecurrence();
        }
    };

    const handleTimeCellClick = async (e: any, bookingInformation: any, period: any) => {
        if (
            selectedRecurrenceTypeIndex === t('Booking.does_not_repeat') ||
            (await confirmRecurrenceReset())
        ) {
            let bookingInfo = bookingInformation;
            if (currentBooking.id) {
                bookingInfo = {
                    ...currentBooking,
                    ...bookingInformation,
                    bookingId: currentBooking.id,
                };
            }

            setCurrentBooking({ ...bookingInfo });
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            getRecurrencePresets(resource[0].id, bookingInfo.bookingStart, bookingInfo.bookingEnd);
            resetRecurrence();
        }
    };

    useEffect(() => {
        //set if current booking is conflicting with another in the calendar
        if (currentBooking) {
            const resourcePeriods = resource[0]?.periods;
            const bookedPeriods = resourcePeriods?.filter(
                (period: any) =>
                    period.unavailabilityReason ===
                    DailyBookingSummaryPeriodUnavailabilityReasonDto.Booked
            );

            let currentBookingIsErrored = false;
            if (bookedPeriods) {
                bookedPeriods.forEach((period: any) => {
                    if (
                        currentBooking.bookingId &&
                        currentBooking.bookingId === period.booking?.id
                    ) {
                        return;
                    }
                    const bookedPeriodStart = moment(period?.start);
                    const bookedPeriodEnd = moment(period?.end);
                    const currentStart = moment(currentBooking?.bookingStart);
                    const currentEnd = moment(currentBooking?.bookingEnd);
                    if (
                        currentStart.isSame(bookedPeriodStart) ||
                        currentStart.isBetween(bookedPeriodStart, bookedPeriodEnd) ||
                        (currentStart.isSameOrBefore(bookedPeriodStart) &&
                            currentEnd.isAfter(bookedPeriodStart))
                    ) {
                        currentBookingIsErrored = true;
                        return;
                    }
                });
            }
            setCurrentBookingIsErrored(currentBookingIsErrored);
        }
    }, [currentBooking, resource]);

    const handleBookingResize = async ({ formatedBookingStart, formatedBookingEnd }: any) => {
        if (
            selectedRecurrenceTypeIndex === t('Booking.does_not_repeat') ||
            (await confirmRecurrenceReset())
        ) {
            const booking: any = currentBooking;
            const start = formatedBookingStart
                ? formatedBookingStart
                : momentTimezone(booking.bookingStart).tz(timezone).format();
            const bookingEnd = formatedBookingEnd
                ? formatedBookingEnd
                : momentTimezone(booking.bookingEnd).tz(timezone).format();
            if (booking !== undefined) {
                booking.bookingStart = start;
                booking.bookingEnd = bookingEnd;
                setCurrentBooking({ ...booking });
            }
            resetRecurrence();
        }
    };

    useEffect(() => {
        //create recurrence duration dropdown options
        if (availableBookingRecurrencePresets) {
            if (selectedRecurrenceTypeIndex === t('Booking.does_not_repeat')) return;

            setBookingRecurrenceDurationOptions(
                availableBookingRecurrencePresets[
                    parseInt(selectedRecurrenceTypeIndex)
                ].presetDurations
                    ?.map(
                        (preset) =>
                            ({
                                value: preset?.occurrenceCount?.toString(),
                                label: preset?.description,
                            } as SelectCustomOption)
                    )
                    .concat([
                        {
                            value: t('Booking.select_number_occurences'),
                            label: t('Booking.select_number_occurences'),
                        } as SelectCustomOption,
                    ]) ?? []
            );
        }
    }, [availableBookingRecurrencePresets, t, selectedRecurrenceTypeIndex]);

    useEffect(() => {
        //create number or occurences dropdown options
        if (
            selectedRecurrenceDuration === t('Booking.select_number_occurences') &&
            availableBookingRecurrencePresets
        ) {
            const maxAmount =
                availableBookingRecurrencePresets[parseInt(selectedRecurrenceTypeIndex)]
                    .maxOccurrences;
            if (!maxAmount) return;
            const options = [];
            let i = 2;
            while (i < maxAmount + 1) {
                options.push({
                    label: t('Booking.index_occurences', { param1: i }),
                    value: i.toString(),
                } as SelectCustomOption);
                i++;
            }
            setCustomNumberOfOccurencesOptions(options);
        }
    }, [
        selectedRecurrenceDuration,
        selectedRecurrenceTypeIndex,
        availableBookingRecurrencePresets,
        t,
    ]);

    const getRecurrencePresets = useCallback(
        async (amenityId: string, periodStart: string, periodEnd: string): Promise<void> => {
            try {
                globalLoadingStore.addLoading();
                const response = await bookingService.getBookingRecurrencePresets({
                    amenityId,
                    periodStart,
                    periodEnd,
                } as GetBookingRecurrencePresetsRequestDto);
                if (response?.items) {
                    const items = response.items
                        .filter((recurrence) => recurrence !== null)
                        .map((recurrence) => recurrence!);
                    setAvailableBookingRecurrencePresets(items);
                    setAvailableRecurrenceTypeOptions(
                        items
                            .map(
                                (preset, i) =>
                                    ({
                                        label: preset.description,
                                        value: i.toString(),
                                    } as SelectCustomOption)
                            )
                            .concat([
                                {
                                    label: t('Booking.does_not_repeat'),
                                    value: t('Booking.does_not_repeat'),
                                } as SelectCustomOption,
                            ])
                    );
                }
            } catch (error) {
                if (!error.treated) {
                    toastStore.displayError(error);
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        },
        [globalLoadingStore, bookingService, toastStore, t]
    );

    useEffect(() => {
        if (resource && currentBooking && !fetchedRecurrencePresets) {
            getRecurrencePresets(
                resource[0].id,
                currentBooking.bookingStart,
                currentBooking.bookingEnd
            );
            setFetchedRecurrencePresets(true);
        }
    }, [resource, getRecurrencePresets, currentBooking, fetchedRecurrencePresets]);

    const onResizeStopped = (): void => {
        if (resource && currentBooking) {
            getRecurrencePresets(
                resource[0].id,
                currentBooking.bookingStart,
                currentBooking.bookingEnd
            );
        }
    };

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        setCurrentBooking(undefined);
        onComplete(success);
        form.resetFields();
        resetErrors();
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <AmenityIcon />,
                title: t(`Booking.book_a_room_confirm_title`),
                message: t(`Booking.book_a_room_confirm_message`),
                positiveText: t(`Booking.book_a_room_confirm_positive${isEditing ? '_edit' : ''}`),
                negativeText: t(`Booking.book_a_room_confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const createBooking = async (values: Store): Promise<void> => {
        const validateModel = {
            organizerUserId: selectedUserId,
            periodStart: currentBooking.bookingStart,
            periodEnd: currentBooking.bookingEnd,
            note: values.note,
            amenityId: currentBooking.bookingAmenity[0].id,
            companyId: selectedCompanyId,
            recurrenceType: selectedRecurrenceTypeIndex,
            recurrenceDuration: selectedRecurrenceDuration,
            occurenceCount: selectedCustomNumberOfOccurences,
        };
        if (!(await validateForm(validateModel, false))) return;

        const recurrenceInfo =
            validateModel.recurrenceType === t('Booking.does_not_repeat')
                ? undefined
                : ({
                      ...availableBookingRecurrencePresets?.[parseInt(selectedRecurrenceTypeIndex)],
                      occurrenceCount:
                          selectedRecurrenceDuration === t('Booking.select_number_occurences')
                              ? validateModel.occurenceCount
                              : selectedRecurrenceDuration,
                      exceptions: recurringBookingExceptions,
                  } as CreateBookingRecurrenceInfoDto);

        const data: CreateBookingRequestDto = {
            organizerUserId: selectedUserId,
            periodStart: currentBooking.bookingStart,
            periodEnd: currentBooking.bookingEnd,
            note: values.note,
            amenityId: currentBooking.bookingAmenity[0].id,
            enableUserRestrictions: false,
            recurrence: recurrenceInfo,
        };
        try {
            globalLoadingStore.addLoading();
            if (recurrenceInfo !== undefined) {
                const response = await bookingService.getBookingPreview(data);
                if (
                    response &&
                    response.bookings?.some(
                        (booking) => !booking?.isAvailable && !booking?.isCancelled
                    )
                ) {
                    setBookingsWithRecurrenceErrors(
                        response.bookings
                            .filter((booking) => booking !== null && !booking?.isAvailable)
                            .map((booking) => booking!)
                    );
                    setConflictModalVisible(true);
                    return;
                } else {
                    await bookingService.book(data);
                }
            } else {
                await bookingService.book(data);
            }
            toastStore.toast({
                type: 'success',
                messageKey: `Booking.booking_created_success`,
            });
            dismiss(true);
        } catch (error) {
            if (!error.treated) {
                toastStore.displayError(error);
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const editBooking = async (values: Store): Promise<void> => {
        const oldValues = booking;
        const data = {
            bookingId: booking.id,
            note: values.note || oldValues.note,
            periodStart: currentBooking.bookingStart,
            periodEnd: currentBooking.bookingEnd,
            companyId: selectedCompanyId,
            organizerUserId: oldValues.organizer.id,
        };

        if (!(await validateForm(data, false))) return;

        try {
            globalLoadingStore.addLoading();
            await bookingService.updateBooking(data.bookingId, data);
            toastStore.toast({
                type: 'success',
                messageKey: `Booking.booking_created_success`,
            });
            dismiss(true);
        } catch (error) {
            if (!error.treated) {
                toastStore.displayError(error);
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const submit = async (values: Store): Promise<void> => {
        if (currentBookingIsErrored) return;

        if (currentBooking?.id) {
            await editBooking(values);
        } else {
            await createBooking(values);
        }
    };

    const cancelBooking = async (withRefund: boolean): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Booking />,
                title: t(`Booking.cancel_confirm_title`),
                message: t(`Booking.cancel_confirm_message`),
                positiveText: t(`Booking.cancel_confirm_positive`),
                negativeText: t(`Booking.cancel_confirm_negative`),
            }))
        )
            return;

        try {
            globalLoadingStore.addLoading();

            await bookingService.cancelBooking(currentBooking.id, {});

            globalLoadingStore.removeLoading();

            // success modal for cancellation
            await confirmationModalStore.confirm({
                icon: <Booking />,
                title: t(`Booking.cancel_success_title`),
                message: t(`Booking.cancel_success_message`),
                positiveText: t(`ok`),
            });

            dismiss(true);
        } catch (error) {
            if (error.response?.data?.error === 'E011003') {
                toastStore.toast({
                    type: 'error',
                    messageKey: `Booking.booking_canceled_past_error`,
                });
            } else if (!error.treated) {
                toastStore.displayError(error);
            }
        }
    };

    const isPastBooking = (): boolean => {
        return moment(endDate).isBefore();
    };

    return (
        <Modal
            visible={visible}
            title={isEditing ? t('Booking.book_an_amenity_edit') : t('Booking.book_an_amenity')}
            className="FormModal BookingModal"
            closeIcon={<Close />}
            width={960}
            footer={null}
            onCancel={(): Promise<void> => exit()}
            maskClosable={false}
            centered
        >
            <Form layout="vertical" onFinish={submit} form={form}>
                <Row gutter={formGutter}>
                    <Col span={12}>
                        <SimpleList title={t('Booking.booking_amenity_category')}>
                            <List.Item>
                                <List.Item.Meta
                                    title={
                                        <Row>
                                            <Col span={12}>{amenityDetails?.category?.name}</Col>
                                        </Row>
                                    }
                                    avatar={
                                        <ImageWithPlaceholder
                                            width="32"
                                            height="32"
                                            imgSrc={amenityDetails?.category?.imageUrl}
                                            defaultImg={<AmenityIcon fill={theme['white']} />}
                                        />
                                    }
                                />
                            </List.Item>
                        </SimpleList>
                        <SimpleList
                            title={t('Amenities.amenity_label')}
                            className="conference-room"
                        >
                            {amenityDetails && (
                                <List.Item>
                                    <List.Item.Meta
                                        title={
                                            <Row>
                                                <Col span={12}>{amenityDetails.name}</Col>
                                                <Col span={12} style={{ textAlign: 'right' }}>
                                                    <div className="credits">
                                                        {selectedCompanyIsCoworking
                                                            ? t(
                                                                  'Booking.book_a_room_credits_per_block',
                                                                  {
                                                                      param1:
                                                                          amenityDetails.creditsPerBlock,
                                                                  }
                                                              )
                                                            : t(
                                                                  'Booking.book_a_room_price_per_block',
                                                                  {
                                                                      param1:
                                                                          amenityDetails.pricePerBlock,
                                                                  }
                                                              )}{' '}
                                                        / {amenityDetails.minutesPerBlock}{' '}
                                                        {t('Booking.book_a_room_per_min')}
                                                    </div>
                                                </Col>
                                            </Row>
                                        }
                                        avatar={
                                            <ImageWithPlaceholder
                                                width="32"
                                                height="32"
                                                imgSrc={conferenceRoom?.imageUrl}
                                                defaultImg={<AmenityIcon fill={theme['white']} />}
                                            />
                                        }
                                    />
                                </List.Item>
                            )}
                        </SimpleList>

                        {(resource[0].isTownhallExclusive || resource[0].isCoworking) && (
                            <SimpleList title={t('type')} className="type">
                                <List.Item>
                                    <List.Item.Meta
                                        title={
                                            <Row>
                                                <Col span={12}>
                                                    {resource[0].isTownhallExclusive
                                                        ? t('townhall')
                                                        : t('coworking')}
                                                </Col>
                                            </Row>
                                        }
                                        avatar={
                                            <Icon
                                                iconName={
                                                    resource[0].isTownhallExclusive
                                                        ? 'Townhall'
                                                        : 'Coworking'
                                                }
                                                fill={theme['primary-color']}
                                                width={32}
                                                height={32}
                                            />
                                        }
                                    />
                                </List.Item>
                            </SimpleList>
                        )}

                        <SimpleList title={t('date')} className="booking-date">
                            {startDate && (
                                <List.Item>
                                    <List.Item.Meta
                                        title={date}
                                        avatar={<Calendar fill={theme['primary-color']} />}
                                    />
                                </List.Item>
                            )}
                        </SimpleList>

                        {booking?.id && booking.company && (
                            <SimpleList title={t('Company.company')}>
                                <List.Item>
                                    <List.Item.Meta
                                        title={
                                            <Row>
                                                <Col span={12}>{booking.company.name}</Col>
                                            </Row>
                                        }
                                        avatar={
                                            <ImageWithPlaceholder
                                                width="32"
                                                height="32"
                                                imgSrc={booking.company?.imageUrl}
                                                defaultImg={<Company fill={theme['white']} />}
                                            />
                                        }
                                    />
                                </List.Item>
                            </SimpleList>
                        )}

                        {booking === undefined && (
                            <ValidatedFormItem
                                errors={errors}
                                name="companyId"
                                label={t('Company.company')}
                                required
                            >
                                <SelectCustom
                                    options={companyOptions}
                                    defaultImg={<UserIcon fill={theme['white']} />}
                                    strongLabel={true}
                                    placeholder={t('SelectCustom.placeholder_company')}
                                    selected={[selectedCompanyId]}
                                    onChange={handleCompanySelect}
                                    onKeywordsChange={handleCompanyKeywordsChange}
                                    onMenuScrollToBottom={handleCompanyMenuScrollToBottom}
                                    hideSelectedOptions={false}
                                />
                            </ValidatedFormItem>
                        )}

                        {booking?.id && booking.organizer && (
                            <SimpleList title={t('Booking.book_a_room_creator')}>
                                <List.Item>
                                    <List.Item.Meta
                                        title={
                                            <Row>
                                                <Col span={12}>
                                                    <span>{`${booking.organizer.firstName} ${booking.organizer.lastName}`}</span>
                                                    {/* <br/> {booking.organize.email} when api ready*/}
                                                </Col>
                                            </Row>
                                        }
                                        avatar={
                                            <ImageWithPlaceholder
                                                width="32"
                                                height="32"
                                                imgSrc={booking.organizer?.imageUrl}
                                                defaultImg={<UserIcon fill={theme['white']} />}
                                            />
                                        }
                                    />
                                </List.Item>
                            </SimpleList>
                        )}

                        {!booking?.id && selectedCompanyId && (
                            <ValidatedFormItem
                                errors={errors}
                                name="organizerUserId"
                                label={t('Booking.book_a_room_creator')}
                                required
                            >
                                <SelectCustom
                                    options={userOptions}
                                    defaultImg={<UserIcon fill={theme['white']} />}
                                    strongLabel={true}
                                    placeholder={t('SelectCustom.placeholder_member')}
                                    onChange={(option: SelectCustomOption): void => {
                                        setSelectedUserId(option.value);
                                        setSelectedUserOption([option]);
                                    }}
                                    onKeywordsChange={handleUserKeywordsChange}
                                    onMenuScrollToBottom={handleUserMenuScrollToBottom}
                                    hideSelectedOptions={false}
                                    selected={[selectedUserId]}
                                />
                            </ValidatedFormItem>
                        )}

                        {!isEditing && (
                            <ValidatedFormItem
                                errors={errors}
                                name="recurrenceType"
                                label={t('Booking.recurrence_type')}
                            >
                                <SelectCustom
                                    className="recurrence-dropdown"
                                    options={availableRecurrenceTypeOptions}
                                    strongLabel={true}
                                    onChange={(option): void => {
                                        setSelectedRecurrenceTypeIndex(option.value);
                                        if (option.value !== t('Booking.does_not_repeat'))
                                            setRecurrenceBlocksResize(true);
                                    }}
                                    hideSelectedOptions={false}
                                    selected={
                                        selectedRecurrenceTypeIndex
                                            ? [selectedRecurrenceTypeIndex]
                                            : [t('Booking.does_not_repeat')]
                                    }
                                />
                            </ValidatedFormItem>
                        )}

                        {!isEditing &&
                            selectedRecurrenceTypeIndex !== t('Booking.does_not_repeat') && (
                                <ValidatedFormItem
                                    errors={errors}
                                    name="recurrenceDuration"
                                    label={t('Booking.recurrence_duration')}
                                    required
                                >
                                    <SelectCustom
                                        className="recurrence-dropdown"
                                        options={bookingRecurrenceDurationOptions}
                                        strongLabel={true}
                                        onChange={(option): void =>
                                            setSelectedRecurrenceDuration(option.value)
                                        }
                                        hideSelectedOptions={false}
                                        selected={
                                            selectedRecurrenceDuration
                                                ? [selectedRecurrenceDuration]
                                                : null
                                        }
                                    />
                                </ValidatedFormItem>
                            )}

                        {!isEditing &&
                            selectedRecurrenceDuration ===
                                t('Booking.select_number_occurences') && (
                                <ValidatedFormItem
                                    errors={errors}
                                    name="occurenceCount"
                                    label={t('Booking.number_of_occurences')}
                                    required
                                >
                                    <SelectCustom
                                        className="recurrence-dropdown"
                                        options={customNumberOfOccurencesOptions}
                                        strongLabel={true}
                                        onChange={(option): void =>
                                            setSelectedCustomNumberOfOccurences(option.value)
                                        }
                                        hideSelectedOptions={false}
                                        selected={
                                            selectedCustomNumberOfOccurences
                                                ? [selectedCustomNumberOfOccurences]
                                                : null
                                        }
                                    />
                                </ValidatedFormItem>
                            )}

                        <ValidatedFormItem
                            errors={errors}
                            name="note"
                            label={t('note')}
                            className="description"
                        >
                            <Input hidden />
                        </ValidatedFormItem>

                        <ReactQuill
                            theme="snow"
                            className="description-quill"
                            value={currentBooking?.note || form.getFieldValue('note') || null}
                            onChange={handleNoteChange}
                        />
                    </Col>
                    <Col span={12}>
                        <FlexibleBooking
                            view="details"
                            classNames="w-100 position-relative"
                            resizable
                            resources={resource}
                            currentBooking={currentBooking}
                            timezone={timezone}
                            onTimeCellClick={handleTimeCellClick}
                            onBookingResize={handleBookingResize}
                            min={0}
                            max={24}
                            zoom={2}
                            isEditing={isEditing}
                            filters={[]}
                            resourcePeriod={resourcePeriod}
                            selectedDate={selectedDate}
                            currentBookingIsErrored={currentBookingIsErrored}
                            recurrenceBlocksResize={recurrenceBlocksResize}
                            onRecurrenceBlockedResize={onRecurrenceBlockedResize}
                            onResizeStopped={onResizeStopped}
                        />
                    </Col>
                </Row>
                <div className="actions d-flex">
                    {isEditing && (
                        <>
                            <Button
                                type="default"
                                className="secondary cancel"
                                htmlType="button"
                                onClick={(): Promise<void> => cancelBooking(false)}
                                disabled={isPastBooking()}
                            >
                                {t('Booking.cancel_booking')}
                            </Button>
                        </>
                    )}
                    <Button
                        type="default"
                        className="secondary negative m-l-auto"
                        htmlType="button"
                        onClick={(): Promise<void> => exit()}
                    >
                        {t('close')}
                    </Button>
                    <Button type="primary" className="positive" htmlType="submit">
                        {t('save')}
                    </Button>
                </div>
            </Form>

            {conflictModalVisible && (
                <BookingConflictModal
                    visible={conflictModalVisible}
                    onComplete={(
                        success: boolean,
                        exceptions: CreateBookingRecurrenceExceptionDto[] | null
                    ): void => {
                        setRecurringBookingExceptions(exceptions);
                        setConflictModalVisible(false);
                    }}
                    amenity={resource[0]}
                    bookingsWithRecurrenceErrors={bookingsWithRecurrenceErrors}
                    timezone={timezone}
                    locationId={locationId}
                    resourcePeriod={resourcePeriod}
                />
            )}
        </Modal>
    );
};

export default React.memo(BookingModal);
