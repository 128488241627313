import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

const { Title } = Typography;

const Account: FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <Title level={4} className="bold">
            { t('Sidebar.menu_item_account') }
        </Title>
    );
};

export default Account;
