import React, { FunctionComponent, useLayoutEffect } from 'react';
import { Layout } from 'antd';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';
import { AuthenticatedLayout } from 'Components/webapp/authenticated-layout';
import { UserHeader } from 'Components/webapp/user-header';
import { AmenityBookings } from './amenity-bookings';
import { Account } from './account';
import { Company } from './company';
import { useStores, useService } from 'Hooks';
import { BaseDataService } from 'Services/BaseDataService';

import './index.less';

const { Content } = Layout;

interface AuthenticatedProps {
    returnUrl: string;
}

const AuthenticatedRoutes: FunctionComponent<AuthenticatedProps> = ({ returnUrl }) => {
    const location = useLocation();
    const { authorizationStore } = useStores();
    const baseDataService = useService(BaseDataService);
    useLayoutEffect(() => {
        // Reset forbidden state on route change.
        authorizationStore.resetForbidden();

        //initialise data the app needs to function
        baseDataService.initBaseData();
    }, [location, authorizationStore, baseDataService]);

    return (
        <AuthenticatedLayout>
            <UserHeader />
            <Content className="AuthenticatedLayout-Content">
                <Switch>
                    <Route exact path="/amenity-bookings">
                        <AmenityBookings />
                    </Route>
                    <Route exact path="/account">
                        <Account />
                    </Route>
                    <Route exact path="/company">
                        <Company />
                    </Route>
                    <Redirect from="/" to="/amenity-bookings" />
                </Switch>
            </Content>
        </AuthenticatedLayout>
    );
};

export default AuthenticatedRoutes;
