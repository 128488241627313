// GENERATED FILE - DO NOT MODIFY
import { CompanyMessageDto } from 'Api/Features/Messages/Dtos/CompanyMessageDto';
import { CreateMessageRequestDto } from 'Api/Features/Messages/Dtos/CreateMessageRequestDto';
import { GetCompanyMessagesRequestDto } from 'Api/Features/Messages/Dtos/GetCompanyMessagesRequestDto';
import { GetCompanyMessagesResponseDto } from 'Api/Features/Messages/Dtos/GetCompanyMessagesResponseDto';
import { GetLocationMessagesRequestDto } from 'Api/Features/Messages/Dtos/GetLocationMessagesRequestDto';
import { GetLocationMessagesResponseDto } from 'Api/Features/Messages/Dtos/GetLocationMessagesResponseDto';
import { GetUserMessagesRequestDto } from 'Api/Features/Messages/Dtos/GetUserMessagesRequestDto';
import { GetUserMessagesResponseDto } from 'Api/Features/Messages/Dtos/GetUserMessagesResponseDto';
import { LocationMessageDto } from 'Api/Features/Messages/Dtos/LocationMessageDto';
import { UpdateUserMessageStatusRequestDto } from 'Api/Features/Messages/Dtos/UpdateUserMessageStatusRequestDto';
import { UserMessageDto } from 'Api/Features/Messages/Dtos/UserMessageDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class MessageProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getUserMessage(userId: string, messageId: string): Promise<UserMessageDto | null> {
        const uri = this.buildUri(
            "/users/{userId}/messages/{messageId}",
            { userId: userId, messageId: messageId },
            null
        );

        const data: UserMessageDto | null = await this.httpClient.get<UserMessageDto | null>(uri);
        return data;
    }

    public async getUserMessages(userId: string, request: GetUserMessagesRequestDto | null): Promise<GetUserMessagesResponseDto | null> {
        const uri = this.buildUri(
            "/users/{userId}/messages",
            { userId: userId },
            { ...request || undefined }
        );

        const data: GetUserMessagesResponseDto | null = await this.httpClient.get<GetUserMessagesResponseDto | null>(uri);
        return data;
    }

    public async getCompanyMessage(companyId: string, messageId: string): Promise<CompanyMessageDto | null> {
        const uri = this.buildUri(
            "/companies/{companyId}/messages/{messageId}",
            { companyId: companyId, messageId: messageId },
            null
        );

        const data: CompanyMessageDto | null = await this.httpClient.get<CompanyMessageDto | null>(uri);
        return data;
    }

    public async getCompanyMessages(companyId: string, request: GetCompanyMessagesRequestDto | null): Promise<GetCompanyMessagesResponseDto | null> {
        const uri = this.buildUri(
            "/companies/{companyId}/messages",
            { companyId: companyId },
            { ...request || undefined }
        );

        const data: GetCompanyMessagesResponseDto | null = await this.httpClient.get<GetCompanyMessagesResponseDto | null>(uri);
        return data;
    }

    public async getLocationMessage(locationId: string, messageId: string): Promise<LocationMessageDto | null> {
        const uri = this.buildUri(
            "/locations/{locationId}/messages/{messageId}",
            { locationId: locationId, messageId: messageId },
            null
        );

        const data: LocationMessageDto | null = await this.httpClient.get<LocationMessageDto | null>(uri);
        return data;
    }

    public async getLocationMessages(locationId: string, request: GetLocationMessagesRequestDto | null): Promise<GetLocationMessagesResponseDto | null> {
        const uri = this.buildUri(
            "/locations/{locationId}/messages",
            { locationId: locationId },
            { ...request || undefined }
        );

        const data: GetLocationMessagesResponseDto | null = await this.httpClient.get<GetLocationMessagesResponseDto | null>(uri);
        return data;
    }

    public async createMessage(request: CreateMessageRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/messages",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreateMessageRequestDto | null, string>(uri, request);
        return data;
    }

    public async setUserMessageStatus(userId: string, messageId: string, request: UpdateUserMessageStatusRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/users/{userId}/messages/{messageId}/status",
            { userId: userId, messageId: messageId },
            null
        );

        await this.httpClient.put<UpdateUserMessageStatusRequestDto | null, void>(uri, request);
    }

    public async setUserMessagesStatus(userId: string, request: UpdateUserMessageStatusRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/users/{userId}/messages/status",
            { userId: userId },
            null
        );

        await this.httpClient.put<UpdateUserMessageStatusRequestDto | null, void>(uri, request);
    }
}