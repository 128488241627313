import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { AmenityProxy } from 'Api/Features/Amenities/AmenityProxy';
import { AmenityDto } from 'Api/Features/Amenities/Dtos/AmenityDto';
import { CreateAmenityRequestDto } from 'Api/Features/Amenities/Dtos/CreateAmenityRequestDto';
import { UpdateAmenityRequestDto } from 'Api/Features/Amenities/Dtos/UpdateAmenityRequestDto';
import { GetAmenitiesRequestDto } from 'Api/Features/Amenities/Dtos/GetAmenitiesRequestDto';
import { GetAmenitiesResponseDto } from 'Api/Features/Amenities/Dtos/GetAmenitiesResponseDto';
import { Amenity } from 'Models/Amenities/Amenity';
import { GetFoodItemsOrderResponseDto } from 'Api/Features/FoodItems/Dtos/GetFoodItemsOrderResponseDto';
import { OrderingMethodDto } from 'Api/Features/General/Dtos/OrderingMethodDto';

@inject(AmenityProxy)
export class AmenityService extends ApiService {
    constructor(private readonly amenityProxy: AmenityProxy) {
        super();
    }

    public async getAmenity(id: string): Promise<Amenity | null> {
        const response: AmenityDto | null = await this.amenityProxy.getAmenity(id);
        return response ? new Amenity(response) : null;
    }

    public async getAmenities(
        request: GetAmenitiesRequestDto
    ): Promise<[Amenity[], number]> {
        const response: GetAmenitiesResponseDto | null = await this.amenityProxy.getAmenities(
            request
        );
        return [
            response?.items?.map((dto: AmenityDto | null) => new Amenity(dto!)) || [],
            response?.totalItemCount || 0,
        ];
    }

    public async deleteAmenity(id: string): Promise<void> {
        await this.amenityProxy.deleteAmenity(id);
    }

    public async createAmenity(request: CreateAmenityRequestDto): Promise<void> {
        await this.amenityProxy.createAmenity(request);
    }

    public async updateAmenity(request: UpdateAmenityRequestDto, id: string): Promise<void> {
        await this.amenityProxy.updateAmenity(id, request);
    }

    public async getAmenitiesOrder(
        id: string
    ): Promise<GetFoodItemsOrderResponseDto | null> {
        return await this.amenityProxy.getAmenitiesOrder(id);
    }

    public async updateAmenitiesOrder(
        id: string,
        method: OrderingMethodDto,
        ids: string[]
    ): Promise<void> {
        await this.amenityProxy.updateAmenitiesOrder(id, { method: method, ids: ids });
    }

    public async getCreditPrice(): Promise<number> {
        const data: number = await this.amenityProxy.getCreditPrice();
        return data;
    }

    public async updateCreditValue(value: number): Promise<void> {
        await this.amenityProxy.updateCreditValue(value);
    }
}
