import { ServiceRequestDto } from 'Api/Features/ServiceRequests/Dtos/ServiceRequestDto';
import { ServiceRequestLocationDto } from 'Api/Features/ServiceRequests/Dtos/ServiceRequestLocationDto';
import { ServiceRequestUserDto } from 'Api/Features/ServiceRequests/Dtos/ServiceRequestUserDto';
import { ServiceRequestCompanyDto } from 'Api/Features/ServiceRequests/Dtos/ServiceRequestCompanyDto';
import { ServiceRequestStatusDto } from 'Api/Features/ServiceRequests/Dtos/ServiceRequestStatusDto';
import { UserDetails } from 'Models/Users/UserDetails';
import { UserDetailsDto } from 'Api/Features/Users/Dtos/UserDetailsDto';
import { Company } from 'Models/Companies/Company';
import { CompanyDto } from 'Api/Features/Companies/Dtos/CompanyDto';

export class ServiceRequest implements ServiceRequestDto {
    constructor(dto: ServiceRequestDto) {
        Object.assign(this, dto);
    }

    id = '';
    description?: string | null;
    location?: ServiceRequestLocationDto | null;
    user?: ServiceRequestUserDto | null;
    company?: ServiceRequestCompanyDto | null;
    sendingDate?: string;
    status?: ServiceRequestStatusDto;

    private _creator: UserDetails | null = null;
    private _company: Company | null = null;

    public getConsoleUrl(locationId: string): string {
        return `/locations/${locationId}/service/${this.id}`;
    }

    get from(): UserDetails | null {
        if (this.user && !this._creator) {
            this._creator = new UserDetails({ ...this.user } as UserDetailsDto);
        }

        return this._creator;
    }

    get fromName(): string {
        const fromUser = this.from;
        return fromUser ? fromUser.name || '' : '';
    }

    public getCompany(): Company | null {
        if (this.company && !this._company) {
            this._company = new Company({ ...this.company } as CompanyDto);
        }

        return this._company;
    }

    get opened(): boolean {
        return !!(this.status && this.status === ServiceRequestStatusDto.Open);
    }

    get closed(): boolean {
        return !!(this.status && this.status === ServiceRequestStatusDto.Closed);
    }
}
