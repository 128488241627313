import React, { FunctionComponent } from 'react';
import { Col, Row } from 'antd';
import classnames from 'classnames';

import './card.less';

const classPrefix = 'Card';

interface CardProps {
    title?: any;
    extra?: any;
    borderType?: 'boxShadow' | 'bordered';
    theme?: 'primary' | 'secondary';
    className?: string | null;
}

const Card: FunctionComponent<CardProps> = ({
    title = null,
    extra = null,
    borderType = 'boxShadow',
    theme = 'secondary',
    className = null,
    children,
}) => {
    return (
        <div
            className={classnames(
                classPrefix,
                className,
                `${classPrefix}-${theme}`,
                `${classPrefix}-${borderType}`,
                {
                    'has-header': title || extra,
                }
            )}
        >
            {title || extra ? (
                <header className="Card-header">
                    <Row align="middle" justify="space-between">
                        <Col>{title}</Col>
                        {!!extra && <Col>{extra}</Col>}
                    </Row>
                </header>
            ) : null}
            <div className="Card-content">{children}</div>
        </div>
    );
};

export default Card;
