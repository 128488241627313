import { MAX_LENGTH_25, MAX_LENGTH_50 } from 'Models/Constants';
import * as yup from 'yup';
const phoneRegex = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const EditUserSchema = yup.object({
    firstName: yup.string().required('Errors.required').max(MAX_LENGTH_50, 'Errors.length_50'),
    lastName: yup.string().required('Errors.required').max(MAX_LENGTH_50, 'Errors.length_50'),
    contactInfo: yup.object().shape({
        //api release v1 does not support email change
        // email: yup.string().email('Errors.email_invalid'),
        phoneNumber: yup.string().optional().matches(phoneRegex, 'Phone number is not valid').max(MAX_LENGTH_25, 'Errors.length_25'),
    }),
});
