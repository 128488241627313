import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { i18nVariant } from 'variant';

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: i18nVariant.en,
        },
        lng: 'en',
        fallbackLng: 'en',

        interpolation: {
            escapeValue: false,
        },
    });
