import React, { FunctionComponent, useState } from 'react';
import { Button, Row, Col, Form, Input, Alert, Spin, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Store } from 'antd/es/form/interface';
import { observer } from 'mobx-react';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { LoginRequestSchema } from 'Schemas';
import { useFormValidation, useService } from 'Hooks';
import { AuthenticationService } from 'Services/AuthenticationService';
import { getDayPeriod } from 'Utils/TimeUtils';
import './index.less';
import { images } from 'variant';

const { Text, Title } = Typography;
const dayPeriod = getDayPeriod();

const Login: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const [errors, validateForm] = useFormValidation(LoginRequestSchema);
    const authenticationService = useService(AuthenticationService);
    const [loginErrorState, setLoginErrorState] = useState();
    const [spinner, setSpinner] = useState(false);

    const history = useHistory();
    const hasResetPassword = history.location.state;
    //clear history state after consuming so that re-render does not keep state.
    window.history.replaceState(null, '');

    async function submit(values: Store): Promise<void> {
        const valid = await validateForm(values, false);
        if (!valid) return;
        try {
            setSpinner(true);
            await authenticationService.initAccessToken(values.username, values.password, false);
        } catch (error) {
            if (error.response && error.response.status === 400)
                setLoginErrorState(t('Account.login_error_app_message'));
            else setLoginErrorState(t('Errors.unexpected_error'));
        } finally {
            setSpinner(false);
        }
    }

    return (
        <>
            <div className="Login-container">
                <div className="Login-header">
                    <img src={images.logoWhite} alt={t('brand_name')} />
                </div>
                <div className="Login-contents">
                    <Row className="Login-greetings" gutter={[0, loginErrorState ? 30 : 40]}>
                        <Col span={18} offset={3}>
                            <Title className="title" level={3}>{t(`greetings_${dayPeriod}`)}!</Title>
                            <Text className="message">{t('Login.login_form_message')}</Text>
                        </Col>
                    </Row>
                    {loginErrorState && (
                        <Row gutter={[0, 40]} justify="center">
                            <Col>
                                <Alert
                                    className="login-error"
                                    message={loginErrorState}
                                    type="error"
                                    showIcon
                                    icon={<FontAwesomeIcon icon={['fas', 'exclamation']} />}
                                />
                            </Col>
                        </Row>
                    )}
                    {hasResetPassword && (
                        <Row gutter={[0, 40]} justify="center">
                            <Col>
                                <Alert
                                    className="password-reset"
                                    message={t('PasswordRecovery.password_email_sent')}
                                    type="success"
                                    showIcon
                                    icon={<FontAwesomeIcon icon={['fas', 'check']} />}
                                />
                            </Col>
                        </Row>
                    )}
                    <Form
                        layout="vertical"
                        className="action-form"
                        hideRequiredMark={true}
                        onFinish={async (values): Promise<void> => {
                            submit(values);
                        }}
                    >
                        <ValidatedFormItem
                            errors={errors}
                            label={t('Login.email')}
                            name="username"
                            required
                        >
                            <Input maxLength={50} />
                        </ValidatedFormItem>

                        <ValidatedFormItem
                            errors={errors}
                            label={t('Login.password')}
                            name="password"
                            required
                        >
                            <Input type="password" maxLength={50} />
                        </ValidatedFormItem>

                        {spinner && <Spin indicator={antIcon} size="large" />}

                        <p className="forgot-password">
                            <Link to="/forgot-password">{t('Login.forgot_password')}</Link>
                        </p>

                        {/* @TODO - Check/ follow how to resolve the findDOMNode warning issue with Ant and Strictmode */}
                        {/* See Open issue here: https://github.com/ant-design/ant-design/issues/22493 */}

                        <Button type="primary" htmlType="submit" className="login-btn">
                            {t('Account.login')}
                        </Button>
                    </Form>
                </div>
            </div>
        </>
    );
});

export default Login;
