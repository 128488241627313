import React, { FunctionComponent } from 'react';

import './reset-password-form.less';
import { ActionMessage } from 'Components/webapp-actions-layout';
import { Button, Checkbox, Form, Input } from 'antd';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useTranslation } from 'react-i18next';
import { useFormValidation } from 'Hooks';
import { ResetPasswordSchema } from 'Schemas';
import { Store } from 'antd/es/form/interface';
import { ConfirmAccountSchema } from 'Schemas/ResetPasswordSchema';

export interface ResetPasswordFormValues {
    password: string;
    confirmPassword: string;
}

interface ResetPasswordFormProps {
    message: string;
    submitText: string;
    isAccountConfirm: boolean;
    onSubmit: (values: ResetPasswordFormValues) => void;
}

const externalLink = (url: string, text: string): string =>
    `<a href="${url}" target="_blank" rel="noopener noreferrer">${text}</a>`;

const ResetPasswordForm: FunctionComponent<ResetPasswordFormProps> = ({
    message,
    submitText,
    isAccountConfirm,
    onSubmit,
}) => {
    const { t } = useTranslation();
    const [errors, validateForm] = useFormValidation(
        isAccountConfirm ? ConfirmAccountSchema : ResetPasswordSchema
    );

    const submit = async (values: Store) => {
        if (!(await validateForm(values, false))) return;
        onSubmit({ password: values.password, confirmPassword: values.confirmPassword });
    };

    return (
        <div className="ResetPasswordForm">
            <ActionMessage>{message}</ActionMessage>
            <Form layout="vertical" className="action-form" onFinish={submit}>
                <ValidatedFormItem
                    errors={errors}
                    label={t('Login.password')}
                    name="password"
                    required
                >
                    <Input type="password" maxLength={50} />
                </ValidatedFormItem>

                <ValidatedFormItem
                    errors={errors}
                    label={t('User.reset_confirm_password')}
                    name="confirmPassword"
                    required
                >
                    <Input type="password" maxLength={50} />
                </ValidatedFormItem>
                {isAccountConfirm && (
                    <ValidatedFormItem
                        errors={errors}
                        name="acceptTerms"
                        valuePropName="checked"
                        className="checkbox"
                        label="&nbsp;"
                    >
                        <Checkbox className="userAgreement">
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t('consent_terms_and_policy', {
                                        param1: externalLink(
                                            t('Account.privacy_policy_url'),
                                            t('Account.privacy_policy')
                                        ),
                                        param2: externalLink(
                                            t('Account.terms_of_use_url'),
                                            t('Account.terms_of_use')
                                        ),
                                    }),
                                }}
                            />
                        </Checkbox>
                    </ValidatedFormItem>
                )}

                <div className="submit-container">
                    <Button type="primary" htmlType="submit">
                        {submitText}
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default ResetPasswordForm;
