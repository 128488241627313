import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const CheckMark: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    d="M1.87142 14.7404C1.41785 14.3156 0.711899 14.3456 0.294648 14.8073C-0.122602 15.269 -0.0931533 15.9876 0.360425 16.4123L8.14313 23.7C8.66517 24.1888 9.49691 24.0643 9.85859 23.4432L22.5105 1.71527C22.8248 1.1756 22.6497 0.478802 22.1196 0.158929C21.5894 -0.160945 20.9049 0.0172349 20.5906 0.556904L8.64075 21.0791L1.87142 14.7404Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default CheckMark;
