import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Address: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.0428 13.754H2.71883V5.35562H18.0428L20.4264 9.55397L18.0428 13.754ZM7.50732 22.9315H12.738V14.6729H7.50732V22.9315ZM7.50732 4.43671H12.738V0.918904H7.50732V4.43671ZM21.3535 9.32712L18.7102 4.66849C18.628 4.52548 18.4752 4.43671 18.3108 4.43671H13.6569V0.460274C13.6569 0.207123 13.4514 0 13.1983 0H7.04869C6.7939 0 6.58842 0.207123 6.58842 0.460274V4.43671H2.25856C2.00541 4.43671 1.79993 4.64219 1.79993 4.89534V14.2126C1.79993 14.4674 2.00541 14.6729 2.25856 14.6729H6.58842V23.3918C6.58842 23.6449 6.7939 23.8504 7.04869 23.8504H13.1983C13.4514 23.8504 13.6569 23.6449 13.6569 23.3918V14.6729H18.3108C18.4752 14.6729 18.628 14.5841 18.7102 14.4395L21.3535 9.78082C21.4341 9.6411 21.4341 9.46849 21.3535 9.32712Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Address;
