import { UserDetailsDto } from 'Api/Features/Users/Dtos/UserDetailsDto';
import { AddressDto } from 'Api/Features/General/Dtos/AddressDto';
import { ContactInfoDto } from 'Api/Features/General/Dtos/ContactInfoDto';
import { CompanyRoleDto } from 'Api/Features/Companies/Dtos/CompanyRoleDto';
import { UserLocationDto } from 'Api/Features/Users/Dtos/UserLocationDto';
import { UserStatusDto } from 'Api/Features/Users/Dtos/UserStatusDto';
import { ManagementRoleWithContextDto } from 'Api/Features/Users/Dtos/ManagementRoleWithContextDto';
import { Address } from 'Models/Contact/Address';
import { htmlContainsText } from 'Utils';
import { StatusBadgeStatus } from 'Components/status-badge/status-badge';
import { UserCompany } from './UserCompany';

export class UserDetails implements UserDetailsDto {
    constructor(dto: UserDetailsDto) {
        Object.assign(this, dto);
    }
    id = '';
    firstName: string | null = null;
    lastName: string | null = null;
    birthDate: string | null = null;
    introduction: string | null = null;
    jobPosition: string | null = null;
    imageUrl: string | null = null;
    status?: UserStatusDto;
    contactInfo: ContactInfoDto | null = null;
    address: AddressDto | null = null;
    company: UserCompany | null = null;
    companyRoles: CompanyRoleDto[] | null = null;
    isConfirmed = false;
    location: UserLocationDto | null = null;
    locations: UserLocationDto[] | null = null;
    managementRoles: Array<ManagementRoleWithContextDto | null> | null = null;
    isPrivate?: boolean;

    private _address: Address | null = null;

    get introductionEmpty(): boolean {
        return !htmlContainsText(this.introduction);
    }

    get mainAddress(): Address | null {
        if (this.address && !this._address) {
            this._address = new Address(this.address);
        }
        return this._address;
    }

    get name(): string | null {
        if (this.firstName && this.lastName) return `${this.firstName} ${this.lastName}`;
        if (this.firstName) return this.firstName;
        if (this.lastName) return this.lastName;
        return null;
    }

    get email(): string | null | undefined {
        return this.contactInfo?.email;
    }

    get isActive(): boolean {
        return this.status === UserStatusDto.Active;
    }

    get statusBadgeStatus(): StatusBadgeStatus | undefined {
        if (this.status === UserStatusDto.Active) {
            return StatusBadgeStatus.Active;
        } else if (this.status === UserStatusDto.Deactivated) {
            return StatusBadgeStatus.Deactivated;
        }
        return undefined;
    }

    get companyRole(): string {
        return this.companyRoles ? this.companyRoles[0] : 'Member';
    }

    get consoleUrl(): string {
        return `/users/${this.id}/profile`;
    }

    get consoleTeamUrl(): string {
        return this.company ? `/companies/${this.company.id}/team/${this.id}` : '';
    }

    get consoleManagementUrl(): string {
        return `/staff-accesses/${this.id}`;
    }
}
