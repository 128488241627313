/**
 * @IMPORTANT
 * DO NOT EDIT THIS FILE. It is generated at build-time, and it is git-ignored. If you want to modify
 * the contents of this file, edit "scripts/generate-variant.js" instead.
 */

import merge from 'lodash.merge';

// Import fonts.
import './Style/webapp/heyday/fonts';

// Import variant overrides
import './Style/webapp/heyday/variant-overrides.less';

// Import variant's strings.
import en from './i18n/webapp/en.json';
import enVariant from './i18n/webapp/en_heyday.json';
import enPrivacyPolicy from './i18n/webapp/heyday/en/privacy-policy.html';
import enTermsOfUse from './i18n/webapp/heyday/en/terms-of-use.html';

// Import variant-specific images.
import logoColor from './Assets/Images/webapp/heyday/logo-color.svg';
import logoWhite from './Assets/Images/webapp/heyday/logo-white.svg';
import logoSmallWhite from './Assets/Images/webapp/heyday/logo-small-white.svg';
import logoVertical from './Assets/Images/webapp/heyday/logo-vertical.svg';
import homeHeader from './Assets/Images/webapp/heyday/home-header.jpg';
import listSectionHeader from './Assets/Images/webapp/heyday/list-section-header.jpg';
import usersHeader from './Assets/Images/webapp/heyday/users-header.jpg';
import anonymousBackground from './Assets/Images/webapp/heyday/login-page.jpg';
import locationsHeader from './Assets/Images/webapp/heyday/locations-header.jpg';
import logoBlack from './Assets/Images/webapp/heyday/logo-black.svg';
import grayPineapple from './Assets/Images/webapp/heyday/grey-pineapple.svg';
// # @TODO - Enlever ça partout, c'est un placeholder
import androidAppleApps from './Assets/Images/webapp/heyday/android-apple-apps.svg';
import settingsHeader from './Assets/Images/heyday/settings-header.png';
import webAppHomeHeader from './Assets/Images/webapp/heyday/webapp-home-header.png';
import utilitiesBackground from './Assets/Images/webapp/heyday/background-utilities-layout.jpg';
import verdocsLogo from './Assets/Images/webapp/heyday/VerdocsLogo.png';
import bbotLogo from './Assets/Images/webapp/heyday/bbotLogo.svg';
import pineappleBlack from './Assets/Images/webapp/heyday/pineapple-black.svg';

// Build legal pages Map
interface LegalPages {
    privacyPolicy: string;
    termsOfUse: string;
}

const legalPages: Map<string, LegalPages> = new Map();
legalPages.set('en', {
    privacyPolicy: enPrivacyPolicy,
    termsOfUse: enTermsOfUse,
});

// Expose variant specifics.
// Merge variant into base strings.
export const i18nVariant = { en: merge(en, enVariant) };
export { legalPages };
export const images = {
    logoColor,
    logoWhite,
    logoSmallWhite,
    logoVertical,
    homeHeader,
    listSectionHeader,
    usersHeader,
    anonymousBackground,
    locationsHeader,
    logoBlack,
    grayPineapple,
    androidAppleApps,
    settingsHeader,
    webAppHomeHeader,
    utilitiesBackground,
    verdocsLogo,
    bbotLogo,
    pineappleBlack,
};
export const theme = {
    'font-family': "'Open Sans', sans-serif",
    'heading-font-family': "'Open Sans', sans-serif",
    'font-size-base': '16px',
    'design-s2021-pure-black': '#000000',
    'design-s2021-pure-white': '#ffffff',
    'design-s2021-success-900': '#17361c',
    'design-s2021-success-500': '#4cb25d',
    'design-s2021-success-100': '#e4f0e6',
    'design-s2021-alert-900': '#4b0f01',
    'design-s2021-alert-500': '#ff453a',
    'design-s2021-alert-100': '#ffe7e5',
    'design-s2021-heyday-primary-900': '#4e4732',
    'design-s2021-heyday-primary-700': '#847348',
    'design-s2021-heyday-primary-500': '#b19f71',
    'design-s2021-heyday-primary-300': '#d0c5aa',
    'design-s2021-heyday-primary-100': '#f1eee4',
    'design-s2021-heyday-secondary-500': '#d6be89',
    'design-s2021-heyday-grayscale-900': '#19191a',
    'design-s2021-heyday-grayscale-700': '#3a3a3b',
    'design-s2021-heyday-grayscale-500': '#727274',
    'design-s2021-heyday-grayscale-300': '#b2b2b3',
    'design-s2021-heyday-grayscale-200': '#d8d8d9',
    'design-s2021-heyday-grayscale-100': '#e6e7e8',
    'design-s2021-passport-primary-900': '#0c1527',
    'design-s2021-passport-primary-700': '#192b4d',
    'design-s2021-passport-primary-500': '#315498',
    'design-s2021-passport-primary-300': '#6588cd',
    'design-s2021-passport-primary-100': '#e0e7f5',
    'design-s2021-passport-secondary-500': '#aec4dc',
    'design-s2021-passport-grayscale-900': '#2a2723',
    'design-s2021-passport-grayscale-700': '#3b3731',
    'design-s2021-passport-grayscale-500': '#565048',
    'design-s2021-passport-grayscale-300': '#a39b8f',
    'design-s2021-passport-grayscale-200': '#dddad5',
    'design-s2021-passport-grayscale-100': '#e8e6e3',
    'design-black': '#1d1c1d',
    'design-black-80': '#3c393c',
    'design-black-60': '#707372',
    'design-black-40': '#a7acaa',
    'design-black-20': '#e6e7e8',
    'design-black-5': '#f4f5f5',
    'design-primary-100': '#312b1b',
    'design-primary-80': '#73653f',
    'design-primary-60': '#b19f71',
    'design-primary-40': '#c9bc9c',
    'design-primary-20': '#f2efe8',
    'design-primary-5': '#f9f8f5',
    'design-secondary-100': '#09100f',
    'design-secondary-80': '#1c302e',
    'design-secondary-60': '#355b57',
    'design-secondary-40': '#a3c2bf',
    'design-secondary-20': '#dfeceb',
    'design-secondary-5': '#fcfbf8',
    'design-input-border-gray': '#b2b2b3',
    'design-success': '#4db35d',
    'design-success-100': '#e4f0e6',
    'design-alert': '#fc5f3c',
    'burning-red': '#4b0f01',
    'burning-red-80': '#fc5f3c',
    'cactus-green': '#17361c',
    'cactus-green-80': '#4db35d',
    'alert-100': '#feded7',
    'primary-color': '#b19f71',
    'primary-1': '#73653f',
    'primary-2': '#b19f71',
    'primary-3': '#c9bc9c',
    'primary-4': '#f2efe8',
    'primary-5': '#f9f8f5',
    'primary-900': '#4e4732',
    'primary-700': '#847348',
    'primary-500': '#b19f71',
    'primary-300': '#d0c5aa',
    'primary-100': '#f1eee4',
    'secondary-color': '#355b57',
    'secondary-1': '#1c302e',
    'secondary-2': '#355b57',
    'secondary-3': '#a3c2bf',
    'secondary-4': '#dfeceb',
    'secondary-5': '#fcfbf8',
    'secondary-500': '#d6be89',
    black: '#1d1c1d',
    'black-1': '#3c393c',
    'black-2': '#707372',
    'black-3': '#a7acaa',
    'black-4': '#e6e7e8',
    'black-5': '#f4f5f5',
    'pure-black': '#000',
    'gray-900': '#19191a',
    'gray-700': '#3a3a3b',
    'gray-500': '#727274',
    'gray-300': '#b2b2b3',
    'gray-200': '#d8d8d9',
    'gray-100': '#e6e7e8',
    white: '#fff',
    'status-closed': '#a7acaa',
    'error-red': '#fc5f3c',
    'error-red-1': '#fc5f3c',
    'success-green': '#4db35d',
    'success-green-1': '#4db35d',
    'success-green-2': '#e4f0e6',
    'pending-orange': '#ffc700',
    'login-gray': '#19212a',
    'bg-light': '#e1e1e1',
    'dark-grey': '#43434c',
    'page-background': '#e6e7e8',
    'input-background': '#f4f5f6',
    'input-border': '#b2b2b3',
    scrollbar: '#b2b2b3',
    'notFoundPage-color': '#43434c',
    'error-color': '#fc5f3c',
    'alert-error-border-color': '#feded7',
    'alert-error-bg-color': '#feded7',
    'alert-error-icon-color': '#4b0f01',
    'loading-color': '#b19f71',
    'height-base': '50px',
    'btn-padding-horizontal-base': '30px',
    'alert-with-description-padding-vertical': '18px',
    'height-lg': '60px',
    'padding-lg': '24px',
    'padding-md': '16px',
    'padding-sm': '12px',
    'padding-xs': '8px',
    'padding-xss': '4px',
    'text-color': '#1d1c1d',
    'link-color': '#b19f71',
    'layout-sider-background': '#3c393c',
    'layout-trigger-background': 'rgba(@pure-black, 0.6)',
    'menu-dark-bg': '#3c393c',
    'menu-dark-color': '#fff',
    'menu-dark-item-active-bg': '#73653f',
    'menu-inline-toplevel-item-height': '62px',
    'menu-item-font-size': '16px',
    'menu-icon-size': '16px',
    'heading-super-size': 'ceil(@font-size-base * 5)',
    'heading-1-size': 'ceil(@font-size-base * 2.75)',
    'heading-2-size': 'ceil(@font-size-base * 2.25)',
    'heading-3-size': 'ceil(@font-size-base * 2)',
    'heading-4-size': 'ceil(@font-size-base * 1.5)',
    'typography-title-font-weight': 'bold',
    'btn-font-weight': 'bold',
    'btn-border-radius-base': '5px',
    'btn-square-only-icon-size-lg': '24px',
    'btn-ghost-color': '#fff',
    'btn-ghost-bg': 'transparent',
    'btn-ghost-border': 'transparent',
    'btn-default-color': '#b19f71',
    'btn-default-bg': '#fff',
    'btn-default-border': '#fff',
    'btn-danger-color': '#4b0f01',
    'btn-danger-bg': '#feded7',
    'btn-danger-border': 'none',
    'breadcrumb-separator-color': '#355b57',
    'breadcrumb-last-item-color': '#a7acaa',
    'breadcrumb-link-color': '#3c393c',
    'breadcrumb-separator-margin': '12px',
    'breadcrumb-link-color-hover': '#73653f',
    'dropdown-vertical-padding': '10px',
    'dropdown-edge-child-vertical-padding': '10px',
    'modal-body-padding': '20px 40px',
    'modal-header-padding-vertical': '30px',
    'modal-header-padding-horizontal': '40px',
    'border-color-base': '#f2efe8',
    'border-color-split': '#f2efe8',
    'table-header-bg': '#fff',
    'table-header-color': '#1d1c1d',
    'table-bg': '#f9f8f5',
    'table-padding-vertical': '10px',
    'table-padding-horizontal': '20px',
    'pagination-font-family': "'Open Sans', sans-serif",
    'pagination-item-bg-active': '#dfeceb',
    'pagination-font-weight-active': 'bold',
    'pagination-item-size': '40px',
    'form-item-margin-bottom': '40px',
    'menu-item-color': '#3a3a3b',
    'menu-item-active-bg': '#f1eee4',
    'booking-table-cell-odd': '#fff',
    'booking-table-cell-even': '#ededed',
    'booking-table-cell-past': '#bdbdbd',
};
