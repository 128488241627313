import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const MainLocation: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 14 14" fill="none" {...props}>
                <circle r="7" transform="matrix(-1 0 0 1 7 7)" fill="white" />
                <path
                    d="M7.00078 13.3002C3.52139 13.3002 0.700782 10.4796 0.700782 7.0002C0.700782 3.5208 3.52139 0.700195 7.00078 0.700195C10.4802 0.700195 13.3008 3.5208 13.3008 7.0002C13.3008 10.4796 10.4802 13.3002 7.00078 13.3002Z"
                    fill={fill}
                />
                <g clipPath="url(#clip0)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.01014 10.7333C7.07464 10.7333 7.1364 10.7066 7.18063 10.6596C7.2583 10.577 9.0909 8.62611 9.65578 7.77688C9.91441 7.3882 10.1094 6.78154 10.1094 6.36568C10.1094 4.65685 8.71907 3.2666 7.01014 3.2666C5.30126 3.2666 3.9109 4.65685 3.9109 6.36568C3.9109 6.78154 4.10592 7.3882 4.3646 7.77694C4.92948 8.62611 6.76198 10.577 6.83965 10.6596C6.88388 10.7066 6.94563 10.7333 7.01014 10.7333ZM8.19651 7.77175C8.21471 7.882 8.11252 7.96836 8.02245 7.91908L6.9986 7.35761L5.97406 7.91908C5.88399 7.96836 5.78179 7.882 5.79999 7.77175L5.99366 6.59454L5.17164 5.7593C5.09487 5.68115 5.13477 5.53829 5.23767 5.52286L6.38052 5.34964L6.89011 4.27273C6.93607 4.17567 7.06044 4.17567 7.1064 4.27273L7.61599 5.34964L8.75884 5.52286C8.86174 5.53829 8.90163 5.68115 8.82487 5.7593L8.00285 6.59454L8.19651 7.77175Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect
                            width="7.46667"
                            height="7.46667"
                            fill="white"
                            transform="matrix(-1 0 0 1 10.7324 3.2666)"
                        />
                    </clipPath>
                </defs>
            </svg>
        </span>
    );
};

export default MainLocation;
