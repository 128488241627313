import { LocationDto } from 'Api/Features/Locations/Dtos/LocationDto';
import { AddressDto } from 'Api/Features/General/Dtos/AddressDto';
import { ContactInfoDto } from 'Api/Features/General/Dtos/ContactInfoDto';
import { StoredFileDto } from 'Api/Features/General/Dtos/StoredFileDto';
import { Address } from 'Models/Contact/Address';
import { ContactInfo } from 'Models/Contact/ContactInfo';
import { htmlContainsText } from 'Utils';
import { LocationTypeDto } from 'Api/Features/Locations/Dtos/LocationTypeDto';

export class Location implements LocationDto {
    constructor(dto: LocationDto) {
        this.id = dto.id;
        this.name = dto.name;
        this.introduction = dto.introduction;
        this.logoImageUrl = dto.logoImageUrl;
        this.mainImageUrl = dto.mainImageUrl;
        this.appHomeBackgroundImageUrl = dto.appHomeBackgroundImageUrl;
        this.images = dto.images;
        this.timeZone = dto.timeZone;
        this.latitude = dto.latitude;
        this.longitude = dto.longitude;
        this.contactInfo = dto.contactInfo;
        this.address = dto.address;
        this.type = dto.type;
        this.businessHoursStart = dto.businessHoursStart;
        this.businessHoursEnd = dto.businessHoursEnd;
    }
    id: string | undefined;
    name: string | null | undefined;
    introduction: string | null | undefined;
    address: AddressDto | null | undefined = null;
    contactInfo: ContactInfoDto | null | undefined;
    logoImageUrl: string | null | undefined;
    mainImageUrl: string | null | undefined;
    appHomeBackgroundImageUrl: string | null | undefined;
    images: (StoredFileDto | null)[] | null | undefined;
    timeZone: string | null | undefined;
    latitude: number | null | undefined;
    longitude: number | null | undefined;
    type?: LocationTypeDto;
    businessHoursStart?: string | null;
    businessHoursEnd?: string | null;

    private _info: ContactInfo | null = null;
    private _address: Address | null = null;

    get introductionEmpty(): boolean {
        return !htmlContainsText(this.introduction || null);
    }

    get mainAddress(): Address | null {
        if (this.address && !this._address) {
            this._address = new Address(this.address);
        }
        return this._address;
    }

    get info(): ContactInfo | null {
        if (this.contactInfo && !this._info) {
            this._info = new ContactInfo(this.contactInfo);
        }
        return this._info;
    }

    get consoleUrl(): string {
        return `/locations/${this.id}/service`;
    }

    get isHeyday(): boolean {
        return this.type === LocationTypeDto.Heyday;
    }
}
