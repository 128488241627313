import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Download: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M12.505 6.29787L11.8307 5.92754L8.29343 9.22679V0H7.44112V9.22788L3.90385 5.92754L3.22835 6.29787L7.86666 10.6253L12.505 6.29787Z"
                    fill={fill}
                />
                <path
                    d="M12.2416 9.27472V11.0262H10.5711V11.5708H12.8513V9.81933H14.7851V13.6839H0.948205V9.81933H2.88206V11.5708H5.16098V11.0262H3.49172V9.27472H0.266602V14.2928H15.4667V9.27472H12.2416Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Download;
