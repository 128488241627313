import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Member: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M32.607 38.386h-25.3v-7.92c0-3.733 2.653-6.891 6.156-7.702a7.494 7.494 0 011.57-.197c.066-.006.135-.006.202-.006h9.55c.067 0 .136 0 .203.006.98.022 1.91.227 2.767.586a7.91 7.91 0 014.852 7.314v7.92zm-7.822-17.439h-9.55c-5.23 0-9.542 4.286-9.542 9.52V40h28.528v-9.533c0-5.234-4.203-9.52-9.436-9.52zM20.01 14.533a6.466 6.466 0 01-6.458-6.458 6.466 6.466 0 016.458-6.458c3.561 0 6.461 2.897 6.461 6.458 0 3.561-2.9 6.458-6.46 6.458zM20.01 0c-4.45 0-8.072 3.622-8.072 8.075 0 4.453 3.622 8.072 8.072 8.072 4.453 0 8.075-3.62 8.075-8.072C28.085 3.622 24.463 0 20.01 0z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Member;
