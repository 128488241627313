import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const CreditCard: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg
                width="1em"
                height="1em"
                viewBox="0 0 24 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.0625 5.27965H0.937739V1.70399C0.937739 1.28135 1.28172 0.93754 1.70419 0.93754H22.2962C22.7189 0.93754 23.0625 1.28135 23.0625 1.70399V5.27965ZM23.0625 14.9483C23.0625 15.3726 22.7189 15.7165 22.2962 15.7165H1.70419C1.28172 15.7165 0.937739 15.3726 0.937739 14.9483V6.21716H23.0625V14.9483ZM22.296 0H1.70397C0.764942 0 0 0.764775 0 1.70397V14.9483C0 15.8892 0.764942 16.654 1.70397 16.654H22.296C23.2351 16.654 24 15.8892 24 14.9483V1.70397C24 0.764775 23.2351 0 22.296 0Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.7956 10.4311H3.68654C3.42775 10.4311 3.21794 10.2213 3.21794 9.96236C3.21794 9.70357 3.42775 9.4936 3.68654 9.4936H12.7956C13.0545 9.4936 13.2643 9.70357 13.2643 9.96236C13.2643 10.2213 13.0545 10.4311 12.7956 10.4311Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default CreditCard;
