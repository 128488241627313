import { observable, action, computed } from 'mobx';
import { inject } from 'aurelia-dependency-injection';
import { AmenityService } from 'Services/AmenityService';
import LoadingStore from 'Stores/LoadingStore';
import { GetAmenitiesRequestDto } from 'Api/Features/Amenities/Dtos/GetAmenitiesRequestDto';
import { Amenity } from 'Models/Amenities/Amenity';

@inject(AmenityService, LoadingStore)
class AmenityStore {
    constructor(
        private readonly amenityService: AmenityService,
        private readonly loadingStore: LoadingStore
    ) {}

    @observable
    private _amenities: Amenity[] = [];

    @computed
    get amenities(): Amenity[] {
        return this._amenities;
    }

    @action
    async setAmenitiesForLocations(id: string): Promise<void> {
        this.loadingStore.addLoading();
        try {
            const request: GetAmenitiesRequestDto = {
                locationIds: [id],
            };
            const [items] = await this.amenityService.getAmenities(request);
            this._amenities = items;
        } finally {
            this.loadingStore.removeLoading();
        }
    }
}

export default AmenityStore;
