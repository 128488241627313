import * as yup from 'yup';

export const CreateFoodMerchantSchema = yup.object({
    description: yup.string().max(400, 'Errors.length_400'),
    isBbot: yup.bool(),
    bbotRestaurantId: yup.string().when("isBbot", {
        is: true,
        then: yup.string().required('Errors.required')
    }),
    name: yup.string().when("isBbot", {
        is: false,
        then: yup.string().required('Errors.required')
    })
});
