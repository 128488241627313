import { useState } from 'react';

import { ResetPasswordFormValues } from './reset-password-form';
import { useService, useStores } from 'Hooks';
import { UserService } from 'Services/UserService';
import { UpdateUserPasswordAnonymousRequestDto } from 'Api/Features/Users/Dtos/UpdateUserPasswordAnonymousRequestDto';

export const useResetPassword = (): [boolean, (values: ResetPasswordFormValues) => void] => {
    const userService = useService(UserService);
    const { globalLoadingStore, toastStore } = useStores();
    const [success, setSuccess] = useState(false);

    const submit = async (values: ResetPasswordFormValues): Promise<void> => {
        const urlParams = new URLSearchParams(window.location.search);

        const request: UpdateUserPasswordAnonymousRequestDto = {
            newPassword: values.password,
            currentPassword: null,
            email: urlParams.get('email'),
            token: urlParams.get('token'),
        };

        try {
            globalLoadingStore.addLoading();
            await userService.setPassword(request);
            setSuccess(true);
        } catch (error) {
            if (!error.treated) {
                toastStore.displayError(error);
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    return [success, submit];
};
