import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Website: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.0339 15.1464C23.0339 16.017 22.3283 16.7226 21.4576 16.7226H2.54214C1.67145 16.7226 0.965848 16.017 0.965848 15.1464V4.54224C0.965848 3.67156 1.67145 2.96595 2.54214 2.96595H21.4576C22.3283 2.96595 23.0339 3.67156 23.0339 4.54224V15.1464ZM6.41142 21.9388H17.5887V21.079H6.41142V21.9388ZM21.4862 2.10619H2.57079C1.22765 2.10619 0.106195 3.17033 0.106195 4.51376V15.1179C0.106195 16.461 1.22765 17.5825 2.57079 17.5825H11.5701V20.2192H5.55155V22.7986H18.4484V20.2192H12.4299V17.5825H21.4862C22.8297 17.5825 23.8938 16.461 23.8938 15.1179V4.51376C23.8938 3.17033 22.8297 2.10619 21.4862 2.10619Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 4.51376C0 3.10846 1.17227 2 2.57079 2H21.4862C22.8883 2 24 3.11168 24 4.51376V15.1179C24 16.5164 22.8915 17.6887 21.4862 17.6887H12.5361V20.113H18.5546V22.9048H5.44536V20.113H11.4639V17.6887H2.57079C1.169 17.6887 0 16.5197 0 15.1179V4.51376ZM2.57079 2.21239C1.28303 2.21239 0.212389 3.2322 0.212389 4.51376V15.1179C0.212389 16.4024 1.2863 17.4763 2.57079 17.4763H11.6763V20.3254H5.65775V22.6924H18.3423V20.3254H12.3237V17.4763H21.4862C22.7678 17.4763 23.7876 16.4056 23.7876 15.1179V4.51376C23.7876 3.22898 22.771 2.21239 21.4862 2.21239H2.57079ZM2.54214 3.07215C1.7301 3.07215 1.07204 3.7302 1.07204 4.54224V15.1464C1.07204 15.9584 1.7301 16.6164 2.54214 16.6164H21.4576C22.2696 16.6164 22.9277 15.9584 22.9277 15.1464V4.54224C22.9277 3.7302 22.2696 3.07215 21.4576 3.07215H2.54214ZM0.859653 4.54224C0.859653 3.61291 1.6128 2.85976 2.54214 2.85976H21.4576C22.3869 2.85976 23.1401 3.61291 23.1401 4.54224V15.1464C23.1401 16.0757 22.3869 16.8288 21.4576 16.8288H2.54214C1.6128 16.8288 0.859653 16.0757 0.859653 15.1464V4.54224ZM6.30523 20.9728H17.6949V22.045H6.30523V20.9728ZM6.51762 21.1852V21.8326H17.4825V21.1852H6.51762Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.47414 14.1676H20.4909V5.49804H3.47414V14.1676ZM2.75748 14.8842H21.2072V4.78159H2.75748V14.8842Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.65129 4.6754H21.3134V14.9904H2.65129V4.6754ZM2.86368 4.88778V14.778H21.101V4.88778H2.86368ZM3.36794 5.39184H20.5971V14.2738H3.36794V5.39184ZM3.58033 5.60423V14.0614H20.3847V5.60423H3.58033Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Website;
