import React, { FunctionComponent, useState } from 'react';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Layout, Typography, Row, Col, Button } from 'antd';
import { User } from 'Components/icons';
// import Modal from 'Components/modal/modal-component';
import BookingModal from 'Components/booking/booking-flow/booking-modal';
import { CSSTransition } from 'react-transition-group';
import { images } from '../../../variant';
import { getDayPeriod } from 'Utils/TimeUtils';
import { ImageWithPlaceholder } from 'Components/image-with-placeholder';

import './user-header.less';

const { Header } = Layout;
const { Title } = Typography;

const dayPeriod = getDayPeriod();

const UserHeader: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const { userStore } = useStores();
    const [modalVisible, setModalVisibility] = useState(false);

    const handleBookAnAmenityClick = (): void => {
        setModalVisibility(true);
    };

    return (
        <Header
            className="UserHeader"
            style={{
                background: `url(${images.webAppHomeHeader}`,
            }}
        >
            <Row align="middle" justify="space-between">
                <Col flex="0 0 93px">
                    <ImageWithPlaceholder
                        className="UserAvatar"
                        defaultImg={<User />}
                        imgSrc={userStore.user?.imageUrl}
                        title={userStore.user?.name || 'Avatar'}
                        width={93}
                        height={93}
                    />
                </Col>
                <Col flex="0 0 26px" />
                <Col flex="1 1 auto">
                    <CSSTransition
                        in={userStore.firstName !== null && userStore.firstName.length > 0}
                        timeout={300}
                        classNames="username-animation"
                        unmountOnExit
                    >
                        <Title level={4} className="UserHeader-greetings bold">
                            {t(`greetings_${dayPeriod}`)}, {userStore.firstName}!
                        </Title>
                    </CSSTransition>
                </Col>
                <Col flex="0 0 210px">
                    <Button type="primary" onClick={handleBookAnAmenityClick}>
                        {t('Booking.book_amenities')}
                    </Button>
                </Col>
            </Row>
            {modalVisible && (<BookingModal visible={modalVisible} setVisibleState={setModalVisibility} />)}
        </Header>
    );
});

export default UserHeader;
