import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

const { Title } = Typography;

const Company: FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <div className="">
            <Title level={4} className="bold">
                { t('Sidebar.menu_item_company') }
            </Title>
        </div>
    );
};

export default Company;
