import React, { FunctionComponent } from 'react';
import { useStores } from 'Hooks';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, Col, Form, Input, Space, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { SelectCountry } from 'Components/select-country';

interface BookingPaymentFormProps {
    isEditing: boolean;
    onCancelClick: () => void;
    onSubmit: (token: any) => void;
}

const BookingPaymentForm: FunctionComponent<BookingPaymentFormProps> = ({
    isEditing,
    onCancelClick,
    onSubmit,
}) => {
    const { t } = useTranslation();
    const { toastStore } = useStores();
    const stripe = useStripe();
    const elements = useElements();

    const onFinish = (values: any): void => {
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const cardElement = elements.getElement(CardElement);

        if (cardElement) {
            stripe
                .createToken(cardElement, {
                    name: values.name,
                    address_line1: values.line1,
                    address_line2: values.line2,
                    address_city: values.city,
                    address_state: values.state,
                    address_country: values.country,
                })
                .then((result) => {
                    if (result.error) {
                        toastStore.displayError(result.error);
                    } else {
                        onSubmit(result.token.id);
                    }
                });
        }
    };

    return (
        <Form
            name="cardInformation"
            layout="vertical"
            initialValues={undefined}
            onFinish={onFinish}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        label={t('Payment.card_information')}
                        name="card"
                        rules={[
                            {
                                required: true,
                                message: t('All.empty_field_message'),
                            },
                        ]}
                    >
                        <CardElement
                            options={{
                                style: {
                                    base: {
                                        fontSize: '16px',
                                    },
                                },
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Form.Item
                        label={t('Payment.card_holder_name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: t('All.empty_field_message'),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={16}>
                    <Form.Item
                        label={t('Payment.card_address_line_1')}
                        name="line1"
                        rules={[
                            {
                                required: true,
                                message: t('All.empty_field_message'),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={t('Payment.card_address_line_2')} name="line2">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={12}>
                    <SelectCountry
                        name="country"
                        label={t('Payment.card_country')}
                        rules={[
                            {
                                required: true,
                                message: t('All.empty_field_message'),
                            },
                        ]}
                    />
                </Col>

                <Col span={12}>
                    <Form.Item
                        label={t('Payment.card_state')}
                        name="state"
                        rules={[
                            {
                                required: true,
                                message: t('All.empty_field_message'),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        label={t('Payment.card_city')}
                        name="city"
                        rules={[
                            {
                                required: true,
                                message: t('All.empty_field_message'),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>

            <div className="d-flex justify-content-end w-100">
                <Space>
                    {isEditing ? (
                        <Button onClick={onCancelClick} className="mb-1">
                            {t('cancel')}
                        </Button>
                    ) : null}

                    <Button type="primary" htmlType="submit" className="mb-1">
                        {t('Booking.submit_card')}
                    </Button>
                </Space>
            </div>
        </Form>
    );
};

export default BookingPaymentForm;
