import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Modal, Form, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useService, useStores } from 'Hooks';
import { Amenity as AmenityIcon, Close } from 'Components/icons';
import FlexibleBooking from 'Components/booking/flexible-booking/flexible-booking';
import './edit-booking-conflict-modal.less'
import momentTimezone from 'moment-timezone';
import { BookingService } from 'Services/BookingService';
import { Amenity } from 'Models/Amenities/Amenity';
import { CurrentConflict } from './booking-conflicts-modal';
import { DailyBookingSummaryAmenityDto } from 'Api/Features/Bookings/Dtos/DailyBookingSummaryAmenityDto';
import moment from 'moment';
import { DATE_API_FORMAT, TIME_WITH_OFFSET_FORMAT } from 'Models/Constants';
import { DailyBookingSummaryPeriodUnavailabilityReasonDto } from 'Api/Features/Bookings/Dtos/DailyBookingSummaryPeriodUnavailabilityReasonDto';
import DateControls from 'Components/booking/flexible-booking/date-controls';

interface EditBookingConflictModalProps {
    visible: boolean;
    onComplete: (resolvedConflict?: CurrentConflict) => void;
    conflict?: CurrentConflict;
    timezone: string | null;
    amenity: Amenity;
    locationId?: string;
    resourcePeriod: any;
}

const EditBookingConflictModal: FunctionComponent<EditBookingConflictModalProps> = ({
    visible,
    onComplete,
    conflict,
    timezone,
    amenity, 
    locationId,
    resourcePeriod
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { confirmationModalStore, globalLoadingStore, toastStore } = useStores();
    const bookingService = useService(BookingService);

    const [resource, setResource] = useState<(DailyBookingSummaryAmenityDto | null)[]>([]);
    const [currentBookingIsErrored, setCurrentBookingIsErrored] = useState(!conflict?.resolved);

    const [currentConflict, setCurrentConflict] = useState<CurrentConflict | undefined>(conflict);
    const [selectedDate, setSelectedDate] = useState(moment(conflict?.bookingStart));


    const dismiss = (conflictResolved?: boolean): void => {
        onComplete(conflictResolved ? currentConflict : undefined);
    };

    const exit = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <AmenityIcon />,
                title: t(`Booking.book_a_room_confirm_title`),
                message: t(`Booking.book_a_room_confirm_message`),
                positiveText: t('Booking.leave_booking_conflicts_confirm_positive'),
                negativeText: t(`Booking.book_a_room_confirm_negative`),
            }))
        )
            return;
        dismiss();
    };

    const submit = (): void => {
        if(currentBookingIsErrored)
            return;
        
        dismiss(true);
    };

    const handleDateChange = (selectedDate: any) => {
        setSelectedDate(moment(selectedDate));

        const conflict = currentConflict;
        const startTime = moment(conflict?.bookingStart).format(TIME_WITH_OFFSET_FORMAT);
        const endTime = moment(conflict?.bookingEnd).format(TIME_WITH_OFFSET_FORMAT);
        setCurrentConflict({
            ...currentConflict,
            bookingStart: moment(moment(selectedDate.format(DATE_API_FORMAT + 'T' + startTime))).format(),
            bookingEnd: moment(moment(selectedDate.format(DATE_API_FORMAT + 'T' + endTime))).format(),
        } as CurrentConflict)
    };

    const handleTimeCellClick = (e: any, bookingInformation: any, period: any) => {
        setCurrentBookingIsErrored(period.unavailabilityReason === DailyBookingSummaryPeriodUnavailabilityReasonDto.Booked);
        bookingInformation.index = conflict?.index;
        setCurrentConflict({...bookingInformation});
    };

    useEffect(() => {
        //set if current booking is conflicting with another in the calendar
        if (currentConflict) {
            const resourcePeriods = resource[0]?.periods;
            const bookedPeriods = resourcePeriods?.filter(
                (period: any) =>
                    period.unavailabilityReason ===
                    DailyBookingSummaryPeriodUnavailabilityReasonDto.Booked
            );

            let currentBookingIsErrored = false;
            if (bookedPeriods) {
                bookedPeriods.forEach((period) => {
                    const bookedPeriodStart = moment(period?.start);
                    const bookedPeriodEnd = moment(period?.end);
                    const currentStart = moment(currentConflict?.bookingStart);
                    const currentEnd = moment(currentConflict?.bookingEnd);
                    if (
                        currentStart.isSame(bookedPeriodStart) ||
                        currentStart.isBetween(bookedPeriodStart, bookedPeriodEnd) ||
                        (currentStart.isSameOrBefore(bookedPeriodStart) && currentEnd.isAfter(bookedPeriodStart))
                    ) {
                        currentBookingIsErrored = true;
                        return;
                    }
                });
            }
            setCurrentBookingIsErrored(currentBookingIsErrored);
        }
    }, [currentConflict, resource]);

    const handleBookingResize = ({ formatedBookingStart, formatedBookingEnd }: any) => {
        const oldConflict = currentConflict;
        
        const start = formatedBookingStart
            ? formatedBookingStart
            : momentTimezone(oldConflict?.bookingStart).tz(timezone!).format();
        const bookingEnd = formatedBookingEnd
            ? formatedBookingEnd
            : momentTimezone(oldConflict?.bookingEnd).tz(timezone!).format();
        if (oldConflict !== undefined) {
            oldConflict.bookingStart = start;
            oldConflict.bookingEnd = bookingEnd;
            oldConflict.index = currentConflict?.index!;
            setCurrentConflict({...oldConflict});
        }
    };

    const fetchResource = useCallback(async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();
            const response = await bookingService.getDailyBookingSummary(locationId!, {
                date: moment(selectedDate).format(DATE_API_FORMAT),
                enableUserRestrictions: false,
                amenityIds: [amenity.id],
            })
            if(response?.amenities)
                setResource(response.amenities)
        }
        catch (error) {
            if(!error.treated)
                toastStore.displayError(error);
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, toastStore, bookingService, amenity, locationId, selectedDate])

    useEffect(() => {
        fetchResource();
    }, [fetchResource])
    
    return (
        <Modal
            visible={visible}
            title={t('Booking.edit_booking_conflicts')}
            className="FormModal EditBookingConflictModal"
            closeIcon={<Close />}
            width={960}
            footer={null}
            onCancel={(): Promise<void> => exit()}
            maskClosable={false}
            centered
        >
            <Form layout="vertical" form={form} onFinish={submit}>
                <div className="banner">
                    <div className="text">{t('Booking.booking_time')}</div>
                </div>

                <div className="date-controls-container">
                    <DateControls
                        selectedDate={selectedDate}
                        onChange={handleDateChange}
                        showTodayButton={false}
                        displayForConflictResolution
                    />
                </div>

                <FlexibleBooking
                    view="conflicts"
                    classNames="w-100 position-relative"
                    resizable
                    resources={resource}
                    currentBooking={currentConflict}
                    timezone={timezone}
                    onTimeCellClick={handleTimeCellClick}
                    onBookingResize={handleBookingResize}
                    min={0}
                    max={24}
                    zoom={2}
                    isEditing={false}
                    filters={[]}
                    resourcePeriod={resourcePeriod}
                    selectedDate={conflict?.bookingStart}
                    currentBookingIsErrored={currentBookingIsErrored}
                />

                <div className="actions d-flex">
                    <Button
                        type="default"
                        className="secondary negative m-l-auto"
                        htmlType="button"
                        onClick={(): Promise<void> => exit()}
                    >
                        {t('cancel')}
                    </Button>
                    <Button type="primary" className="positive" htmlType="submit">
                        {t('confirm')}
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};

export default React.memo(EditBookingConflictModal);
