import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Edit: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg
                width="1em"
                height="1em"
                viewBox="0 0 13 13"
                fill="none"
                {...props}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.4741 3.48196L10.5515 4.40298L8.68838 2.54002L9.61023 1.61747C9.66294 1.56469 9.73132 1.53138 9.79725 1.52596C9.82826 1.52359 9.88571 1.52519 9.92681 1.56545L11.5261 3.16541C11.5967 3.2353 11.5719 3.38342 11.4741 3.48196ZM4.6393 10.3045L2.78626 8.45173L8.38264 2.84725L10.245 4.70876L4.6393 10.3045ZM1.6984 11.3924L2.56976 8.84895L4.24044 10.5187L1.6984 11.3924ZM11.8326 2.85817L10.2333 1.25898C10.1131 1.13874 9.94003 1.07901 9.76073 1.09367C9.59159 1.10773 9.42941 1.18534 9.30297 1.311L2.2858 8.33921L1 12.0915L4.75801 10.7988L11.7806 3.78851C12.0514 3.51771 12.0747 3.10025 11.8326 2.85817Z"
                    fill={fill}
                    stroke="#B19F71"
                    strokeWidth="0.2"
                />
            </svg>
        </span>
    );
};

export default Edit;
