import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { Descriptions, Typography } from 'antd';
import { Location } from 'Models/Location/Location';
import { Amenity } from 'Models/Amenities/Amenity';
import { BookingDetails } from 'Models/Bookings/BookingDetails';
import { CC_EXPIRATION_FORMAT } from 'Models/Constants';
import { CheckMarkWeb } from 'Components/icons';
import { theme } from 'variant';
import './booking-confirmation.less';

const { Text, Title } = Typography;

interface BookingConfirmationProps {
    booking: BookingDetails | null;
    location: Location | null;
    amenityCategoryName: string;
    amenity: Amenity | null;
    date: any;
}

const BookingConfirmation: FunctionComponent<BookingConfirmationProps> = ({
    booking,
    location,
    amenityCategoryName,
    amenity,
    date,
}) => {
    const { t } = useTranslation();

    const paidWithCredits = !!(booking && booking.creditCost > 0);

    return (
        <div className="BookingModal__Confirmation">
            <CheckMarkWeb fill={theme['primary-color']} />
            <Title level={1}>
                {amenity?.isApprovalRequired
                    ? t('Booking.booking_confirmation_title_needs_approval')
                    : t('Booking.booking_confirmation_title')}
            </Title>

            {amenity?.isApprovalRequired ? (
                <>
                    <Text>{t('Booking.booking_confirmation_text_needs_approval')}</Text>
                    <Text>{t('Booking.booking_confirmation_text2_needs_approval')}</Text>
                </>
            ) : null}

            <Descriptions colon={false} column={1}>
                <Descriptions.Item label={t('Locations.location_title')}>
                    <Text strong>{location?.name}</Text>
                </Descriptions.Item>
                <Descriptions.Item label={t('Booking.amenity_category')}>
                    <Text strong>{amenityCategoryName}</Text>
                </Descriptions.Item>
                <Descriptions.Item label={t('Booking.amenity_card')}>
                    <Text strong>{amenity?.name}</Text>
                </Descriptions.Item>
                <Descriptions.Item label={t('Booking.date_time')} className="time-description">
                    <Text strong>
                        {momentTimezone(date.bookingStart)
                            .tz(location?.timeZone || '')
                            .format('MMMM Do YYYY')}
                    </Text>
                    <Text strong>
                        {momentTimezone(date.bookingStart)
                            .tz(location?.timeZone || '')
                            .format('h:mm a')}{' '}
                        -{' '}
                        {momentTimezone(date.bookingEnd)
                            .tz(location?.timeZone || '')
                            .format('h:mm a')}
                    </Text>
                </Descriptions.Item>

                {(paidWithCredits) ? (
                    <>
                        <Descriptions.Item label={t('Booking.payment')}>
                        <Text strong>{booking?.paymentMethod?.holder?.name || booking?.paymentMethod?.name}</Text>
                        </Descriptions.Item>

                        <Descriptions.Item
                            label={t('Booking.payment_details')}
                            className="time-description"
                        >
                            <Text strong>
                                {t('Payment.paid_with_credits')}
                            </Text>
                        </Descriptions.Item>
                    </>
                ) : null}

                {(!paidWithCredits && booking?.paymentMethod) ? (
                    <>
                        <Descriptions.Item label={t('Booking.payment')}>
                            <Text strong>{booking?.paymentMethod?.holder?.name || booking?.paymentMethod?.name}</Text>
                        </Descriptions.Item>

                        <Descriptions.Item
                            label={t('Booking.payment_details')}
                            className="time-description"
                        >
                            <Text strong>
                                {t('ending_with', {
                                    param1: booking?.paymentMethod?.card?.brand,
                                    param2: booking?.paymentMethod?.card?.last4,
                                })}
                            </Text>
                            <Text strong>{moment(booking?.paymentMethod?.card?.expiration).format(CC_EXPIRATION_FORMAT)}</Text>
                        </Descriptions.Item>
                    </>
                ) : null}
            </Descriptions>
        </div>
    );
};

export default BookingConfirmation;
