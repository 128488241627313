import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { PaymentMethodProxy } from 'Api/Features/PaymentMethods/PaymentMethodProxy';
import { PaymentMethod } from 'Models/PaymentMethods/PaymentMethod';
import { GetCompanyPaymentMethodsResponseDto } from 'Api/Features/PaymentMethods/Dtos/GetCompanyPaymentMethodsResponseDto';
import { GetUserPaymentMethodsResponseDto } from '../Api/Features/PaymentMethods/Dtos/GetUserPaymentMethodsResponseDto';
import { GetPaymentApiInfoResponseDto } from 'Api/Features/PaymentMethods/Dtos/GetPaymentApiInfoResponseDto';
import { CreateUserPaymentMethodRequestDto } from 'Api/Features/PaymentMethods/Dtos/CreateUserPaymentMethodRequestDto';
import { CreateCompanyPaymentMethodRequestDto } from 'Api/Features/PaymentMethods/Dtos/CreateCompanyPaymentMethodRequestDto';

@inject(PaymentMethodProxy)
export class PaymentMethodService extends ApiService {
    constructor(private readonly invoiceProxy: PaymentMethodProxy) {
        super();
    }

    public async getPaymentApiInfo(): Promise<GetPaymentApiInfoResponseDto | null> {
        const response = this.invoiceProxy.getPaymentApiInfo();
        return response;
    }

    public async getCompanyPaymentMethod(companyId: string): Promise<PaymentMethod[] | null> {
        const response: GetCompanyPaymentMethodsResponseDto | null = await this.invoiceProxy.getCompanyPaymentMethods(
            companyId
        );
        return response ? (response.items as PaymentMethod[]) : null;
    }

    public async getUserPaymentMethod(userId: string): Promise<PaymentMethod[] | null> {
        const response: GetUserPaymentMethodsResponseDto | null = await this.invoiceProxy.getUserPaymentMethods(
            userId
        );
        return response ? (response.items as PaymentMethod[]) : null;
    }

    public async createUserPaymentMethod(
        userId: string,
        request: CreateUserPaymentMethodRequestDto
    ): Promise<void> {
        const response = await this.invoiceProxy.createUserPaymentMethod(userId, request);
        return response;
    }

    public async createCompanyPaymentMethod(
        companyId: string,
        request: CreateCompanyPaymentMethodRequestDto
    ): Promise<void> {
        const response = await this.invoiceProxy.createCompanyPaymentMethod(companyId, request);
        return response;
    }
}
