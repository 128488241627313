import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const GeneralMessage: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.76135 1.80062C3.84793 1.80062 0.664157 4.45438 0.664157 7.71635C0.664157 9.67071 1.82186 11.4971 3.761 12.6021L3.87733 12.6684L4.26437 14.8127L5.92705 13.4329L6.06224 13.4609C6.61014 13.5745 7.18191 13.6322 7.76135 13.6322C11.6748 13.6322 14.8585 10.9783 14.8585 7.71635C14.8585 4.45438 11.6748 1.80062 7.76135 1.80062ZM3.87448 15.8809L3.36035 13.0324C1.31042 11.8175 0.0908203 9.83937 0.0908203 7.71635C0.0908203 4.1383 3.53176 1.22736 7.76127 1.22736C11.9908 1.22736 15.4317 4.1383 15.4317 7.71635C15.4317 11.2945 11.9908 14.2055 7.76127 14.2055C7.19057 14.2055 6.62626 14.1529 6.08198 14.0494L3.87448 15.8809Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.04222 7.37427C4.81349 7.37427 4.62727 7.56039 4.62727 7.78912C4.62727 8.01785 4.81349 8.20396 5.04222 8.20396C5.27084 8.20396 5.45706 8.01785 5.45706 7.78912C5.45706 7.56039 5.27084 7.37427 5.04222 7.37427ZM5.0421 8.77723C4.49728 8.77723 4.054 8.33394 4.054 7.78912C4.054 7.24431 4.49728 6.80102 5.0421 6.80102C5.58692 6.80102 6.0301 7.24431 6.0301 7.78912C6.0301 8.33394 5.58692 8.77723 5.0421 8.77723Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.76146 7.37427C7.53273 7.37427 7.34651 7.56039 7.34651 7.78912C7.34651 8.01785 7.53273 8.20396 7.76146 8.20396C7.99008 8.20396 8.1763 8.01785 8.1763 7.78912C8.1763 7.56039 7.99008 7.37427 7.76146 7.37427ZM7.76134 8.77723C7.21653 8.77723 6.77324 8.33394 6.77324 7.78912C6.77324 7.24431 7.21653 6.80102 7.76134 6.80102C8.30616 6.80102 8.74934 7.24431 8.74934 7.78912C8.74934 8.33394 8.30616 8.77723 7.76134 8.77723Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.4807 7.37427C10.252 7.37427 10.0658 7.56039 10.0658 7.78912C10.0658 8.01785 10.252 8.20396 10.4807 8.20396C10.7093 8.20396 10.8955 8.01785 10.8955 7.78912C10.8955 7.56039 10.7093 7.37427 10.4807 7.37427ZM10.4806 8.77723C9.93577 8.77723 9.49248 8.33394 9.49248 7.78912C9.49248 7.24431 9.93577 6.80102 10.4806 6.80102C11.0254 6.80102 11.4686 7.24431 11.4686 7.78912C11.4686 8.33394 11.0254 8.77723 10.4806 8.77723Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default GeneralMessage;
