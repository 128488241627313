import * as yup from 'yup';
import moment from 'moment';
import {
    MAX_LENGTH_4000,
    MAX_LENGTH_50,
    MOMENT_TIME_FORMAT,
    SAME_DAY_MINIMUM_BOOKING_MINUTES,
    TOTAL_MINUTES_IN_A_DAY,
} from 'Models/Constants';

export const CreateAmenitySchema = yup.object({
    name: yup.string().required('Errors.required').max(MAX_LENGTH_50, 'Errors.length_50'),
    description: yup.string().max(MAX_LENGTH_4000),
    categoryId: yup.string().required('Errors.required'),
    pricePerBlock: yup.number().min(0, 'Errors.must_be_positive').required('Errors.required'),
    taxAmountPerBlock: yup.number().min(0, 'Errors.must_be_positive').required('Errors.required'),
    creditsPerBlock: yup.number().min(0, 'Errors.must_be_positive').required('Errors.required'),
    allDay: yup.boolean(),
    openingTime: yup
        .string()
        .nullable()
        .when('allDay', { is: false, then: yup.string().required('Errors.required') }),
    closingTime: yup
        .string()
        .nullable()
        .when('allDay', {
            is: false,
            then: yup
                .string()
                .required('Errors.required')
                .test('dateAfter', 'Errors.closing_time_before_opening', function (value) {
                    const { openingTime } = this.parent;
                    return (
                        !value ||
                        (parseInt(value || '') !== 0 &&
                            moment(value, MOMENT_TIME_FORMAT).isSameOrAfter(
                                moment(openingTime, MOMENT_TIME_FORMAT)
                            ))
                    );
                }),
        }),
    sameDayMaximumTotalBookingDuration: yup
        .number()
        .min(SAME_DAY_MINIMUM_BOOKING_MINUTES, 'Errors.min_thirty')
        .max(TOTAL_MINUTES_IN_A_DAY, 'Errors.more_minutes_than_day'),
    maximumContiguousBookingDuration: yup
        .number()
        .min(SAME_DAY_MINIMUM_BOOKING_MINUTES, 'Errors.min_thirty')
        .max(TOTAL_MINUTES_IN_A_DAY, 'Errors.more_minutes_than_day'),
    bookingWindowMaxDays: yup.number().required('Errors.required')
});
