import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useService, useStores } from 'Hooks';
import { Location } from 'Models/Location/Location';
import { Amenity } from 'Models/Amenities/Amenity';
import { AmenityCategory } from 'Models/Amenities/AmenityCategory';
import { AmenityService } from 'Services/AmenityService';
import { Typography } from 'antd';
import { Card } from 'Components/card';
import {Amenity as AmenityIcon} from 'Components/icons';
import { useTranslation } from 'react-i18next';
import SelectableList from 'Components/list/selectable-list';

import './booking-amenity.less';

const { Text } = Typography;

interface BookingAmenityProps {
    selectedLocation: Location | null;
    selectedAmenityCategory: AmenityCategory | null;
    selectedAmenity: Amenity | null;
    onChange: (value: any) => void;
}

const BookingAmenity: FunctionComponent<BookingAmenityProps> = ({
    selectedLocation,
    selectedAmenityCategory,
    selectedAmenity = null,
    onChange,
}) => {
    const { t } = useTranslation();
    const { globalLoadingStore, userStore } = useStores();
    const amenityService = useService(AmenityService);
    const [amenities, setAmenities] = useState<Amenity[]>([]);

    const fetch = useCallback(async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();
            // call api
            const [_amenities] = await amenityService.getAmenities({
                categoryIds:
                    selectedAmenityCategory && selectedAmenityCategory.id
                        ? [selectedAmenityCategory.id]
                        : null,
                locationIds:
                    selectedLocation && selectedLocation.id ? [selectedLocation?.id] : null,
            });
            setAmenities(_amenities);
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, amenityService, selectedAmenityCategory, selectedLocation]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    const getThumbnailExtra = (amenity: any): JSX.Element | null => {
        if (amenity.isApprovalRequired) {
            return (
                <div className="needs-approval">
                    <Text className="small d-flex align-items-center">
                        {t('Booking.needs_approval')}
                    </Text>
                </div>
            );
        } else return null;
    };

    const withCredits = userStore.isCoworking;

    return (
        <div className="BookingModal__Amenities">
            <SelectableList
                defaultSelected={selectedAmenity}
                itemKeyId="id"
                items={amenities}
                fallbackThumbnail={<AmenityIcon fill="#FFFFFF" />}
                getThumbnailUrl={(amenity): string => amenity.imageUrl || null}
                getThumbnailName={(amenity): string => amenity.name}
                getThumbnailExtra={(amenity): JSX.Element | null =>
                    getThumbnailExtra(amenity)
                }
                getDescription={(amenity): JSX.Element => (
                    <div className="amenity-description">
                        <Text strong className="amenity-title">
                            {amenity.name}
                        </Text>
                        <p className="mb-0">
                            {withCredits ? (
                                (amenity?.creditsPerBlock > 0) ? (
                                    t('Booking.credits_per_minutes', {
                                        param1: amenity?.creditsPerBlock,
                                        param2: amenity?.minutesPerBlock})) : null
                            ) : (
                                t('Booking.price_per_minutes', {
                                    param1: amenity?.pricePerBlock,
                                    param2: amenity?.minutesPerBlock})
                            )}
                        </p>
                    </div>
                )}
                getDetails={(amenity): JSX.Element => (
                    <Card
                        className="amenity-details-description"
                        title={<Text strong>{t('details')}</Text>}
                        theme="primary"
                        borderType="bordered"
                    >
                        <div
                            className="wysiwyg-text"
                            dangerouslySetInnerHTML={{
                                __html: amenity?.description || '',
                            }}
                        />
                    </Card>
                )}
                onItemClick={onChange}
            />
        </div>
    );
};

export default BookingAmenity;
