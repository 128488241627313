import React from 'react';
import { inject } from 'aurelia-dependency-injection';
import {
    AuthenticationStore,
    AuthorizationStore,
    ConfirmationModalStore,
    LoadingStore,
    LocationStore,
    NavigationStore,
    ToastStore,
    AmenityStore,
    BookingStore,
    UserStore,
} from 'Stores';

export interface GlobalStores {
    navigationStore: NavigationStore;
    authenticationStore: AuthenticationStore;
    authorizationStore: AuthorizationStore;
    confirmationModalStore: ConfirmationModalStore;
    locationStore: LocationStore;
    globalLoadingStore: LoadingStore;
    amenityStore: AmenityStore;
    bookingStore: BookingStore;
    toastStore: ToastStore;
    userStore: UserStore;
}

@inject(
    NavigationStore,
    AuthenticationStore,
    AuthorizationStore,
    ConfirmationModalStore,
    LocationStore,
    LoadingStore,
    AmenityStore,
    BookingStore,
    ToastStore,
    UserStore
)
export class StoresContext {
    context: React.Context<GlobalStores>;

    constructor(
        navigationStore: NavigationStore,
        authenticationStore: AuthenticationStore,
        authorizationStore: AuthorizationStore,
        confirmationModalStore: ConfirmationModalStore,
        locationStore: LocationStore,
        globalLoadingStore: LoadingStore,
        amenityStore: AmenityStore,
        bookingStore: BookingStore,
        toastStore: ToastStore,
        userStore: UserStore
    ) {
        this.context = React.createContext({
            navigationStore,
            authenticationStore,
            authorizationStore,
            confirmationModalStore,
            locationStore,
            globalLoadingStore,
            amenityStore,
            bookingStore,
            toastStore,
            userStore,
        });
    }
}
