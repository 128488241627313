import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Actions3: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg viewBox="0 0 24 24" fill="none" width="1em" height="1em" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.2 2.913A1.915 1.915 0 0 0 12.287 1a1.915 1.915 0 0 0-1.912 1.913c0 1.054.857 1.912 1.912 1.912A1.915 1.915 0 0 0 14.2 2.913zm-4.825 0A2.916 2.916 0 0 1 12.287 0 2.916 2.916 0 0 1 15.2 2.913a2.916 2.916 0 0 1-2.913 2.912 2.916 2.916 0 0 1-2.912-2.912zM14.2 11.975a1.915 1.915 0 0 0-1.913-1.912 1.915 1.915 0 0 0-1.912 1.912c0 1.055.857 1.913 1.912 1.913a1.915 1.915 0 0 0 1.913-1.913zm-4.825 0a2.916 2.916 0 0 1 2.912-2.912 2.916 2.916 0 0 1 2.913 2.912 2.916 2.916 0 0 1-2.913 2.913 2.916 2.916 0 0 1-2.912-2.913zM14.2 21.038a1.915 1.915 0 0 0-1.913-1.913 1.915 1.915 0 0 0-1.912 1.913c0 1.054.857 1.912 1.912 1.912a1.915 1.915 0 0 0 1.913-1.912zm-4.825 0a2.916 2.916 0 0 1 2.912-2.913 2.916 2.916 0 0 1 2.913 2.913 2.916 2.916 0 0 1-2.913 2.912 2.916 2.916 0 0 1-2.912-2.912z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Actions3;
