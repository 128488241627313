import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Pdf: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 21 21" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.6875 0H12.9079L18.4375 5.52958V21H2.6875V0ZM3.64205 0.954545V20.0455H17.483V6.20455H12.233V0.954545H3.64205ZM13.1875 1.62951L16.808 5.25H13.1875V1.62951Z"
                    fill={fill}
                />
                <path
                    d="M14.6173 13.4781C14.6112 13.4132 14.5539 12.6603 13.2489 12.6925C11.9439 12.7247 11.6269 12.8094 11.6269 12.8094C11.6269 12.8094 10.651 11.7967 10.2965 11.0116C10.2965 11.0116 10.7271 9.71993 10.7084 8.90885C10.6891 8.09725 10.4989 7.63013 9.89075 7.63637C9.28211 7.64312 9.19335 8.18817 9.27349 8.99977C9.34551 9.72772 9.71323 10.5835 9.71323 10.5835C9.71323 10.5835 9.43224 11.4793 9.06047 12.3709C8.68869 13.2625 8.43509 13.7296 8.43509 13.7296C8.43509 13.7296 7.17826 14.1604 6.63353 14.6794C6.0888 15.1985 5.86715 15.5975 6.15423 15.9971C6.40123 16.3411 7.26702 16.419 8.04049 15.3803C8.81346 14.3417 9.16191 13.6927 9.16191 13.6927C9.16191 13.6927 10.3416 13.3617 10.7084 13.2708C11.0751 13.1799 11.5189 13.1087 11.5189 13.1087C11.5189 13.1087 12.5961 14.2185 13.6349 14.1796C14.6741 14.1401 14.6234 13.5431 14.6173 13.4781ZM6.42456 15.8215C5.77839 15.4256 7.7803 14.205 8.14143 14.1661C8.14193 14.1661 7.10116 16.2361 6.42456 15.8215ZM9.49767 8.70101C9.49767 8.05828 9.70055 7.88317 9.8588 7.88317C10.017 7.88317 10.1946 7.96111 10.2012 8.51915C10.2072 9.07719 9.8588 10.1678 9.8588 10.1678C9.73859 10.0379 9.49767 9.34374 9.49767 8.70101ZM10.332 13.1019C9.68381 13.2641 9.35819 13.4329 9.35819 13.4329C9.35819 13.4329 9.35819 13.4329 9.62447 12.8229C9.89075 12.2129 10.1631 11.3821 10.1631 11.3821C10.5308 12.083 11.2592 12.9076 11.2592 12.9076C11.2592 12.9076 10.9802 12.9393 10.332 13.1019ZM11.8906 13.0453C11.8906 13.0453 13.9985 12.6541 13.9985 13.3914C13.9985 14.1292 12.693 13.8289 11.8906 13.0453Z"
                    fill={fill}
                    stroke={fill}
                    strokeWidth="0.2"
                />
            </svg>
        </span>
    );
};

export default Pdf;
