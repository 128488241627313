import { UserMessageDto } from 'Api/Features/Messages/Dtos/UserMessageDto';
import { MessageEntityTypeDto } from 'Api/Features/Messages/Dtos/MessageEntityTypeDto';
import { MessageReadStatusDto } from 'Api/Features/Messages/Dtos/MessageReadStatusDto';
import { NotificationTypeDto } from 'Api/Features/Notifications/Dtos/NotificationTypeDto';
import { UserMessageUserDto } from 'Api/Features/Messages/Dtos/UserMessageUserDto';
import { User } from 'Models/Users/User';
import { UserDto } from 'Api/Features/Users/Dtos/UserDto';
import { StatusBadgeStatus } from 'Components/status-badge/status-badge';

export class UserMessage implements UserMessageDto {
    constructor(dto: UserMessageDto) {
        Object.assign(this, dto);
    }

    id = '';
    entityType?: MessageEntityTypeDto | null = null;
    entityId?: string | null;
    type?: NotificationTypeDto | null;
    title?: string | null;
    body?: string | null;
    date?: string;
    createdByUser?: UserMessageUserDto | null;
    status?: MessageReadStatusDto;

    private _creator: User | null = null;

    public getConsoleUrl(userId: string): string {
        return `/users/${userId}/messages/${this.id}`;
    }

    get from(): User | null {
        if (this.createdByUser && !this._creator) {
            this._creator = new User({ ...this.createdByUser } as UserDto);
        }

        return this._creator;
    }

    get fromName(): string {
        const fromUser = this.from;
        return fromUser ? fromUser.name || '' : '';
    }

    get isUnread(): boolean {
        return !!(this.status && this.status === MessageReadStatusDto.Unread);
    }

    get isRead(): boolean {
        return !!(this.status && this.status === MessageReadStatusDto.Read);
    }

    get statusBadgeStatus(): StatusBadgeStatus | undefined {
        if(this.status === MessageReadStatusDto.Read) {
            return StatusBadgeStatus.Read;
        }
        else if(this.status === MessageReadStatusDto.Unread) {
            return StatusBadgeStatus.Unread;
        }
        return undefined;
    }
}
