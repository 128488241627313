import * as yup from 'yup';
import { StringSchema } from 'yup';

const BaseSchema = {
    password: yup.string().required('Errors.required'),
    confirmPassword: yup
        .string()
        .required('Errors.required')
        .when('password', (password: string, schema: StringSchema) =>
            schema.equals([password], 'Errors.passwords_must_match')
        ),
};

export const ResetPasswordSchema = yup.object(BaseSchema);

export const ConfirmAccountSchema = yup.object(
    Object.assign({}, BaseSchema, {
        acceptTerms: yup
            .bool()
            .required('Errors.consent_terms_and_policy_error')
            .equals([true], 'Errors.consent_terms_and_policy_error'),
    })
);
