import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useService, useStores } from 'Hooks';
import { LocationService } from 'Services/LocationService';
import { Location } from 'Models/Location/Location';
import { Col, Row, Typography } from 'antd';
import { Card } from 'Components/card';
import { Location as LocationIcon, Pin, PinStar, Email, Phone, Website } from 'Components/icons';
import { theme } from 'variant';
import { useTranslation } from 'react-i18next';
import SelectableList from 'Components/list/selectable-list';

import './booking-location.less';

const { Text } = Typography;

interface BookingLocationProps {
    selectedLocation: Location | null;
    onChange: (value: any) => void;
}

const BookingLocation: FunctionComponent<BookingLocationProps> = ({
    selectedLocation = null,
    onChange,
}) => {
    const { t } = useTranslation();
    const { globalLoadingStore, toastStore, userStore } = useStores();
    const locationService = useService(LocationService);
    const [locations, setLocations] = useState<Location[]>([]);

    const fetch = useCallback(async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();
            // call api
            const [_locations] = await locationService.getLocations({ hasAmenities: true });
            setLocations(_locations);
        }
        catch(error) {
            toastStore.displayError(error);
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, toastStore, locationService]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    const getDetails = (currentLocation: any): JSX.Element => {
        return (
            <Row className="no-wrap" gutter={[16, 0]}>
                {currentLocation?.introduction ? (
                    <Col flex="1 1 100%">
                        <Card
                            className="location-details-description"
                            title={<Text strong>{t('details')}</Text>}
                            borderType="bordered"
                            theme="primary"
                        >
                            <div
                                className="wysiwyg-text"
                                dangerouslySetInnerHTML={{
                                    __html: currentLocation.introduction,
                                }}
                            />
                        </Card>
                    </Col>
                ) : null}

                <Col flex="1 1 100%">
                    <Card
                        className="location-details-contact"
                        title={<Text strong>{t('Locations.contact_information_title')}</Text>}
                        theme="primary"
                        borderType="bordered"
                    >
                        {currentLocation?.contactInfo?.phoneNumber ? (
                            <div className="phone">
                                <span className="icon">
                                    <Phone fill={theme['primary-color']} />
                                </span>
                                {currentLocation.contactInfo.phoneNumber}
                            </div>
                        ) : null}
                        {currentLocation.contactInfo.website ? (
                            <div className="website">
                                <a
                                    href={currentLocation.contactInfo.website}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <span className="icon">
                                        <Website fill={theme['primary-color']} />
                                    </span>
                                    {currentLocation.contactInfo.website}
                                </a>
                            </div>
                        ) : null}
                        {currentLocation.contactInfo.email ? (
                            <div className="email">
                                <a href={`mailto:${currentLocation.contactInfo.email}`}>
                                    <span className="icon">
                                        <Email fill={theme['primary-color']} />
                                    </span>{' '}
                                    {currentLocation.contactInfo.email}
                                </a>
                            </div>
                        ) : null}
                    </Card>
                </Col>
            </Row>
        );
    };

    const getThumbnailExtra = (location: any): JSX.Element | null => {
        const userLocation = userStore?.user?.location;
        const userLocations = userStore?.user?.locations;

        if (userLocation?.id === location.id) {
            //Main location
            return (
                <div className="main-location">
                    <Text className="small d-flex align-items-center">
                        <span className="main-location-icon">
                            <PinStar fill="#fff" />
                        </span>{' '}
                        {t('Booking.main_location')}
                    </Text>
                </div>
            );
        } else if (
            userLocations &&
            userLocations?.filter((uLocation) => uLocation.id === location.id).length > 0
        ) {
            return (
                <div className="my-location">
                    <Text className="small d-flex align-items-center">
                        <span className="my-location-icon">
                            <Pin fill="#262627" />
                        </span>{' '}
                        {t('Booking.my_location')}
                    </Text>
                </div>
            );
        } else return null;
    };

    return (
        <div className="BookingModal__Locations">
            <div className="locations">
                <SelectableList
                    defaultSelected={selectedLocation}
                    itemKeyId="id"
                    items={locations}
                    getThumbnailUrl={(location): string => location.mainImageUrl || null}
                    fallbackThumbnail={<LocationIcon fill="#FFFFFF" />}
                    getThumbnailName={(location): string => location.name}
                    getThumbnailExtra={(location): JSX.Element | null =>
                        getThumbnailExtra(location)
                    }
                    getDescription={(location): JSX.Element => <Text strong>{location.name}</Text>}
                    getDetails={getDetails}
                    onItemClick={onChange}
                />
            </div>
        </div>
    );
};

export default BookingLocation;
