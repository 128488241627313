import React, { FunctionComponent, ReactNode } from 'react';
import { Modal, Typography, Button } from 'antd';

import './confirmation-modal.less';
import { Close } from 'Components/icons';

const { Title } = Typography;

interface ModalAction {
    text: string;
    action: () => void;
}

export interface ConfirmationModalProps {
    id: string;
    icon: ReactNode;
    title: string;
    message?: string;
    positive: ModalAction;
    negative?: ModalAction;
    width?: number | null;
}

const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = ({
    icon,
    title,
    message,
    positive,
    negative,
    width,
}) => {
    return (
        <Modal
            visible
            className="ConfirmationModal"
            footer={null}
            centered
            width={width ? width : 780}
            onCancel={negative && negative.action}
            closeIcon={<Close />}
        >
            <div className="icon">{icon}</div>
            <Title level={2} className="title">
                {title}
            </Title>
            {message && <div className="message">{message}</div>}
            <div className="actions">
                {negative && negative.text && (
                    <Button type="default" className="negative secondary" onClick={negative.action}>
                        {negative.text}
                    </Button>
                )}
                <Button type="default" className="positive" onClick={positive.action}>
                    {positive.text}
                </Button>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;
