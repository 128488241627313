import { observable, action, computed } from 'mobx';
import { LocationDto } from 'Api/Features/Locations/Dtos/LocationDto';
import { LightLocationInfo } from 'Models/Location/LightLocationInfo';
import { inject } from 'aurelia-dependency-injection';
import { LocationService } from 'Services/LocationService';

@inject(LocationService)
class LocationStore {
    constructor(private readonly locationService: LocationService) {}

    @observable
    private _locations: LightLocationInfo[] = [];

    @computed
    get locations(): LightLocationInfo[] {
        return this._locations;
    }

    @action
    async setLocations(): Promise<void> {
        const [items] = await this.locationService.getLocations();
        this._locations = items.map(
            (location: LocationDto) => {
                return new LightLocationInfo(
                    location.id,
                    location.name,
                    location.mainImageUrl,
                    location.timeZone,
                    location.type,
                    location.address
                );
            }
        );
    }
}

export default LocationStore;
