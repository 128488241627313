import { inject } from 'aurelia-dependency-injection';
import { CompanyProxy } from 'Api/Features/Companies/CompanyProxy';
import { ApiService } from './ApiService';
import { Company } from 'Models/Companies/Company';
import { CompanyDto } from 'Api/Features/Companies/Dtos/CompanyDto';
import { GetCompaniesRequestDto } from 'Api/Features/Companies/Dtos/GetCompaniesRequestDto';
import { GetCompaniesResponseDto } from 'Api/Features/Companies/Dtos/GetCompaniesResponseDto';
import { CreateCompanyRequestDto } from 'Api/Features/Companies/Dtos/CreateCompanyRequestDto';
import { UpdateCompanyRequestDto } from 'Api/Features/Companies/Dtos/UpdateCompanyRequestDto';
import { UpdateCompanyStatusRequestDto } from 'Api/Features/Companies/Dtos/UpdateCompanyStatusRequestDto';
import { GetCompanyMessagesRequestDto } from 'Api/Features/Messages/Dtos/GetCompanyMessagesRequestDto';
import { GetCompanyMessagesResponseDto } from 'Api/Features/Messages/Dtos/GetCompanyMessagesResponseDto';
import { CreateMessageRequestDto } from 'Api/Features/Messages/Dtos/CreateMessageRequestDto';
import { CompanyMessage } from 'Models/Messages/CompanyMessage';
import { CompanyMessageDto } from 'Api/Features/Messages/Dtos/CompanyMessageDto';
import { MessageProxy } from 'Api/Features/Messages/MessageProxy';
import { UpdateCompanyCreditBalanceRequestDto } from 'Api/Features/Companies/Dtos/UpdateCompanyCreditBalanceRequestDto';
import { UpdateCompanyCreditsPerIntervalRequestDto } from 'Api/Features/Companies/Dtos/UpdateCompanyCreditsPerIntervalRequestDto';
import { GetSectorsOfBusinessResponseDto } from 'Api/Features/Companies/Dtos/GetSectorsOfBusinessResponseDto';

@inject(CompanyProxy, MessageProxy)
export class CompanyService extends ApiService {
    constructor(
        private readonly companyProxy: CompanyProxy,
        private readonly messageProxy: MessageProxy) {
        super();
    }

    public async getCompanies(request: GetCompaniesRequestDto): Promise<[Company[], number]> {
        const response: GetCompaniesResponseDto | null = await this.companyProxy.getCompanies(
            request
        );
        return [
            response?.items?.map((dto: CompanyDto | null) => new Company(dto!)) || [],
            response?.totalItemCount || 0,
        ];
    }

    public async getById(id: string): Promise<Company | null> {
        const response: CompanyDto | null = await this.companyProxy.getCompany(id);
        return response ? new Company(response) : null;
    }

    public async createCompany(request: CreateCompanyRequestDto): Promise<void> {
        await this.companyProxy.createCompany(request);
    }

    public async updateCompanyStatus(companyId: string, request: UpdateCompanyStatusRequestDto | null): Promise<void> {
        await this.companyProxy.updateCompanyStatus(companyId, request);
    }

    public async editCompany(id: string, request: UpdateCompanyRequestDto): Promise<void> {
        await this.companyProxy.updateCompany(id, request);
    }

    public async deleteCompany(companyId: string): Promise<void> {
        await this.companyProxy.deleteCompany(companyId);
    }

    public async getCompanyMessages(
        id: string,
        request: GetCompanyMessagesRequestDto
    ): Promise<[CompanyMessage[], number]> {
        const response: GetCompanyMessagesResponseDto | null = await this.messageProxy.getCompanyMessages(
            id,
            request
        );
        return [
            response?.items?.map((dto) => new CompanyMessage(dto!)) || [],
            response?.totalItemCount || 0,
        ];
    }

    public async sendCompanyMessage(request: CreateMessageRequestDto): Promise<void> {
        await this.messageProxy.createMessage(request);
    }

    public async getCompanyMessageById(
        companyId: string,
        messageId: string
    ): Promise<CompanyMessage | null> {
        const response: CompanyMessageDto | null = await this.messageProxy.getCompanyMessage(companyId, messageId);
        return response ? new CompanyMessage(response) : null;
    }

    public async updateCompanyCreditBalance(companyId: string, request: UpdateCompanyCreditBalanceRequestDto | null): Promise<void> {
        await this.companyProxy.updateCompanyCreditBalance(companyId, request);
    }

    public async updateCompanyCreditsPerInterval(companyId: string, request: UpdateCompanyCreditsPerIntervalRequestDto | null): Promise<void> {
        await this.companyProxy.updateCompanyCreditsPerInterval(companyId, request);
    }

    public async getSectorsOfBusiness(): Promise<GetSectorsOfBusinessResponseDto | null> {
        const data: GetSectorsOfBusinessResponseDto | null = await this.companyProxy.getSectorsOfBusiness();
        return data;
    }
}
