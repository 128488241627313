import React, { FunctionComponent, useState } from 'react';
import { ForgotPasswordSchema } from 'Schemas';
import { useFormValidation, useService } from 'Hooks';
import { useTranslation } from 'react-i18next';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { AuthenticationService } from 'Services/AuthenticationService';
import { Link, Redirect } from 'react-router-dom';
import { Button, Row, Col, Form, Input, Typography } from 'antd';
import './forgot-password.less';
import { images } from 'variant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const { Title, Text } = Typography;

const ForgotPassword: FunctionComponent = () => {
    const [errors, validateForm] = useFormValidation(ForgotPasswordSchema);
    const authenticationService = useService(AuthenticationService);
    const [redirectPath, setRedirect] = useState<string>();
    const { t } = useTranslation();

    if (redirectPath) {
        return (
            <Redirect
                to={{
                    pathname: redirectPath,
                    state: { resetPasword: true },
                }}
            />
        );
    }
    return (
        <div className="ForgotPassword-container">
            <div className="ForgotPassword-header">
                <img src={images.logoSmallWhite} alt={t('brand_name')} />
            </div>
            <div className="ForgotPassword">
                <Link to="/" className="ForgotPassword-backButton">
                    <FontAwesomeIcon icon={['fas', 'angle-left']} />{' '}
                    {t('PasswordRecovery.recover_password_back_link')}
                </Link>
                <Row className="ForgotPassword-text" gutter={[0, 40]}>
                    <Col span={24}>
                        <Title level={3} className="title">{t('PasswordRecovery.recover_password_title')}</Title>
                        <Text className="message">{t('PasswordRecovery.recover_password_message')}</Text>
                    </Col>
                </Row>
                <Form
                    layout="vertical"
                    className="action-form"
                    hideRequiredMark={true}
                    onFinish={async (values): Promise<void> => {
                        const valid = await validateForm(values, false);
                        if (!valid) return;
                        try {
                            await authenticationService.forgottenPassword(values.username);
                        } finally {
                            setRedirect('/');
                        }
                    }}
                >
                    <ValidatedFormItem
                        errors={errors}
                        label={t('Login.email')}
                        name="username"
                        required
                    >
                        <Input />
                    </ValidatedFormItem>
                    <Button type="primary" htmlType="submit" className="login-btn">
                        {t('PasswordRecovery.recover_password_cta')}
                    </Button>
                </Form>
            </div>
        </div>
    );
};

export default ForgotPassword;
