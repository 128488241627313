import { inject } from 'aurelia-dependency-injection';
import { useTranslation } from 'react-i18next';
import { ApiService } from './ApiService';
import { LocationStore } from 'Stores';
import { UserProxy } from 'Api/Features/Users/UserProxy';
import { User } from 'Models/Users/User';
import { UserDetails } from 'Models/Users/UserDetails';
import { UserMessage } from 'Models/Messages/UserMessage';
import { LightLocationInfo } from 'Models/Location/LightLocationInfo';
import { GetUsersResponseDto } from 'Api/Features/Users/Dtos/GetUsersResponseDto';
import { UserDto } from 'Api/Features/Users/Dtos/UserDto';
import { UserDetailsDto } from 'Api/Features/Users/Dtos/UserDetailsDto';
import { GetUsersRequestDto } from 'Api/Features/Users/Dtos/GetUsersRequestDto';
import { CreateUserRequestDto } from 'Api/Features/Users/Dtos/CreateUserRequestDto';
import { UpdateUserRequestDto } from 'Api/Features/Users/Dtos/UpdateUserRequestDto';
import { UpdateUserStatusRequestDto } from 'Api/Features/Users/Dtos/UpdateUserStatusRequestDto';
import { GetUserMessagesRequestDto } from 'Api/Features/Messages/Dtos/GetUserMessagesRequestDto';
import { GetUserMessagesResponseDto } from 'Api/Features/Messages/Dtos/GetUserMessagesResponseDto';
import { CreateMessageRequestDto } from 'Api/Features/Messages/Dtos/CreateMessageRequestDto';
import { UpdateUserPasswordAnonymousRequestDto } from 'Api/Features/Users/Dtos/UpdateUserPasswordAnonymousRequestDto';
import { UpdateUserCompanyRolesRequestDto } from 'Api/Features/Users/Dtos/UpdateUserCompanyRolesRequestDto';
import { UserMessageDto } from 'Api/Features/Messages/Dtos/UserMessageDto';
import { MessageProxy } from 'Api/Features/Messages/MessageProxy';
import { ForgotPasswordRequestDto } from 'Api/Features/Users/Dtos/ForgotPasswordRequestDto';

@inject(UserProxy, MessageProxy, LocationStore)
export class UserService extends ApiService {
    constructor(
        private readonly userProxy: UserProxy,
        private readonly messageProxy: MessageProxy,
        private readonly locationStore: LocationStore
    ) {
        super();
    }

    public async getUsers(request: GetUsersRequestDto): Promise<[User[], number]> {
        const response: GetUsersResponseDto | null = await this.userProxy.getUsers(request);
        return [response?.items?.map((dto) => new User(dto!)) || [], response?.totalItemCount || 0];
    }

    public async getById(id: string): Promise<UserDetails | null> {
        const response: UserDetailsDto | null = await this.userProxy.getUser(id);
        return response ? new UserDetails(response) : null;
    }

    public async userIsManager(id: string): Promise<boolean> {
        let response = false;
        try {
            const userDto: UserDto | null = await this.userProxy.getUser(id);
            if (userDto && userDto!.managementRoles && userDto!.managementRoles.length > 0) {
                response = true;
            }
        } catch {
            response = false;
        }

        return response;
    }

    public async createUser(request: CreateUserRequestDto): Promise<string> {
        return await this.userProxy.createUser(request);
    }

    public async createManagerUserFromExistingUser(
        id: string,
        request: UpdateUserRequestDto
    ): Promise<void> {
        await this.userProxy.updateUser(id, request);
    }

    public async editUser(id: string, request: UpdateUserRequestDto): Promise<void> {
        await this.userProxy.updateUser(id, request);
    }

    public getManagerForLocationsString(locationsIds: string[]): string {
        const { t } = useTranslation();
        const locationsNames = this.locationStore.locations
            .filter((x: LightLocationInfo) => locationsIds.find((y) => y === x.id))
            .map((x: LightLocationInfo) => x.name)
            .join(', ');

        return `${t('ManagerUser.management_role_Manager')} (${locationsNames})`;
    }

    public async setPassword(request: UpdateUserPasswordAnonymousRequestDto): Promise<void> {
        await this.userProxy.updateUserPassword1(request);
    }

    public async setUserStatus(id: string, request: UpdateUserStatusRequestDto): Promise<void> {
        await this.userProxy.updateUserStatus(id, request);
    }

    public async updateUserCompanyRoles(
        userId: string,
        request: UpdateUserCompanyRolesRequestDto | null
    ): Promise<void> {
        await this.userProxy.updateUserCompanyRoles(userId, request);
    }

    public async getUserMessages(
        id: string,
        request: GetUserMessagesRequestDto
    ): Promise<[UserMessage[], number]> {
        const response: GetUserMessagesResponseDto | null = await this.messageProxy.getUserMessages(
            id,
            request
        );
        return [
            response?.items?.map((dto) => new UserMessage(dto!)) || [],
            response?.totalItemCount || 0,
        ];
    }

    public async sendUserMessage(request: CreateMessageRequestDto): Promise<void> {
        await this.messageProxy.createMessage(request);
    }

    public async getUserMessageById(
        userId: string,
        messageId: string
    ): Promise<UserMessage | null> {
        const response: UserMessageDto | null = await this.messageProxy.getUserMessage(
            userId,
            messageId
        );
        return response ? new UserMessage(response) : null;
    }

    public async forgotPassword(request: ForgotPasswordRequestDto | null): Promise<void> {
        await this.userProxy.forgotPassword(request);
    }

    public async deleteUser(id: string): Promise<void> {
        await this.userProxy.deleteUser(id);
    }

    public async deleteManagerUser(id: string): Promise<void> {
        await this.userProxy.deleteUser(id, true);
    }

    public async resendCompanyInvitation(userId: string): Promise<void> {
        await this.userProxy.resendCompanyInvitation(userId);
    }
}
