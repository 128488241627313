import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Pencil: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.4539 3.75546L15.0045 5.20241L12.0777 2.27562L13.5259 0.826271C13.6087 0.743344 13.7161 0.69102 13.8197 0.6825C13.8684 0.678779 13.9586 0.681299 14.0232 0.744544L16.5356 3.25814C16.6465 3.36795 16.6075 3.60065 16.4539 3.75546ZM5.71699 14.474L2.80604 11.5631L11.5974 2.7583L14.523 5.68281L5.71699 14.474ZM1.09711 16.183L2.46594 12.1872L5.09042 14.8105L1.09711 16.183ZM17.0171 2.77546L14.5047 0.263067C14.3158 0.0741723 14.044 -0.019675 13.7623 0.00336676C13.4966 0.0254485 13.2418 0.147378 13.0432 0.344793L2.01986 11.3864L-1.52588e-05 17.2813L5.90348 15.2506L16.9353 4.23706C17.3608 3.81162 17.3974 3.15577 17.0171 2.77546Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Pencil;
