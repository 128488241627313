import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const StaffUser: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 10 16" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.333252 16H9.59105V1.45972H0.333252V16ZM0.735731 15.4745H9.18858V1.98535H0.735731V15.4745Z"
                    fill="#CEB888"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.8092 11.7931H8.11522V11.3906H1.8092V11.7931Z"
                    fill="#CEB888"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.8092 13.1238H8.11522V12.7214H1.8092V13.1238Z"
                    fill="#CEB888"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.8092 14.4814H8.11522V14.0789H1.8092V14.4814Z"
                    fill="#CEB888"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.9775 6.58407H4.97513C4.76691 6.58328 4.57074 6.50193 4.42313 6.35365C4.27564 6.20536 4.19429 6.00841 4.19508 5.80007C4.19508 5.59105 4.27722 5.39477 4.4255 5.24728C4.57378 5.10046 4.76916 5.01911 4.9775 5.01911H4.97908C5.1873 5.0199 5.38358 5.10204 5.53187 5.24953C5.67936 5.3986 5.76071 5.59477 5.75992 5.80311C5.75913 6.23432 5.40781 6.58407 4.9775 6.58407ZM5.79046 4.99173C5.57367 4.77427 5.28623 4.65415 4.97987 4.65336H4.97671C4.67181 4.65336 4.38437 4.77201 4.1677 4.98801C3.95023 5.20469 3.83001 5.4928 3.82933 5.79928C3.82865 6.43196 4.34178 6.94756 4.97457 6.94903H4.9775C5.2824 6.94903 5.56995 6.83049 5.7873 6.61449C6.00477 6.3986 6.12499 6.11038 6.12567 5.8039C6.12646 5.4981 6.00713 5.20919 5.79046 4.99173Z"
                    fill="#CEB888"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.12044 7.82472C4.18286 7.81041 4.24743 7.80219 4.3174 7.80083C4.32438 7.80005 4.33193 7.80005 4.33869 7.80005L5.60709 7.80286C5.61396 7.80286 5.62072 7.80286 5.63222 7.80365C5.75007 7.80703 5.86478 7.83159 5.97261 7.87667C6.33588 8.02799 6.57002 8.38281 6.56912 8.77976L6.56709 9.75689L3.35672 9.75002L3.35875 8.77278C3.35976 8.32421 3.67999 7.92568 4.12044 7.82472ZM5.60788 7.43779L4.33948 7.43509C3.59886 7.43351 2.99458 8.03362 2.993 8.77199L2.99007 10.1141L6.93126 10.1227L6.93419 8.78055C6.93576 8.04219 6.34084 7.43948 5.60788 7.43779Z"
                    fill="#CEB888"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.75849 3.60721H6.1657V3.03256H3.75849V3.60721ZM3.4492 3.91662H6.47522V2.72316H3.4492V3.91662Z"
                    fill="#CEB888"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.31759 3.33333H5.60683V0H4.31759V3.33333Z"
                    fill="#CEB888"
                />
            </svg>
        </span>
    );
};

export default StaffUser;
