import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const ConferenceRoom: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.842399 1.96229H13.0593V0.84243H0.842399V1.96229ZM1.48712 11.2947H12.4146V2.47135H1.48712V11.2947ZM7.58234 13.8595C7.58234 14.2077 7.29899 14.491 6.95086 14.491C6.60263 14.491 6.31938 14.2077 6.31938 13.8595C6.31938 13.5114 6.60263 13.228 6.95086 13.228C7.29899 13.228 7.58234 13.5114 7.58234 13.8595ZM13.5684 0.333374H0.333344V2.47124H0.978168V11.8036H6.67943V12.752C6.18117 12.8741 5.81033 13.3241 5.81033 13.8595C5.81033 14.4884 6.32193 15 6.95086 15C7.5797 15 8.0913 14.4884 8.0913 13.8595C8.0913 13.312 7.70365 12.8537 7.18848 12.744V11.8036H12.9237V2.47124H13.5684V0.333374Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.60719 10.0051H3.86261V8.74939H2.60719V10.0051ZM5.25401 4.54138H8.61359V3.76089H5.25401V4.54138ZM10.0391 10.0051H11.2945V8.74939H10.0391V10.0051ZM6.30614 10.0051H7.59558V8.74939H6.30614V10.0051ZM4.37167 8.24034H3.48933V6.98481H6.67943V8.24034H5.79708V10.5142H8.10464V8.24034H7.18848V6.98481H10.4124V8.24034H9.52995V10.5142H11.8036V8.24034H10.9215V6.47586H7.18848V5.05044H9.12265V3.25194H4.74506V5.05044H6.67943V6.47586H2.98027V8.24034H2.09813V10.5142H4.37167V8.24034Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default ConferenceRoom;
