import React, { FunctionComponent } from 'react';
import { Layout, Row, Col } from 'antd';
import { images } from 'variant';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import './webapp-utilities-layout.less';

type WebAppUtilitiesLayoutProps = {
    showBackToLogin?: boolean;
    children: React.ReactNode;
};

const { Content } = Layout;

const WebAppUtilitiesLayout: FunctionComponent<WebAppUtilitiesLayoutProps> = ({
    showBackToLogin = false,
    children,
}) => {
    const { t } = useTranslation();
    return (
        <div
            className={`WebAppUtilitiesLayout ${process.env.REACT_APP_VARIANT}`}
            style={{
                background: 'linear-gradient(180deg, rgba(50, 50, 50, 0.81) 31.04%, rgba(66, 66, 67, 0.19) 121.3%), ' +
                    `url(${images.utilitiesBackground}) no-repeat center center, #fff`,
                backgroundSize: 'cover'
            }}
        >
            <Layout>
                <Content>
                    <Row align="middle" className="">
                        <Col>
                            <div className="logo">
                                <img src={images.logoSmallWhite} alt={t('app_name')} />
                            </div>
                        </Col>
                    </Row>
                    <Row align="middle" className="">
                        <Col>
                            {showBackToLogin && (
                                <Link to="/" className="WebAppActionsLayout-backButton">
                                    <FontAwesomeIcon icon={['fas', 'angle-left']} />{' '}
                                    {t('PasswordRecovery.recover_password_back_link')}
                                </Link>
                            )}
                            <div className="content">{children}</div>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </div>
    );
};

export default WebAppUtilitiesLayout;
