import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Recurring: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 16 17" fill="none" {...props}>
                <path
                    d="M8.00005 15.8923C11.9765 15.8923 15.2 12.6688 15.2 8.69231C15.2 4.71586 11.9765 1.49231 8.00005 1.49231C4.0236 1.49231 0.800049 4.71586 0.800049 8.69231C0.800049 12.6688 4.0236 15.8923 8.00005 15.8923Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.2666 8.15493L11.2681 9.20971L10.2695 8.15493H11.1832C10.9674 6.52266 9.568 5.25888 7.87767 5.25888C6.67494 5.25888 5.56095 5.91093 4.97 6.9596L4.65039 6.77993C5.30611 5.61577 6.54233 4.89221 7.87767 4.89221C9.76967 4.89221 11.3353 6.31977 11.5523 8.15493H12.2666Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.8861 7.88833L11.2679 9.59766L9.64963 7.88833H10.8632C10.5435 6.5354 9.32621 5.52561 7.87747 5.52561C6.77122 5.52561 5.74591 6.12559 5.20212 7.09058L5.07134 7.32266L4.28687 6.88167L4.41785 6.64913C5.12074 5.40122 6.44567 4.62561 7.87747 4.62561C9.82466 4.62561 11.4487 6.03526 11.7819 7.88833H12.8861ZM11.1366 7.88833C11.1556 7.976 11.1711 8.06494 11.183 8.155H10.2693L11.2679 9.20978L12.2664 8.155H11.5521C11.3351 6.31983 9.76947 4.89228 7.87747 4.89228C6.63146 4.89228 5.47175 5.52226 4.78944 6.55236C4.74052 6.62621 4.69406 6.70212 4.65019 6.78L4.9698 6.95967C5.56075 5.911 6.67474 5.25894 7.87747 5.25894C9.47454 5.25894 10.8119 6.38711 11.1366 7.88833Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.0169 10.5549C10.3349 11.6445 9.16156 12.2947 7.87761 12.2947C6.07905 12.2947 4.57633 11.0059 4.24456 9.30331H3.46655L4.46517 8.24854L5.46372 9.30331H4.61917C4.94489 10.8018 6.282 11.928 7.87761 11.928C9.03384 11.928 10.0916 11.342 10.7058 10.3605L11.0169 10.5549Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.3846 10.4701L11.243 10.6963C10.5123 11.8637 9.25362 12.5613 7.87767 12.5613C6.02565 12.5613 4.46718 11.2873 4.03122 9.56992H2.84692L4.46524 7.8606L6.08345 9.56992H4.96904C5.37754 10.7841 6.52748 11.6613 7.87767 11.6613C8.94191 11.6613 9.91431 11.1226 10.4798 10.219L10.6212 9.99313L11.3846 10.4701ZM4.68897 9.56992C5.10704 10.9333 6.37821 11.928 7.87767 11.928C9.0339 11.928 10.0917 11.3419 10.7059 10.3605L11.0168 10.5547C10.9694 10.6306 10.9196 10.7044 10.8675 10.7759C10.1711 11.7318 9.07225 12.2946 7.87767 12.2946C6.07911 12.2946 4.57639 11.0058 4.24462 9.30325H3.46661L4.46523 8.24848L5.46378 9.30325H4.61923C4.63886 9.39355 4.66216 9.48249 4.68897 9.56992Z"
                    fill="white"
                />
            </svg>
        </span>
    );
};

export default Recurring;
