import { Delete, PencilBorder, Amenity as AmenityIcon, CheckMark, Caution } from 'Components/icons';
import { ImageWithPlaceholder } from 'Components/image-with-placeholder';
import { Amenity } from 'Models/Amenities/Amenity';
import { DAY_DISPLAY_FORMAT, TIME_FORMAT } from 'Models/Constants';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'variant';
import './booking-conflict-item.less';

export interface BookingConflictItemProps {
    index: number;
    periodStart?: moment.Moment;
    periodEnd?: moment.Moment;
    amenity?: Amenity;
    resolved?: boolean;
    errorMessage?: string;
    onDeleteClick: (index: number) => void;
    onEditClick: (index: number) => void;
    isCanceled?: boolean;
}

export const BookingConflictItem: FunctionComponent<BookingConflictItemProps> = ({
    index,
    periodStart,
    periodEnd,
    amenity,
    resolved,
    errorMessage,
    onDeleteClick,
    onEditClick,
    isCanceled
}) => {
    const { t } = useTranslation();

    return (
        <div className={`BookingConflictItem ${isCanceled ? 'canceled' : ''}`}>
            <div className="row-container">
                <div className="booking-date-container">
                    <div className="booking-date">{periodStart?.format(DAY_DISPLAY_FORMAT)}</div>
                    <div className="booking-time">{`${periodStart?.format(
                        TIME_FORMAT
                    )} - ${periodEnd?.format(TIME_FORMAT)}`}</div>
                </div>
                <div className="actions-container">
                    <div onClick={(): void => onDeleteClick(index)}>
                        <Delete width={24} height={24} fill={theme['primary-color']} />
                    </div>
                    <div onClick={(): void => onEditClick(index)}>
                        <PencilBorder width={24} height={24} fill={theme['primary-color']} />
                    </div>
                </div>
            </div>

            <div className="row-container">
                <div className="amenity">
                    <ImageWithPlaceholder
                        width={32}
                        height={32}
                        imgSrc={amenity?.imageUrl}
                        defaultImg={<AmenityIcon fill={theme['white']} />}
                    />
                    <span className="amenity-name">{amenity?.name}</span>
                </div>

                <div className={`resolution ${resolved ? 'resolved' : ''}`}>
                    {resolved ? (
                        <div className="resolution-container">
                            <span className="text">{t('Booking.conflict_resolved')}</span>
                            <CheckMark width={22} height={24} fill={theme['success-green']} />
                        </div>
                    ) : (
                        <div className="resolution-container">
                            <span className="text">{errorMessage}</span>
                            <Caution width={24} height={20} fill={theme['error-red']} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};