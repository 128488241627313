import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Trash: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg
                width="1em"
                height="1em"
                viewBox="0 0 50 60"
                fill="none"
                {...props}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.55922 15.2358H46.6128V8.91411H2.55922V15.2358ZM6.39636 57.4449H42.7759V17.5469H6.39636V57.4449ZM17.9477 6.60277H31.2232V2.55922H17.9477V6.60277ZM47.766 6.60281H33.535V1.40573C33.535 0.764884 33.0182 0.248071 32.3773 0.248071H16.7902C16.1539 0.248071 15.6371 0.764884 15.6371 1.40573V6.60281H1.4016C0.764884 6.60281 0.248071 7.11963 0.248071 7.76047V16.3933C0.248071 17.03 0.764884 17.5469 1.4016 17.5469H4.0849V58.5984C4.0849 59.2351 4.60171 59.7519 5.23842 59.7519H43.9292C44.5655 59.7519 45.0868 59.2351 45.0868 58.5984V17.5469H47.766C48.4031 17.5469 48.9236 17.03 48.9236 16.3933V7.76047C48.9236 7.11963 48.4031 6.60281 47.766 6.60281Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.3891 1.40573C15.3891 0.628515 16.0163 0 16.7902 0H32.3773C33.1552 0 33.7831 0.627879 33.7831 1.40573V6.35474H47.766C48.5396 6.35474 49.1717 6.9821 49.1717 7.76047V16.3933C49.1717 17.1681 48.539 17.7949 47.766 17.7949H45.3349V58.5984C45.3349 59.3733 44.7013 60 43.9292 60H5.23842C4.4647 60 3.83682 59.3721 3.83682 58.5984V17.7949H1.4016C0.627879 17.7949 0 17.167 0 16.3933V7.76047C0 6.9832 0.627301 6.35474 1.4016 6.35474H15.3891V1.40573ZM16.7902 0.496141C16.2916 0.496141 15.8852 0.901254 15.8852 1.40573V6.85088H1.4016C0.902468 6.85088 0.496141 7.25605 0.496141 7.76047V16.3933C0.496141 16.893 0.90189 17.2988 1.4016 17.2988H4.33297V58.5984C4.33297 59.0981 4.73871 59.5039 5.23842 59.5039H43.9292C44.4297 59.5039 44.8387 59.0969 44.8387 58.5984V17.2988H47.766C48.2672 17.2988 48.6756 16.8919 48.6756 16.3933V7.76047C48.6756 7.25715 48.2666 6.85088 47.766 6.85088H33.2869V1.40573C33.2869 0.901889 32.8812 0.496141 32.3773 0.496141H16.7902ZM17.6996 2.31115H31.4713V6.85084H17.6996V2.31115ZM18.1958 2.80729V6.3547H30.9751V2.80729H18.1958ZM2.31115 8.66604H46.8609V15.4838H2.31115V8.66604ZM2.80729 9.16218V14.9877H46.3648V9.16218H2.80729ZM6.14829 17.2989H43.024V57.693H6.14829V17.2989ZM6.64443 17.795V57.1969H42.5278V17.795H6.64443Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.9847 52.7997C14.3463 52.7997 13.8291 52.2825 13.8291 51.6441V27.5436C13.8291 26.9053 14.3463 26.3881 14.9847 26.3881C15.6231 26.3881 16.1403 26.9053 16.1403 27.5436V51.6441C16.1403 52.2825 15.6231 52.7997 14.9847 52.7997Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.9847 26.6361C14.4834 26.6361 14.0772 27.0423 14.0772 27.5436V51.6441C14.0772 52.1455 14.4834 52.5516 14.9847 52.5516C15.4861 52.5516 15.8922 52.1455 15.8922 51.6441V27.5436C15.8922 27.0423 15.4861 26.6361 14.9847 26.6361ZM13.581 27.5436C13.581 26.7683 14.2093 26.14 14.9847 26.14C15.7601 26.14 16.3884 26.7683 16.3884 27.5436V51.6441C16.3884 52.4195 15.7601 53.0478 14.9847 53.0478C14.2093 53.0478 13.581 52.4195 13.581 51.6441V27.5436Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24.5836 52.7997C23.9452 52.7997 23.428 52.2825 23.428 51.6441V27.5436C23.428 26.9053 23.9452 26.3881 24.5836 26.3881C25.2219 26.3881 25.7391 26.9053 25.7391 27.5436V51.6441C25.7391 52.2825 25.2219 52.7997 24.5836 52.7997Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24.5836 26.6361C24.0822 26.6361 23.676 27.0423 23.676 27.5436V51.6441C23.676 52.1455 24.0822 52.5516 24.5836 52.5516C25.0849 52.5516 25.4911 52.1455 25.4911 51.6441V27.5436C25.4911 27.0423 25.0849 26.6361 24.5836 26.6361ZM23.1799 27.5436C23.1799 26.7683 23.8082 26.14 24.5836 26.14C25.3589 26.14 25.9872 26.7683 25.9872 27.5436V51.6441C25.9872 52.4195 25.3589 53.0478 24.5836 53.0478C23.8082 53.0478 23.1799 52.4195 23.1799 51.6441V27.5436Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M34.1853 52.7997C33.5469 52.7997 33.0297 52.2825 33.0297 51.6441V27.5436C33.0297 26.9053 33.5469 26.3881 34.1853 26.3881C34.8237 26.3881 35.3409 26.9053 35.3409 27.5436V51.6441C35.3409 52.2825 34.8237 52.7997 34.1853 52.7997Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M34.1853 26.6361C33.6839 26.6361 33.2778 27.0423 33.2778 27.5436V51.6441C33.2778 52.1455 33.6839 52.5516 34.1853 52.5516C34.6867 52.5516 35.0928 52.1455 35.0928 51.6441V27.5436C35.0928 27.0423 34.6867 26.6361 34.1853 26.6361ZM32.7816 27.5436C32.7816 26.7683 33.4099 26.14 34.1853 26.14C34.9607 26.14 35.589 26.7683 35.589 27.5436V51.6441C35.589 52.4195 34.9607 53.0478 34.1853 53.0478C33.4099 53.0478 32.7816 52.4195 32.7816 51.6441V27.5436Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Trash;
