import { action, computed, observable } from 'mobx';

class LoadingStore {
    parent: LoadingStore;
    constructor(parent: LoadingStore) {
        this.parent = parent;
    }

    @observable loadingCount = 0;
    @observable useExplanation = false;

    @computed get isLoading(): boolean {
        return !(this.parent && this.parent.isLoading) && this.loadingCount > 0;
    }

    @computed get withExplanation(): boolean {
        return this.useExplanation;
    }

    @action addLoading(useExplanation?: boolean): void {
        this.loadingCount++;
        if(useExplanation)
            this.useExplanation = true;
    }

    @action removeLoading(): void {
        if (this.loadingCount > 0) {
            this.loadingCount--;
            this.useExplanation = false;
        }
    }
}

export default LoadingStore;
