import { MAX_LENGTH_25, MAX_LENGTH_256, MAX_LENGTH_4000, MAX_LENGTH_50 } from 'Models/Constants';
import * as yup from 'yup';

const phoneRegex = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const InviteUserSchema = yup.object({
    firstName: yup.string().required('Errors.required').max(MAX_LENGTH_50, 'Errors.length_50'),
    lastName: yup.string().required('Errors.required').max(MAX_LENGTH_50, 'Errors.length_50'),
    contactInfo: yup.object().shape({
        phoneNumber: yup.string().matches(phoneRegex, 'Errors.phone_invalid').max(MAX_LENGTH_25, 'Errors.length_25'),
        email: yup.string().email('Errors.email_invalid').required('Errors.required').max(MAX_LENGTH_256, 'Errors.length_256'),
    }),
    companyRoles: yup.string().required('Errors.required'),
    companyId: yup.string().required('Errors.required'),
    createBuildingAccess: yup.boolean(),
    locationId: yup.string().when('createBuildingAccess', {
        is: true,
        then: yup.string().required('Errors.required')
    }),
    note: yup.string().max(MAX_LENGTH_4000, 'Errors.length_4000')
});
