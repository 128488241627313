import React from 'react';
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { AngleLeft, AngleRight } from 'Components/icons';
import { Button } from 'antd';

const stringDateFormat = 'dddd, MMMM D, YYYY';

interface DateControlsProps {
    selectedDate?: moment.Moment;
    onChange: (newDate: moment.Moment) => void;
    showTodayButton: boolean;
    displayForConflictResolution?: boolean;
}

interface DateControlsState {
    selectedDate?: moment.Moment;
    pickerOpen: boolean;
}

class DateControls extends React.Component<DateControlsProps, DateControlsState> {
    constructor(props: DateControlsProps) {
        super(props);

        this.state = {
            pickerOpen: false,
            selectedDate: props.selectedDate,
        };
    }

    componentDidUpdate(prevProps: any): any {
        if (prevProps.selectedDate !== this.props.selectedDate) {
            this.setState({ selectedDate: this.props.selectedDate });
        }
    }

    handleSubtractDay = () => {
        this.setState(
            (state): DateControlsState => {
                const newDate = moment(state.selectedDate).subtract(1, 'day');
                this.props.onChange(newDate);
                return {
                    selectedDate: newDate,
                    pickerOpen: this.state.pickerOpen,
                };
            }
        );
    };

    handleAddDay = () => {
        this.setState((state): any => {
            const newDate = moment(state.selectedDate).add(1, 'day');
            this.props.onChange(newDate);
            return {
                selectedDate: newDate,
            };
        });
    };

    togglePicker = (e?: any): void => {
        this.setState((prevState) => ({ pickerOpen: !prevState.pickerOpen }));
    };

    handleDateChange = (date: any): void => {
        this.setState({
            selectedDate: moment(date),
        });
        this.props.onChange(date);
    };

    handleTodayClick = (): void => {
        const selectedDate = moment();
        this.setState({ selectedDate });
        this.props.onChange(selectedDate);
    };

    renderDateComponent = (props: any): any => {
        return (
            <div
                className="FlexibleBooking__ActionsBar_DateControls_CurrentDate"
                onClick={this.togglePicker}
            >
                {moment(props.value).format(stringDateFormat)}
            </div>
        );
    };

    render() {
        return (
            <>
                <div className={'FlexibleBooking__ActionsBar_DateControls'}>
                    {this.props.displayForConflictResolution && (
                        <span className="add-btn" onClick={this.handleSubtractDay}>
                            <AngleLeft width={14} height={24} />
                        </span>
                    )}

                    <div className="FlexibleBooking__ActionsBar_DateControls_DatePicker">
                        <KeyboardDatePicker
                            value={this.state.selectedDate}
                            onChange={this.handleDateChange}
                            format="MM/DD/YYYY"
                            open={this.state.pickerOpen}
                            onClose={this.togglePicker}
                            TextFieldComponent={this.renderDateComponent}
                        />
                    </div>

                    {this.props.displayForConflictResolution && (
                        <span className="substract-btn" onClick={this.handleAddDay}>
                            <AngleRight width={14} height={24} />
                        </span>
                    )}

                    {!this.props.displayForConflictResolution && (
                        <>
                            <Button
                                className="bordered-btn date-change-btn"
                                color="link"
                                onClick={this.handleSubtractDay}
                            >
                                <AngleLeft />
                            </Button>
                            <Button
                                className="bordered-btn date-change-btn"
                                color="link"
                                onClick={this.handleAddDay}
                            >
                                <AngleRight />
                            </Button>
                        </>
                    )}
                </div>
                {this.props.showTodayButton && (
                    <Button className="bordered-btn" onClick={this.handleTodayClick}>
                        {'Today'}
                    </Button>
                )}
            </>
        );
    }
}

export default DateControls;
