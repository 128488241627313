import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Credit: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 29 24" fill="none" {...props}>
                <path
                    d="M28 13.7059V18.4706C28 20.5353 23.0225 23.2353 16.8824 23.2353C10.7422 23.2353 5.76471 20.5353 5.76471 18.4706V14.5001"
                    stroke={fill}
                />
                <path
                    d="M6.23163 14.9113C7.60545 16.7346 11.854 18.4452 16.8823 18.4452C23.0224 18.4452 28 15.8945 28 13.7027C28 12.4718 26.4324 11.1234 23.9722 10.1768"
                    stroke={fill}
                />
                <path
                    d="M23.2353 5.76471V10.5294C23.2353 12.5941 18.2578 15.2941 12.1176 15.2941C5.97753 15.2941 1 12.5941 1 10.5294V5.76471"
                    stroke={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.1176 10.504C18.2578 10.504 23.2353 7.95329 23.2353 5.76153C23.2353 3.56818 18.2578 1 12.1176 1C5.97753 1 1 3.56818 1 5.76153C1 7.95329 5.97753 10.504 12.1176 10.504Z"
                    stroke={fill}
                />
            </svg>
        </span>
    );
};

export default Credit;
