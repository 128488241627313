import React, { FunctionComponent, useLayoutEffect } from 'react';
import './App.less';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'Routes/webapp';
import { ConfirmationModalContainer } from 'Components/confirmation-modal';
import { GlobalLoadingContainer } from 'Components/global-loading-container';
import { ToastContainer } from 'Components/toast-container';
import { AuthenticationService } from 'Services/AuthenticationService';
import { useService } from 'Hooks';
import faLibrary from 'Components/icons/faLibrary';
import { dom } from '@fortawesome/fontawesome-svg-core';

const App: FunctionComponent = () => {
    const authenticationService = useService(AuthenticationService);
    useLayoutEffect(() => {
        authenticationService.installInterceptors();
    }, [authenticationService]);
    faLibrary.init();
    dom.watch();
    return (
        <>
            <BrowserRouter forceRefresh={true}>
                <Routes />
            </BrowserRouter>
            <ConfirmationModalContainer />
            <ToastContainer />
            <GlobalLoadingContainer />
        </>
    );
};

export default App;
