import { AmenityCategoryDto } from 'Api/Features/AmenityCategories/Dtos/AmenityCategoryDto';

export class AmenityCategory implements AmenityCategoryDto {
    constructor(dto: AmenityCategoryDto) {
        Object.assign(this, dto);
    }
    id = '';
    name: string | null = null;
    order = 0;
    imageUrl: string | null = null;
    iconUrl: string | null = null;

    get consoleUrl(): string {
        return `/settings/amenity-categories/${this.id}`;
    }
}