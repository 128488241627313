import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Coworking: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="28" height="16" viewBox="0 0 28 16" fill="none" {...props}>
                <circle cx="8" cy="8" r="8" fill="white" />
                <path
                    d="M8.00005 15.1998C11.9765 15.1998 15.2 11.9763 15.2 7.9998C15.2 4.02335 11.9765 0.799805 8.00005 0.799805C4.0236 0.799805 0.800049 4.02335 0.800049 7.9998C0.800049 11.9763 4.0236 15.1998 8.00005 15.1998Z"
                    fill={fill}
                />
                <path
                    d="M6.09791 11.0086C5.93065 11.0944 5.74085 10.944 5.77465 10.7521L6.13431 8.70244L4.60771 7.2482C4.46514 7.11214 4.53924 6.86341 4.73034 6.83654L6.85276 6.53495L7.79915 4.65995C7.88451 4.49095 8.11547 4.49095 8.20084 4.65995L9.14722 6.53495L11.2696 6.83654C11.4607 6.86341 11.5348 7.11214 11.3923 7.2482L9.86567 8.70244L10.2253 10.7521C10.2591 10.944 10.0693 11.0944 9.90207 11.0086L7.99934 10.031L6.09791 11.0086Z"
                    fill="white"
                />
                <circle cx="20" cy="8" r="8" fill="white" />
                <path
                    d="M20 15.1998C23.9765 15.1998 27.2 11.9763 27.2 7.9998C27.2 4.02335 23.9765 0.799805 20 0.799805C16.0236 0.799805 12.8 4.02335 12.8 7.9998C12.8 11.9763 16.0236 15.1998 20 15.1998Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.2989 9.27777H16.7059V5.41189C16.704 5.31618 16.7401 5.22361 16.8062 5.1544C16.8723 5.08518 16.9632 5.04495 17.0589 5.04248H22.9412C23.0369 5.04495 23.1277 5.08518 23.1939 5.1544C23.26 5.22361 23.2961 5.31618 23.2942 5.41189V9.27777H22.9472V9.27778H17.2989V9.27777ZM21.3671 9.74835C21.2847 9.96011 21.1459 10.1013 20.9882 10.1013H18.9976C18.8376 10.1013 18.7012 9.96011 18.6188 9.74835H16V10.1601C16.012 10.311 16.083 10.4511 16.1974 10.5502C16.3119 10.6492 16.4607 10.6993 16.6118 10.6895H23.3882C23.5393 10.6993 23.6881 10.6492 23.8026 10.5502C23.917 10.4511 23.988 10.311 24 10.1601V9.74835H21.3671Z"
                    fill="white"
                />
            </svg>
        </span>
    );
};

export default Coworking;
