import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const PinStar: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg
                width="1em"
                height="1em"
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <g clipPath="url(#clip0)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.01185 7.80663C4.07635 7.80663 4.13811 7.77998 4.18234 7.73295C4.26001 7.65035 6.09261 5.69948 6.65748 4.85025C6.91612 4.46157 7.11108 3.8549 7.11108 3.43905C7.11108 1.73022 5.72078 0.339966 4.01185 0.339966C2.30297 0.339966 0.912611 1.73022 0.912611 3.43905C0.912611 3.8549 1.10763 4.46157 1.36631 4.8503C1.93119 5.69948 3.76368 7.65035 3.84136 7.73295C3.88559 7.77998 3.94734 7.80663 4.01185 7.80663ZM5.19822 4.84511C5.21642 4.95536 5.11423 5.04173 5.02416 4.99245L4.00031 4.43097L2.97577 4.99245C2.8857 5.04173 2.7835 4.95536 2.8017 4.84511L2.99537 3.6679L2.17335 2.83266C2.09658 2.75451 2.13648 2.61165 2.23938 2.59622L3.38223 2.423L3.89182 1.3461C3.93778 1.24903 4.06215 1.24903 4.10811 1.3461L4.6177 2.423L5.76055 2.59622C5.86344 2.61165 5.90334 2.75451 5.82658 2.83266L5.00456 3.6679L5.19822 4.84511Z"
                        fill={fill}
                    />
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect
                            width="7.46667"
                            height="7.46667"
                            fill={fill}
                            transform="matrix(-1 0 0 1 7.73328 0.339966)"
                        />
                    </clipPath>
                </defs>
            </svg>
        </span>
    );
};

export default PinStar;
