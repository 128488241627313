import { MAX_LENGTH_4000, MAX_LENGTH_50 } from 'Models/Constants';
import * as yup from 'yup';
import { string } from 'yup';

export const CreateFoodPromotionSchema = yup.object({
    name: yup.string().required('Errors.required').max(MAX_LENGTH_50, 'Errors.length_50'),
    description: yup.string().max(MAX_LENGTH_4000, 'Errors.length_4000'),
    startDate: yup.string(),
    endDate: yup.string(),
    duration: yup.string()
        .test(
            'duration_test',
            'Errors.required',
            function() {
                const { startDate } = this.parent;
                return !!startDate;
            }
        ),
    itemIds: yup.array().of(string()).required('Errors.required'),
    price: yup.number(),
    isFixed: yup.boolean(),
    taxAmount: yup.number().moreThan(0).when('isFixed', {
        is: true,
        then: yup.number().moreThan(0).required('Errors.tax_amount_required')
    }),
    promotionType: yup.number()
        .test(
            'promotion_test',
            'Errors.required',
            function() {
                const { isFixed, price, reductionPercentage } = this.parent;
                return isFixed ? price > 0 : reductionPercentage > 0 && reductionPercentage <= 100;
            }
        ),

});
