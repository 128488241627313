import React, { FunctionComponent, memo } from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

import { ChevronRight } from 'Components/icons';
import { useTranslation } from 'react-i18next';

export interface BreadcrumbSegment {
    /**
     * Segments's display name.
     */
    name?: string;
    /**
     * Segments's display name i18n key.
     */
    nameKey?: string;
    /**
     * Segment's individual path. Ex: `invoices`, `uuid`
     */
    path: string;
}

interface RoutedBreadcrumbProps {
    routes: BreadcrumbSegment[];
}

const RoutedBreadcrumb: FunctionComponent<RoutedBreadcrumbProps> = ({ routes }) => {
    const { t } = useTranslation();
    const items = routes.map((item, index) => {
        const url = `/${routes
            .slice(0, index + 1)
            .map((r) => r.path)
            .join('/')}`;
        return (
            <Breadcrumb.Item key={url}>
                <Link to={url}>{item.name || t(item.nameKey || '')}</Link>
            </Breadcrumb.Item>
        );
    });
    return <Breadcrumb separator={<ChevronRight />}>{items}</Breadcrumb>;
};

export default memo(RoutedBreadcrumb);
