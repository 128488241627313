// GENERATED FILE - DO NOT MODIFY
import { AmenityCategoryDto } from 'Api/Features/AmenityCategories/Dtos/AmenityCategoryDto';
import { CreateAmenityCategoryRequestDto } from 'Api/Features/AmenityCategories/Dtos/CreateAmenityCategoryRequestDto';
import { GetAmenityCategoriesOrderingResponseDto } from 'Api/Features/AmenityCategories/Dtos/GetAmenityCategoriesOrderingResponseDto';
import { GetAmenityCategoriesRequestDto } from 'Api/Features/AmenityCategories/Dtos/GetAmenityCategoriesRequestDto';
import { GetAmenityCategoriesResponseDto } from 'Api/Features/AmenityCategories/Dtos/GetAmenityCategoriesResponseDto';
import { UpdateAmenityCategoriesOrderingRequestDto } from 'Api/Features/AmenityCategories/Dtos/UpdateAmenityCategoriesOrderingRequestDto';
import { UpdateAmenityCategoryRequestDto } from 'Api/Features/AmenityCategories/Dtos/UpdateAmenityCategoryRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class AmenityCategoryProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getAmenityCategory(amenityCategoryId: string): Promise<AmenityCategoryDto | null> {
        const uri = this.buildUri(
            "/amenity-categories/{amenityCategoryId}",
            { amenityCategoryId: amenityCategoryId },
            null
        );

        const data: AmenityCategoryDto | null = await this.httpClient.get<AmenityCategoryDto | null>(uri);
        return data;
    }

    public async getAmenityCategories(request: GetAmenityCategoriesRequestDto | null): Promise<GetAmenityCategoriesResponseDto | null> {
        const uri = this.buildUri(
            "/amenity-categories",
            null,
            { ...request || undefined }
        );

        const data: GetAmenityCategoriesResponseDto | null = await this.httpClient.get<GetAmenityCategoriesResponseDto | null>(uri);
        return data;
    }

    public async createAmenityCategory(request: CreateAmenityCategoryRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/amenity-categories",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreateAmenityCategoryRequestDto | null, string>(uri, request);
        return data;
    }

    public async updateAmenityCategory(amenityCategoryId: string, request: UpdateAmenityCategoryRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/amenity-categories/{amenityCategoryId}",
            { amenityCategoryId: amenityCategoryId },
            null
        );

        await this.httpClient.patch<UpdateAmenityCategoryRequestDto | null, void>(uri, request);
    }

    public async deleteAmenityCategory(amenityCategoryId: string): Promise<void> {
        const uri = this.buildUri(
            "/amenity-categories/{amenityCategoryId}",
            { amenityCategoryId: amenityCategoryId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }

    public async getAmenityCategoriesOrdering(): Promise<GetAmenityCategoriesOrderingResponseDto | null> {
        const uri = this.buildUri(
            "/amenity-categories/order",
            null,
            null
        );

        const data: GetAmenityCategoriesOrderingResponseDto | null = await this.httpClient.get<GetAmenityCategoriesOrderingResponseDto | null>(uri);
        return data;
    }

    public async updateAmenityCategoriesOrdering(request: UpdateAmenityCategoriesOrderingRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/amenity-categories/order",
            null,
            null
        );

        await this.httpClient.put<UpdateAmenityCategoriesOrderingRequestDto | null, void>(uri, request);
    }
}