import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Dollar: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
                <path
                    d="M7.471 0c.211 0 .382.171.382.382v1.53h.038c.617 0 1.17.098 1.658.293.493.196 1.085.716 1.205.823.12.106.04.265.04.265l-.1.193c-.051.095-.124.142-.22.142-.072 0-.168-.05-.286-.15a2.993 2.993 0 00-.463-.344 3.522 3.522 0 00-.74-.351c-.292-.106-.654-.16-1.086-.16-.432 0-.814.062-1.145.185-.325.123-.6.29-.824.502a2.217 2.217 0 00-.505.737 2.333 2.333 0 00-.169.879c0 .401.084.733.253.996.174.262.401.485.682.67.28.183.597.34.95.468.36.123.727.245 1.103.368s.74.26 1.094.41c.36.145.68.33.96.553.28.223.505.5.673.828.174.324.26.729.26 1.214 0 .497-.086.966-.26 1.406a3.229 3.229 0 01-.74 1.139 3.55 3.55 0 01-1.196.77c-.355.139-.75.225-1.182.26v1.61a.382.382 0 11-.764 0v-1.603c-.647-.033-1.213-.167-1.698-.401-.567-.28-1.159-.837-1.33-1.024-.172-.188.076-.483.076-.483.067-.084.146-.125.236-.125.05 0 .115.033.193.1.079.067.174.15.286.251.113.095.247.201.404.318.157.112.34.218.547.318a3.812 3.812 0 001.633.343c.471 0 .892-.07 1.263-.209.37-.145.681-.34.934-.586.258-.245.454-.535.589-.87.135-.34.202-.703.202-1.088 0-.419-.087-.762-.26-1.03a2.106 2.106 0 00-.674-.678c-.28-.184-.6-.337-.96-.46a35.866 35.866 0 00-1.094-.36 14.306 14.306 0 01-1.102-.394 3.889 3.889 0 01-.951-.552A2.657 2.657 0 014.7 6.24c-.169-.34-.253-.765-.253-1.273 0-.396.076-.778.228-1.146.151-.369.373-.692.664-.971a3.19 3.19 0 011.078-.678c.21-.084.433-.148.67-.19v-1.6c0-.21.172-.382.383-.382z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Dollar;
