import React, { FunctionComponent, useState, useEffect } from 'react';
import { Col, Row } from 'antd';
import { BtnDetails } from 'Components/buttons';
import { useTranslation } from 'react-i18next';
import { CheckMarkWeb } from 'Components/icons';

import './selectable-list.less';

interface SelectableListProps {
    disabled?: boolean;
    defaultSelected: any;
    items?: object[];
    itemKeyId?: string;
    elPerRow?: number;
    fallbackThumbnail: JSX.Element;
    getThumbnailUrl: (item: any) => string;
    getThumbnailName: (item: any) => string;
    getThumbnailExtra?: (item: any) => JSX.Element | null | undefined;
    getDescription: (item: any) => JSX.Element;
    getDetails?: (item: any) => JSX.Element | undefined;
    onItemClick: (item: any) => void;
}

const SelectableList: FunctionComponent<SelectableListProps> = ({
    disabled = false,
    defaultSelected = null,
    items = [],
    itemKeyId = 'id',
    elPerRow = 3,
    fallbackThumbnail,
    getThumbnailUrl,
    getThumbnailName,
    getThumbnailExtra = undefined,
    getDescription,
    getDetails = undefined,
    onItemClick,
}) => {
    const { t } = useTranslation();
    const [selectedItem, setSelectedItem] = useState<any>(defaultSelected);
    const [hoveredItemId, setHoveredItemId] = useState<string | null>(null);
    const [selectedDetails, setSelectedDetails] = useState<any>(null);
    const [selectedDetailsIndex, setSelectedDetailsIndex] = useState<number | null>(null);

    useEffect(() => {
        setSelectedItem(defaultSelected);
    }, [defaultSelected]);

    const handleItemClick = (item: any): void => {
        if (selectedItem && selectedItem[itemKeyId] === item[itemKeyId]) {
            setSelectedItem(null);
            onItemClick(null);
        } else {
            setSelectedItem(item);
            onItemClick(item);
        }
    };

    const handleItemDetailsClick = (item: any, index: number): void => {
        if (selectedDetails && item[itemKeyId] === selectedDetails[itemKeyId]) {
            setSelectedDetails(null);
            setSelectedDetailsIndex(null);
        } else {
            setSelectedDetails(item);
            setSelectedDetailsIndex(index);
        }
    };

    const rangeToShowDetails = (index: number): boolean => {
        if (selectedDetailsIndex !== null) {
            const lowerIntDivisableByElPerRow =
                selectedDetailsIndex - (selectedDetailsIndex % elPerRow);
            const higherIntDivisableByElPerRow =
                selectedDetailsIndex + elPerRow - (selectedDetailsIndex % elPerRow) - 1;

            if ((index + 1) % elPerRow === 0 || index === items.length - 1) {
                return (
                    index >= lowerIntDivisableByElPerRow && index <= higherIntDivisableByElPerRow
                );
            } else return false;
        } else return false;
    };

    return (
        <div className="SelectableList items">
            {items.length > 0
                ? items.map((item: any, index) => {
                      const thumbnailUrl = getThumbnailUrl(item);

                      return (
                          <React.Fragment key={item[itemKeyId]}>
                              <div
                                  className={`item ${
                                      selectedItem
                                          ? item[itemKeyId] === selectedItem[itemKeyId]
                                              ? 'selected'
                                              : 'greyed'
                                          : hoveredItemId
                                          ? item[itemKeyId] === hoveredItemId
                                              ? 'hovered'
                                              : 'greyed'
                                          : ''
                                  } ${disabled ? 'disabled' : ''}`}
                                  onClick={
                                      !disabled ? (): void => handleItemClick(item) : undefined
                                  }
                                  onMouseEnter={(): void => setHoveredItemId(item[itemKeyId])}
                                  onMouseLeave={(): void => setHoveredItemId(null)}
                                  style={{ width: `${Math.trunc(100 / elPerRow)}%` }}
                              >
                                  <div className="item-inner">
                                      <div
                                          className="item-image"
                                          style={{
                                              backgroundImage: thumbnailUrl
                                                  ? `url(${getThumbnailUrl(item)}`
                                                  : undefined,
                                          }}
                                          title={getThumbnailName(item)}
                                      >
                                          {!thumbnailUrl ? (
                                              <div className="item-image-fallback">
                                                  {fallbackThumbnail}
                                              </div>
                                          ) : null}
                                          <div className="item-image-overlay" />
                                          {selectedItem &&
                                          item[itemKeyId] === selectedItem[itemKeyId] ? (
                                              <span className="selected-icon">
                                                  <CheckMarkWeb fill="#fff" />
                                              </span>
                                          ) : null}
                                          {typeof getThumbnailExtra === 'function' ? (
                                              <div className="thumbnail-extra">
                                                  {getThumbnailExtra(item)}
                                              </div>
                                          ) : null}
                                      </div>
                                      <Row justify="space-between" align="middle">
                                          <Col className="description">{getDescription(item)}</Col>
                                          {typeof getDetails === 'function' ? (
                                              <Col>
                                                  <BtnDetails
                                                      onClick={(): void =>
                                                          handleItemDetailsClick(item, index)
                                                      }
                                                      toggled={
                                                          selectedDetails &&
                                                          item[itemKeyId] ===
                                                              selectedDetails[itemKeyId]
                                                      }
                                                  >
                                                      {t('see_details')}
                                                  </BtnDetails>
                                              </Col>
                                          ) : null}
                                      </Row>
                                  </div>
                              </div>

                              {typeof getDetails === 'function' &&
                              selectedDetailsIndex != null &&
                              selectedDetailsIndex >= 0 &&
                              rangeToShowDetails(index) ? (
                                  <div className="item-details">{getDetails(selectedDetails)}</div>
                              ) : null}
                          </React.Fragment>
                      );
                  })
                : null}
        </div>
    );
};

export default SelectableList;
