import { MAX_LENGTH_100, MAX_LENGTH_25, MAX_LENGTH_256, MAX_LENGTH_50 } from 'Models/Constants';
import * as yup from 'yup';

// See: https://github.com/jquense/yup/issues/58
yup.addMethod(yup.mixed, 'optional', function optional() {
    return this.transform((value) => {
        return (typeof value == 'string' && !value) ||
            (value instanceof Array && !value.length) ||
            value === null // allow to skip "nullable"
            ? undefined
            : value;
    });
});

const phoneRegex = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const BaseShape = {
    name: yup.string().required('Errors.required').max(MAX_LENGTH_50, 'Errors.length_50'),
    contactInfo: yup.object().shape({
        email: yup.string().email('Errors.email_invalid').required('Errors.required'),
        phoneNumber: yup
            .string()
            .matches(phoneRegex, 'Errors.phone_invalid')
            .required('Errors.required')
            .max(MAX_LENGTH_25, 'Errors.length_25'),
            website: yup
            .string()
            .optional()
            .max(MAX_LENGTH_256, 'Errors.length_256'),
    }),
    address: yup.object().shape({
        addressLine1: yup.string().required('Errors.required').max(MAX_LENGTH_100, 'Errors.length_100'),
        city: yup.string().required('Errors.required').max(MAX_LENGTH_50, 'Errors.length_50'),
        state: yup.string().required('Errors.required').max(MAX_LENGTH_50, 'Errors.length_50'),
        countryCode: yup.string().required('Errors.required'),
        zipCode: yup.string().required('Errors.required').max(10, 'Errors.length_10'),
    }),
    timeZone: yup.string().required('Errors.required'),
    latitude: yup.number().required('Errors.required'),
    longitude: yup.number().required('Errors.required'),
    businessHoursStart: yup.date().when('businessHoursEnd', {
        is: businessHoursEnd => businessHoursEnd !== undefined,
        then: yup.date().required('Errors.required')
    }),
    businessHoursEnd: yup
        .date()
        .when(
            'businessHoursStart',
            (businessHoursStart: any, schema: any) =>
                businessHoursStart && schema.min(businessHoursStart, 'Errors.closing_time_before_opening')
        )
        .when('businessHoursStart', {
            is: businessHoursStart => businessHoursStart !== undefined,
            then: yup.date().required('Errors.required')
        })
};

export const CreateLocationSchema = yup.object().shape(BaseShape, [['businessHoursStart', 'businessHoursEnd']]);

 export const EditLocationSchema =  yup.object().shape(BaseShape, [['businessHoursStart', 'businessHoursEnd']]);
