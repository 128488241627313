import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'Hooks';
import { Booking } from 'Models/Bookings/Booking';
import { useTranslation } from 'react-i18next';
import { GetBookingsSortColumnDto } from 'Api/Features/Bookings/Dtos/GetBookingsSortColumnDto';
import { SortDirectionDto } from 'Api/Features/General/Dtos/SortDirectionDto';
import { UpcomingBookings } from 'Components/booking/upcoming-bookings';
import { BookingDetails } from 'Components/booking/booking-details';
import BookingModal from 'Components/booking/booking-flow/booking-modal';
import { Booking as BookingIcon } from 'Components/icons';
import { Row, Col, Typography, Button } from 'antd';
import moment from 'moment';

import './amenity-bookings.less';

const { Text, Title, Paragraph } = Typography;

const AmenityBookings: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const { bookingStore } = useStores();
    const [selectedBooking, setSelectedBooking] = useState<Booking>();
    const [modalVisible, setModalVisibility] = useState(false);

    const handleBookAnAmenityClick = (): void => {
        setModalVisibility(true);
    };

    const fetch = useCallback(async (): Promise<void> => {
        await bookingStore.setUpcomingBookings({
            sortColumn: GetBookingsSortColumnDto.Date,
            sortDirection: SortDirectionDto.Ascending,
            from: moment().format(),
        });

        const [upcomingBookings, numberOfBookings] = bookingStore.upcomingBookings;
        if (Array.isArray(upcomingBookings) && numberOfBookings > 0) setSelectedBooking(upcomingBookings[0]);
        else setSelectedBooking(undefined);
    }, [bookingStore]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    useEffect(() => {
        const [upcomingBookings, numberOfBookings] = bookingStore.upcomingBookings;
        if (Array.isArray(upcomingBookings) && numberOfBookings > 0) setSelectedBooking(upcomingBookings[0]);
        else setSelectedBooking(undefined);
    }, [bookingStore.upcomingBookings]);

    const handleClickBooking = (booking: Booking): void => {
        if (selectedBooking?.id !== booking.id) setSelectedBooking(booking);
        else setSelectedBooking(undefined);
    };

    return (
        <div className="AmenityBookings">
            {!bookingStore.isBookingsLoading &&
            bookingStore.upcomingBookingsByDate &&
            Object.keys(bookingStore.upcomingBookingsByDate).length > 0 ? (
                <>
                    <Title level={4} className="AmenityBookings-title">
                        {t('Home.home_upcoming_amenity_title')}
                    </Title>
                    <Row gutter={24}>
                        <Col span={8} className="AmenityBookings-leftCol">
                            <UpcomingBookings
                                bookings={bookingStore.upcomingBookingsByDate}
                                handleClickBooking={(booking): void => handleClickBooking(booking)}
                                selectedBooking={selectedBooking}
                            />
                        </Col>
                        <Col span={16} className="AmenityBookings-rightCol">
                            <BookingDetails
                                bookingId={selectedBooking?.id}
                                onCancelBooking={(): Promise<void> => fetch()}
                            />
                        </Col>
                    </Row>
                </>
            ) : !bookingStore.isBookingsLoading &&
              bookingStore.upcomingBookingsByDate &&
              Object.keys(bookingStore.upcomingBookingsByDate).length === 0 ? (
                <Row align="middle" justify="center" className="AmenityBookings_empty">
                    <Col>
                        <BookingIcon />
                        <Title level={3}>{t('Booking.empty_bookings_title')}</Title>
                        <Paragraph className="empty-message">
                            <Text>{t('Booking.empty_bookings_message')}</Text>
                        </Paragraph>
                        <Button type="primary" onClick={handleBookAnAmenityClick}>
                            {t('Booking.book_amenities')}
                        </Button>
                    </Col>
                </Row>
            ) : null}
            {modalVisible && (<BookingModal visible={modalVisible} setVisibleState={setModalVisibility} />)}
        </div>
    );
});

export default AmenityBookings;
