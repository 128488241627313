import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const EditSquare: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg
                width="1em"
                height="1em"
                viewBox="0 0 20 20"
                fill="none"
                {...props}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.36625 14.3875L4.10479 16.2724C3.79917 16.4143 3.52417 16.1199 3.66667 15.8337L5.60313 12.6225L7.36625 14.3875ZM13.2744 4.51375L15.485 6.695L8.46 13.7244C7.6375 12.9006 6.49813 11.7612 6.265 11.5281L13.2744 4.51375ZM15.485 2.30188C15.7275 2.05875 16.1206 2.05875 16.3638 2.30188L17.6806 3.61938C17.9231 3.8625 17.9231 4.25563 17.6806 4.49875L16.3638 5.81625L14.1994 3.58813L15.485 2.30188ZM5.06875 11.2931L2.38063 16.7337C2.15563 17.3463 2.64563 17.895 3.25813 17.6106L8.695 14.9212C8.92937 14.8756 9.15687 14.785 9.33813 14.6038L18.9975 4.9375C19.4825 4.4525 19.4825 3.66563 18.9975 3.18L16.8025 0.98375C16.3169 0.498125 15.5306 0.498125 15.0463 0.98375L5.38688 10.6494C5.205 10.8313 5.11438 11.0588 5.06875 11.2931ZM18.75 7.5V17.5C18.75 18.1869 18.0713 18.7575 17.3856 18.7575H2.48375C1.79813 18.7575 1.24188 18.2019 1.24188 17.515V2.60375C1.24188 1.91688 1.81375 1.25 2.5 1.25H12.5V0H2.5C1.12875 0 0 1.23063 0 2.60375V17.515C0 18.8875 1.11188 20 2.48375 20H17.3856C18.7575 20 20 18.8731 20 17.5V7.5H18.75Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default EditSquare;
