import { AddressDto } from 'Api/Features/General/Dtos/AddressDto';
import { countries } from 'appcom-globalization';

export class Address implements AddressDto {
    constructor(dto: AddressDto) {
        Object.assign(this, dto);
    }
    addressLine1: string | null = null;
    addressLine2: string | null = null;
    city: string | null = null;
    state: string | null = null;
    countryCode: string | null = null;
    zipCode: string | null = null;

    get isEmpty(): boolean {
        return !(
            this.addressLine1 ||
            this.addressLine2 ||
            this.city ||
            this.state ||
            this.countryCode ||
            this.zipCode
        );
    }

    get countryName(): string | null {
        return countries
            .filter((country) => {
                return country.twoLetterCode === this.countryCode;
            })
            .map((country) => {
                return country.englishName;
            })[0];
    }

    get text(): string {
        return (
            (this.addressLine1 ? this.addressLine1 : '') +
            (this.addressLine2 ? ' ' + this.addressLine2 : '') +
            (this.city ? ' ' + this.city : '') +
            (this.state ? ', ' + this.state : '') +
            (this.countryName ? ' ' + this.countryName : '') +
            (this.zipCode ? ', ' + this.zipCode : '')
        );
    }

    public toString() {
        return this.text;
    }
}
