import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const ChevronRight: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 12 12" fill="none" {...props}>
                <path d="M7.832 6L2.5 11.332l.668.668 6-6-6-6L2.5.668 7.832 6z" fill={fill} />
            </svg>
        </span>
    );
};

export default ChevronRight;
