import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import './legal-page.less';
import { images } from 'variant';

interface LegalPageProps {
    content: string;
}

const LegalPage: FunctionComponent<LegalPageProps> = ({ content }) => {
    const { t } = useTranslation();
    return (
        <div className={`LegalPage ${process.env.REACT_APP_VARIANT}`}>
            <div className="header">
                <img className="logo" src={images.logoWhite} alt={t('brand_name')} />
            </div>
            <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
        </div>
    );
};

export default LegalPage;
