/**********************************************
 * List of imported FontAwesome icons.        *
 * If you add icons, sort them alphabeticaly. *
 **********************************************/

import { library as faLibrary } from '@fortawesome/fontawesome-svg-core';

/***********
 * REGULAR *
 ***********/
//import {} from '@fortawesome/free-regular-svg-icons';

/*********
 * SOLID *
 *********/
import {
    faAngleLeft as FasAngleLeft,
    faAngleRight as FasAngleRight,
    faCheck as FasCheck,
    faExclamation as FasExclamation
} from '@fortawesome/free-solid-svg-icons';

export default {
    init: () =>
        faLibrary.add(
            // REGULAR
            // SOLID
            FasAngleLeft,
            FasAngleRight,
            FasCheck,
            FasExclamation
        ),
};
