import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Upload: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    d="M18.362 15.574v2.94h-2.505v.915h3.42v-2.94h2.9v6.489H1.423v-6.49h2.901v2.941h3.418v-.914H5.238v-2.941H.4V24h22.8v-8.426h-4.838z"
                    fill={fill}
                />
                <path
                    d="M4.842 7.425l1.012.622 5.306-5.54V18h1.278V2.505l5.306 5.542 1.013-.622L11.8.158 4.842 7.425z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Upload;
