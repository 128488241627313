import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useService, useStores } from 'Hooks';
import { PaymentMethodService } from 'Services/PaymentMethodService';
import { PaymentMethod } from 'Models/PaymentMethods/PaymentMethod';
import { PaymentMethodHolderTypeDto } from 'Api/Features/PaymentMethods/Dtos/PaymentMethodHolderTypeDto';
import { CompanyRoleDto } from 'Api/Features/Companies/Dtos/CompanyRoleDto';
import { Elements } from '@stripe/react-stripe-js';
import { RadioChangeEvent } from 'antd/es/radio';
import { Col, Radio, Row, Skeleton, Typography } from 'antd';
import BookingPaymentForm from './booking-payment-form';
import { useTranslation } from 'react-i18next';
import { ImageWithPlaceholder } from 'Components/image-with-placeholder';
import { Credit, CreditCard, Company, EditSquare, User } from 'Components/icons';
import { Cell } from 'Components/cell';
import { CC_EXPIRATION_FORMAT } from 'Models/Constants';
import { theme } from 'variant';
import { moneyFormat } from 'Utils';
import moment from 'moment';
import { loadStripe } from '@stripe/stripe-js';

import './booking-payment.less';

const { Paragraph, Text, Title } = Typography;

interface BookingPaymentProps {
    selectedAmenity: any;
    selectedTime: any;
    onChange: (value: any) => void;
}

const BookingPayment: FunctionComponent<BookingPaymentProps> = ({
    selectedAmenity,
    selectedTime,
    onChange,
}) => {
    const { t } = useTranslation();
    const { toastStore, userStore, globalLoadingStore } = useStores();
    const paymentMethodService = useService(PaymentMethodService);
    const [isLoadingPayment, setIsLoadingPayment] = useState<boolean>(true);
    const [stripe, setStripe] = useState<any>();
    const [isEditingCard, setIsEditingCard] = useState<boolean>(false);
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[] | null>();
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>();
    const [selectedPayment, setSelectedPayment] = useState<PaymentMethodHolderTypeDto>(
        PaymentMethodHolderTypeDto.User
    );

    const numberOfBlocks: number =
        moment(selectedTime.bookingEnd).diff(moment(selectedTime.bookingStart), 'minutes', true) /
        selectedAmenity.minutesPerBlock;

    const isFree =
        userStore.isCoworking && selectedAmenity.creditsPerBlock === 0
            ? true
            : !userStore.isCoworking && selectedAmenity.pricePerBlock === 0
            ? true
            : false;

    const coworkingCompanyHasEnoughCredits =
        !isFree &&
        userStore.isCoworking &&
        userStore.user?.company?.creditBalance?.available &&
        userStore.user.company.creditBalance.available >=
            numberOfBlocks * selectedAmenity.creditsPerBlock;

    const fetch = useCallback(async (): Promise<void> => {
        if (userStore?.user?.id)
            try {
                globalLoadingStore.addLoading();
                // call api
                const _paymentMethods = await paymentMethodService.getUserPaymentMethod(
                    userStore?.user?.id
                );
                const paymentInformation = _paymentMethods?.filter(
                    (paymentMethod) =>
                        paymentMethod.holder?.type === PaymentMethodHolderTypeDto.User
                )[0];

                if (!stripe) {
                    const stripeApi = await paymentMethodService.getPaymentApiInfo();
                    if (stripeApi?.stripePublicKey) {
                        const _stripe = await loadStripe(stripeApi?.stripePublicKey);
                        setStripe(_stripe);
                    }
                }

                setPaymentMethods(_paymentMethods);
                setPaymentMethod(paymentInformation);
            } finally {
                globalLoadingStore.removeLoading();
                setIsLoadingPayment(false);
            }
    }, [globalLoadingStore, userStore, paymentMethodService, stripe]);

    useEffect(() => {
        !isFree && fetch();
    }, [fetch, isFree]);

    useEffect(() => {
        if (isFree) {
            onChange('free');
        }
    }, [onChange, isFree]);

    useEffect(() => {
        !isFree && !coworkingCompanyHasEnoughCredits && onChange(paymentMethod);
    }, [paymentMethod, onChange, isFree, coworkingCompanyHasEnoughCredits]);

    const onRadioGroupChange = (e: RadioChangeEvent): void => {
        setSelectedPayment(e.target.value);
        if (!isFree) {
            const paymentInformation = paymentMethods?.filter(
                (paymentMethod) => paymentMethod.holder?.type === e.target.value
            )[0];
            isEditingCard && setIsEditingCard(false);
            coworkingCompanyHasEnoughCredits
                ? onChange({
                      holder: {
                          type: 'Company',
                          id: userStore.user?.company?.id,
                      },
                  })
                : setPaymentMethod(paymentInformation);
        }
    };

    const handleBookingPaymentFormSubmit = async (token: any): Promise<void> => {
        try {
            if (userStore?.user?.id && selectedPayment === PaymentMethodHolderTypeDto.User) {
                await paymentMethodService.createUserPaymentMethod(userStore.user.id, { token });
                setIsEditingCard(false);
                fetch();
            } else if (
                userStore?.user?.company?.id &&
                selectedPayment === PaymentMethodHolderTypeDto.Company
            ) {
                await paymentMethodService.createCompanyPaymentMethod(userStore.user.company.id, { token });
                setIsEditingCard(false);
                fetch();
            }
        } catch (error) {
            toastStore.displayError(error);
        }
    };

    const isMember =
        userStore.user?.companyRoles && userStore.user.companyRoles.includes(CompanyRoleDto.Member);

    const isCompanyAdmin =
        userStore.user?.companyRoles &&
        userStore.user.companyRoles.includes(CompanyRoleDto.Administrator);

    const hasCompanyPaymentMethod = paymentMethods?.filter(
        (paymentMethod) => paymentMethod.holder?.type === PaymentMethodHolderTypeDto.Company
    ).length;

    return (
        <div className="BookingModal__Payment">
            <Row>
                <Col span={16} className="payment">
                    <Title level={4}>{t('Booking.payment_title')}</Title>

                    <Radio.Group
                        name="paymentMethod"
                        defaultValue={PaymentMethodHolderTypeDto.User}
                        onChange={onRadioGroupChange}
                    >
                        <Radio value={PaymentMethodHolderTypeDto.User}>
                            <div className="radio-inner">
                                <ImageWithPlaceholder
                                    width={50}
                                    height={50}
                                    imgSrc={userStore.user?.imageUrl}
                                    defaultImg={
                                        <User
                                            fill={theme['primary-color']}
                                            width={20}
                                            height={20}
                                        />
                                    }
                                />
                                <div className="d-flex flex-direction-column">
                                    <Text strong>{t('Booking.payment_type_personal')}</Text>
                                    {userStore.isCoworking ? (
                                        <Text>
                                            {t('Booking.payment_credits_charged_immediately')}
                                        </Text>
                                    ) : null}
                                </div>
                            </div>
                        </Radio>
                        <Radio
                            value={PaymentMethodHolderTypeDto.Company}
                            disabled={
                                isFree
                                    ? false
                                    : isMember ||
                                      (!isCompanyAdmin && !hasCompanyPaymentMethod) ||
                                      (userStore.isCoworking &&
                                          selectedAmenity.isApprovalRequired) ||
                                      (userStore.isCoworking && !coworkingCompanyHasEnoughCredits)
                            }
                        >
                            <div className="radio-inner">
                                <ImageWithPlaceholder
                                    width={50}
                                    height={50}
                                    imgSrc={userStore.user?.company?.imageUrl}
                                    defaultImg={
                                        <Company
                                            fill={theme['primary-color']}
                                            width={20}
                                            height={20}
                                        />
                                    }
                                />
                                <div className="d-flex flex-direction-column">
                                    <Text strong>{t('Booking.payment_type_company')}</Text>
                                    {!isCompanyAdmin && !hasCompanyPaymentMethod && !isFree ? (
                                        <Text>{t('Booking.payment_company_no_card')}</Text>
                                    ) : isMember ? (
                                        <Text>{t('Booking.payment_company_permission')}</Text>
                                    ) : userStore.isCoworking &&
                                      selectedAmenity.isApprovalRequired ? (
                                        <Text>{t('Booking.payment_company_req_approval')}</Text>
                                    ) : userStore.isCoworking &&
                                      !coworkingCompanyHasEnoughCredits &&
                                      !isFree ? (
                                        <Text>
                                            {t('Booking.payment_company_insufficient_credits')}
                                        </Text>
                                    ) : userStore.isCoworking ? (
                                        <Text>
                                            {t('Booking.payment_available_credits', {
                                                count:
                                                    userStore?.user?.company?.creditBalance
                                                        ?.available,
                                            })}
                                        </Text>
                                    ) : null}
                                </div>
                            </div>
                        </Radio>
                    </Radio.Group>

                    {isFree ? null : selectedPayment === PaymentMethodHolderTypeDto.User ||
                      !userStore.isCoworking ? (
                        <>
                            <hr className="seperator" />

                            <Title level={4}>{t('Booking.payment_details')}</Title>
                            {isLoadingPayment ? (
                                <Skeleton title={false} paragraph={{ rows: 3 }} active />
                            ) : paymentMethod && !isEditingCard ? (
                                <Cell
                                    icon={<CreditCard />}
                                    extra={
                                        <span
                                            onClick={(): void => setIsEditingCard(true)}
                                            className="cursor-pointer"
                                        >
                                            <EditSquare fill={theme['primary-color']} />
                                        </span>
                                    }
                                    className="align-items-center"
                                >
                                    <div className="d-flex flex-direction-column">
                                        <Text strong>{paymentMethod.name}</Text>
                                        <Text>
                                            {t('ending_with', {
                                                param1: paymentMethod?.card?.brand,
                                                param2: paymentMethod?.card?.last4,
                                            })}
                                        </Text>
                                        <Text>
                                            {moment(paymentMethod?.card?.expiration).format(
                                                CC_EXPIRATION_FORMAT
                                            )}
                                        </Text>
                                    </div>
                                </Cell>
                            ) : (
                                <div>
                                    <Elements stripe={stripe}>
                                        <BookingPaymentForm
                                            isEditing={
                                                paymentMethod && isEditingCard ? true : false
                                            }
                                            onCancelClick={(): void => setIsEditingCard(false)}
                                            onSubmit={handleBookingPaymentFormSubmit}
                                        />
                                    </Elements>

                                    <div className="mb-1 mt-1">
                                        <Paragraph className="text-align-center">
                                            <Text strong>
                                                {t('Booking.payment_information_save')}
                                            </Text>
                                        </Paragraph>
                                        <Text className="text-align-center">
                                            {t('Booking.payment_information_save_details')}
                                        </Text>
                                    </div>
                                </div>
                            )}
                        </>
                    ) : userStore.isCoworking &&
                      coworkingCompanyHasEnoughCredits &&
                      selectedPayment === PaymentMethodHolderTypeDto.Company ? (
                        <>
                            <hr className="seperator" />
                            <Title level={4}>{t('Booking.payment_details')}</Title>

                            <Cell icon={<Credit />} className="align-items-center">
                                <Text strong>{t('Booking.payment_details_company_credit')}</Text>
                            </Cell>
                        </>
                    ) : null}
                </Col>
                <Col span={8} className="summary">
                    {userStore.isCoworking ? (
                        <>
                            <Title level={4}>{t('Booking.payment_credits_summary')}</Title>
                            <div className="summary-inner">
                                <div className="d-flex justify-content-space-between align-items-center">
                                    <Text strong>
                                        {numberOfBlocks} x {selectedAmenity.creditsPerBlock} /{' '}
                                        {t('Booking.time_minute', {
                                            count: selectedAmenity.minutesPerBlock,
                                        })}
                                    </Text>

                                    <Text>
                                        {t('Booking.payment_count_credits', {
                                            count: numberOfBlocks * selectedAmenity.creditsPerBlock,
                                        })}
                                    </Text>
                                </div>
                            </div>
                            <hr className="seperator" />
                        </>
                    ) : null}

                    {!userStore.isCoworking ||
                    (userStore.isCoworking &&
                        selectedPayment === PaymentMethodHolderTypeDto.User) ? (
                        <>
                            <Title level={4}>{t('Booking.payment_summary')}</Title>
                            <div className="summary-inner">
                                <div className="d-flex justify-content-space-between align-items-center">
                                    <Text strong>
                                        {!userStore.isCoworking ? (
                                            <>
                                                {numberOfBlocks} x ${selectedAmenity.pricePerBlock}{' '}
                                                /{' '}
                                                {t('Booking.time_minute', {
                                                    count: selectedAmenity.minutesPerBlock,
                                                })}
                                            </>
                                        ) : (
                                            t('Booking.payment_count_credits', {
                                                count:
                                                    numberOfBlocks *
                                                    selectedAmenity.creditsPerBlock,
                                            })
                                        )}
                                    </Text>

                                    <Text>
                                        {!userStore.isCoworking
                                            ? moneyFormat(
                                                  numberOfBlocks * selectedAmenity.pricePerBlock
                                              )
                                            : moneyFormat(
                                                  numberOfBlocks *
                                                      selectedAmenity.creditsPerBlock *
                                                      selectedAmenity.pricePerCredit
                                              )}
                                    </Text>
                                </div>
                                <hr className="seperator" />
                                <div className="subtotal summary-detail-row d-flex justify-content-end align-items-center">
                                    <label>{t('subtotal')}</label>{' '}
                                    <Text strong>
                                        {!userStore.isCoworking
                                            ? moneyFormat(
                                                  numberOfBlocks * selectedAmenity.pricePerBlock
                                              )
                                            : moneyFormat(
                                                  numberOfBlocks *
                                                      selectedAmenity.creditsPerBlock *
                                                      selectedAmenity.pricePerCredit
                                              )}
                                    </Text>
                                </div>
                                <div className="summary-detail-row d-flex justify-content-end align-items-center">
                                    <label>{t('tax')}</label>{' '}
                                    <Text>
                                        {moneyFormat(
                                            numberOfBlocks * selectedAmenity.taxAmountPerBlock
                                        )}
                                    </Text>
                                </div>
                                <hr className="seperator" />
                                <div className="summary-detail-row d-flex justify-content-end align-items-center">
                                    <label>{t('total')}</label>
                                    <Text className="big" strong>
                                        {!userStore.isCoworking
                                            ? moneyFormat(
                                                  numberOfBlocks * selectedAmenity.pricePerBlock +
                                                      numberOfBlocks *
                                                          selectedAmenity.taxAmountPerBlock
                                              )
                                            : moneyFormat(
                                                  numberOfBlocks *
                                                      selectedAmenity.creditsPerBlock *
                                                      selectedAmenity.pricePerCredit +
                                                      numberOfBlocks *
                                                          selectedAmenity.taxAmountPerBlock
                                              )}
                                    </Text>
                                </div>
                            </div>
                        </>
                    ) : null}

                    <Paragraph className="text-align-center mt-1">
                        {t('Booking.payment_cancel_warning')}
                    </Paragraph>
                </Col>
            </Row>
        </div>
    );
};

export default BookingPayment;
