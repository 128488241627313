import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const CircleCheckmark: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 15 16" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.367 1.93981C9.06009 1.35748 7.59996 1.21322 6.20437 1.52854C4.80878 1.84385 3.5525 2.60185 2.6229 3.68948C1.6933 4.77711 1.14019 6.1361 1.04605 7.56377C0.951907 8.99144 1.32179 10.4113 2.10052 11.6116C2.87926 12.8118 4.02513 13.7282 5.36723 14.2241C6.70934 14.7199 8.17577 14.7686 9.54782 14.363C10.9199 13.9573 12.124 13.119 12.9807 11.973C13.8374 10.8271 14.3007 9.43489 14.3015 8.00412V7.36178C14.3015 7.16892 14.4579 7.01257 14.6507 7.01257C14.8436 7.01257 14.9999 7.16892 14.9999 7.36178V8.00432C14.999 9.5857 14.4869 11.1246 13.5401 12.3912C12.5932 13.6578 11.2623 14.5843 9.74584 15.0327C8.22937 15.4811 6.60857 15.4272 5.1252 14.8792C3.64182 14.3312 2.37533 13.3183 1.51462 11.9917C0.653914 10.6651 0.245099 9.09577 0.349148 7.51782C0.453198 5.93987 1.06454 4.43783 2.09199 3.23571C3.11944 2.03359 4.50796 1.1958 6.05046 0.847297C7.59295 0.49879 9.20678 0.658237 10.6513 1.30186C10.8274 1.38035 10.9066 1.58679 10.8281 1.76296C10.7496 1.93912 10.5432 2.0183 10.367 1.93981Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.8975 2.16954C15.034 2.30584 15.0341 2.52695 14.8978 2.66339L7.91366 9.6545C7.84818 9.72004 7.75934 9.75688 7.6667 9.7569C7.57405 9.75693 7.4852 9.72013 7.41969 9.65462L5.32445 7.55939C5.18807 7.42301 5.18807 7.20191 5.32445 7.06553C5.46082 6.92916 5.68193 6.92916 5.8183 7.06553L7.66649 8.91372L14.4037 2.16979C14.54 2.03334 14.7611 2.03323 14.8975 2.16954Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default CircleCheckmark;
