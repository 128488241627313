import React, { FunctionComponent } from 'react';
import { Route, Switch } from 'react-router-dom';

import AnonymousLayout from 'Components/webapp/anonymous-layout/anonymous-layout';
import Login from './login';
import { WebAppActionsLayout } from 'Components/webapp-actions-layout';
import { WebAppUtilitiesLayout } from 'Components/webapp-utilities-layout';
import { ForgotPassword } from './forgot-password';
import ResetPassword from './actions/reset-password';
import ConfirmAccount from './actions/confirm-account';
import PrivacyPolicy from './privacy-policy';
import TermsOfUse from './terms-of-use';
import HIDInvitation from '../actions/HID-invitation';

const appVariants = ['heyday', 'passport'];

const AnonymousRoutes: FunctionComponent = () => {
    return (
        <Switch>
            <Route exact path="/">
                <AnonymousLayout>
                    <WebAppActionsLayout>
                        <Login />
                    </WebAppActionsLayout>
                </AnonymousLayout>
            </Route>
            <Route path={`/${process.env.REACT_APP_VARIANT}/privacy-policy`}>
                <PrivacyPolicy />
            </Route>
            <Route path={`/${process.env.REACT_APP_VARIANT}/terms-of-use`}>
                <TermsOfUse />
            </Route>

            <Route exact path="/actions/hid-invitation">
                <WebAppUtilitiesLayout>
                    <HIDInvitation />
                </WebAppUtilitiesLayout>
            </Route>

            <Route exact path="/forgot-Password">
                <AnonymousLayout>
                    <WebAppActionsLayout showBackToLogin>
                        <ForgotPassword />
                    </WebAppActionsLayout>
                </AnonymousLayout>
            </Route>

            {appVariants.map((appVariant) => (
                <Route key={appVariant} path={`/${appVariant}/actions`}>
                    <WebAppUtilitiesLayout>
                        <Route path={`/${appVariant}/actions/confirm-account`}>
                            <ConfirmAccount />
                        </Route>
                        <Route path={`/${appVariant}/actions/reset-password`}>
                            <ResetPassword />
                        </Route>
                    </WebAppUtilitiesLayout>
                </Route>
            ))}
        </Switch>
    );
};

export default AnonymousRoutes;
