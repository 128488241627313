import React, { FunctionComponent } from 'react';
import './empty-results.less';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';

interface EmptyResultsProps {
    description?: string | null;
}

const EmptyResults: FunctionComponent<EmptyResultsProps> = ({ description = null }) => {
    const { t } = useTranslation();
    return (
        <Empty
            className="EmptyResults"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={description || t('no_results')}
        />
    );
};

export default EmptyResults;
