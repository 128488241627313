// GENERATED FILE - DO NOT MODIFY
import { AmenityDto } from 'Api/Features/Amenities/Dtos/AmenityDto';
import { CreateAmenityRequestDto } from 'Api/Features/Amenities/Dtos/CreateAmenityRequestDto';
import { GetAmenitiesOrderResponseDto } from 'Api/Features/Amenities/Dtos/GetAmenitiesOrderResponseDto';
import { GetAmenitiesRequestDto } from 'Api/Features/Amenities/Dtos/GetAmenitiesRequestDto';
import { GetAmenitiesResponseDto } from 'Api/Features/Amenities/Dtos/GetAmenitiesResponseDto';
import { UpdateAmenitiesOrderingRequestDto } from 'Api/Features/Amenities/Dtos/UpdateAmenitiesOrderingRequestDto';
import { UpdateAmenityRequestDto } from 'Api/Features/Amenities/Dtos/UpdateAmenityRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class AmenityProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getAmenity(amenityId: string): Promise<AmenityDto | null> {
        const uri = this.buildUri(
            "/amenities/{amenityId}",
            { amenityId: amenityId },
            null
        );

        const data: AmenityDto | null = await this.httpClient.get<AmenityDto | null>(uri);
        return data;
    }

    public async getAmenities(request: GetAmenitiesRequestDto | null): Promise<GetAmenitiesResponseDto | null> {
        const uri = this.buildUri(
            "/amenities",
            null,
            { ...request || undefined }
        );

        const data: GetAmenitiesResponseDto | null = await this.httpClient.get<GetAmenitiesResponseDto | null>(uri);
        return data;
    }

    public async createAmenity(request: CreateAmenityRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/amenities",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreateAmenityRequestDto | null, string>(uri, request);
        return data;
    }

    public async updateAmenity(amenityId: string, request: UpdateAmenityRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/amenities/{amenityId}",
            { amenityId: amenityId },
            null
        );

        await this.httpClient.patch<UpdateAmenityRequestDto | null, void>(uri, request);
    }

    public async deleteAmenity(amenityId: string): Promise<void> {
        const uri = this.buildUri(
            "/amenities/{amenityId}",
            { amenityId: amenityId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }

    public async getAmenitiesOrder(locationId: string): Promise<GetAmenitiesOrderResponseDto | null> {
        const uri = this.buildUri(
            "/locations/{locationId}/amenities/order",
            { locationId: locationId },
            null
        );

        const data: GetAmenitiesOrderResponseDto | null = await this.httpClient.get<GetAmenitiesOrderResponseDto | null>(uri);
        return data;
    }

    public async updateAmenitiesOrder(locationId: string, request: UpdateAmenitiesOrderingRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/locations/{locationId}/amenities/order",
            { locationId: locationId },
            null
        );

        await this.httpClient.put<UpdateAmenitiesOrderingRequestDto | null, void>(uri, request);
    }

    public async getCreditPrice(): Promise<number> {
        const uri = this.buildUri(
            "/credit-price",
            null,
            null
        );

        const data: number = await this.httpClient.get<number>(uri);
        return data;
    }

    public async updateCreditValue(value: number): Promise<void> {
        const uri = this.buildUri(
            "/credit-price",
            null,
            { value: (value === null || value === undefined) ? value : value.toString() }
        );

        await this.httpClient.put<void, void>(uri, );
    }
}