import { ManagementRoleNameDto } from 'Api/Features/Users/Dtos/ManagementRoleNameDto';
import { MAX_LENGTH_256, MAX_LENGTH_50 } from 'Models/Constants';
import * as yup from 'yup';
import { StringSchema } from 'yup';

export const CreateManagerUserSchema = yup.object().shape(
    {
        firstName: yup.string().required('Errors.required').max(MAX_LENGTH_50, 'Errors.length_50'),
        lastName: yup.string().required('Errors.required').max(MAX_LENGTH_50, 'Errors.length_50'),
        contactInfo: yup.object().shape({
            email: yup.string().email('Errors.email_invalid').required('Errors.required').max(MAX_LENGTH_256, 'Errors.length_256'),
        }),
        password: yup
            .string()
            .when('confirmPassword', {
                is: (confirmPassword) => confirmPassword?.length > 0,
                then: yup.string().required('Errors.required'),
            })
            .min(6, 'Errors.password_restrictions')
            .max(MAX_LENGTH_50, 'Errors.password_restrictions'),
        confirmPassword: yup
            .string()
            .when('password', {
                is: (password) => password?.length > 0,
                then: yup.string().required('Errors.required'),
            })
            .when(
                'password',
                (password: string, schema: StringSchema) =>
                    password && schema.equals([password], 'Errors.passwords_must_match')
            )
            .min(6, 'Errors.password_restrictions')
            .max(MAX_LENGTH_50, 'Errors.password_restrictions'),
        managementRoles: yup.string().required('Errors.required'),
        locationIds: yup.string().when('managementRoles', {
            is: (role) => role === ManagementRoleNameDto.BuildingManager,
            then: yup.string().required('Errors.required')
        })
    },
    [['confirmPassword', 'password']]
);

export const CreateManagerUserFromExistingUserSchema = yup.object().shape({
    firstName: yup.string().required('Errors.required').max(MAX_LENGTH_50, 'Errors.length_50'),
    lastName: yup.string().required('Errors.required').max(MAX_LENGTH_50, 'Errors.length_50'),
    contactInfo: yup.object().shape({
        email: yup.string().email('Errors.email_invalid').required('Errors.required').max(MAX_LENGTH_256, 'Errors.length_256'),
    }),
    managementRoles: yup.string().required('Errors.required'),
    locationIds: yup.string().when('managementRoles', {
        is: (role) => role === ManagementRoleNameDto.BuildingManager,
        then: yup.string().required('Errors.required')
    })    
});

export const EditManagerUserSchema = yup.object().shape(
    {
        firstName: yup.string().required('Errors.required').max(MAX_LENGTH_50, 'Errors.length_50'),
        lastName: yup.string().required('Errors.required').max(MAX_LENGTH_50, 'Errors.length_50'),
        password: yup
            .string()
            .optional()
            .nullable()
            .when('confirmPassword', {
                is: (confirmPassword) => confirmPassword?.length > 0,
                then: yup.string().required('Errors.required'),
            })
            .min(6, 'Errors.password_restrictions')
            .max(MAX_LENGTH_50, 'Errors.password_restrictions'),
        confirmPassword: yup
            .string()
            .optional()
            .nullable()
            .when('password', {
                is: (password) => password?.length > 0,
                then: yup.string().required('Errors.required'),
            })
            .when(
                'password',
                (password: string, schema: StringSchema) =>
                    password && schema.equals([password], 'Errors.passwords_must_match')
            )
            .min(6, 'Errors.password_restrictions')
            .max(MAX_LENGTH_50, 'Errors.password_restrictions'),
        managementRoles: yup.string().required('Errors.required'),
        locationIds: yup.string().when('managementRoles', {
            is: (role) => role === ManagementRoleNameDto.BuildingManager,
            then: yup.string().required('Errors.required')
        })   
    },
    [['confirmPassword', 'password']]
);
