import React, { FunctionComponent } from 'react';
import './status-badge.less';
import { useTranslation } from 'react-i18next';

interface StatusBadgeProps {
    status: StatusBadgeStatus | null | undefined;
    text?: string;
}

export enum StatusBadgeStatus {
    Active = 'Active',
    Deactivated = 'Deactivated',
    Disabled = 'Disabled',
    Open = 'Open',
    Closed = 'Closed',
    Approved = 'Approved',
    Refused = 'Refused',
    WaitingForApproval = 'WaitingForApproval',
    Read = 'Read',
    Unread = 'Unread',
    Paid = 'Paid',
    Pending = 'Pending',
    Failed = 'Failed',
    AwaitingPayment = 'AwaitingPayment',
    Refunded = 'Refunded',
    Void = 'Void',
    Ongoing = 'Ongoing',
    Upcoming = 'Upcoming',
    Expired = 'Expired',
    Unknown = 'Unknown',
    Complete = 'Complete',
    InProgress = 'InProgress',
    Canceled = 'Canceled',
    Declined = 'Declined',
    Invited = 'Invited',
    Signed = 'Signed',
    Submitted = 'Submitted',
    Opened = 'Opened',
}

const StatusBadge: FunctionComponent<StatusBadgeProps> = ({ status, text }) => {
    const { t } = useTranslation();
    let badgeClass = '';
    switch (status) {
        case StatusBadgeStatus.Active:
        case StatusBadgeStatus.Open:
        case StatusBadgeStatus.Read:
        case StatusBadgeStatus.Ongoing:
        case StatusBadgeStatus.Complete:
        case StatusBadgeStatus.Invited:
        case StatusBadgeStatus.Signed:
        case StatusBadgeStatus.Submitted:
            badgeClass = 'badge-active';
            break;
        case StatusBadgeStatus.Closed:
        case StatusBadgeStatus.Deactivated:
        case StatusBadgeStatus.Disabled:
        case StatusBadgeStatus.Unread:
        case StatusBadgeStatus.Refunded:
        case StatusBadgeStatus.Unknown:
        case StatusBadgeStatus.Void:
        case StatusBadgeStatus.Upcoming:
        case StatusBadgeStatus.Opened:
            badgeClass = 'badge-disabled';
            break;
        case StatusBadgeStatus.Approved:
        case StatusBadgeStatus.Paid:
            badgeClass = 'badge-success';
            break;
        case StatusBadgeStatus.Refused:
        case StatusBadgeStatus.Failed:
        case StatusBadgeStatus.Expired:
        case StatusBadgeStatus.Canceled:
        case StatusBadgeStatus.Declined:
            badgeClass = 'badge-error';
            break;
        case StatusBadgeStatus.Pending:
        case StatusBadgeStatus.WaitingForApproval:
        case StatusBadgeStatus.AwaitingPayment:
        case StatusBadgeStatus.InProgress:
            badgeClass = 'badge-pending';
            break;
    }
    return (
        <>{status && <span className={`StatusBadge ${badgeClass}`}>{text ? text : t('StatusBadge.badge_status_'+status)}</span>}</>
    );
};

export default StatusBadge;
