export const moneyFormat = (value: string | number | null | undefined): string => {
    value = parseFloat(value ? value.toString() : '0.00' ).toFixed(2);
    return `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const moneyParser = (value: string | undefined): string =>
    value ? value.replace(/\$\s?|(,*)/g, '') : '';

export const moneyToFloat = (value: string): number | undefined => {
    return value !== undefined && value !== null
        ? Number(value.toString().replace(/[^0-9.-]+/g, ''))
        : undefined;
};
