import React, { FunctionComponent, useCallback, useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'Hooks';
import { Location } from 'Models/Location/Location';
import { Amenity } from 'Models/Amenities/Amenity';
import { DailyBookingSummaryAmenityDto } from 'Api/Features/Bookings/Dtos/DailyBookingSummaryAmenityDto';
import { GetDailyBookingSummaryRequestDto } from 'Api/Features/Bookings/Dtos/GetDailyBookingSummaryRequestDto';
import { DatePicker } from 'antd';
import FlexibleBooking from 'Components/booking/flexible-booking/flexible-booking';
import { generateResourcePeriod } from 'Routes/console/authenticated/locations/id/booking';
import { DATE_API_FORMAT } from 'Models/Constants';
import moment from 'moment';
import momentTimezone from 'moment-timezone';

import './booking-time.less';

interface BookingTimeProps {
    selectedLocation: Location | null;
    selectedAmenity: Amenity | null;
    selectedTime: any;
    isTimeValid: boolean;
    setIsTimeValid: (value: boolean) => void;
    onChange: (value: any) => void;
}

const BookingTime: FunctionComponent<BookingTimeProps> = observer(
    ({
        selectedLocation,
        selectedAmenity,
        selectedTime,
        isTimeValid,
        setIsTimeValid,
        onChange,
    }) => {
        const { bookingStore } = useStores();
        const [currentBooking, setCurrentBooking] = useState(selectedTime || undefined);
        const [date, setDate] = useState<moment.Moment | null>(
            selectedTime ? moment(selectedTime.bookingStart) : moment()
        );
        const datePickerContainerRef = useRef(null);

        const getBookings = (): (DailyBookingSummaryAmenityDto | null)[] | null | undefined => {
            return bookingStore.dailyBookingSummary;
        };

        const fetch = useCallback(async (): Promise<void> => {
            if (selectedLocation?.id)
                bookingStore.setDailyBookingSummary(selectedLocation.id, {
                    date: date ? date.format(DATE_API_FORMAT) : moment(),
                    enableUserRestrictions: true,
                    amenityIds: selectedAmenity && [selectedAmenity.id],
                } as GetDailyBookingSummaryRequestDto);
        }, [bookingStore, date, selectedAmenity, selectedLocation]);

        useEffect(() => {
            fetch();
        }, [fetch, bookingStore, date]);

        useEffect(() => {
            setCurrentBooking(selectedTime || undefined);
        }, [selectedTime, setCurrentBooking]);

        const onDateChange = (value: moment.Moment | null): void => {
            onChange(null);
            setCurrentBooking(undefined);
            setDate(value);
        };

        const onTimeCellClick = (e: any, bookingInformation: any, period: any): void => {
            setIsTimeValid(true);
            onChange(bookingInformation);
        };

        const onBookingResize = ({ formatedBookingStart, formatedBookingEnd }: any): void => {
            if (selectedLocation?.timeZone) {
                const booking: any = currentBooking;
                const start = formatedBookingStart
                    ? formatedBookingStart
                    : momentTimezone(booking.bookingStart).tz(selectedLocation.timeZone).format();
                const bookingEnd = formatedBookingEnd
                    ? formatedBookingEnd
                    : momentTimezone(booking.bookingEnd).tz(selectedLocation.timeZone).format();

                const ressources = getBookings();
                const periods = Array.isArray(ressources) && ressources[0] && ressources[0].periods;

                let isValid = true;

                if (periods) {
                    const currentBookingPeriod = periods.filter(
                        (period) =>
                            selectedLocation.timeZone &&
                            momentTimezone(start)
                                .tz(selectedLocation.timeZone)
                                .isBetween(moment(period?.start), moment(period?.end))
                    );

                    isValid =
                        momentTimezone(start)
                            .tz(selectedLocation.timeZone)
                            .isSameOrAfter(moment(currentBookingPeriod[0]?.start)) &&
                        momentTimezone(bookingEnd)
                            .tz(selectedLocation.timeZone)
                            .isSameOrBefore(moment(currentBookingPeriod[0]?.end));
                }

                if (booking !== undefined) {
                    booking.bookingStart = start;
                    booking.bookingEnd = bookingEnd;
                    onChange(booking);
                    setIsTimeValid(isValid);
                }
            }
        };

        const disabledDate = (currentDate: moment.Moment): boolean =>
            currentDate && currentDate < moment().startOf('day');

        return (
            <div className="BookingModal__Time">
                <div className="BookingModal__Time-inner">
                    <div className="date-container" ref={datePickerContainerRef}>
                        {datePickerContainerRef.current !== null && (
                            <DatePicker
                                value={date || undefined}
                                onChange={onDateChange}
                                open={true}
                                disabledDate={disabledDate}
                                getPopupContainer={(): HTMLElement =>
                                    datePickerContainerRef.current! as HTMLElement
                                }
                                size="large"
                            />
                        )}
                    </div>

                    <div className="flexible-container w-100">
                        <div className="current-date d-flex align-items-center justify-content-center">
                            {moment(date).format('dddd, MMMM D')}
                        </div>
                        <FlexibleBooking
                            view="details"
                            classNames="w-100"
                            resizable
                            resources={getBookings()}
                            currentBooking={currentBooking}
                            timezone={selectedLocation?.timeZone}
                            onTimeCellClick={onTimeCellClick}
                            onBookingResize={onBookingResize}
                            min={0}
                            max={24}
                            zoom={2}
                            isEditing
                            filters={[]}
                            resourcePeriod={generateResourcePeriod}
                            selectedDate={date}
                            currentBookingIsErrored={!isTimeValid}
                        />
                    </div>
                </div>
            </div>
        );
    }
);

export default BookingTime;
