import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Caution: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.34717 21.3847L11.9399 3.03953L22.5311 21.3847H1.34717ZM23.8118 21.6313L12.3656 1.80663C12.2787 1.65376 12.1159 1.56006 11.9399 1.56006C11.7623 1.56006 11.5996 1.65376 11.5126 1.80663L0.0664521 21.6313C-0.0221507 21.7842 -0.0221507 21.9716 0.0664521 22.1244C0.155384 22.2773 0.318123 22.371 0.494014 22.371H23.3844C23.562 22.371 23.7247 22.2773 23.8118 22.1244C23.9004 21.9716 23.9004 21.7842 23.8118 21.6313Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.9398 18.8944C11.6292 18.8944 11.376 18.6429 11.376 18.3322C11.376 18.0215 11.6292 17.77 11.9398 17.77C12.2489 17.77 12.502 18.0215 12.502 18.3322C12.502 18.6429 12.2489 18.8944 11.9398 18.8944ZM11.9398 16.9004C11.1492 16.9004 10.5081 17.5431 10.5081 18.3322C10.5081 19.1212 11.1492 19.764 11.9398 19.764C12.729 19.764 13.3716 19.1212 13.3716 18.3322C13.3716 17.5431 12.729 16.9004 11.9398 16.9004Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.2771 15.4949H11.6015L11.3878 9.99461H12.4906L12.2771 15.4949ZM13.255 9.25979C13.1744 9.17432 13.0595 9.125 12.9427 9.125H10.9357C10.8188 9.125 10.7039 9.17432 10.6232 9.25979C10.541 9.34363 10.4968 9.46034 10.5016 9.57705L10.7498 15.9469C10.7582 16.1803 10.9489 16.3645 11.1839 16.3645H12.6944C12.9295 16.3645 13.1202 16.1803 13.1286 15.9469L13.3766 9.57705C13.3816 9.46034 13.3372 9.34363 13.255 9.25979Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Caution;
