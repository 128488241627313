import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Users: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.852 12.886c0 1.402-1.235 2.542-2.753 2.542H5.891c-1.519 0-2.753-1.14-2.753-2.542v-1.543c0-1.182.872-2.197 2.121-2.469l.107-.024.084.068c.75.596 1.63.91 2.545.91s1.794-.314 2.544-.91l.086-.068.105.024c1.25.272 2.122 1.287 2.122 2.469v1.543zM4.167 4.914C4.167 2.52 5.883.571 7.995.571c2.111 0 3.829 1.949 3.829 4.343 0 2.396-1.718 4.343-3.829 4.343-2.112 0-3.828-1.947-3.828-4.343zm7.328 3.484l-.294-.102.185-.25a5.29 5.29 0 001.01-3.132c0-2.71-1.976-4.914-4.4-4.914-2.427 0-4.4 2.205-4.4 4.914 0 1.14.357 2.253 1.007 3.132l.186.25-.294.102a2.852 2.852 0 00-1.928 2.688v2.057A2.86 2.86 0 005.424 16h5.143a2.86 2.86 0 002.857-2.857v-2.058a2.852 2.852 0 00-1.93-2.687z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Users;
