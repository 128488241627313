// GENERATED FILE - DO NOT MODIFY
import { ApproveBookingRequestDto } from 'Api/Features/Bookings/Dtos/ApproveBookingRequestDto';
import { BookingDetailsDto } from 'Api/Features/Bookings/Dtos/BookingDetailsDto';
import { CancelBookingRequestDto } from 'Api/Features/Bookings/Dtos/CancelBookingRequestDto';
import { CreateBookingRequestDto } from 'Api/Features/Bookings/Dtos/CreateBookingRequestDto';
import { DailyBookingSummaryDto } from 'Api/Features/Bookings/Dtos/DailyBookingSummaryDto';
import { GetBookingPreviewRequestDto } from 'Api/Features/Bookings/Dtos/GetBookingPreviewRequestDto';
import { GetBookingPreviewResponseDto } from 'Api/Features/Bookings/Dtos/GetBookingPreviewResponseDto';
import { GetBookingRecurrencePresetsRequestDto } from 'Api/Features/Bookings/Dtos/GetBookingRecurrencePresetsRequestDto';
import { GetBookingRecurrencePresetsResponseDto } from 'Api/Features/Bookings/Dtos/GetBookingRecurrencePresetsResponseDto';
import { GetBookingsRequestDto } from 'Api/Features/Bookings/Dtos/GetBookingsRequestDto';
import { GetBookingsResponseDto } from 'Api/Features/Bookings/Dtos/GetBookingsResponseDto';
import { GetDailyBookingSummaryRequestDto } from 'Api/Features/Bookings/Dtos/GetDailyBookingSummaryRequestDto';
import { RefuseBookingRequestDto } from 'Api/Features/Bookings/Dtos/RefuseBookingRequestDto';
import { UpdateBookingRequestDto } from 'Api/Features/Bookings/Dtos/UpdateBookingRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class BookingProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getBooking(bookingId: string): Promise<BookingDetailsDto | null> {
        const uri = this.buildUri(
            "/bookings/{bookingId}",
            { bookingId: bookingId },
            null
        );

        const data: BookingDetailsDto | null = await this.httpClient.get<BookingDetailsDto | null>(uri);
        return data;
    }

    public async getBookings(request: GetBookingsRequestDto | null): Promise<GetBookingsResponseDto | null> {
        const uri = this.buildUri(
            "/bookings",
            null,
            { ...request || undefined }
        );

        const data: GetBookingsResponseDto | null = await this.httpClient.get<GetBookingsResponseDto | null>(uri);
        return data;
    }

    public async createBooking(request: CreateBookingRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/bookings",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreateBookingRequestDto | null, string>(uri, request);
        return data;
    }

    public async getBookingPreview(request: GetBookingPreviewRequestDto | null): Promise<GetBookingPreviewResponseDto | null> {
        const uri = this.buildUri(
            "/bookings/preview",
            null,
            null
        );

        const data: GetBookingPreviewResponseDto | null = await this.httpClient.post<GetBookingPreviewRequestDto | null, GetBookingPreviewResponseDto | null>(uri, request);
        return data;
    }

    public async updateBooking(bookingId: string, request: UpdateBookingRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/bookings/{bookingId}",
            { bookingId: bookingId },
            null
        );

        await this.httpClient.patch<UpdateBookingRequestDto | null, void>(uri, request);
    }

    public async cancelBooking(bookingId: string, request: CancelBookingRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/bookings/{bookingId}/cancellation",
            { bookingId: bookingId },
            null
        );

        await this.httpClient.put<CancelBookingRequestDto | null, void>(uri, request);
    }

    public async getDailyBookingSummary(locationId: string, request: GetDailyBookingSummaryRequestDto | null): Promise<DailyBookingSummaryDto | null> {
        const uri = this.buildUri(
            "/locations/{locationId}/daily-booking-summary",
            { locationId: locationId },
            null
        );

        const data: DailyBookingSummaryDto | null = await this.httpClient.post<GetDailyBookingSummaryRequestDto | null, DailyBookingSummaryDto | null>(uri, request);
        return data;
    }

    public async approveBooking(bookingId: string, request: ApproveBookingRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/bookings/{bookingId}/approvals",
            { bookingId: bookingId },
            null
        );

        await this.httpClient.post<ApproveBookingRequestDto | null, void>(uri, request);
    }

    public async refuseBooking(bookingId: string, request: RefuseBookingRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/bookings/{bookingId}/refusals",
            { bookingId: bookingId },
            null
        );

        await this.httpClient.post<RefuseBookingRequestDto | null, void>(uri, request);
    }

    public async getBookingRecurrencePresets(request: GetBookingRecurrencePresetsRequestDto | null): Promise<GetBookingRecurrencePresetsResponseDto | null> {
        const uri = this.buildUri(
            "/booking-recurrence-presets",
            null,
            { ...request || undefined }
        );

        const data: GetBookingRecurrencePresetsResponseDto | null = await this.httpClient.get<GetBookingRecurrencePresetsResponseDto | null>(uri);
        return data;
    }
}