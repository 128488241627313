import { AddressDto } from 'Api/Features/General/Dtos/AddressDto';
import { LocationTypeDto } from 'Api/Features/Locations/Dtos/LocationTypeDto';

export class LightLocationInfo {
    constructor(
        id: string | undefined,
        name: string | null | undefined,
        mainImageUrl: string | null | undefined,
        timeZone: string | null | undefined,
        type: LocationTypeDto | undefined,
        address?: AddressDto | null,
    ) {
        this.id = id;
        this.name = name;
        this.mainImageUrl = mainImageUrl;
        this.timeZone = timeZone;
        this.type = type;
        this.address = address;
    }
    id: string | undefined;
    name: string | null | undefined;
    mainImageUrl: string | null | undefined;
    timeZone: string | null | undefined;
    type: LocationTypeDto | undefined;
    address?: AddressDto | null;

    get consoleUrl(): string {
        return `/locations/${this.id}/service`;
    }

    get isHeyday(): boolean {
        return this.type === LocationTypeDto.Heyday;
    }
}
