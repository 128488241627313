import { observable, action, computed } from 'mobx';
import { Location } from 'Models/Location/Location';
import { LightLocationInfo } from 'Models/Location/LightLocationInfo';
import { UserDetails } from 'Models/Users/UserDetails';
import { Company } from 'Models/Companies/Company';
import { AmenityCategory } from 'Models/Amenities/AmenityCategory';
import { Invoice } from 'Models/Invoices/Invoice';
import { AmenityCategories } from 'Models/Amenities/AmenityCategories';

class NavigationStore {
    // # Sub-menus items
    @observable currentLocation = '';
    @observable currentSelectedItemType: string | null = null;
    @observable currentSelectedItem:
        | Location
        | LightLocationInfo
        | Company
        | UserDetails
        | AmenityCategories
        | AmenityCategory
        | Invoice
        | null = null;

    // # Sub-menus items types tests
    @computed get subIsLocation(): boolean {
        return (
            this.currentSelectedItem !== null &&
            this.currentLocation.startsWith('/locations/') &&
            (this.currentSelectedItemType === 'Location' ||
                this.currentSelectedItemType === 'LightLocationInfo')
        );
    }

    @computed get subIsCompany(): boolean {
        return (
            this.currentSelectedItem !== null &&
            (this.currentLocation.startsWith('/companies/') ||
                this.currentLocation.startsWith('/company/')) &&
            this.currentSelectedItemType === 'Company'
        );
    }

    @computed get subIsAccount(): boolean {
        return (
            this.currentSelectedItem !== null &&
            this.currentLocation.startsWith('/account/') &&
            this.currentSelectedItemType === 'User'
        );
    }

    @computed get subIsUser(): boolean {
        return (
            this.currentSelectedItem !== null &&
            this.currentLocation.startsWith('/users/') &&
            this.currentSelectedItemType === 'User'
        );
    }

    @computed get subIsStaff(): boolean {
        return (
            this.currentSelectedItem !== null &&
            this.currentLocation.startsWith('/staff-accesses/') &&
            this.currentSelectedItemType === 'User'
        );
    }

    @computed get subIsAmenityCategories(): boolean {
        return (
            this.currentSelectedItem !== null &&
            this.currentLocation.startsWith('/settings/') &&
            this.currentSelectedItemType === 'AmenityCategories'
        );
    }

    @computed get subIsAmenityCategory(): boolean {
        return (
            this.currentSelectedItem !== null &&
            this.currentLocation.startsWith('/settings/') &&
            this.currentSelectedItemType === 'AmenityCategory'
        );
    }

    @computed get subIsInvoice(): boolean {
        return (
            this.currentSelectedItem !== null &&
            this.currentLocation.startsWith('/invoices/') &&
            this.currentSelectedItemType === 'Invoice'
        );
    }

    // # Current Sub-menu title and path
    @computed get submenu(): string {
        const empty = '';

        if (this.currentSelectedItemType === 'Location') {
            return (this.currentSelectedItem as Location).name || empty;
        }
        if (this.currentSelectedItemType === 'LightLocationInfo') {
            return (this.currentSelectedItem as LightLocationInfo).name || empty;
        }
        if (this.currentSelectedItemType === 'Company') {
            return (this.currentSelectedItem as Company).name || empty;
        }
        if (this.currentSelectedItemType === 'User') {
            return (this.currentSelectedItem as UserDetails).name || empty;
        }
        if (this.currentSelectedItemType === 'AmenityCategories') {
            return 'Amenity Categories';
        }
        if (this.currentSelectedItemType === 'AmenityCategory') {
            return (this.currentSelectedItem as AmenityCategory).name || empty;
        }
        if (this.currentSelectedItemType === 'Invoice') {
            return (this.currentSelectedItem as Invoice).number || empty;
        }

        return empty;
    }

    @computed get submenuPath(): string {
        const empty = '';

        if (this.currentSelectedItemType === 'Location') {
            return (this.currentSelectedItem as Location).consoleUrl || empty;
        }
        if (this.currentSelectedItemType === 'LightLocationInfo') {
            return (this.currentSelectedItem as LightLocationInfo).consoleUrl || empty;
        }
        if (this.currentSelectedItemType === 'Company') {
            return (this.currentSelectedItem as Company).consoleUrl || empty;
        }
        if (this.currentSelectedItemType === 'User') {
            if (this.subIsStaff) {
                return (this.currentSelectedItem as UserDetails).consoleManagementUrl || empty;
            }
            return (this.currentSelectedItem as UserDetails).consoleUrl || empty;
        }
        if (this.currentSelectedItemType === 'AmenityCategories') {
            return (this.currentSelectedItem as AmenityCategories).consoleUrl || empty;
        }
        if (this.currentSelectedItemType === 'AmenityCategory') {
            return (this.currentSelectedItem as AmenityCategory).consoleUrl || empty;
        }
        if (this.currentSelectedItemType === 'Invoice') {
            return (this.currentSelectedItem as Invoice).consoleUrl || empty;
        }

        return empty;
    }

    @action
    setLocation(location: string): void {
        this.currentLocation = location;
    }

    @action
    setSubmenu(
        item:
            | Location
            | LightLocationInfo
            | Company
            | UserDetails
            | AmenityCategories
            | AmenityCategory
            | Invoice
            | null
    ): void {
        if (item) {
            if (item instanceof Location) {
                this.currentSelectedItemType = 'Location';
            } else if (item instanceof Company) {
                this.currentSelectedItemType = 'Company';
            } else if (item instanceof UserDetails) {
                this.currentSelectedItemType = 'User';
            } else if (item instanceof Invoice) {
                this.currentSelectedItemType = 'Invoice';
            } else if (item instanceof AmenityCategories) {
                this.currentSelectedItemType = 'AmenityCategories';
            } else if (item instanceof AmenityCategory) {
                this.currentSelectedItemType = 'AmenityCategory';
            } else {
                this.currentSelectedItemType = 'LightLocationInfo';
            }
        } else {
            this.currentSelectedItemType = null;
        }

        this.currentSelectedItem = item;
    }

    @action
    clearSubmenu(): void {
        this.currentSelectedItemType = null;
        this.currentSelectedItem = null;
    }
}

export default NavigationStore;
