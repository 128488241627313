import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { BookingProxy } from 'Api/Features/Bookings/BookingProxy';
import { DailyBookingSummaryDto } from 'Api/Features/Bookings/Dtos/DailyBookingSummaryDto';
import { BookingDto } from 'Api/Features/Bookings/Dtos/BookingDto';
import { BookingDetailsDto } from 'Api/Features/Bookings/Dtos/BookingDetailsDto';
import { CreateBookingRequestDto } from 'Api/Features/Bookings/Dtos/CreateBookingRequestDto';
import { CancelBookingRequestDto } from 'Api/Features/Bookings/Dtos/CancelBookingRequestDto';
import { UpdateBookingRequestDto } from 'Api/Features/Bookings/Dtos/UpdateBookingRequestDto';
import { GetDailyBookingSummaryRequestDto } from 'Api/Features/Bookings/Dtos/GetDailyBookingSummaryRequestDto';
import { Booking } from 'Models/Bookings/Booking';
import { BookingDetails } from 'Models/Bookings/BookingDetails';
import { DailyBookingSummary } from 'Models/Bookings/DailyBookingSummary';
import { GetBookingsRequestDto } from 'Api/Features/Bookings/Dtos/GetBookingsRequestDto';
import { GetBookingsResponseDto } from 'Api/Features/Bookings/Dtos/GetBookingsResponseDto';
import { ApproveBookingRequestDto } from 'Api/Features/Bookings/Dtos/ApproveBookingRequestDto';
import { RefuseBookingRequestDto } from 'Api/Features/Bookings/Dtos/RefuseBookingRequestDto';
import { BookingApprovalStatusDto } from 'Api/Features/Bookings/Dtos/BookingApprovalStatusDto';
import { GetBookingRecurrencePresetsRequestDto } from 'Api/Features/Bookings/Dtos/GetBookingRecurrencePresetsRequestDto';
import { GetBookingRecurrencePresetsResponseDto } from 'Api/Features/Bookings/Dtos/GetBookingRecurrencePresetsResponseDto';
import { GetBookingPreviewResponseDto } from 'Api/Features/Bookings/Dtos/GetBookingPreviewResponseDto';

@inject(BookingProxy)
export class BookingService extends ApiService {
    constructor(private readonly bookingProxy: BookingProxy) {
        super();
    }

    public async getBookings(request: GetBookingsRequestDto): Promise<[Booking[], number]> {
        const response: GetBookingsResponseDto | null = await this.bookingProxy.getBookings(
            request
        );
        return [
            response?.items?.map((dto: BookingDto | null) => new Booking(dto!)) || [],
            response?.totalItemCount || 0,
        ];
    }

    public async getDailyBookingSummary(
        id: string,
        request: GetDailyBookingSummaryRequestDto
    ): Promise<DailyBookingSummary | null> {
        const response: DailyBookingSummaryDto | null = await this.bookingProxy.getDailyBookingSummary(
            id,
            request
        );
        return response ? new DailyBookingSummary(response) : null;
    }

    public async book(request: CreateBookingRequestDto): Promise<string | null> {
        const bookingID:string | null = await this.bookingProxy.createBooking(request);
        return bookingID ? bookingID : null;
    }

    public async getBooking(id: string): Promise<BookingDetails | null> {
        const response: BookingDetailsDto | null = await this.bookingProxy.getBooking(id);
        return response ? new BookingDetails(response) : null;
    }

    public async updateBooking(id: string, request: UpdateBookingRequestDto): Promise<void> {
        await this.bookingProxy.updateBooking(id, request);
    }

    public async cancelBooking(id: string, request: CancelBookingRequestDto | null): Promise<void> {
        await this.bookingProxy.cancelBooking(id, request);
    }

    public async approveBooking(
        bookingId: string,
        request: ApproveBookingRequestDto | null
    ): Promise<void> {
        await this.bookingProxy.approveBooking(bookingId, request);
    }

    public async refuseBooking(
        bookingId: string,
        request: RefuseBookingRequestDto | null
    ): Promise<void> {
        await this.bookingProxy.refuseBooking(bookingId, request);
    }

    public async getWaitingForApprovalBookings(locationId: string): Promise<[Booking[], number]> {
        return this.getBookings({
            locationIds: [locationId],
            approvalStatuses: [BookingApprovalStatusDto.WaitingForApproval],
        });
    }

    public async getBookingRecurrencePresets(
        request: GetBookingRecurrencePresetsRequestDto | null
    ): Promise<GetBookingRecurrencePresetsResponseDto | null> {
        const data: GetBookingRecurrencePresetsResponseDto | null = await this.bookingProxy.getBookingRecurrencePresets(
            request
        );
        return data;
    }

    public async getBookingPreview(
        request: CreateBookingRequestDto | null
    ): Promise<GetBookingPreviewResponseDto | null> {
        const data: GetBookingPreviewResponseDto | null = await this.bookingProxy.getBookingPreview(
            request
        );
        return data;
    }
}
