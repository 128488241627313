import React, { FunctionComponent, ReactNode } from 'react';
import { List, Typography } from 'antd';
import './simple-list.less';

const { Title } = Typography;

export interface SimpleListItemProps {
    title?: string | React.ReactNode;
    description?: string;
    avatar?: React.ReactNode;
    content?: ReactNode;
}

interface SimpleListProps {
    className?: string;
    title: string;
    data?: SimpleListItemProps[] | undefined;
    children?: ReactNode;
}

const SimpleList: FunctionComponent<SimpleListProps> = ({
    className,
    title,
    data,
    children,
}) => {
    const listHeader = (
        <>
            <Title level={4} className="header-title">
                {title}
            </Title>
        </>
    );

    return (
        <List
            className={`SimpleList ${className || ''}`}
            size="large"
            bordered
            header={listHeader}
            dataSource={data}
            renderItem={(item) => (
                <List.Item>
                    {item.content ? (
                        item.content
                    ) : (
                        <List.Item.Meta
                            title={
                                typeof item.title === 'string' ? (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: item.title || '',
                                        }}
                                    />
                                ) : (
                                    <div>{item.title}</div>
                                )
                            }
                            description={
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: item.description || '',
                                    }}
                                />
                            }
                            avatar={item.avatar}
                        />
                    )}
                </List.Item>
            )}
        >
            {children}
        </List>
    );
};

export default SimpleList;
