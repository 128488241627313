import { MAX_LENGTH_4000 } from 'Models/Constants';
import * as yup from 'yup';

export const CreateBookingSchema = yup.object({
    organizerUserId: yup.string().required('Errors.required'),
    description: yup.string().max(MAX_LENGTH_4000, 'Errors.length_4000'),
    companyId: yup.string().required('Errors.required'),
    recurrenceDuration: yup.string().when('recurrenceType', {
        is: (value) =>  value !== 'Does not repeat',
        then: yup.string().required('Errors.required')
    }),
    occurenceCount: yup.string().when('recurrenceDuration', {
        is: (value) =>  value === 'Select number of occurences',
        then: yup.string().required('Errors.required')
    })
});

export const EditBookingSchema = yup.object({
    organizerUserId: yup.string().required('Errors.required'),
    companyId: yup.string().required('Errors.required'),
})