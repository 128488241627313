import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import { Skeleton, Typography } from 'antd';
import { theme, images } from 'variant';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import { RoutedBreadcrumb } from 'Components/routed-breadcrumb';
import StatusBadge from 'Components/status-badge/status-badge';
import { StatusBadgeStatus } from 'Components/status-badge/status-badge';
import { ImageWithPlaceholderProps } from '../image-with-placeholder/image-with-placeholder';
import { ImageWithPlaceholder } from '../image-with-placeholder';

import { Location } from 'Components/icons';
import './location-header.less';

const { Title } = Typography;

interface LocationHeaderProps extends ImageWithPlaceholderProps {
    title?: string;
    subTitle?: ReactElement | string | null;
    showStatusBadge?: boolean;
    status?: StatusBadgeStatus;
    routes?: BreadcrumbSegment[];
    loading?: boolean;
    action?: ReactNode;
}

const LocationHeader: FunctionComponent<LocationHeaderProps> = observer(
    ({
        title,
        subTitle,
        showStatusBadge = false,
        status,
        routes = [],
        loading,
        action,
        ...imageProps
    }) => {
        const { id } = useParams<{ id: string }>();

        const { locationStore } = useStores();

        const locationData = locationStore.locations.find((location) => location.id === id);
        //const statusBadge: CampusStatusTypeDto | null = locationData?.status || null;

        const locationRoutes: BreadcrumbSegment[] = [
            {
                path: 'locations',
                name: 'Locations',
            },
            {
                path: `${locationData?.id || ''}`,
                name: `${locationData?.name || ''}`,
            },
            ...routes,
        ];

        return (
            <div
                className="LocationHeader"
                style={{
                    background: `linear-gradient(0deg, rgba(238, 235, 226, 0.9), rgba(238, 235, 226, 0.9)), url(${
                        locationData?.mainImageUrl || images.locationsHeader
                    }`,
                }}
            >
                <Skeleton loading={loading}>
                    {imageProps.imgSrc || imageProps.defaultImg ? (
                        <ImageWithPlaceholder
                            className="header-icon"
                            defaultImg={
                                imageProps.defaultImg || <Location fill={theme['primary-color']} />
                            }
                            {...imageProps}
                        />
                    ) : (
                        <img
                            className="location-avatar"
                            alt={''}
                            src={locationData?.mainImageUrl || undefined}
                        />
                    )}

                    <div className="header-info">
                        <RoutedBreadcrumb routes={locationRoutes} />
                        <Title className="header-title">{title ? title : locationData?.name}</Title>
                        {subTitle ? <div className="header-subtitle">{subTitle}</div> : null}
                        {showStatusBadge ? (
                            <div className="location-avatar-badge">
                                <StatusBadge status={status} />
                            </div>
                        ) : null}
                    </div>
                    {action && <div className="header-action">{action}</div>}
                </Skeleton>
            </div>
        );
    }
);

export default LocationHeader;
