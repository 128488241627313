import React from 'react';
import { Link } from 'react-router-dom';
import { ContactInfoDto } from 'Api/Features/General/Dtos/ContactInfoDto';

export class ContactInfo implements ContactInfoDto {
    constructor(dto: ContactInfoDto) {
        Object.assign(this, dto);
    }
    email: string | null = null;
    phoneNumber: string | null = null;
    website: string | null = null;

    get isEmpty(): boolean {
        return !(this.email || this.phoneNumber || this.website);
    }

    get websiteUrl(): string | null {
        if (this.website) {
            // Force https if no protocol found in string for the link
            return !/^https?:\/\//i.test(this.website) ? 'https://' + this.website : this.website;
        }

        return null;
    }

    get websiteDisplayUrl(): string | null {
        if (this.website) {
            // Remove protocol and trailing slash from url for display
            return this.website.replace(/(^\w+:|^)\/\//, '').replace(/\/$/, '');
        }
        return null;
    }

    get websiteLink(): JSX.Element | null {
        if (this.websiteUrl) {
            return (
                <Link to={{ pathname: this.websiteUrl }} target="_blank">
                    {this.websiteDisplayUrl}
                </Link>
            );
        }
        return null;
    }
}
