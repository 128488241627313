// GENERATED FILE - DO NOT MODIFY
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import { CreateLocationRequestDto } from 'Api/Features/Locations/Dtos/CreateLocationRequestDto';
import { GetLocationsRequestDto } from 'Api/Features/Locations/Dtos/GetLocationsRequestDto';
import { GetLocationsResponseDto } from 'Api/Features/Locations/Dtos/GetLocationsResponseDto';
import { GetUserLocationsRequestDto } from 'Api/Features/Locations/Dtos/GetUserLocationsRequestDto';
import { LocationDto } from 'Api/Features/Locations/Dtos/LocationDto';
import { UpdateLocationImagesRequest } from 'Api/Features/Locations/Dtos/UpdateLocationImagesRequest';
import { UpdateLocationRequestDto } from 'Api/Features/Locations/Dtos/UpdateLocationRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class LocationProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getLocation(locationId: string): Promise<LocationDto | null> {
        const uri = this.buildUri(
            "/locations/{locationId}",
            { locationId: locationId },
            null
        );

        const data: LocationDto | null = await this.httpClient.get<LocationDto | null>(uri);
        return data;
    }

    public async getLocations(request: GetLocationsRequestDto | null): Promise<GetLocationsResponseDto | null> {
        const uri = this.buildUri(
            "/locations",
            null,
            { ...request || undefined }
        );

        const data: GetLocationsResponseDto | null = await this.httpClient.get<GetLocationsResponseDto | null>(uri);
        return data;
    }

    public async getUserLocations(userId: string, request: GetUserLocationsRequestDto | null): Promise<GetLocationsResponseDto | null> {
        const uri = this.buildUri(
            "/users/{userId}/locations",
            { userId: userId },
            { ...request || undefined }
        );

        const data: GetLocationsResponseDto | null = await this.httpClient.get<GetLocationsResponseDto | null>(uri);
        return data;
    }

    public async createLocation(request: CreateLocationRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/locations",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreateLocationRequestDto | null, string>(uri, request);
        return data;
    }

    public async updateLocation(locationId: string, request: UpdateLocationRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/locations/{locationId}",
            { locationId: locationId },
            null
        );

        await this.httpClient.patch<UpdateLocationRequestDto | null, void>(uri, request);
    }

    public async updateLocationLogoImage(locationId: string, request: UpdateFileRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/locations/{locationId}/logo-image",
            { locationId: locationId },
            null
        );

        await this.httpClient.put<UpdateFileRequestDto | null, void>(uri, request);
    }

    public async deleteLocationLogoImage(locationId: string): Promise<void> {
        const uri = this.buildUri(
            "/locations/{locationId}/logo-image",
            { locationId: locationId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }

    public async updateLocationMainImage(locationId: string, request: UpdateFileRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/locations/{locationId}/main-image",
            { locationId: locationId },
            null
        );

        await this.httpClient.put<UpdateFileRequestDto | null, void>(uri, request);
    }

    public async deleteLocationMainImage(locationId: string): Promise<void> {
        const uri = this.buildUri(
            "/locations/{locationId}/main-image",
            { locationId: locationId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }

    public async updateLocationAppHomeBackgroundImage(locationId: string, request: UpdateFileRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/locations/{locationId}/app-home-background-image",
            { locationId: locationId },
            null
        );

        await this.httpClient.put<UpdateFileRequestDto | null, void>(uri, request);
    }

    public async deleteLocationAppHomeBackgroundImage(locationId: string): Promise<void> {
        const uri = this.buildUri(
            "/locations/{locationId}/app-home-background-image",
            { locationId: locationId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }

    public async updateLocationImages(locationId: string, request: UpdateLocationImagesRequest | null): Promise<void> {
        const uri = this.buildUri(
            "/locations/{locationId}/images",
            { locationId: locationId },
            null
        );

        await this.httpClient.patch<UpdateLocationImagesRequest | null, void>(uri, request);
    }
}