import React from 'react';
import moment from 'moment';
import { Resizable } from 'react-resizable';
import { ResizableProps } from '../../../../node_modules/@types/react-resizable/index';
import { TIME_FORMAT, DATETIME_DISPLAY_FORMAT, TIME_FORMAT_12_HOUR } from 'Models/Constants';

import 'react-resizable/css/styles.css';

interface CurrentBookingProps {
    currentBooking: any;
    zoom: any;
    view: any;
    onResize?: any;
    resizable?: any;
    selectedDate?: any;
    isEditing?: any;
    periods: { end: string; start: string; status: string }[];
    isErrored?: boolean;
    recurrenceBlocksResize?: boolean;
    onRecurrenceBlockedResize?: any;
    onResizeStopped?: () => void;
}

interface CurrentBookingState {
    position: {};
    top: any;
    left: any;
    width: any;
    height: any;
}

class CurrentBooking extends React.Component<CurrentBookingProps, CurrentBookingState> {
    private prevHeight: any;
    constructor(props: any) {
        super(props);

        this.state = {
            position: {},
            top: null,
            left: null,
            width: null,
            height: null,
        };

        this.prevHeight = null;
    }

    componentDidMount(): void {
        if (this.props.currentBooking.conferenceRoomId) {
            this.calculateBookingPosition();
        }
    }

    componentDidUpdate(prevProps: any): void {
        const { zoom, currentBooking } = this.props;
        if (prevProps.zoom !== zoom) {
            this.calculateBookingPosition();
        }

        if (
            prevProps.currentBooking.conferenceRoomId !== currentBooking.conferenceRoomId ||
            prevProps.currentBooking.bookingStart !== currentBooking.bookingStart ||
            prevProps.currentBooking.bookingEnd !== currentBooking.bookingEnd
        ) {
            this.calculateBookingPosition();
        }
    }

    startTime = (): string => {
        return `${moment.parseZone(this.props.currentBooking.bookingStart).format(TIME_FORMAT)}`;
    };

    endTime = (): string => {
        return `${moment.parseZone(this.props.currentBooking.bookingEnd).format(TIME_FORMAT)}`;
    };

    allDay = (): boolean => {
        const startDate = moment.parseZone(this.props.currentBooking.bookingStart);
        const endDate = moment.parseZone(this.props.currentBooking.bookingEnd);
        const duration = moment.duration(endDate.diff(startDate));
        return duration.asHours() >= 24;
    };

    calculateBookingPosition = (): void => {
        let parentHeight = 0;
        const bookingStart = document.getElementById(
            `${this.props.view}_${this.props.currentBooking.conferenceRoomId}_${this.startTime()}`
        );
        const bookingEnd = document.getElementById(
            `${this.props.view}_${this.props.currentBooking.conferenceRoomId}_${this.endTime()}`
        );
        let bookingStartOffsetTop = bookingStart?.offsetTop || 0;
        let bookingEndOffsetTop = bookingEnd?.offsetTop || 0;

        if (bookingStart?.offsetParent instanceof HTMLElement) {
            if (bookingStart.offsetParent.classList.contains('Booked')) {
                bookingStartOffsetTop = bookingStart.offsetParent.offsetTop + bookingStartOffsetTop;
            }
            if (bookingStart.offsetParent.classList.contains('CurrentBookingPeriod')) {
                bookingStartOffsetTop = bookingStart.offsetParent.offsetTop;
            }
        }

        if (bookingEnd?.offsetParent instanceof HTMLElement) {
            if (bookingEnd.offsetParent.classList.contains('Booked')) {
                bookingEndOffsetTop = bookingEnd.offsetParent.offsetTop + bookingEndOffsetTop;
            }
            if (bookingEnd.offsetParent.classList.contains('CurrentBookingPeriod')) {
                bookingEndOffsetTop += bookingEnd.offsetParent.offsetHeight;
            }
            parentHeight = bookingEnd.offsetParent.offsetHeight;
        }

        // if it ends at midnight get timespan height has offset instead of start of timespan : 0:00 AM
        if (this.endTime() === '00:00') {
            bookingEndOffsetTop = parentHeight;
        }

        if (bookingStart && bookingEnd) {
            const top = !this.allDay() ? bookingStartOffsetTop : 0;
            const left = 0;
            const width = bookingStart.getBoundingClientRect().width;
            const height = !this.allDay() ? bookingEndOffsetTop - top : parentHeight;
            this.prevHeight = height;

            this.setState({
                top,
                left,
                width,
                height,
            });
        }
    };

    onResize = (event: any, data: any): void => {
        if (this.props.recurrenceBlocksResize) {
            this.props.onRecurrenceBlockedResize();
        } else {
            const { handle, size } = data;
            const { currentBooking } = this.props;

            const isNorthHandle = handle === 'n';
            const height = size.height < 40 ? 40 : size.height;
            const bookingDurationInMinutes = (height / 40) * 15;

            let bookingStart = null;
            let bookingEnd = null;

            if (isNorthHandle) {
                const isGrowing = size.height > this.prevHeight;
                const heightDifference = isGrowing
                    ? size.height - this.prevHeight
                    : this.prevHeight - size.height;

                bookingStart = moment
                    .parseZone(currentBooking.bookingEnd)
                    .subtract(bookingDurationInMinutes, 'minutes');

                this.setState((prevState) => {
                    const top = isGrowing
                        ? prevState.top - heightDifference
                        : prevState.top + heightDifference;

                    return {
                        top,
                        height,
                    };
                });
            } else {
                bookingEnd = moment
                    .parseZone(currentBooking.bookingStart)
                    .add(bookingDurationInMinutes, 'minutes');
                this.setState({ height });
            }
            this.prevHeight = height;
            this.props.onResize({ bookingStart, bookingEnd });
        }
    };

    displayDateTimeFormat = (): string => {
        return this.allDay() ? DATETIME_DISPLAY_FORMAT : TIME_FORMAT_12_HOUR;
    };

    render() {
        const { currentBooking, resizable } = this.props;
        const { width, height, top, left } = this.state;

        if (!width || !height) {
            return null;
        }

        // If is bookings view, verify if selected date is same date as bookingStart
        if (
            this.props.view === 'bookings' &&
            !moment(currentBooking.bookingStart).isSame(this.props.selectedDate, 'day')
        ) {
            return null;
        }

        const Container = resizable ? Resizable : 'div';

        const resizableProps: ResizableProps = resizable
            ? {
                  handleSize: [6, 6],
                  onResize: this.onResize,
                  onResizeStop: this.props.onResizeStopped ?? undefined,
                  resizeHandles: ['n', 's'],
                  draggableOpts: { grid: [40, 40] },
                  height: height,
                  width: width,
              }
            : {
                  height: height,
                  width: width,
              };

        return (
            <div
                className={`position-absolute CurrentBooking CurrentBooking-${
                    this.props.view
                } w-100 ${this.props.isErrored ? 'BookingConflict' : ''}`}
                style={{
                    top: top,
                    left: left,
                    height: height,
                }}
                //onClick={this.props.view === 'bookings' ? this.props.onBookingClick : null}
            >
                <Container {...resizableProps}>
                    {(this.props.view === 'details' ||
                        this.props.view === 'conflicts' ||
                        this.props.isEditing) && (
                        <div className="p-1">
                            <div className="float-left mr-2">
                                <span className="CurrentBooking__Information">
                                    {moment
                                        .parseZone(currentBooking.bookingStart)
                                        .format(this.displayDateTimeFormat())}{' '}
                                    -{' '}
                                    {moment
                                        .parseZone(currentBooking.bookingEnd)
                                        .format(this.displayDateTimeFormat())}
                                </span>
                            </div>
                        </div>
                    )}
                </Container>
            </div>
        );
    }
}

export default CurrentBooking;
