import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useService, useStores } from 'Hooks';
import { Location } from 'Models/Location/Location';
import { AmenityCategory } from 'Models/Amenities/AmenityCategory';
import { AmenityCategoryService } from 'Services/AmenityCategoryService';
import { Avatar, Button, Typography } from 'antd';
import { Amenity } from 'Components/icons';
import { useTranslation } from 'react-i18next';
import SelectableList from 'Components/list/selectable-list';

import './booking-amenity-category.less';

const { Text, Title } = Typography;

interface BookingAmenityCategoryProps {
    selectedLocation: Location | null;
    selectedAmenityCategory: AmenityCategory | null;
    onChange: (value: any) => void;
    backToLocation: () => void;
    skipStep: () => void;
}

const BookingAmenityCategory: FunctionComponent<BookingAmenityCategoryProps> = ({
    selectedLocation,
    selectedAmenityCategory = null,
    onChange,
    backToLocation,
    skipStep,
}) => {
    const { t } = useTranslation();
    const { globalLoadingStore } = useStores();
    const amenityCategoryService = useService(AmenityCategoryService);
    const [amenityCategories, setAmenityCategories] = useState<AmenityCategory[]>([]);

    const fetch = useCallback(async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();
            // call api
            const [_amenityCategories] = await amenityCategoryService.getAmenityCategories({
                locationIds:
                    selectedLocation && selectedLocation.id ? [selectedLocation?.id] : null,
            });

            if (!selectedAmenityCategory && _amenityCategories.length === 1) {
                onChange(_amenityCategories[0]);
                skipStep();
            }

            setAmenityCategories(_amenityCategories);
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [
        globalLoadingStore,
        amenityCategoryService,
        selectedLocation,
        selectedAmenityCategory,
        onChange,
        skipStep,
    ]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return (
        <div className="BookingModal__AmenityCategories">
            <div className="location-header">
                <Avatar
                    size={110}
                    icon={!selectedLocation?.mainImageUrl ? <Amenity /> : undefined}
                    src={selectedLocation?.mainImageUrl || undefined}
                />
                <Title level={4}>{selectedLocation?.name}</Title>
                <Button onClick={backToLocation}>{t('Booking.change_location')}</Button>
            </div>
            <div className="AmenityCategories_Card">
                <div>
                    <SelectableList
                        disabled={amenityCategories.length === 1}
                        defaultSelected={selectedAmenityCategory}
                        itemKeyId="id"
                        items={amenityCategories}
                        elPerRow={4}
                        fallbackThumbnail={<Amenity fill="#FFFFFF" />}
                        getThumbnailUrl={(amenityCategory): string =>
                            amenityCategory.imageUrl || null
                        }
                        getThumbnailName={(amenityCategory): string => amenityCategory.name}
                        getDescription={(amenityCategory): JSX.Element => (
                            <>
                                {amenityCategory.iconUrl ? (
                                    <img
                                        className="amenity-category-icon"
                                        src={amenityCategory.iconUrl}
                                        alt={amenityCategory.name}
                                    />
                                ) : null}
                                <Text className="amenity-category-title">
                                    {amenityCategory.name}
                                </Text>
                            </>
                        )}
                        onItemClick={onChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default BookingAmenityCategory;
