import memoize from 'memoize-one';
import moment from 'moment';

const quarterHours = ['00', '15', '30', '45'];
const halfHours = ['00', '30'];
const zoomMinutes = [60, 30, 15];

function splitToDigit(n: number): number[] {
    return [...(n + '')].map(Number);
}

export const generateTimespan = memoize(
    (min, max, zoom, showSeconds = false, showSuffix = false) => {
        const times = [];
        const minutes = zoomMinutes[zoom];
        const seconds = showSeconds ? ':00' : '';

        for (let i = min; i < max; i++) {
            const timeSuffix = showSuffix ? (i < 12 ? ' AM' : ' PM') : '';
            const minutesToUse = minutes === 30 ? halfHours : quarterHours;

            let hours = i;

            if (i > 12) {
                const splitedHours = splitToDigit(i - 2);
                if (i < 22) {
                    hours = splitedHours[1];
                } else {
                    hours = Number('1' + splitedHours[1]);
                }
            }

            for (let j = 0; j < 60 / minutes; j++) {
                times.push(hours + ':' + minutesToUse[j] + seconds + timeSuffix);
            }
        }
        return times;
    }
);

export const getDayPeriod = (): 'morning' | 'afternoon' | 'evening' => {
    const today = new Date();
    const curHr = today.getHours();
    return curHr < 12 ? 'morning' : curHr < 18 ? 'afternoon' : 'evening';
};

export const convertDurationToTimeSpanString = (duration: moment.Duration): string => {
    return `${duration.days() > 0 ? `${duration.days()}.` : ''}${
        duration.hours() < 10 ? `0${duration.hours()}` : duration.hours()
    }:${duration.minutes() < 10 ? `0${duration.minutes()}` : duration.minutes()}:00`;
};

export const convertTimeSpanStringToMinutes = (timeSpan: string): number => {
    const splitDays = timeSpan.split('.');
    const splitTime = splitDays.length > 1 ? splitDays[1].split(':') : timeSpan.split(':');
    if (splitTime.length !== 3) return 0;
    const days = splitDays.length > 1 ? Number.parseInt(splitDays[0]) : 0;
    const hours = Number.parseInt(splitTime[0]);
    const minutes = Number.parseInt(splitTime[1]);
    return days * 24 * 60 + hours * 60 + minutes;
};
