import React from 'react';
import * as yup from 'yup';
import { FormInstance } from 'antd/lib/form/util';

export const useFormValidation = (
    schema: yup.ObjectSchema,
    form?: FormInstance | undefined
): [
    Map<string, string[]>,
    (values: object, flatForm: boolean | undefined) => Promise<boolean>,
    () => void,
    (errors: Map<string, string[]>) => void,
    (errors: Map<string, string[]>) => void,
] => {
    const [errors, setErrors] = React.useState(new Map<string, string[]>());

    const resetErrors = (): void => {
        setErrors(new Map<string, string[]>());
    };

    const scrollToErrors = (errors: Map<string, string[]>): void => {
        // scroll to first error
        if (form && errors.size > 0) {
            const errorKey = errors.keys().next();
            if (errorKey) {
                form.scrollToField(errorKey.value, {
                    block: 'center',
                    behavior: 'smooth',
                    scrollMode: 'if-needed',
                    inline: 'center',
                });
            }
        }
    };

    const validateForm = async (
        values: object,
        flatForm: boolean | undefined
    ): Promise<boolean> => {
        try {
            await schema.validate(values, { abortEarly: false, context: values });
            resetErrors();
            return true;
        } catch (validationErrors) {
            const errors = new Map<string, string[]>();
            for (const error of validationErrors.inner) {
                // # Allow simple yup validation of nested objects, with a flat form fields definition
                // # remove prefix: 'address.city' becomes 'city'
                const path = flatForm
                    ? error.path.slice(error.path.lastIndexOf('.') + 1)
                    : error.path;
                errors.set(path, (errors.get(path) ?? []).concat(error.errors));
            }
            setErrors(errors);
            scrollToErrors(errors);
            return false;
        }
    };
    return [errors, validateForm, resetErrors, setErrors, scrollToErrors];
};
