import { BookingDetailsDto } from 'Api/Features/Bookings/Dtos/BookingDetailsDto';
import { BookingDetailsAmenityDto } from 'Api/Features/Bookings/Dtos/BookingDetailsAmenityDto';
import { BookingDetailsOrganizerUserDto } from 'Api/Features/Bookings/Dtos/BookingDetailsOrganizerUserDto';
import { BookingDetailsCompanyDto } from 'Api/Features/Bookings/Dtos/BookingDetailsCompanyDto';
import { BookingApprovalStatusDto } from 'Api/Features/Bookings/Dtos/BookingApprovalStatusDto';
import { PaymentMethodDto } from 'Api/Features/PaymentMethods/Dtos/PaymentMethodDto';
import moment from 'moment';
import {
    DATE_DISPLAY_FORMAT,
    DATE_WEEKDAY_DISPLAY_FORMAT,
    TIME_DISPLAY_FORMAT,
} from '../Constants';
import { StatusBadgeStatus } from 'Components/status-badge/status-badge';

export class BookingDetails implements BookingDetailsDto {
    constructor(dto: BookingDetailsDto) {
        Object.assign(this, dto);
    }
    id = '';
    periodStart = undefined;
    periodEnd = undefined;
    amenity: BookingDetailsAmenityDto | null = null;
    organizer: BookingDetailsOrganizerUserDto | null = null;
    company: BookingDetailsCompanyDto | null = null;
    isCancelled = false;
    cost = 0;
    costTaxAmount = 0;
    creditCost = 0;
    note: string | null = null;
    approvalStatus: BookingApprovalStatusDto | undefined = undefined;
    paymentMethod: PaymentMethodDto | null = null;

    get organizerName(): string {
        return `${this.organizer?.firstName} ${this.organizer?.lastName}`;
    }

    get dateAndTime(): string {
        return `${moment(this.periodStart).format(DATE_DISPLAY_FORMAT)} ${this.time}` || '';
    }

    get time(): string {
        return `${moment(this.periodStart).format(TIME_DISPLAY_FORMAT)} - ${moment(
            this.periodEnd
        ).format(TIME_DISPLAY_FORMAT)}`;
    }

    get dateWeekDay(): string {
        return moment(this.periodStart).format(DATE_WEEKDAY_DISPLAY_FORMAT);
    }

    get statusBadgeStatus(): StatusBadgeStatus | undefined {
        if (this.approvalStatus === BookingApprovalStatusDto.Approved) {
            return StatusBadgeStatus.Approved;
        } else if (this.approvalStatus === BookingApprovalStatusDto.Refused) {
            return StatusBadgeStatus.Refused;
        } else if (this.approvalStatus === BookingApprovalStatusDto.WaitingForApproval) {
            return StatusBadgeStatus.WaitingForApproval;
        }
        return undefined;
    }
}
