// GENERATED FILE - DO NOT MODIFY
import { CreateServiceRequestRequestDto } from 'Api/Features/ServiceRequests/Dtos/CreateServiceRequestRequestDto';
import { GetServiceRequestsRequestDto } from 'Api/Features/ServiceRequests/Dtos/GetServiceRequestsRequestDto';
import { GetServiceRequestsResponseDto } from 'Api/Features/ServiceRequests/Dtos/GetServiceRequestsResponseDto';
import { ServiceRequestDto } from 'Api/Features/ServiceRequests/Dtos/ServiceRequestDto';
import { UpdateServiceRequestStatusRequestDto } from 'Api/Features/ServiceRequests/Dtos/UpdateServiceRequestStatusRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class ServiceRequestProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getServiceRequest(serviceRequestId: string): Promise<ServiceRequestDto | null> {
        const uri = this.buildUri(
            "/service-requests/{serviceRequestId}",
            { serviceRequestId: serviceRequestId },
            null
        );

        const data: ServiceRequestDto | null = await this.httpClient.get<ServiceRequestDto | null>(uri);
        return data;
    }

    public async getServiceRequests(request: GetServiceRequestsRequestDto | null): Promise<GetServiceRequestsResponseDto | null> {
        const uri = this.buildUri(
            "/service-requests",
            null,
            { ...request || undefined }
        );

        const data: GetServiceRequestsResponseDto | null = await this.httpClient.get<GetServiceRequestsResponseDto | null>(uri);
        return data;
    }

    public async createServiceRequest(request: CreateServiceRequestRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/service-requests",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreateServiceRequestRequestDto | null, string>(uri, request);
        return data;
    }

    public async updateServiceRequestStatus(serviceRequestId: string, request: UpdateServiceRequestStatusRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/service-requests/{serviceRequestId}/status",
            { serviceRequestId: serviceRequestId },
            null
        );

        await this.httpClient.put<UpdateServiceRequestStatusRequestDto | null, void>(uri, request);
    }
}