import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const ChevronDown: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
                <path d="M8 10.656L.89 3.546 0 4.438l8 8 8-8-.89-.89L8 10.657z" fill={fill} />
            </svg>
        </span>
    );
};

export default ChevronDown;
