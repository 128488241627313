import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useService } from 'Hooks';
import { BookingDetails as BookingDetailsModel } from 'Models/Bookings/BookingDetails';
import { Location as LocationModel } from 'Models/Location/Location';
import { Amenity as AmenityModel } from 'Models/Amenities/Amenity';
import { AmenityService } from 'Services/AmenityService';
import { BookingService } from 'Services/BookingService';
import { LocationService } from 'Services/LocationService';
import { Avatar, Button, Row, Col, Skeleton, Typography } from 'antd';
import moment from 'moment';
import { Card } from 'Components/card';
import { ConferenceRoom, CreditCard, Townhall, Amenity, Trash } from 'Components/icons';
import { useStores } from 'Hooks';
import { BtnDetails } from 'Components/buttons';
import { ImageWithPlaceholder } from 'Components/image-with-placeholder';
import { theme } from 'variant';
import './booking-details.less';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

const { Paragraph, Text, Title } = Typography;

interface BookingDetailsProps {
    bookingId?: string;
    onCancelBooking: () => void;
}

const BookingDetails: FunctionComponent<BookingDetailsProps> = ({ bookingId, onCancelBooking }) => {
    const { t } = useTranslation();
    const bookingService = useService(BookingService);
    const amenityService = useService(AmenityService);
    const locationService = useService(LocationService);
    const { confirmationModalStore, toastStore } = useStores();

    const [booking, setBooking] = useState<BookingDetailsModel>();
    const [location, setLocation] = useState<LocationModel>();
    const [amenity, setAmenity] = useState<AmenityModel>();
    const [loading, setLoading] = useState(true);
    const [showDetails, setDetailsVisibility] = useState(false);

    const fetch = useCallback(async (): Promise<void> => {
        if (bookingId) {
            setLoading(true);
            try {
                // get booking
                const item = await bookingService.getBooking(bookingId);
                if (item) setBooking(item);

                // get amenity information
                if (item?.amenity?.id) {
                    const currentAmenity = await amenityService.getAmenity(item.amenity.id);
                    currentAmenity && setAmenity(currentAmenity);
                }

                // get location information
                if (item?.amenity?.location?.id) {
                    const currentLocation = await locationService.getById(item.amenity.location.id);
                    currentLocation && setLocation(currentLocation);
                }
            } catch (error) {
                toastStore.displayError(error);
            } finally {
                setLoading(false);
            }
        }
    }, [bookingService, amenityService, locationService, bookingId, toastStore]);

    useEffect(() => {
        fetch();
    }, [bookingId, fetch]);

    const showBookingDetails = (isToggled: boolean): void => {
        setDetailsVisibility(isToggled);
    };

    const handleCancelBookingClick = async (): Promise<void> => {
        // TODO verity text confirmation modal
        if (
            !(await confirmationModalStore.confirm({
                icon: <Trash fill={theme['primary-color']} />,
                title: t('Booking.cancel_booking_title'),
                message: t('Booking.payment_cancel_warning'),
                positiveText: t('yes'),
                negativeText: t('no'),
            }))
        )
            return;

        if (booking?.id) {
            try {
                await bookingService.cancelBooking(booking?.id, {
                    cancelRecurringOccurrences: false,
                });
            } finally {
                onCancelBooking();
            }
        }
    };

    const withCredits = !!(booking && booking.creditCost > 0);

    return (
        <div className="BookingDetails">
            <div className="BookingDetails-Amenity">
                <div className="AvatarBorder">
                    {!loading ? (
                        <ImageWithPlaceholder
                            className="primary-4"
                            imgSrc={booking?.amenity?.location?.mainImageUrl}
                            defaultImg={<Amenity fill={theme['primary-1']} />}
                            width={150}
                            height={150}
                        />
                    ) : (
                        <Skeleton.Avatar shape="circle" size={150} active />
                    )}
                </div>

                <Card className="content" borderType="boxShadow">
                    <Row className="content-upper" justify="space-between" align="middle">
                        <Col flex="1 1 auto">
                            {!loading ? (
                                <>
                                    {location?.address?.addressLine1 && (
                                        <p className="location-address">
                                            {location.address.addressLine1}
                                        </p>
                                    )}
                                    {booking?.amenity?.location?.name && (
                                        <Title level={4} className="mb-0">
                                            {booking.amenity.location.name}
                                        </Title>
                                    )}
                                    {amenity?.category?.name ? (
                                        <Row className="amenity-category-type">
                                            <Col>
                                                {amenity.category.name === 'Conference Room' ? (
                                                    <ConferenceRoom fill={theme['primary-900']} />
                                                ) : amenity.category.name ===
                                                  'Conference Center' ? (
                                                    <ConferenceRoom fill={theme['primary-900']} />
                                                ) : null}
                                            </Col>
                                            <Col>
                                                <Text className="small amenity-category-name">
                                                    {amenity.category.name}
                                                </Text>
                                            </Col>
                                        </Row>
                                    ) : null}
                                    {booking?.amenity?.isTownhallExclusive && (
                                        <Row className="townhall-exclusive" gutter={[8, 0]}>
                                            <Col>
                                                <Townhall fill={theme['primary-500']} />
                                            </Col>
                                            <Col>
                                                <Text className="small">
                                                    {t('Booking.townhall_exclusive_title')}
                                                </Text>
                                            </Col>
                                        </Row>
                                    )}
                                </>
                            ) : (
                                <>
                                    <Skeleton title={false} paragraph={{ rows: 3 }} active />
                                </>
                            )}
                        </Col>
                        <Col flex="0 0 auto">
                            {!loading && amenity?.description && (
                                <BtnDetails onClick={showBookingDetails} toggled={showDetails}>
                                    {t('details')}
                                </BtnDetails>
                            )}
                        </Col>
                    </Row>
                    {amenity?.description && (
                        <CSSTransition
                            in={showDetails}
                            timeout={300}
                            classNames="details-animation"
                            unmountOnExit
                        >
                            <Row className="content-lower">
                                <Col span={24}>
                                    <Card
                                        title={
                                            <Text className="small" strong>
                                                {t('details')}
                                            </Text>
                                        }
                                        borderType="bordered"
                                        className="amenity-details"
                                        theme="primary"
                                    >
                                        <Text className="small">
                                            <div
                                                className="wysiwyg-text"
                                                dangerouslySetInnerHTML={{
                                                    __html: amenity.description,
                                                }}
                                            />
                                        </Text>
                                    </Card>
                                </Col>
                            </Row>
                        </CSSTransition>
                    )}
                </Card>
            </div>

            <Card
                className="booking-details"
                title={
                    <>
                        {!loading ? (
                            <>
                                <Paragraph strong>{booking?.dateWeekDay}</Paragraph>
                                <Paragraph className="small mb-0">{booking?.time}</Paragraph>

                                {booking?.cost && booking?.amenity?.pricePerBlock ? (
                                    <Paragraph strong className="small mt-1 mb-0">
                                        {`${booking.cost / booking.amenity.pricePerBlock} x $${
                                            booking.amenity.pricePerBlock
                                        }`}
                                    </Paragraph>
                                ) : null}
                            </>
                        ) : (
                            <Skeleton title={false} paragraph={{ rows: 3 }} active />
                        )}
                    </>
                }
                extra={
                    !loading &&
                    (withCredits ? (
                        <div>
                            {t('Booking.credits_per_minutes', {
                                param1: amenity?.creditsPerBlock,
                                param2: amenity?.minutesPerBlock,
                            })}
                        </div>
                    ) : (
                        booking?.cost && (
                            <Title level={4} className="mb-0 booking-price">
                                ${booking?.cost}
                            </Title>
                        )
                    ))
                }
                borderType="boxShadow"
            >
                <Row gutter={[24, 24]}>
                    <Col span={12}>
                        <Card
                            title={
                                <Text className="small" strong>
                                    {t('Booking.amenity_card')}
                                </Text>
                            }
                            theme="primary"
                            borderType="bordered"
                            className="booking-details-amenity"
                        >
                            {!loading ? (
                                <Row align="middle" gutter={12}>
                                    {booking?.amenity?.imageUrl ? (
                                        <Col flex="32px">
                                            <Avatar src={booking.amenity?.imageUrl} />
                                        </Col>
                                    ) : null}
                                    <Col flex="auto">
                                        <Text className="small">{booking?.amenity?.name}</Text>
                                    </Col>
                                </Row>
                            ) : (
                                <Skeleton title={false} paragraph={{ rows: 1 }} active />
                            )}
                        </Card>
                    </Col>

                    <Col span={12}>
                        <Card
                            title={
                                <Text className="small" strong>
                                    {t('Booking.location_card')}
                                </Text>
                            }
                            theme="primary"
                            borderType="bordered"
                            className="booking-details-location"
                        >
                            {!loading ? (
                                <Row align="middle" gutter={12}>
                                    {booking?.amenity?.location?.mainImageUrl ? (
                                        <Col flex="32px">
                                            <Avatar src={booking.amenity?.location?.mainImageUrl} />
                                        </Col>
                                    ) : null}
                                    <Col flex="auto">
                                        <Text className="small">
                                            {booking?.amenity?.location?.name}
                                        </Text>
                                    </Col>
                                </Row>
                            ) : (
                                <Skeleton title={false} paragraph={{ rows: 1 }} active />
                            )}
                        </Card>
                    </Col>

                    <Col span={24}>
                        <Card
                            title={
                                <Text className="small" strong>
                                    {t('Booking.payment_card')}
                                </Text>
                            }
                            theme="primary"
                            borderType="bordered"
                            className="booking-details-payment"
                        >
                            {!loading ? (
                                <Row align="middle" gutter={12}>
                                    {booking?.paymentMethod ? (
                                        <>
                                            {booking.paymentMethod.type === 'CreditCard' ? (
                                                <Col>
                                                    <CreditCard fill={theme['primary-color']} />
                                                </Col>
                                            ) : null}
                                            <Col className="credit-card-holder">
                                                <Text className="small">
                                                    {booking.paymentMethod.holder?.name}
                                                </Text>
                                            </Col>
                                            <Col>
                                                {booking.paymentMethod.type === 'CreditCard' ? (
                                                    <>
                                                    <Paragraph
                                                        className="small credit-card-last4"
                                                        strong
                                                    >
                                                        {t('Payment.card_ending_with', {
                                                            param1: booking.paymentMethod.card?.brand,
                                                            param2: booking.paymentMethod.card?.last4 })
                                                        }
                                                    </Paragraph>
                                                    <Text className="small">
                                                        {moment(
                                                            booking.paymentMethod.card?.expiration
                                                        ).format('MM/YY')}
                                                    </Text>
                                                    </>
                                                ) : null}
                                                {withCredits ? (
                                                    <Paragraph
                                                        className="small credits-payment"
                                                        strong
                                                    >
                                                        {t('Payment.paid_with_credits')}
                                                    </Paragraph>
                                                ) : null}
                                            </Col>
                                        </>
                                    ) : booking?.organizer ? (
                                        <>
                                            {booking.organizer.imageUrl && (
                                                <Col flex="32px">
                                                    <Avatar src={booking.organizer.imageUrl} />
                                                </Col>
                                            )}
                                            {booking.organizer.firstName && (
                                                <Col flex="auto">
                                                    <Text className="small" strong>
                                                        {booking.organizer.firstName}{' '}
                                                        {booking.organizer.lastName}
                                                    </Text>
                                                </Col>
                                            )}
                                        </>
                                    ) : null}
                                </Row>
                            ) : (
                                <Skeleton title={false} paragraph={{ rows: 1 }} active />
                            )}
                        </Card>
                    </Col>
                </Row>
                <Row justify="end">
                    <Col>
                        {!loading ? (
                            <Button className="cancel-booking" onClick={handleCancelBookingClick}>
                                {t('Booking.cancel_booking_button')}
                            </Button>
                        ) : (
                            <Skeleton.Button shape="square" />
                        )}
                    </Col>
                </Row>
            </Card>
        </div>
    );
};

export default BookingDetails;
