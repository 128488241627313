import { observable, action, computed } from 'mobx';
import { inject } from 'aurelia-dependency-injection';
import { Booking } from 'Models/Bookings/Booking';
import { BookingService } from 'Services/BookingService';
import LoadingStore from 'Stores/LoadingStore';
import AmenityStore from './AmenityStore';
import { GetBookingsRequestDto } from 'Api/Features/Bookings/Dtos/GetBookingsRequestDto';
import { DailyBookingSummaryAmenityDto } from 'Api/Features/Bookings/Dtos/DailyBookingSummaryAmenityDto';
import { GetDailyBookingSummaryRequestDto } from 'Api/Features/Bookings/Dtos/GetDailyBookingSummaryRequestDto';

interface BookingsGroupedByDate {
    [key: string]: Booking[];
}

@inject(BookingService, AmenityStore)
class BookingStore {
    constructor(
        private readonly bookingService: BookingService,
        private readonly loadingStore: LoadingStore,
        private readonly amenityStore: AmenityStore
    ) {}

    @observable private _upcomingBookings: [Booking[], number] | null = [[], 0];
    @observable
    private _dailyBookingSummary: Array<DailyBookingSummaryAmenityDto | null> | null = [];
    @observable currentLocationPendingBookingApprovals = 0;

    @observable private _isBookingsLoading = false;

    @computed
    get isBookingsLoading(): boolean {
        return this._isBookingsLoading;
    }

    @computed
    get upcomingBookings(): [Booking[], number] | null {
        return this._upcomingBookings;
    }

    @computed
    get upcomingBookingsByDate(): BookingsGroupedByDate | null {
        if (this._upcomingBookings && this._upcomingBookings[0]) {
            const items = this._upcomingBookings[0];
            const itemsGroupedByDate: BookingsGroupedByDate = items.reduce(
                (groups: BookingsGroupedByDate, item) => {
                    const date = item.dateWeekDay;
                    if (!groups[date]) {
                        groups[date] = [];
                    }
                    groups[date].push(item);
                    return groups;
                },
                {}
            );
            return itemsGroupedByDate;
        } else return null;
    }

    @computed
    get dailyBookingSummary(): Array<DailyBookingSummaryAmenityDto | null> | null {
        return this._dailyBookingSummary;
    }

    @action
    async setUpcomingBookings(request: GetBookingsRequestDto): Promise<void> {
        this._isBookingsLoading = true;
        this._upcomingBookings = await this.bookingService.getBookings(request);
        this._isBookingsLoading = false;
    }

    @action
    async setDailyBookingSummary(
        id: string,
        request: GetDailyBookingSummaryRequestDto
    ): Promise<void> {
        const data = await this.bookingService.getDailyBookingSummary(id, request);
        if (data?.amenities !== undefined) this._dailyBookingSummary = data?.amenities;
    }

    @action
    async setCurrentLocationPendingBookingApprovals(locationId: string): Promise<void> {
        const [items] = await this.bookingService.getWaitingForApprovalBookings(locationId);
        this.currentLocationPendingBookingApprovals = items.length;
    }
}

export default BookingStore;
