import React, { FunctionComponent } from 'react';
import classnames from 'classnames';

import './cell.less';

const classPrefix = 'Cell';

interface CellProps {
    icon?: JSX.Element;
    extra?: JSX.Element;
    className?: string | null;
}

const Cell: FunctionComponent<CellProps> = ({
    icon = null,
    extra = null,
    className = null,
    children,
}) => {
    return (
        <div className={classnames(classPrefix, className, 'd-flex')}>
            {icon && <div className={`${classPrefix}-icon`}>{icon}</div>}
            <div className={`${classPrefix}-content`}>{children}</div>
            {extra && <div className={`${classPrefix}-extra`}>{extra}</div>}
        </div>
    );
};

export default Cell;
