import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Invoice: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
                <path
                    d="M40 5H5.744v5.744H0V35.85h34.256v-5.745H40V5zM1.594 12.339h8.192v21.914H1.594V12.339zm31.067 21.916H11.383V12.34h21.278v21.916zm5.745-5.744h-4.15V10.744H7.339v-4.15h31.064v21.917h.003z"
                    fill={fill}
                />
                <path
                    d="M28.936 15.32H15.211v1.594h13.725v-1.595zM28.936 18.722H15.211v1.595h13.725v-1.595zM28.936 22.233H15.211v1.595h13.725v-1.595zM23.189 25.639h-7.978v1.594h7.978V25.64z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Invoice;
