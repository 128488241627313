import { inject } from 'aurelia-dependency-injection';
import { AmenityCategoryProxy } from 'Api/Features/AmenityCategories/AmenityCategoryProxy';
import { ApiService } from './ApiService';

import { GetAmenityCategoriesRequestDto } from 'Api/Features/AmenityCategories/Dtos/GetAmenityCategoriesRequestDto';
import { GetAmenityCategoriesResponseDto } from 'Api/Features/AmenityCategories/Dtos/GetAmenityCategoriesResponseDto';
import { CreateAmenityCategoryRequestDto } from '../Api/Features/AmenityCategories/Dtos/CreateAmenityCategoryRequestDto';
import { UpdateAmenityCategoryRequestDto } from '../Api/Features/AmenityCategories/Dtos/UpdateAmenityCategoryRequestDto';
import { AmenityCategory } from '../Models/Amenities/AmenityCategory';
import { AmenityCategoryDto } from '../Api/Features/AmenityCategories/Dtos/AmenityCategoryDto';
import { GetAmenityCategoriesOrderingResponseDto } from '../Api/Features/AmenityCategories/Dtos/GetAmenityCategoriesOrderingResponseDto';
import { OrderingMethodDto } from '../Api/Features/General/Dtos/OrderingMethodDto';

@inject(AmenityCategoryProxy)
export class AmenityCategoryService extends ApiService {
    constructor(private readonly amenityCategoryProxy: AmenityCategoryProxy) {
        super();
    }

    public async getAmenityCategory(id: string): Promise<AmenityCategory | null> {
        const response: AmenityCategoryDto | null = await this.amenityCategoryProxy.getAmenityCategory(
            id
        );
        return response ? new AmenityCategory(response) : null;
    }

    public async getAmenityCategories(
        request?: GetAmenityCategoriesRequestDto
    ): Promise<[AmenityCategory[], number]> {
        const response: GetAmenityCategoriesResponseDto | null = await this.amenityCategoryProxy.getAmenityCategories(
            request || null
        );
        return [
            response?.items?.map((dto: AmenityCategoryDto | null) => new AmenityCategory(dto!)) ||
                [],
            response?.totalItemCount || 0,
        ];
    }

    public async createAmenityCategory(request: CreateAmenityCategoryRequestDto): Promise<void> {
        await this.amenityCategoryProxy.createAmenityCategory(request);
    }

    public async updateAmenityCategory(
        request: UpdateAmenityCategoryRequestDto,
        id: string
    ): Promise<void> {
        await this.amenityCategoryProxy.updateAmenityCategory(id, request);
    }

    public async deleteAmenityCategory(id: string): Promise<void> {
        await this.amenityCategoryProxy.deleteAmenityCategory(id);
    }

    public async getAmenityCategoriesOrder(): Promise<GetAmenityCategoriesOrderingResponseDto | null> {
        return await this.amenityCategoryProxy.getAmenityCategoriesOrdering();
    }

    public async updateAmenityCategoriesOrder(
        method: OrderingMethodDto,
        ids: string[]
    ): Promise<void> {
        await this.amenityCategoryProxy.updateAmenityCategoriesOrdering({ method: method, ids: ids });
    }
}
