import { AmenityDto } from 'Api/Features/Amenities/Dtos/AmenityDto';
import { AmenityAmenityCategoryDto } from 'Api/Features/Amenities/Dtos/AmenityAmenityCategoryDto';
import { AmenityLocationDto } from 'Api/Features/Amenities/Dtos/AmenityLocationDto';

export class Amenity implements AmenityDto {
    constructor(dto: AmenityDto) {
        Object.assign(this, dto);
    }
    id = '';
    name: string | null = null;
    description: string | null = null;
    imageUrl: string | null = null;
    category: AmenityAmenityCategoryDto | null = null;
    location: AmenityLocationDto | null = null;
    pricePerBlock = 0;
    taxAmountPerBlock = 0;
    minutesPerBlock= 0;
    order = 0;
    staffConfirmationRequired = false;
    minimumBookingDuration = '';
    maximumBookingDuration = '';
    openingTime: string | null = null;
    closingTime: string | null = null;
    isTownhallExclusive = false;
    isApprovalRequired = false;
    isCoworking = false;
    creditsPerBlock = 0;
}