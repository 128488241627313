import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const CheckMarkWeb: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg
                width="1em"
                height="1em"
                viewBox="0 0 19 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M7.08203 13.2188C7.43359 13.5703 8.03125 13.5703 8.38281 13.2188L18.7188 2.88281C19.0703 2.53125 19.0703 1.93359 18.7188 1.58203L17.4531 0.316406C17.1016 -0.0351562 16.5391 -0.0351562 16.1875 0.316406L7.75 8.75391L3.77734 4.81641C3.42578 4.46484 2.86328 4.46484 2.51172 4.81641L1.24609 6.08203C0.894531 6.43359 0.894531 7.03125 1.24609 7.38281L7.08203 13.2188Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default CheckMarkWeb;
