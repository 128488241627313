import axios, { AxiosResponse, AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios';
import URI from 'urijs';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { inject } from 'aurelia-dependency-injection';
import { AuthorizationStore, ToastStore } from 'Stores';
import { Environment } from 'Misc/Environment';
import moment from 'moment';

export interface HttpError extends AxiosError {
    treated: boolean;
}

@inject(AuthorizationStore, ToastStore, Environment)
export class HttpClient implements ApiHttpClient {
    private readonly axiosInstance: AxiosInstance;

    constructor(
        private readonly authorizationStore: AuthorizationStore,
        private readonly toastStore: ToastStore,
        private readonly environment: Environment
    ) {
        const requestConfig: AxiosRequestConfig = {
            baseURL: environment.REACT_APP_API_DOMAIN || undefined,
            headers: {
                'App-Info': `Web;ca.appcom.mrp.${environment.REACT_APP_CURRENT_APP};${process.env.REACT_APP_VERSION}`
            }
        };
        this.axiosInstance = axios.create(requestConfig);
        this.handleAutoUpdate = this.handleAutoUpdate.bind(this);
        this.handleRejection = this.handleRejection.bind(this);
        this.axiosInstance.interceptors.response.use(this.handleAutoUpdate, this.handleRejection);
    }

    public async get<TR>(uri: string): Promise<TR> {
        const response: AxiosResponse<TR> = await this.axiosInstance.get(uri);
        return response?.data;
    }

    public async getAsBuffer(uri: string): Promise<ArrayBuffer> {
        const response: AxiosResponse<ArrayBuffer> = await this.axiosInstance.get(uri, {
            responseType: 'arraybuffer',
        });
        return response?.data;
    }

    public async delete<TR>(uri: string): Promise<TR> {
        const response: AxiosResponse<TR> = await this.axiosInstance.delete(uri);
        return response?.data;
    }

    public async post<TD, TR>(uri: string, data?: TD): Promise<TR> {
        const response: AxiosResponse<TR> = await this.axiosInstance.post(uri, data);
        return response?.data;
    }

    private async postFormUrlEncoded<TD, TR>(uri: string, data?: TD): Promise<TR> {
        const requestConfig: AxiosRequestConfig = {
            // eslint-disable-next-line no-undef
            baseURL: process.env.REACT_APP_API_DOMAIN,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        };

        // # Convert data to serialized url query format param1=value1&param2=value2…
        const FormUrlEncodedData = URI.buildQuery(data as Object);
        const response: AxiosResponse<TR> = await this.axiosInstance.post(
            uri,
            FormUrlEncodedData,
            requestConfig
        );
        return response?.data;
    }

    public async postAsBuffer<TD>(uri: string, data?: TD): Promise<ArrayBuffer> {
        const response: AxiosResponse<ArrayBuffer> = await this.axiosInstance.post(uri, data, {
            responseType: 'arraybuffer',
        });
        return response?.data;
    }

    public async put<TD, TR>(uri: string, data?: TD | undefined): Promise<TR> {
        const response: AxiosResponse<TR> = await this.axiosInstance.put(uri, data);
        return response?.data;
    }

    public async patch<TD, TR>(uri: string, data?: TD | undefined): Promise<TR> {
        const response: AxiosResponse<TR> = await this.axiosInstance.patch(uri, data);
        return response?.data;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public addRequestInterceptor(
        onFulfilled?: (
            value: AxiosRequestConfig
        ) => AxiosRequestConfig | Promise<AxiosRequestConfig>,
        onRejected?: (error: any) => any
    ): void {
        this.axiosInstance.interceptors.request.use(onFulfilled, onRejected);
    }

    public handleRejection(error: AxiosError): void {
        const httpError = error as HttpError;
        if (httpError.response) {
            this.authorizationStore.handleErrorCode(httpError.response.status);
            if (httpError.response.status >= 500) {
                this.toastStore.serverError();
                httpError.treated = true;
            }
        }
        throw httpError;
    }

    public handleAutoUpdate(response: AxiosResponse): AxiosResponse {
        if (response.config.method === 'get' && response.headers['x-update']) {
            const now = moment();
            const lastUpdateAttempt = localStorage.getItem('lastUpdateAttempt');
            // Check last update attempt to prevent infinite reloads.
            if (
                !lastUpdateAttempt ||
                moment.duration(now.diff(moment(lastUpdateAttempt))).asMinutes() >
                10
            ) {
                localStorage.setItem('lastUpdateAttempt', now.toISOString());
                window.location.reload();
            }
        }
        return response;
    }
}
