import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { legalPages } from 'variant';
import { LegalPage } from 'Components/legal-page';

const PrivacyPolicy: FunctionComponent = () => {
    const { i18n, t } = useTranslation();

    useEffect(() => {
        document.title = t('privacy_policy') + ' - ' + t('brand_name');
    }, [t]);

    return <LegalPage content={legalPages.get(i18n.language)?.privacyPolicy || ''} />;
};

export default PrivacyPolicy;
