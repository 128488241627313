export const ALL_LOCATIONS = 'all';
export const RETURN_URL = 'returnUrl';
export const PAGE_SIZE = 10;
export const DEBOUNCE_DELAY = 500;
export const DATE_API_FORMAT = 'YYYY-MM-DD';
export const DATETIME_API_FORMAT = 'YYYY-MM-DDTHH:mm A';
export const DATE_DISPLAY_FORMAT = 'MM/DD/YYYY';
export const TABLE_DATE_FORMAT = 'MMMM DD YYYY';
export const DAY_DISPLAY_FORMAT = 'dddd MMMM D';
export const DATE_WEEKDAY_DISPLAY_FORMAT = 'dddd, MMMM D YYYY';
export const DATE_BOOKING_FORMAT = 'MMMM Do YYYY';
export const DATETIME_DISPLAY_FORMAT = 'MM/DD/YYYY H:mm A';
export const CC_EXPIRATION_FORMAT = 'MM/YY';
export const TIME_FORMAT = 'HH:mm';
export const TIME_FORMAT_12_HOUR = 'h:mm A';
export const TIME_DISPLAY_FORMAT = 'h:mm A';
export const TIME_API_FORMAT = 'HH:mm A';
export const MOMENT_TIME_FORMAT = 'HH:mm A';
export const TIME_WITH_OFFSET_FORMAT = 'HH:mm:ssZ';
export const LOCALE = 'en-US';

// Coords for : 1501 M St NW, Washington, DC 20005, United States
export const DEFAULT_LAT = 38.9060993;
export const DEFAULT_LNG = -77.0371584;
export const MAP_DEFAULT_ZOOM = 11;
export const MAP_SEARCHED_ZOOM = 16;

export const SIDER_WIDTH = 300;
export const SIDER_MENU_INDENT = 40;
export const WEBAPP_SIDER_WIDTH = 240;
export const WEBAPP_SIDER_MENU_INDENT = 0;

export const TABLE_COL_ORDER_DEFAULT_WIDTH = '10%';
export const MIN_ITEMS_FOR_REORDER = 2;
export const TOTAL_MINUTES_IN_A_DAY = 1440;
export const SAME_DAY_MINIMUM_BOOKING_MINUTES = 30;
export const BUILDING_ACCESS_SITE_CODE = 1207;
export const MAX_LENGTH_25 = 25;
export const MAX_LENGTH_50 = 50;
export const MAX_LENGTH_100 = 100;
export const MAX_LENGTH_256 = 256;
export const MAX_LENGTH_4000 = 4000;
export const PHONE_REGEX = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const WEBSITE_PLACEHOLDER = 'http://www.example.com';
export const STORE_URL_HEYDAY_APPLE = 'https://apps.apple.com/us/app/heyday-properties/id1530625809';
export const STORE_URL_HEYDAY_GOOGLE = 'https://play.google.com/store/apps/details?id=ca.appcom.mrp.heyday';
export const STORE_URL_PASSPORT_APPLE = 'https://apps.apple.com/us/app/mrp-realty-passport/id1536820373';
export const STORE_URL_PASSPORT_GOOGLE = 'https://play.google.com/store/apps/details?id=ca.appcom.mrp.passport';

export const VERDOCS_NO_MANDATORY_DOCUMENT_ASSIGNED = 'No template';
