import { CompanyDto } from 'Api/Features/Companies/Dtos/CompanyDto';
import { CompanyLocationDto } from 'Api/Features/Companies/Dtos/CompanyLocationDto';
import { AddressDto } from 'Api/Features/General/Dtos/AddressDto';
import { ContactInfoDto } from 'Api/Features/General/Dtos/ContactInfoDto';
import { CompanyMainContactDto } from 'Api/Features/Companies/Dtos/CompanyMainContactDto';
import { SectorOfBusinessDto } from 'Api/Features/Companies/Dtos/SectorOfBusinessDto';
import { CompanyNumberOfEmployeesDto } from 'Api/Features/Companies/Dtos/CompanyNumberOfEmployeesDto';
import { CompanyStatusDto } from 'Api/Features/Companies/Dtos/CompanyStatusDto';
import { Address } from 'Models/Contact/Address';
import { ContactInfo } from 'Models/Contact/ContactInfo';
import { User } from 'Models/Users/User';
import { htmlContainsText } from 'Utils';
import { StatusBadgeStatus } from 'Components/status-badge/status-badge';
import { CompanyTypeDto } from 'Api/Features/Companies/Dtos/CompanyTypeDto';
import { CreditBalanceDto } from 'Api/Features/Companies/Dtos/CreditBalanceDto';

export class Company implements CompanyDto {
    constructor(dto: CompanyDto) {
        Object.assign(this, dto);
    }

    id = '';
    location: CompanyLocationDto | null = null;
    name: string | null = null;
    introduction: string | null = null;
    sectorOfBusiness: SectorOfBusinessDto | null = null;
    numberOfEmployees: CompanyNumberOfEmployeesDto | null = null;
    contactInfo: ContactInfoDto | null = null;
    address: AddressDto | null = null;
    mainContact: CompanyMainContactDto | null = null; // Maybe this should be an instance of User model instead...
    imageUrl: string | null = null;
    status: CompanyStatusDto = CompanyStatusDto.Active;
    membersCount = 0;
    isTownhall = false;
    isCoworking = false;
    type: CompanyTypeDto | undefined;
    locations?: CompanyLocationDto[] | null;
    creditBalance?: CreditBalanceDto | null;
    creditsPerInterval?: number | null;

    private _info: ContactInfo | null = null;
    private _user: User | null = null;
    private _address: Address | null = null;

    get isActive(): boolean {
        return this.status === CompanyStatusDto.Active;
    }

    get statusBadgeStatus(): StatusBadgeStatus | undefined {
        if(this.status === CompanyStatusDto.Active) {
            return StatusBadgeStatus.Active;
        }
        else if(this.status === CompanyStatusDto.Deactivated) {
            return StatusBadgeStatus.Deactivated;
        }
        return undefined;
    }

    get introductionEmpty(): boolean {
        return !htmlContainsText(this.introduction);
    }

    get mainAddress(): Address | null {
        if (this.address && !this._address) {
            this._address = new Address(this.address);
        }
        return this._address;
    }

    get info(): ContactInfo | null {
        if (this.contactInfo && !this._info) {
            this._info = new ContactInfo(this.contactInfo);
        }
        return this._info;
    }

    get mainContactUser(): User | null {
        if (this.mainContact && !this._user) {
            this._user = new User(this.mainContact);
        }
        return this._user;
    }

    get sector(): string | null {
        return this.sectorOfBusiness
            ? SectorOfBusinessDto[
                  (this.sectorOfBusiness as string) as keyof typeof SectorOfBusinessDto
              ]
            : null;
    }

    get numEmployees(): string | null {
        return this.numberOfEmployees
            ? CompanyNumberOfEmployeesDto[
                  (this.numberOfEmployees as string) as keyof typeof CompanyNumberOfEmployeesDto
              ]
            : null;
    }

    get consoleUrl(): string {
        return `/companies/${this.id}/profile`;
    }
}
