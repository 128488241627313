// GENERATED FILE - DO NOT MODIFY
import { CompanyDto } from 'Api/Features/Companies/Dtos/CompanyDto';
import { CreateCompanyRequestDto } from 'Api/Features/Companies/Dtos/CreateCompanyRequestDto';
import { GetCompaniesRequestDto } from 'Api/Features/Companies/Dtos/GetCompaniesRequestDto';
import { GetCompaniesResponseDto } from 'Api/Features/Companies/Dtos/GetCompaniesResponseDto';
import { GetSectorsOfBusinessResponseDto } from 'Api/Features/Companies/Dtos/GetSectorsOfBusinessResponseDto';
import { UpdateCompanyCreditBalanceRequestDto } from 'Api/Features/Companies/Dtos/UpdateCompanyCreditBalanceRequestDto';
import { UpdateCompanyCreditsPerIntervalRequestDto } from 'Api/Features/Companies/Dtos/UpdateCompanyCreditsPerIntervalRequestDto';
import { UpdateCompanyRequestDto } from 'Api/Features/Companies/Dtos/UpdateCompanyRequestDto';
import { UpdateCompanyStatusRequestDto } from 'Api/Features/Companies/Dtos/UpdateCompanyStatusRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class CompanyProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getCompany(companyId: string): Promise<CompanyDto | null> {
        const uri = this.buildUri(
            "/companies/{companyId}",
            { companyId: companyId },
            null
        );

        const data: CompanyDto | null = await this.httpClient.get<CompanyDto | null>(uri);
        return data;
    }

    public async getCompanies(request: GetCompaniesRequestDto | null): Promise<GetCompaniesResponseDto | null> {
        const uri = this.buildUri(
            "/companies",
            null,
            { ...request || undefined }
        );

        const data: GetCompaniesResponseDto | null = await this.httpClient.get<GetCompaniesResponseDto | null>(uri);
        return data;
    }

    public async createCompany(request: CreateCompanyRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/companies",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreateCompanyRequestDto | null, string>(uri, request);
        return data;
    }

    public async updateCompany(companyId: string, request: UpdateCompanyRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/companies/{companyId}",
            { companyId: companyId },
            null
        );

        await this.httpClient.patch<UpdateCompanyRequestDto | null, void>(uri, request);
    }

    public async deleteCompany(companyId: string): Promise<void> {
        const uri = this.buildUri(
            "/companies/{companyId}",
            { companyId: companyId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }

    public async updateCompanyStatus(companyId: string, request: UpdateCompanyStatusRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/companies/{companyId}/status",
            { companyId: companyId },
            null
        );

        await this.httpClient.put<UpdateCompanyStatusRequestDto | null, void>(uri, request);
    }

    public async updateCompanyCreditBalance(companyId: string, request: UpdateCompanyCreditBalanceRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/companies/{companyId}/credit-balance",
            { companyId: companyId },
            null
        );

        await this.httpClient.put<UpdateCompanyCreditBalanceRequestDto | null, void>(uri, request);
    }

    public async updateCompanyCreditsPerInterval(companyId: string, request: UpdateCompanyCreditsPerIntervalRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/companies/{companyId}/credits-per-interval",
            { companyId: companyId },
            null
        );

        await this.httpClient.put<UpdateCompanyCreditsPerIntervalRequestDto | null, void>(uri, request);
    }

    public async getSectorsOfBusiness(): Promise<GetSectorsOfBusinessResponseDto | null> {
        const uri = this.buildUri(
            "/sectors-of-business",
            null,
            null
        );

        const data: GetSectorsOfBusinessResponseDto | null = await this.httpClient.get<GetSectorsOfBusinessResponseDto | null>(uri);
        return data;
    }
}