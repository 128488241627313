import { inject } from 'aurelia-dependency-injection';
import { LocationProxy } from '../Api/Features/Locations/LocationProxy';
import { ApiService } from './ApiService';
import { LocationDto } from 'Api/Features/Locations/Dtos/LocationDto';
import { CreateLocationRequestDto } from 'Api/Features/Locations/Dtos/CreateLocationRequestDto';
import { UpdateLocationRequestDto } from 'Api/Features/Locations/Dtos/UpdateLocationRequestDto';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import { UpdateLocationImagesRequest } from 'Api/Features/Locations/Dtos/UpdateLocationImagesRequest';
import { GetLocationMessagesRequestDto } from 'Api/Features/Messages/Dtos/GetLocationMessagesRequestDto';
import { GetLocationMessagesResponseDto } from 'Api/Features/Messages/Dtos/GetLocationMessagesResponseDto';
import { CreateMessageRequestDto } from 'Api/Features/Messages/Dtos/CreateMessageRequestDto';
import { LocationMessage } from 'Models/Messages/LocationMessage';
import { LocationMessageDto } from 'Api/Features/Messages/Dtos/LocationMessageDto';
import { GetServiceRequestsRequestDto } from 'Api/Features/ServiceRequests/Dtos/GetServiceRequestsRequestDto';
import { ServiceRequest } from 'Models/ServiceRequests/ServiceRequest';
import { ServiceRequestDto } from 'Api/Features/ServiceRequests/Dtos/ServiceRequestDto';
import { GetServiceRequestsResponseDto } from 'Api/Features/ServiceRequests/Dtos/GetServiceRequestsResponseDto';
import { CreateServiceRequestRequestDto } from 'Api/Features/ServiceRequests/Dtos/CreateServiceRequestRequestDto';
import { UpdateServiceRequestStatusRequestDto } from 'Api/Features/ServiceRequests/Dtos/UpdateServiceRequestStatusRequestDto';
import { MessageProxy } from 'Api/Features/Messages/MessageProxy';
import { GetLocationsResponseDto } from 'Api/Features/Locations/Dtos/GetLocationsResponseDto';
import { ServiceRequestProxy } from 'Api/Features/ServiceRequests/ServiceRequestProxy';
import { Location } from 'Models/Location/Location';
import { GetLocationsRequestDto } from '../Api/Features/Locations/Dtos/GetLocationsRequestDto';

interface UploadImagesResponse {
    success: boolean;
    errors: Map<string, string[]>;
}

@inject(LocationProxy, ServiceRequestProxy, MessageProxy)
export class LocationService extends ApiService {
    constructor(
        private readonly locationProxy: LocationProxy,
        private readonly serviceRequestProxy: ServiceRequestProxy,
        private readonly messageProxy: MessageProxy
    ) {
        super();
    }

    public async getLocations(
        request?: GetLocationsRequestDto | null
    ): Promise<[Location[], number]> {
        const response: GetLocationsResponseDto | null = await this.locationProxy.getLocations(
            request || null
        );
        return [
            response?.items?.map((dto: LocationDto | null) => new Location(dto!)) || [],
            response?.totalItemCount || 0,
        ];
    }

    public async getUserLocations(
        id: string,
        request?: GetLocationsRequestDto | null
    ): Promise<[Location[], number]> {
        const response: GetLocationsResponseDto | null = await this.locationProxy.getUserLocations(
            id,
            request || null
        );
        return [
            response?.items?.map((dto: LocationDto | null) => new Location(dto!)) || [],
            response?.totalItemCount || 0,
        ];
    }

    public async getById(id: string): Promise<Location | null> {
        const response: LocationDto | null = await this.locationProxy.getLocation(id);
        return response ? new Location(response) : null;
    }

    public async createLocation(request: CreateLocationRequestDto): Promise<string> {
        return await this.locationProxy.createLocation(request);
    }

    public async editLocation(id: string, request: UpdateLocationRequestDto): Promise<void> {
        await this.locationProxy.updateLocation(id, request);
    }

    public async editLocationLogoImage(id: string, request: UpdateFileRequestDto): Promise<void> {
        await this.locationProxy.updateLocationLogoImage(id, request);
    }

    public async editLocationMainImage(id: string, request: UpdateFileRequestDto): Promise<void> {
        await this.locationProxy.updateLocationMainImage(id, request);
    }

    public async editLocationHomeBackground(
        id: string,
        request: UpdateFileRequestDto
    ): Promise<void> {
        await this.locationProxy.updateLocationAppHomeBackgroundImage(id, request);
    }

    public async editLocationImages(
        id: string,
        request: UpdateLocationImagesRequest
    ): Promise<void> {
        await this.locationProxy.updateLocationImages(id, request);
    }

    public async uploadImages(
        id: string,
        main?: UpdateFileRequestDto | null,
        logo?: UpdateFileRequestDto | null,
        appHomeBackground?: UpdateFileRequestDto | null,
        images?: UpdateLocationImagesRequest | null
    ): Promise<UploadImagesResponse> {
        const errors = new Map<string, string[]>();

        // upload main image
        if (main) {
            try {
                await this.editLocationMainImage(id, main);
            } catch (e) {
                if (e.response?.data?.errors['uploadBase64'] !== undefined) {
                    errors.set('main', [e.response?.data.errors['uploadBase64'][0].description]);
                }
            }
        }

        // upload logo image
        if (logo) {
            try {
                await this.editLocationLogoImage(id, logo);
            } catch (e) {
                if (e.response?.data?.errors['uploadBase64'] !== undefined) {
                    errors.set('logo', [e.response?.data.errors['uploadBase64'][0].description]);
                }
            }
        }

        // upload app home background
        if (appHomeBackground) {
            try {
                await this.editLocationHomeBackground(id, appHomeBackground);
            } catch (e) {
                if (e.response?.data?.errors['uploadBase64'] !== undefined) {
                    errors.set('appHomeBackground', [
                        e.response?.data.errors['uploadBase64'][0].description,
                    ]);
                }
            }
        }

        // upload other images
        if (images?.images) {
            try {
                await this.editLocationImages(id, images);
            } catch (e) {
                for (let i = 0; i < images.images?.length; i++) {
                    if (e.response?.data?.errors[`images[${i}].UploadBase64`] !== undefined) {
                        errors.set('other', [
                            e.response?.data.errors[`images[${i}].UploadBase64`][0].description,
                        ]);
                    }
                }
            }
        }

        return {
            success: errors.size === 0,
            errors: errors,
        };
    }

    //#region Messages
    public async getLocationMessages(
        id: string,
        request: GetLocationMessagesRequestDto
    ): Promise<[LocationMessage[], number]> {
        const response: GetLocationMessagesResponseDto | null = await this.messageProxy.getLocationMessages(
            id,
            request
        );
        return [
            response?.items?.map((dto) => new LocationMessage(dto!)) || [],
            response?.totalItemCount || 0,
        ];
    }

    public async sendLocationMessage(request: CreateMessageRequestDto): Promise<void> {
        await this.messageProxy.createMessage(request);
    }

    public async getLocationMessageById(
        locationId: string,
        messageId: string
    ): Promise<LocationMessage | null> {
        const response: LocationMessageDto | null = await this.messageProxy.getLocationMessage(
            locationId,
            messageId
        );
        return response ? new LocationMessage(response) : null;
    }

    //#endregion

    //#region Service Requests
    public async getLocationServiceRequests(
        request: GetServiceRequestsRequestDto
    ): Promise<[ServiceRequest[], number]> {
        const response: GetServiceRequestsResponseDto | null = await this.serviceRequestProxy.getServiceRequests(
            request
        );
        return [
            response?.items?.map((dto: ServiceRequestDto | null) => new ServiceRequest(dto!)) || [],
            response?.totalItemCount || 0,
        ];
    }

    public async createServiceRequest(request: CreateServiceRequestRequestDto): Promise<void> {
        await this.serviceRequestProxy.createServiceRequest(request);
    }

    public async getServiceRequestById(id: string): Promise<ServiceRequest | null> {
        const serviceRequestData: ServiceRequestDto | null = await this.serviceRequestProxy.getServiceRequest(
            id
        );
        return new ServiceRequest(serviceRequestData!) || null;
    }

    public async setServiceRequestStatus(
        id: string,
        request: UpdateServiceRequestStatusRequestDto
    ): Promise<void> {
        await this.serviceRequestProxy.updateServiceRequestStatus(id, request);
    }
    //#endregion
}
