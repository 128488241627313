import { BookingDto } from 'Api/Features/Bookings/Dtos/BookingDto';
import { BookingAmenityDto } from 'Api/Features/Bookings/Dtos/BookingAmenityDto';
import { BookingOrganizerUserDto } from 'Api/Features/Bookings/Dtos/BookingOrganizerUserDto';
import { BookingCompanyDto } from 'Api/Features/Bookings/Dtos/BookingCompanyDto';
import { BookingApprovalStatusDto } from 'Api/Features/Bookings/Dtos/BookingApprovalStatusDto';
import moment from 'moment';
import {
    DATE_DISPLAY_FORMAT,
    DATE_WEEKDAY_DISPLAY_FORMAT,
    TIME_FORMAT_12_HOUR,
} from '../Constants';
import { StatusBadgeStatus } from 'Components/status-badge/status-badge';
import { BookingRecurrenceDto } from 'Api/Features/Bookings/Dtos/BookingRecurrenceDto';

export class Booking implements BookingDto {
    constructor(dto: BookingDto) {
        Object.assign(this, dto);
    }
    id = '';
    periodStart = undefined;
    periodEnd = undefined;
    amenity: BookingAmenityDto | null = null;
    organizer: BookingOrganizerUserDto | null = null;
    company: BookingCompanyDto | null = null;
    isCancelled = false;
    cost = 0;
    costTaxAmount = 0;
    approvalStatus: BookingApprovalStatusDto | undefined = undefined;
    bookingRecurrence?: BookingRecurrenceDto | null;

    get organizerName(): string {
        return `${this.organizer?.firstName} ${this.organizer?.lastName}`;
    }

    get dateAndTime(): string {
        return (
            `${moment.parseZone(this.periodStart).format(DATE_DISPLAY_FORMAT)} ${this.time}` || ''
        );
    }

    get time(): string {
        return `${moment
            .parseZone(this.periodStart)
            .format(TIME_FORMAT_12_HOUR)} - ${moment
            .parseZone(this.periodEnd)
            .format(TIME_FORMAT_12_HOUR)}`;
    }

    get dateWeekDay(): string {
        return moment(this.periodStart).format(DATE_WEEKDAY_DISPLAY_FORMAT);
    }

    get statusBadgeStatus(): StatusBadgeStatus | undefined {
        if (this.approvalStatus === BookingApprovalStatusDto.Approved) {
            return StatusBadgeStatus.Approved;
        } else if (this.approvalStatus === BookingApprovalStatusDto.Refused) {
            return StatusBadgeStatus.Refused;
        } else if (this.approvalStatus === BookingApprovalStatusDto.WaitingForApproval) {
            return StatusBadgeStatus.WaitingForApproval;
        }
        return undefined;
    }
}
