import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Message: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.75 4.18c-9.566 0-17.349 6.486-17.349 14.46 0 4.777 2.83 9.242 7.57 11.943l.285.162.946 5.241 4.064-3.372.33.068c1.34.278 2.738.419 4.154.419 9.566 0 17.349-6.488 17.349-14.461 0-7.974-7.783-14.46-17.349-14.46zM9.25 38.597l-1.257-6.963C2.982 28.665 0 23.829 0 18.64 0 9.893 8.411 2.778 18.75 2.778S37.5 9.893 37.5 18.64c0 8.746-8.411 15.862-18.75 15.862-1.395 0-2.774-.128-4.105-.382L9.25 38.599z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.103 17.804c-.559 0-1.014.455-1.014 1.014a1.015 1.015 0 002.028 0c0-.56-.455-1.014-1.014-1.014zm0 3.43a2.418 2.418 0 01-2.415-2.416 2.418 2.418 0 012.415-2.416 2.418 2.418 0 012.415 2.416 2.418 2.418 0 01-2.415 2.415zM18.75 17.804c-.559 0-1.014.455-1.014 1.014 0 .559.455 1.014 1.014 1.014.56 0 1.015-.455 1.015-1.014 0-.56-.456-1.014-1.015-1.014zm0 3.43a2.418 2.418 0 01-2.415-2.416 2.418 2.418 0 012.415-2.416 2.418 2.418 0 012.415 2.416 2.418 2.418 0 01-2.415 2.415zM25.398 17.804c-.56 0-1.015.455-1.015 1.014a1.015 1.015 0 002.029 0c0-.56-.456-1.014-1.014-1.014zm0 3.43a2.418 2.418 0 01-2.416-2.416 2.418 2.418 0 012.415-2.416 2.418 2.418 0 012.415 2.416 2.418 2.418 0 01-2.415 2.415z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Message;
