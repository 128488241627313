import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const FoodHot: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.0041 28.5898C6.64907 28.5898 1.78114 27.3781 1.25036 26.6602C1.48843 26.3653 2.87721 25.6477 7.0965 25.1516C9.06257 26.3919 11.2851 27.0466 13.5401 27.0466C15.9435 27.0466 18.2942 26.3086 20.3539 24.9095C26.0595 25.3164 28.4201 26.2312 28.7595 26.6602C28.2289 27.3761 23.3612 28.5898 15.0041 28.5898ZM1.29943 12.8801H25.7807C25.7586 13.1223 25.7319 13.3621 25.6995 13.5941L25.6914 13.6488L25.665 13.8298C25.2461 16.6543 24.1296 19.2487 22.44 21.335C21.6694 22.2866 20.7911 23.1123 19.8294 23.7892C17.9342 25.1212 15.7586 25.8264 13.5401 25.8264C11.4497 25.8264 9.38786 25.1983 7.57414 24.0088C4.04229 21.6948 1.72629 17.5691 1.29943 12.8801ZM28.5195 14.5295C28.1741 17.3088 26.5941 19.6188 24.4468 20.6274C25.5939 18.8156 26.3908 16.7416 26.7872 14.5295H28.5195ZM26.9541 24.6146C25.5958 24.2692 23.8226 23.9926 21.7669 23.8033C22.2896 23.3398 22.7796 22.8316 23.235 22.2845C26.8239 21.6399 29.5648 18.1792 29.7966 13.9518L29.8311 13.3093H26.9623C27.0006 12.9819 27.0294 12.6444 27.0476 12.3028L27.0821 11.6602H0L0.0347143 12.3028C0.290786 17.0671 2.36486 21.3697 5.66293 24.1085C0.00814283 24.9401 0.00814286 26.2171 0.00814286 26.6583C0.00814286 27.3292 0.498214 28.3093 4.72543 29.0557C7.47664 29.5415 11.1285 29.8098 15.0041 29.8098C18.8796 29.8098 22.5315 29.5415 25.2827 29.0557C29.5099 28.3093 30 27.3292 30 26.6583C30 25.7881 29.0323 25.1392 26.9541 24.6146Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.1238 9.15471C13.0803 9.15471 13.036 9.15021 12.9918 9.14036C12.6629 9.06771 12.4551 8.74221 12.5275 8.41307C12.5333 8.38264 12.6966 7.49186 11.9513 6.46221C10.2852 4.16014 11.2383 1.86428 11.9753 0.97157C12.1893 0.712285 12.5738 0.674999 12.8337 0.889713C13.093 1.10357 13.1301 1.4865 12.9175 1.74643C12.8511 1.82893 11.4222 3.64993 12.9398 5.74693C14.0196 7.23921 13.7316 8.61814 13.7189 8.67621C13.6561 8.96078 13.4039 9.15471 13.1238 9.15471Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.4616 9.15471C16.4181 9.15471 16.3737 9.15021 16.3296 9.14036C16.0007 9.06771 15.7928 8.74221 15.8652 8.41307C15.8652 8.41071 15.9259 8.02586 15.5972 7.57178C14.5896 6.17914 15.172 4.78328 15.621 4.23943C15.8352 3.97993 16.2197 3.94264 16.4798 4.15736C16.7385 4.37078 16.776 4.75221 16.5649 5.01214C16.492 5.10471 15.9002 5.90936 16.5857 6.8565C17.2354 7.75457 17.0768 8.58428 17.0567 8.67621C16.9939 8.96078 16.7417 9.15471 16.4616 9.15471Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default FoodHot;
