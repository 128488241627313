import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Townhall: FunctionComponent<SvgProps> = ({ fill = 'currentColor', width = '16', height = '16', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width={width} height={height} viewBox="0 0 16 16" fill="none" {...props}>
                <circle cx={8} cy={8} r={8} fill="#fff" />
                <path d="M8 15.2A7.2 7.2 0 108 .8a7.2 7.2 0 000 14.4z" fill={fill} />
                <path
                    d="M6.098 11.009c-.167.086-.357-.065-.323-.257l.36-2.05-1.527-1.454c-.143-.136-.069-.384.122-.411l2.123-.302.946-1.875a.223.223 0 01.402 0l.946 1.875 2.123.302c.19.027.265.275.122.411L9.866 8.703l.36 2.05c.033.191-.157.342-.324.256L8 10.03l-1.901.978z"
                    fill="#fff"
                />
            </svg>
        </span>
    );
};

export default Townhall;
