import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const ServiceRequest: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 60 60" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M44.4649 45.0095L44.7291 45.9946L45.7142 46.2588L46.4355 45.5375L46.1713 44.5524L45.1862 44.2882L44.4649 45.0095ZM42.7343 47.9862L41.7415 44.2786L44.4552 41.5648L48.1621 42.5584L49.1556 46.2653L46.4419 48.979L42.7343 47.9862ZM7.98314 14.0774C7.81979 16.0537 8.51998 18.0423 9.96945 19.4918C11.7748 21.2971 14.4118 21.9486 16.8521 21.1924L17.5554 20.9746L40.6065 44.0257L40.5283 44.6224C40.3246 46.1681 40.8392 47.6858 41.9398 48.7863C43.8762 50.7228 47.0275 50.7228 48.9635 48.7867C50.8996 46.8506 50.8992 43.6998 48.9628 41.7633C47.8619 40.6624 46.3441 40.1478 44.7988 40.3518L44.2022 40.4308L21.1514 17.3801L21.3688 16.6772C22.125 14.2361 21.4731 11.5987 19.6678 9.7934C18.2184 8.34393 16.2298 7.64374 14.2538 7.80671L17.3955 10.9484C19.1241 12.677 19.1241 15.49 17.3955 17.2187C15.6668 18.9473 12.8534 18.9477 11.1248 17.219L7.98314 14.0774ZM8.24268 21.216C5.62175 18.595 4.80767 14.6727 6.16856 11.2242L6.8515 9.49447L12.8491 15.4921C13.6264 16.2694 14.891 16.2694 15.6687 15.4917C16.446 14.7145 16.446 13.4498 15.6687 12.6726L9.67105 6.67491L11.4008 5.99197C14.8489 4.6307 18.7709 5.44516 21.3922 8.06647C23.6611 10.3354 24.586 13.5741 23.8931 16.6705L45.0975 37.8749C47.1835 37.7721 49.1928 38.5421 50.6871 40.0364C53.5753 42.9246 53.5757 47.6231 50.6879 50.5109C47.8001 53.3987 43.1012 53.3987 40.213 50.5105C38.719 49.0166 37.9487 47.0076 38.0514 44.9216L16.8459 23.7161C13.7503 24.4098 10.512 23.4853 8.24268 21.216Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default ServiceRequest;
