import * as yup from 'yup';
import { stripHtml } from 'Utils';
import { MAX_LENGTH_4000 } from 'Models/Constants';

export const CreateMessageSchema = yup.object({
    title: yup.string().required('Errors.required').max(200, 'Errors.length_200'),
    body: yup
        .string()
        .transform((value, originalValue) => {
            return stripHtml(originalValue);
        })
        .required('Errors.required')
        .max(MAX_LENGTH_4000, 'Errors.length_4000'),
});
