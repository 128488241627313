import { MAX_LENGTH_25, MAX_LENGTH_256, PHONE_REGEX } from 'Models/Constants';
import * as yup from 'yup';

// See: https://github.com/jquense/yup/issues/58
yup.addMethod(yup.mixed, 'optional', function optional() {
    return this.transform((value) => {
        return (typeof value == 'string' && !value) ||
            (value instanceof Array && !value.length) ||
            value === null // allow to skip "nullable"
            ? undefined
            : value;
    });
});

export const EditLocationServiceSchema = yup.object({
           name: yup.string().required('Errors.required'),
           description: yup.string().max(4000, 'Errors.length_4000'),
           phoneNumber: yup
               .string()
               .optional()
               .matches(PHONE_REGEX, 'Errors.phone_invalid')
               .max(MAX_LENGTH_25, 'Errors.length_25'),
           website: yup
               .string()
               .optional()
               .max(MAX_LENGTH_256, 'Errors.length_256'),
           email: yup.string().optional().email(),
       });
