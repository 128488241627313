// GENERATED FILE - DO NOT MODIFY

export enum DailyBookingSummaryPeriodUnavailabilityReasonDto {
    Unknown = "Unknown",
    Booked = "Booked",
    MaxContiguousPeriodReached = "MaxContiguousPeriodReached",
    MaxPeriodPerDayReached = "MaxPeriodPerDayReached",
    OutsideOpeningHours = "OutsideOpeningHours",
    Cutoff = "Cutoff",
    MinBookingDuration = "MinBookingDuration",
}