import React, { FunctionComponent } from 'react';

import ResetPasswordForm from '../reset-password/reset-password-form';
import { useTranslation } from 'react-i18next';
import ConfirmAccountSuccess from './confirm-account-success';
import { useResetPassword } from '../reset-password/use-reset-password';

const ConfirmAccount: FunctionComponent = () => {
    const { t } = useTranslation();
    const [success, submit] = useResetPassword();

    return (
        <div className="ConfirmAccount">
            {success ? (
                <ConfirmAccountSuccess />
            ) : (
                <ResetPasswordForm
                    message={t('User.set_password_message')}
                    submitText={t('User.set_password')}
                    onSubmit={submit}
                    isAccountConfirm
                />
            )}
        </div>
    );
};

export default ConfirmAccount;
