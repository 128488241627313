import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const LowercaseI: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 12 12" fill="none" {...props}>
                <path
                    d="M5.99979 0.52462C2.97611 0.52462 0.524414 2.97632 0.524414 6C0.524414 9.02368 2.97611 11.4754 5.99979 11.4754C9.02347 11.4754 11.4752 9.02368 11.4752 6C11.4752 2.97632 9.02347 0.52462 5.99979 0.52462ZM5.99979 10.5465C3.48943 10.5465 1.45327 8.51036 1.45327 6C1.45327 3.48964 3.48943 1.45348 5.99979 1.45348C8.51016 1.45348 10.5463 3.48964 10.5463 6C10.5463 8.51036 8.51016 10.5465 5.99979 10.5465Z"
                    fill={fill}
                />
                <path
                    d="M5.41357 3.84896C5.41357 4.00455 5.47538 4.15377 5.5854 4.26378C5.69542 4.3738 5.84463 4.43561 6.00022 4.43561C6.15581 4.43561 6.30503 4.3738 6.41504 4.26378C6.52506 4.15377 6.58687 4.00455 6.58687 3.84896C6.58687 3.69337 6.52506 3.54416 6.41504 3.43414C6.30503 3.32412 6.15581 3.26231 6.00022 3.26231C5.84463 3.26231 5.69542 3.32412 5.5854 3.43414C5.47538 3.54416 5.41357 3.69337 5.41357 3.84896ZM6.29355 5.21781H5.7069C5.65312 5.21781 5.60912 5.26181 5.60912 5.31558V8.63992C5.60912 8.69369 5.65312 8.73769 5.7069 8.73769H6.29355C6.34732 8.73769 6.39132 8.69369 6.39132 8.63992V5.31558C6.39132 5.26181 6.34732 5.21781 6.29355 5.21781Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default LowercaseI;
