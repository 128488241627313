import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Pin: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg
                width="1em"
                height="1em"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.967 6.70853C9.08853 6.70853 8.37367 7.42325 8.37367 8.30172C8.37367 9.18019 9.08853 9.89492 9.967 9.89492C10.8453 9.89492 11.5601 9.18019 11.5601 8.30172C11.5601 7.42325 10.8453 6.70853 9.967 6.70853ZM9.9677 11.1485C8.3977 11.1485 7.12076 9.87141 7.12076 8.30169C7.12076 6.73197 8.3977 5.45489 9.9677 5.45489C11.5374 5.45489 12.8144 6.73197 12.8144 8.30169C12.8144 9.87141 11.5374 11.1485 9.9677 11.1485Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.96745 1.25364C6.0812 1.25364 2.91954 4.41517 2.91954 8.30114C2.91954 9.16948 3.37037 10.5538 3.92467 11.3867C5.11856 13.1817 8.6937 17.0814 9.96745 18.4541C11.2412 17.0813 14.8163 13.1813 16.0099 11.3868C16.5643 10.5538 17.0152 9.16948 17.0152 8.30114C17.0152 4.41517 13.8536 1.25364 9.96745 1.25364ZM9.96815 20C9.79537 20 9.62996 19.9286 9.51149 19.8026C9.30343 19.5814 4.39468 14.3558 2.88163 12.0811C2.18885 11.04 1.66663 9.415 1.66663 8.30111C1.66663 3.72389 5.39065 0 9.96815 0C14.5455 0 18.2697 3.72389 18.2697 8.30111C18.2697 9.415 17.7473 11.04 17.0544 12.0813C15.5413 14.3558 10.6329 19.5814 10.4248 19.8026C10.3063 19.9286 10.1409 20 9.96815 20Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Pin;
