// GENERATED FILE - DO NOT MODIFY
import { CreateCompanyPaymentMethodRequestDto } from 'Api/Features/PaymentMethods/Dtos/CreateCompanyPaymentMethodRequestDto';
import { CreateUserPaymentMethodRequestDto } from 'Api/Features/PaymentMethods/Dtos/CreateUserPaymentMethodRequestDto';
import { GetCompanyPaymentMethodsResponseDto } from 'Api/Features/PaymentMethods/Dtos/GetCompanyPaymentMethodsResponseDto';
import { GetPaymentApiInfoResponseDto } from 'Api/Features/PaymentMethods/Dtos/GetPaymentApiInfoResponseDto';
import { GetUserPaymentMethodsResponseDto } from 'Api/Features/PaymentMethods/Dtos/GetUserPaymentMethodsResponseDto';
import { PaymentMethodHolderTypeDto } from 'Api/Features/PaymentMethods/Dtos/PaymentMethodHolderTypeDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class PaymentMethodProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getPaymentApiInfo(): Promise<GetPaymentApiInfoResponseDto | null> {
        const uri = this.buildUri(
            "/payment-api-info",
            null,
            null
        );

        const data: GetPaymentApiInfoResponseDto | null = await this.httpClient.get<GetPaymentApiInfoResponseDto | null>(uri);
        return data;
    }

    public async getUserPaymentMethods(userId: string, holderType?: PaymentMethodHolderTypeDto | null): Promise<GetUserPaymentMethodsResponseDto | null> {
        const uri = this.buildUri(
            "/users/{userId}/payment-methods",
            { userId: userId },
            { holderType: holderType }
        );

        const data: GetUserPaymentMethodsResponseDto | null = await this.httpClient.get<GetUserPaymentMethodsResponseDto | null>(uri);
        return data;
    }

    public async getCompanyPaymentMethods(companyId: string): Promise<GetCompanyPaymentMethodsResponseDto | null> {
        const uri = this.buildUri(
            "/companies/{companyId}/payment-methods",
            { companyId: companyId },
            null
        );

        const data: GetCompanyPaymentMethodsResponseDto | null = await this.httpClient.get<GetCompanyPaymentMethodsResponseDto | null>(uri);
        return data;
    }

    public async createUserPaymentMethod(userId: string, request: CreateUserPaymentMethodRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/users/{userId}/payment-methods",
            { userId: userId },
            null
        );

        await this.httpClient.post<CreateUserPaymentMethodRequestDto | null, void>(uri, request);
    }

    public async createCompanyPaymentMethod(companyId: string, request: CreateCompanyPaymentMethodRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/companies/{companyId}/payment-methods",
            { companyId: companyId },
            null
        );

        await this.httpClient.post<CreateCompanyPaymentMethodRequestDto | null, void>(uri, request);
    }

    public async deleteUserPaymentMethods(userId: string): Promise<void> {
        const uri = this.buildUri(
            "/users/{userId}/payment-methods",
            { userId: userId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }

    public async deleteCompanyPaymentMethods(companyId: string): Promise<void> {
        const uri = this.buildUri(
            "/companies/{companyId}/payment-methods",
            { companyId: companyId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }
}