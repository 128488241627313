import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Logout2: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 16 15" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.19997 0.299988H14.4C15.2824 0.299988 16 1.01759 16 1.89999V13.1C16 13.9824 15.2824 14.7 14.4 14.7H7.19997C6.31757 14.7 5.59997 13.9824 5.59997 13.1V9.89999H7.19997V13.1H14.4V1.89999H7.19997V5.09999H5.59997V1.89999C5.59997 1.01759 6.31757 0.299988 7.19997 0.299988ZM4 6.69997H11.2V8.29997H4V10.7L0 7.49997L4 4.29997V6.69997Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Logout2;
