import { action, computed, observable } from 'mobx';
import { inject } from 'aurelia-dependency-injection';
import { UserService } from 'Services/UserService';
import { UserDetails } from '../Models/Users/UserDetails';
import AuthenticationStore from './AuthenticationStore';
import { ManagementRoleNameDto } from 'Api/Features/Users/Dtos/ManagementRoleNameDto';

@inject(UserService, AuthenticationStore)
class UserStore {
    constructor(
        private readonly userService: UserService,
        private readonly authenticationStore: AuthenticationStore
    ) {}

    @observable
    private _user: UserDetails | null = null;

    @computed
    get user(): UserDetails | null {
        return this._user;
    }

    @computed
    get firstName(): string {
        return this._user?.firstName || '';
    }

    @computed
    get isAdmin(): boolean {
        if (this._user?.managementRoles)
            return (
                this._user?.managementRoles?.filter(
                    (managementRole) => managementRole?.name === ManagementRoleNameDto.Administrator
                ).length > 0
            );
        else return false;
    }

    @computed
    get isBuildingManager(): boolean {
        if (this._user?.managementRoles)
            return (
                this._user?.managementRoles?.filter(
                    (managementRole) => managementRole?.name === ManagementRoleNameDto.BuildingManager
                ).length > 0
            );
        else return false;
    }


    @computed
    get buildingManagerLocationIds(): string[] {
        if (this.isBuildingManager) {
            return this._user?.managementRoles
                ?.filter((role) => role?.name === ManagementRoleNameDto.BuildingManager)
                ?.filter(role => role?.context?.['locationId'] !== undefined)
                ?.map((role) => role?.context?.['locationId']!) ?? [];
        }
        return []
    }

    @computed
    get isCoworking(): boolean {
        return this._user?.company?.isCoworking || false;
    }

    @action
    async setCurrentUser(): Promise<void> {
        if (this.authenticationStore.userId) {
            this._user = await this.userService.getById(this.authenticationStore.userId);
        }
    }
}

export default UserStore;
