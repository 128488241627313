import { MAX_LENGTH_100, MAX_LENGTH_25, MAX_LENGTH_256, MAX_LENGTH_4000, MAX_LENGTH_50 } from 'Models/Constants';
import * as yup from 'yup';

// See: https://github.com/jquense/yup/issues/58
yup.addMethod(yup.mixed, 'optional', function optional() {
    return this.transform((value) => {
        return (typeof value == 'string' && !value) ||
            (value instanceof Array && !value.length) ||
            value === null // allow to skip "nullable"
            ? undefined
            : value;
    });
});

const phoneRegex = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const BaseShape = {
    name: yup.string().required('Errors.required').max(MAX_LENGTH_100, 'Errors.length_100'),
    introduction: yup.string().max(MAX_LENGTH_4000, 'Errors.length_4000'),
    locationId: yup.string().required('Errors.required'),
    numberOfEmployees: yup.string().required('Errors.required'),
    sectorOfBusiness: yup.string().required('Errors.required'),
    contactInfo: yup.object().shape({
        email: yup
            .string()
            .optional()
            .email('Errors.email_invalid')
            .max(MAX_LENGTH_256, 'Errors.length_256'),
        phoneNumber: yup
            .string()
            .optional()
            .matches(phoneRegex, 'Errors.phone_invalid')
            .max(MAX_LENGTH_25, 'Errors.length_25'),
        website: yup
            .string()
            .optional()
            .max(MAX_LENGTH_256, 'Errors.length_256'),
        addressline1: yup.string().max(MAX_LENGTH_100, 'Errors.length_100'),
        city: yup.string().max(MAX_LENGTH_50, 'Errors.length_50'),
        state: yup.string().max(MAX_LENGTH_50, 'Errors.length_50'),
        zipCode: yup.string().max(10, 'Errors.length_10'),
    }),
    isCoworking: yup.boolean(),
    address: yup.object().shape({
        addressLine1: yup.string().max(MAX_LENGTH_100, 'Errors.length_100'),
        city: yup.string().max(MAX_LENGTH_50, 'Errors.length_50'),
        state: yup.string().max(MAX_LENGTH_50, 'Errors.length_50'),
        zipCode: yup.string().max(10, 'Errors.length_10'),
    }),
};

export const CreateCompanySchema = yup.object(
    Object.assign({}, BaseShape, {
        mainContact: yup.object().shape({
            firstName: yup.string().required('Errors.required').max(MAX_LENGTH_50, 'Errors.length_50'),
            lastName: yup.string().required('Errors.required').max(MAX_LENGTH_50, 'Errors.length_50'),
            contactInfo: yup.object().shape({
                email: yup
                    .string()
                    .email('Errors.email_invalid')
                    .required('Errors.required')
                    .max(MAX_LENGTH_256, 'Errors.length_256'),
                phoneNumber: yup
                    .string()
                    .matches(phoneRegex, 'Errors.phone_invalid')
                    .required('Errors.required')
                    .max(MAX_LENGTH_25, 'Errors.length_25'),
            }),
            address: yup.object().shape({
                addressLine1: yup.string().max(MAX_LENGTH_100, 'Errors.length_100'),
                city: yup.string().max(MAX_LENGTH_50, 'Errors.length_50'),
                state: yup.string().max(MAX_LENGTH_50, 'Errors.length_50'),
                zipCode: yup.string().max(10, 'Errors.length_10')
            }), 
            companyRoles: yup.array().required('Errors.required'),
        }),
    })
);

export const EditCompanySchema = yup.object(Object.assign({}, BaseShape, {}));
