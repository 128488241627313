import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { ActionMessage, ActionTitle } from 'Components/webapp-actions-layout';
import './reset-password-success.less';

const ResetPasswordSuccess: FunctionComponent = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const onButtonClick = (): void => {
        history.push('/');
    };

    return (
        <div className="ResetPasswordSuccess">
            <ActionTitle>{t('success_title')}</ActionTitle>
            <ActionMessage>{t('User.reset_password_success')}</ActionMessage>
            <div className="action">
                <Button type="primary" onClick={onButtonClick} className="login-btn">
                    {t('Account.login_with_web')}
                </Button>
            </div>
        </div>
    );
};

export default ResetPasswordSuccess;
