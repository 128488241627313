import React, { FunctionComponent } from 'react';
import { Form } from 'antd';
import { FormItemProps } from 'antd/es/form';
import { useTranslation } from 'react-i18next';
import './validated-form-item.less';

interface ValidatedFormItemProps extends FormItemProps {
    errors?: Map<string, string[]>;
}

const ValidatedFormItem: FunctionComponent<ValidatedFormItemProps> = ({
    children,
    name,
    label,
    errors,
    ...props
}) => {
    const { t } = useTranslation();
    const fieldErrors = errors?.get(name?.toString() || '');
    const errorProps: FormItemProps = {};
    if (fieldErrors) {
        errorProps.hasFeedback = true;
        errorProps.help = fieldErrors.map((errorKey) => t(errorKey, { param1: label })).join('\n');
        errorProps.validateStatus = 'error';
    }
    return (
        <Form.Item {...props} name={name} label={label} {...errorProps}>
            {children}
        </Form.Item>
    );
};

export default ValidatedFormItem;
