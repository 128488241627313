import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Booking: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
                <path
                    d="M5.042 40L5 0h28.372v40L19.19 29.897 5.042 40zm14.144-12.022l12.633 9.005V1.553H6.556l.038 35.441 12.592-9.016z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Booking;
