import React, { FunctionComponent, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useService, useStores } from 'Hooks';
import { BookingService } from 'Services/BookingService';
import { Location as LocationModel } from 'Models/Location/Location';
import { AmenityCategory as AmenityCategoryModel } from 'Models/Amenities/AmenityCategory';
import { Amenity as AmenityModel } from 'Models/Amenities/Amenity';
import { BookingDetails } from 'Models/Bookings/BookingDetails';
import { CreateBookingRequestDto } from 'Api/Features/Bookings/Dtos/CreateBookingRequestDto';
import { GetBookingsSortColumnDto } from 'Api/Features/Bookings/Dtos/GetBookingsSortColumnDto';
import { SortDirectionDto } from 'Api/Features/General/Dtos/SortDirectionDto';
import { Button, Modal, Steps } from 'antd';
import { theme } from 'variant';
import { CheckMarkWeb, Close, Edit } from 'Components/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BookingLocation from './booking-location';
import BookingAmenityCategory from './booking-amenity-category';
import BookingAmenity from './booking-amenity';
import BookingTime from './booking-time';
import BookingPayment from './booking-payment';
import BookingConfirmation from './booking-confirmation';
import { DATE_BOOKING_FORMAT, TIME_DISPLAY_FORMAT } from 'Models/Constants';
import './booking-modal.less';

const { Step } = Steps;

enum EStep {
    Location = 0,
    AmenityCategory = 1,
    Amenity = 2,
    Time = 3,
    Payment = 4,
    Confirmation = 5,
}

interface BookingDetailsProps {
    visible: boolean;
    setVisibleState: (state: boolean) => void;
}

const BookingModal: FunctionComponent<BookingDetailsProps> = ({ visible, setVisibleState }) => {
    const { t } = useTranslation();

    const {
        bookingStore,
        confirmationModalStore,
        globalLoadingStore,
        toastStore,
        userStore,
    } = useStores();
    const bookingService = useService(BookingService);

    const [booking, setBooking] = useState<BookingDetails | null>(null);
    const [currentStep, setCurrentStep] = useState(EStep.Location);
    const [currentStepValid, setCurrentStepValid] = useState(false);
    const [locationValue, setLocationValue] = useState<LocationModel | null>(null);
    const [amenityCategoryValue, setAmenityCategoryValue] = useState<AmenityCategoryModel | null>(
        null
    );
    const [amenityValue, setAmenityValue] = useState<AmenityModel | null>(null);
    const [timeValue, setTimeValue] = useState<any>(null);
    const [isTimeValid, setIsTimeValid] = useState<boolean>(false);
    const [paymentValue, setPaymentValue] = useState<any>(null);

    const onStepChange = (step: number): void => {
        if (userStore.isAdmin && step === EStep.Payment) {
            submitBooking();
        } else setCurrentStep(step);
    };

    const fetchBooking = useCallback(async (bookingId:string): Promise<void> => {
        if (bookingId) {
            try {
                // get booking for confirmation
                const createdBooking: BookingDetails | null = await bookingService.getBooking(bookingId);
                if (createdBooking) setBooking(createdBooking);
            } catch (error) {
                toastStore.displayError(error);
            } finally {
                userStore.setCurrentUser();
                await bookingStore.setUpcomingBookings({
                    sortColumn: GetBookingsSortColumnDto.Date,
                    sortDirection: SortDirectionDto.Ascending,
                    from: moment().format(),
                });
                setCurrentStep(EStep.Confirmation);
                globalLoadingStore.removeLoading();
            }
        }
    }, [bookingService, bookingStore, userStore, setCurrentStep, toastStore, globalLoadingStore]);

    const resetBookingFlow = (): void => {
        setVisibleState(false);
        setCurrentStep(EStep.Location);
        setLocationValue(null);
        setAmenityCategoryValue(null);
        setAmenityValue(null);
        setTimeValue(null);
        setPaymentValue(null);
    };

    const handleCloseModal = async (): Promise<void> => {
        if (
            currentStep < EStep.Confirmation &&
            !(await confirmationModalStore.confirm({
                icon: null,
                title: t('Booking.leave_booking_title'),
                message: t('Booking.leave_booking_message'),
                positiveText: t('yes'),
                negativeText: t('Booking.leave_confirm_negative'),
            }))
        )
            return;

        resetBookingFlow();
    };

    const content = (): JSX.Element => {
        switch (currentStep) {
            case EStep.Location:
                return (
                    <BookingLocation
                        selectedLocation={locationValue}
                        onChange={(value): void => {
                            setLocationValue(value);
                            setAmenityCategoryValue(null);
                            setAmenityValue(null);
                            setTimeValue(null);
                            setPaymentValue(null);
                        }}
                    />
                );

            case EStep.AmenityCategory:
                return (
                    <BookingAmenityCategory
                        selectedLocation={locationValue}
                        selectedAmenityCategory={amenityCategoryValue}
                        skipStep={(): void => onStepChange(EStep.Amenity)}
                        backToLocation={(): void => onStepChange(EStep.Location)}
                        onChange={(value): void => {
                            setAmenityCategoryValue(value);
                            setAmenityValue(null);
                            setTimeValue(null);
                            setPaymentValue(null);
                        }}
                    />
                );

            case EStep.Amenity:
                return (
                    <BookingAmenity
                        selectedLocation={locationValue}
                        selectedAmenityCategory={amenityCategoryValue}
                        selectedAmenity={amenityValue}
                        onChange={(value): void => {
                            setAmenityValue(value);
                            setTimeValue(null);
                            setPaymentValue(null);
                        }}
                    />
                );

            case EStep.Time:
                return (
                    <BookingTime
                        selectedLocation={locationValue}
                        selectedAmenity={amenityValue}
                        selectedTime={timeValue}
                        isTimeValid={isTimeValid}
                        onChange={(value): void => {
                            setTimeValue(value);
                            setPaymentValue(null);
                        }}
                        setIsTimeValid={(value: boolean): void => setIsTimeValid(value)}
                    />
                );

            case EStep.Payment:
                return (
                    <BookingPayment
                        selectedAmenity={amenityValue}
                        selectedTime={timeValue}
                        onChange={(value): void => {
                            setPaymentValue(value);
                        }}
                    />
                );

            case EStep.Confirmation:
                return (
                    <BookingConfirmation
                        booking={booking}
                        location={locationValue}
                        amenityCategoryName={amenityCategoryValue?.name || ''}
                        amenity={amenityValue || null}
                        date={timeValue || null}
                    />
                );

            default:
                return <div>{t('All.loading_message')}</div>;
        }
    };

    const EditStep = (): JSX.Element => {
        return (
            <div className="edit-step">
                <Edit fill={theme['primary-color']} />
                {t('edit')}
            </div>
        );
    };

    useEffect(() => {
        switch (currentStep) {
            case EStep.Location:
                setCurrentStepValid(locationValue ? true : false);
                break;
            case EStep.AmenityCategory:
                setCurrentStepValid(amenityCategoryValue ? true : false);
                break;

            case EStep.Amenity:
                setCurrentStepValid(amenityValue ? true : false);
                break;

            case EStep.Time:
                setCurrentStepValid(isTimeValid ? true : false);
                break;

            case EStep.Payment:
                setCurrentStepValid(paymentValue ? true : false);
                break;

            default:
                setCurrentStepValid(false);
                break;
        }
    }, [currentStep, locationValue, amenityCategoryValue, amenityValue, isTimeValid, paymentValue]);

    const submitBooking = async (): Promise<void> => {
        const paymentInformation =
            !userStore.isAdmin && paymentValue && paymentValue !== 'free'
                ? {
                      paymentMethodHolderType: paymentValue.holder.type,
                      paymentMethodHolderId: paymentValue.holder.id,
                  }
                : null;
        const request: CreateBookingRequestDto = {
            periodStart: timeValue?.bookingStart,
            periodEnd: timeValue?.bookingEnd,
            amenityId: amenityValue?.id,
            organizerUserId: userStore.user?.id,
            ...paymentInformation,
        };

        try {
            globalLoadingStore.addLoading();
            const bookingID:string | null = await bookingService.book(request);
            if(bookingID) fetchBooking(bookingID);
        } catch (error) {
            if (!error.treated) {
                toastStore.displayError(error);
            }
        }
    };

    const bookingStartDate: string = timeValue?.bookingStart
        ? moment(timeValue?.bookingStart).format(DATE_BOOKING_FORMAT)
        : '';

    const bookingStartTime: string = timeValue?.bookingStart
        ? moment(timeValue?.bookingStart).format(TIME_DISPLAY_FORMAT)
        : '';

    const bookingEndTime: string = timeValue?.bookingEnd
        ? moment(timeValue?.bookingEnd).format(TIME_DISPLAY_FORMAT)
        : '';

    const bookingStartTimeEndTime: string = bookingStartTime
        ? `${bookingStartTime} - ${bookingEndTime}`
        : '';

    const bookingStartDateTimeEndTime: string = bookingStartDate
        ? `${bookingStartDate}, ${bookingStartTime} - ${bookingEndTime}`
        : '';

    const bookingHasDateTime: boolean = currentStep > 3 && timeValue;

    return (
        <Modal
            className="BookingModalWeb"
            visible={visible}
            title={
                <>
                    {t('Booking.book_amenities')}{' '}
                    {currentStep < EStep.Confirmation ? (
                        <span className="subtitle color-primary">
                            ● {t(`Booking.booking_modal_subtitle_${currentStep}`)}
                        </span>
                    ) : null}
                </>
            }
            onCancel={handleCloseModal}
            maskClosable={false}
            footer={null}
            maskStyle={{
                background: 'rgba(29, 28, 29, 0.85)',
            }}
            width="92vw"
            closeIcon={<Close fill={theme['primary-color']} />}
        >
            <div className="BookingModal_Row">
                {currentStep < EStep.Confirmation && (
                    <div className="BookingModal_Steps">
                        <Steps direction="vertical" current={currentStep} onChange={onStepChange}>
                            <Step
                                icon={
                                    currentStep > EStep.Location ? (
                                        <CheckMarkWeb fill={theme['primary-color']} />
                                    ) : undefined
                                }
                                title={
                                    currentStep > 0 && locationValue ? (
                                        <span title={locationValue.name || ''}>
                                            {locationValue.name}
                                        </span>
                                    ) : (
                                        t('Booking.location_card')
                                    )
                                }
                                description={currentStep > 0 ? <EditStep /> : null}
                            />
                            <Step
                                icon={
                                    currentStep > EStep.AmenityCategory ? (
                                        <CheckMarkWeb fill={theme['primary-color']} />
                                    ) : undefined
                                }
                                title={
                                    currentStep > 1 && amenityCategoryValue ? (
                                        <span title={amenityCategoryValue.name || ''}>
                                            {amenityCategoryValue.name}
                                        </span>
                                    ) : (
                                        t('Booking.amenity_category')
                                    )
                                }
                                description={currentStep > 1 ? <EditStep /> : null}
                                disabled={!locationValue}
                            />
                            <Step
                                className={bookingHasDateTime ? 'StepAmenityExtended' : ''}
                                icon={
                                    currentStep > EStep.Amenity ? (
                                        <CheckMarkWeb fill={theme['primary-color']} />
                                    ) : undefined
                                }
                                title={
                                    currentStep > 1 && amenityValue ? (
                                        <span title={amenityValue.name || ''}>
                                            {amenityValue.name}
                                        </span>
                                    ) : (
                                        t('Booking.amenity_card')
                                    )
                                }
                                description={currentStep > 2 ? <EditStep /> : null}
                                disabled={!amenityCategoryValue}
                            />
                            <Step
                                className={bookingHasDateTime ? 'StepDateTimeExtended' : ''}
                                icon={
                                    currentStep > EStep.Time ? (
                                        <CheckMarkWeb fill={theme['primary-color']} />
                                    ) : undefined
                                }
                                title={
                                    bookingHasDateTime ? (
                                        <span title={bookingStartDateTimeEndTime || ''}>
                                            {bookingStartDate}
                                            <br />
                                            {bookingStartTimeEndTime}
                                        </span>
                                    ) : (
                                        t('Booking.date_time')
                                    )
                                }
                                description={currentStep > 3 ? <EditStep /> : null}
                                disabled={!amenityValue}
                            />

                            {!userStore.isAdmin ? (
                                <Step
                                    icon={
                                        currentStep > EStep.Payment ? (
                                            <CheckMarkWeb fill={theme['primary-color']} />
                                        ) : undefined
                                    }
                                    title={t('Booking.payment')}
                                    description={currentStep > 4 ? <EditStep /> : null}
                                    disabled={!timeValue}
                                />
                            ) : null}
                        </Steps>
                    </div>
                )}

                <div
                    className={`BookingModal_Content ${
                        currentStep < EStep.Confirmation ? 'with-sider' : 'no-sider'
                    }`}
                >
                    <>
                        {content()}

                        {currentStep < EStep.Confirmation ? (
                            <div className="controls">
                                {currentStep > 0 ? (
                                    <Button
                                        onClick={(): void => onStepChange(currentStep - 1)}
                                        type="text"
                                    >
                                        <FontAwesomeIcon icon={['fas', 'angle-left']} />{' '}
                                        {t('Booking.back_button')}
                                    </Button>
                                ) : (
                                    <div />
                                )}
                                <Button
                                    onClick={
                                        currentStep < EStep.Payment
                                            ? (): void => onStepChange(currentStep + 1)
                                            : (): Promise<void> => submitBooking()
                                    }
                                    disabled={!currentStepValid}
                                    type={'primary'}
                                >
                                    {currentStep < EStep.Payment ? (
                                        <>
                                            {t('Booking.next_step')}{' '}
                                            <FontAwesomeIcon icon={['fas', 'angle-right']} />
                                        </>
                                    ) : (
                                        t('Booking.confirm_booking')
                                    )}
                                </Button>
                            </div>
                        ) : (
                            <Button onClick={handleCloseModal} type="text">
                                {t('Booking.back_upcoming_bookings')}
                            </Button>
                        )}
                    </>
                </div>
            </div>
        </Modal>
    );
};

export default BookingModal;
