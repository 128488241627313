import { inject } from 'aurelia-dependency-injection';
import { LocationStore, LoadingStore, UserStore } from 'Stores';

//Service to collect base data from api for the app.
@inject(LocationStore, LoadingStore, UserStore)
export class BaseDataService {
    constructor(
        private readonly locationStore: LocationStore,
        private readonly loadingStore: LoadingStore,
        private readonly userStore: UserStore
    ) {}

    public async initBaseData(): Promise<void> {
        this.loadingStore.addLoading();
        try {
            await this.locationStore.setLocations();
            await this.userStore.setCurrentUser();
        } finally {
            this.loadingStore.removeLoading();
        }
    }
}
