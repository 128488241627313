export const stripHtml = (text: string | null): string | null => {
    if (text) {
        return text.trim().replace(/<[^>]*>?/gm, '');
    }
    return text;
};

export const htmlContainsText = (text: string | null): boolean => {
    if (text) {
        return stripHtml(text) !== '';
    }
    return false;
};

export function truncate(str: string , n: number): string {
    return (str.length > n) ? str.substr(0, n-1) + '…' : str;
}