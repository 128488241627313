// GENERATED FILE - DO NOT MODIFY

export enum SectorOfBusinessDto {
    Accounting = "Accounting",
    AdvertisingAndPR = "AdvertisingAndPR",
    Apparel = "Apparel",
    AerospaceAndDefense = "AerospaceAndDefense",
    Architecture = "Architecture",
    AssetManagement = "AssetManagement",
    AssociationAndNonProfit = "AssociationAndNonProfit",
    BankingAndFinance = "BankingAndFinance",
    ComputerSupportAndServices = "ComputerSupportAndServices",
    Consulting = "Consulting",
    ConsumerAndServices = "ConsumerAndServices",
    Construction = "Construction",
    Dentistry = "Dentistry",
    Education = "Education",
    FoodAndBeverage = "FoodAndBeverage",
    GovernmentContractor = "GovernmentContractor",
    HealthcareAndFitness = "HealthcareAndFitness",
    Insurance = "Insurance",
    FinancialServices = "FinancialServices",
    LawFirm = "LawFirm",
    Media = "Media",
    MultifamilyInvestment = "MultifamilyInvestment",
    PrivateEquity = "PrivateEquity",
    ProfessionalAndCommercialServices = "ProfessionalAndCommercialServices",
    RealEstate = "RealEstate",
    SoftwareCompany = "SoftwareCompany",
    Technology = "Technology",
    VentureCapital = "VentureCapital",
    Other = "Other",
    AdvisoryAndFinancialServices = "AdvisoryAndFinancialServices",
    BusinessFranchises = "BusinessFranchises",
    ConsumerGoodsAndServices = "ConsumerGoodsAndServices",
    IndustrialMachineryGasAndChemicals = "IndustrialMachineryGasAndChemicals",
    LifeSciences = "LifeSciences",
    OnlineRetail = "OnlineRetail",
    RetailMarketReports = "RetailMarketReports",
    SpecialistEngineeringInfrastructureAndContractors = "SpecialistEngineeringInfrastructureAndContractors",
}