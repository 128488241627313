import { MAX_LENGTH_50 } from 'Models/Constants';
import * as yup from 'yup';

interface Context {
    specifications: [];
}

const SpecificationSchema = yup.object({
    name: yup.string().test('name', 'Errors.name_required', function(value) {
        const { options } = this;
        const { specifications } = options.context as Context;
        if(specifications.length > 1) {
            return !!value;
        }
        return true;
    }).max(50),
    price: yup.number().required('Errors.price_required'),
    taxAmount: yup.number().required('Errors.tax_amount_required'),
});

export const CreateFoodItemSchema = yup.object({
    name: yup.string().required('Errors.required').max(MAX_LENGTH_50, 'Errors.length_50'),
    categoryId: yup.string().required('Errors.required'),
    specifications: yup.array().of(SpecificationSchema),
    description: yup.string().max(MAX_LENGTH_50, 'Errors.length_50')
});
