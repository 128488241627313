import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const FoodSalad: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27.604 13.29a.632.632 0 00.304.464 1.695 1.695 0 01.698 2.095H26.77l.207-3.726-7.96-.44c.024-.636.358-1.213.903-1.546a1.896 1.896 0 011.433-.233c.33.08.661-.114.756-.44a3.394 3.394 0 013.243-2.429 3.383 3.383 0 013.38 3.38c0 .886-.338 1.72-.952 2.352a.633.633 0 00-.176.524zM26.32 24.489H4.918l-1.802-7.371h25.006l-1.802 7.371zM1.81 16.092a.635.635 0 00-.116.543l.009.036a2.25 2.25 0 01-.435-1.324 2.258 2.258 0 012.254-2.255c.418 0 .826.117 1.18.336.121.074.259.104.392.091a4.36 4.36 0 00-1.046 2.33H2.309a.638.638 0 00-.498.243zm6.605-2.824a3.14 3.14 0 013.086 2.581H5.328a3.14 3.14 0 013.087-2.581zm-.738-2.853c.313 0 .613.07.892.208.31.152.689.025.845-.286a1.127 1.127 0 012.13.372.634.634 0 00.678.56c.196-.017.4-.006.594.023a2.82 2.82 0 011.876 4.416c-.034.048-.07.095-.108.141h-1.802A4.408 4.408 0 008.415 12c-1.04 0-1.997.363-2.75.97a.633.633 0 00-.005-.188 2.046 2.046 0 012.018-2.366zm8.335 5.565l-.006.007c.002-.002.002-.005.004-.009l.002.002zm.192-.43l-.015.3h-.114c.049-.098.091-.2.13-.3zm1.42-2.675l8.015.443-.139 2.531h-8.04l.164-2.974zm-1.568-6.896A2.96 2.96 0 0119 9.235a3.14 3.14 0 00-1.225 2.108 2.564 2.564 0 01-.414.247l-.936-.05-.01.21a2.975 2.975 0 00-.548.178 4.073 4.073 0 00-2.524-1.821 2.925 2.925 0 01-.243-1.17 2.961 2.961 0 012.957-2.958zM30 10.415a4.653 4.653 0 00-4.648-4.647 4.675 4.675 0 00-4.274 2.822 3.132 3.132 0 00-.807.061 4.23 4.23 0 00-4.215-3.94 4.233 4.233 0 00-4.225 4.194 2.363 2.363 0 00-1.407-.46c-.714 0-1.371.31-1.821.836a3.237 3.237 0 00-.926-.133 3.317 3.317 0 00-3.27 2.79A3.526 3.526 0 000 15.347a3.54 3.54 0 002.174 3.252l1.633 6.674a.63.63 0 00.615.484h22.394a.63.63 0 00.615-.484l2.056-8.404A2.911 2.911 0 0030 15.205c0-.788-.319-1.54-.864-2.087.562-.784.864-1.72.864-2.703z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default FoodSalad;
