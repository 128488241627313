import { inject } from 'aurelia-dependency-injection';
import { UserStore } from 'Stores';

@inject(UserStore)
export default class UserPermissionUtils {
    constructor(
        private readonly userStore: UserStore,
    ) {}

    public UserCanViewContent = (contentRelatedLocationIds: (string | undefined)[]): boolean => {
        if(this.userStore.isAdmin)
            return true;
        else {
            return this.userStore.buildingManagerLocationIds.some((managerLocation) =>
                contentRelatedLocationIds
                    .filter((location) => location !== undefined)
                    .map((location) => location!)
                    .some((location) => location === managerLocation)
            );
        }
    }
}