import { InvoiceDto } from 'Api/Features/Invoices/Dtos/InvoiceDto';
import { InvoiceStatusDto } from 'Api/Features/Invoices/Dtos/InvoiceStatusDto';
import { InvoiceLocationDto } from 'Api/Features/Invoices/Dtos/InvoiceLocationDto';
import { DATETIME_DISPLAY_FORMAT } from '../Constants';
import moment from 'moment';
import { PaymentMethodHolderTypeDto } from 'Api/Features/PaymentMethods/Dtos/PaymentMethodHolderTypeDto';
import { InvoiceCompanyDto } from 'Api/Features/Invoices/Dtos/InvoiceCompanyDto';
import { InvoiceUserDto } from 'Api/Features/Invoices/Dtos/InvoiceUserDto';
import { StatusBadgeStatus } from 'Components/status-badge/status-badge';

export class Invoice implements InvoiceDto {
    constructor(dto: InvoiceDto) {
        Object.assign(this, dto);
    }
    id = '';
    number: string | null = null;
    description: string | null = null;
    subTotal = 0;
    tax = 0;
    total = 0;
    status?: InvoiceStatusDto;
    issueDate?: string;
    location?: InvoiceLocationDto | null;
    paymentMethodHolderType?: PaymentMethodHolderTypeDto | undefined;
    company?: InvoiceCompanyDto | null;
    user?: InvoiceUserDto | null;

    get date(): string {
        return moment(this.issueDate).format(DATETIME_DISPLAY_FORMAT) || ''
    }

    get consoleUrl(): string {
        return `/invoices/${this.id}`
    }

    get receiverName(): string {
        if(this.paymentMethodHolderType === PaymentMethodHolderTypeDto.Company) {
            return this.company?.name ? this.company.name : '';
        }
        else if (this.paymentMethodHolderType === PaymentMethodHolderTypeDto.User) {
            return this.user?.firstName && this.user?.lastName ? `${this.user.firstName} ${this.user.lastName}` : '';
        }
        return '';
    }

    get receiverImageUrl(): string {
        if(this.paymentMethodHolderType === PaymentMethodHolderTypeDto.Company) {
            return this.company?.imageUrl ? this.company.imageUrl : '';
        }
        else if (this.paymentMethodHolderType === PaymentMethodHolderTypeDto.User) {
            return this.user?.imageUrl ? this.user.imageUrl : '';
        }
        return '';
    }

    get statusBadgeStatus(): StatusBadgeStatus | undefined {
        if(this.status === InvoiceStatusDto.AwaitingPayment) {
            return StatusBadgeStatus.AwaitingPayment;
        }
        else if(this.status === InvoiceStatusDto.Failed) {
            return StatusBadgeStatus.Failed;
        }
        else if(this.status === InvoiceStatusDto.Paid) {
            return StatusBadgeStatus.Paid;
        }
        else if(this.status === InvoiceStatusDto.Pending) {
            return StatusBadgeStatus.Pending;
        }
        else if(this.status === InvoiceStatusDto.Refunded) {
            return StatusBadgeStatus.Refunded;
        }
        else if(this.status === InvoiceStatusDto.Void) {
            return StatusBadgeStatus.Void;
        }
        return undefined;
    }

}