import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Email: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.0983 19.9148H2.895C2.53 19.9148 2.17833 19.8148 1.87333 19.6265L9.46667 11.7431L11.9967 13.7348L14.52 11.7431L22.18 19.5881C21.8617 19.8015 21.4883 19.9148 21.0983 19.9148ZM0.931667 17.9515V5.6948C0.931667 5.4898 0.965 5.28647 1.02667 5.09314L8.73167 11.1631L1.215 18.9665C1.03 18.6648 0.931667 18.3165 0.931667 17.9515ZM2.895 3.73147H21.0983C21.6133 3.73147 22.1 3.93314 22.4633 4.28647L11.9967 12.5481L1.52 4.29647C1.885 3.93814 2.375 3.73147 2.895 3.73147ZM23.065 5.6948V17.9515C23.065 18.2898 22.9783 18.6148 22.815 18.9048L15.2567 11.1615L22.965 5.07814C23.03 5.2748 23.065 5.48314 23.065 5.6948ZM23.725 4.47647C23.68 4.37647 23.6283 4.28147 23.5717 4.18814C23.5517 4.15814 23.5333 4.12814 23.5133 4.09814C23.4183 3.95647 23.3133 3.82647 23.1983 3.70647C22.655 3.13147 21.9017 2.7998 21.0983 2.7998H2.895C2.08667 2.7998 1.32667 3.13647 0.785 3.71814C0.67 3.8398 0.565 3.97314 0.471667 4.1148C0.393333 4.23647 0.323333 4.36314 0.263333 4.49314C0.09 4.8698 0 5.27814 0 5.6948V17.9515C0 18.7281 0.303333 19.4548 0.855 20.0015C1.40333 20.5465 2.12833 20.8465 2.895 20.8465H21.0983C21.8983 20.8465 22.645 20.5248 23.1983 19.9415C23.7133 19.4031 23.9967 18.6948 23.9967 17.9515V5.6948C23.9967 5.2698 23.9033 4.85647 23.725 4.47647Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Email;
