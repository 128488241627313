import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const FoodSandwich: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M29.348 24.6225H0.615634C0.275704 24.6225 0 24.3468 0 24.0069C0 23.667 0.275704 23.3912 0.615634 23.3912H29.348C29.688 23.3912 29.9637 23.667 29.9637 24.0069C29.9637 24.3468 29.688 24.6225 29.348 24.6225Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.57606 20.7908C3.8486 14.7359 8.85987 9.89364 14.9824 9.89364C21.1028 9.89364 26.1162 14.7359 26.3866 20.7908H3.57606ZM14.9824 6.69293C15.3965 6.69293 15.7324 7.02885 15.7324 7.44505C15.7324 7.85913 15.3965 8.19505 14.9824 8.19505C14.5683 8.19505 14.2303 7.85913 14.2303 7.44505C14.2303 7.02885 14.5683 6.69293 14.9824 6.69293ZM16.4697 8.75068C16.7782 8.40209 16.9641 7.94575 16.9641 7.44505C16.9641 6.35068 16.0747 5.46124 14.9824 5.46124C13.8882 5.46124 12.9988 6.35068 12.9988 7.44505C12.9988 7.94575 13.1845 8.4042 13.493 8.75279C7.2162 9.49012 2.3317 14.8394 2.3317 21.3105C2.3317 21.3549 2.3317 21.4014 2.33592 21.4436L2.36973 22.0225H27.0141L27.6122 22.0246L27.6291 21.4246C27.6291 21.4246 27.631 21.3486 27.631 21.3105C27.631 14.8394 22.7444 9.49012 16.4697 8.75068Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default FoodSandwich;
