import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button } from 'antd';
import { ChevronDown } from 'Components/icons';
import { useTranslation } from 'react-i18next';
import './btn-details.less';

interface BtnDetailsProps {
    toggled?: boolean;
    onClick?: (toggled: boolean) => void;
}

const BtnDetails: FunctionComponent<BtnDetailsProps> = ({ children, toggled = false, onClick }) => {
    const { t } = useTranslation();
    const [isToggled, setToggle] = useState(toggled);

    useEffect(() => {
        toggled !== isToggled && setToggle(toggled);
    }, [isToggled, toggled]);

    const toggleBtn = (event: React.MouseEvent<Element, MouseEvent>): void => {
        event.stopPropagation();
        typeof onClick === 'function' && onClick(!isToggled);
        setToggle(!isToggled);
    };

    return (
        <Button
            onClick={toggleBtn}
            type="link"
            className={`BtnDetails ${isToggled ? 'toggled' : ''}`}
        >
            {!isToggled ? children : t('close')}
            <ChevronDown />
        </Button>
    );
};

export default BtnDetails;
