import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Coworking: FunctionComponent<SvgProps> = ({ fill = 'currentColor', width = '16', height = '16', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width={width} height={height} viewBox="0 0 16 16" fill="none" {...props}>
                <circle cx={8} cy={8} r={8} fill="#fff" />
                <path
                    d="M8.00005 15.1998C11.9765 15.1998 15.2 11.9763 15.2 7.9998C15.2 4.02335 11.9765 0.799805 8.00005 0.799805C4.0236 0.799805 0.800049 4.02335 0.800049 7.9998C0.800049 11.9763 4.0236 15.1998 8.00005 15.1998Z"
                    fill={fill}
                />
                <path
                    d="M5.2989 9.27777H4.70592V5.41189C4.70403 5.31618 4.74008 5.22361 4.80621 5.1544C4.87234 5.08518 4.96317 5.04495 5.05886 5.04248H10.9412C11.0369 5.04495 11.1277 5.08518 11.1939 5.1544C11.26 5.22361 11.2961 5.31618 11.2942 5.41189V9.27777H10.9472V9.27778H5.2989V9.27777ZM9.36706 9.74835C9.28471 9.96011 9.14588 10.1013 8.98824 10.1013H6.99765C6.83765 10.1013 6.70118 9.96011 6.61882 9.74835H4V10.1601C4.01204 10.311 4.08295 10.4511 4.19741 10.5502C4.31188 10.6492 4.46071 10.6993 4.61176 10.6895H11.3882C11.5393 10.6993 11.6881 10.6492 11.8026 10.5502C11.917 10.4511 11.988 10.311 12 10.1601V9.74835H9.36706Z"
                    fill="#fff"
                />
            </svg>
        </span>
    );
};

export default Coworking;
