import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { UserProxy } from '../Api/Features/Users/UserProxy';
import { PagedRequest } from '../Models/Requests/PagedRequest';
import { SearchableRequest } from '../Models/Requests/SearchableRequest';
import { GetUsersResponseDto } from 'Api/Features/Users/Dtos/GetUsersResponseDto';
import { UserDto } from 'Api/Features/Users/Dtos/UserDto';
import { User } from '../Models/Users/User';

interface MemberRequest extends PagedRequest, SearchableRequest {
    companyIds?: string[];
    hasManagementRole?: boolean;
}

@inject(UserProxy)
export class MemberService extends ApiService {
    constructor(private readonly userProxy: UserProxy) {
        super();
    }

    public async getMembers({
        page,
        pageSize,
        searchTerm,
        companyIds
    }: MemberRequest): Promise<[User[], number]> {
        const response: GetUsersResponseDto | null = await this.userProxy.getUsers({
            page: page,
            pageSize: pageSize,
            searchTerm: searchTerm,
            companyIds: companyIds
        });
        return [
            response?.items?.map((dto: UserDto | null) => new User(dto!)) || [],
            response?.totalItemCount || 0,
        ];
    }
}
