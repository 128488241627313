import { observable, action } from 'mobx';
import { cloneDeep } from 'lodash';
import { AdvancedFilter } from '../Models/Filters/AdvancedFilter';
import { DocumentStatusFilter } from 'Models/Filters/DocumentStatusFilter';

interface FilterStoreArgs {
    advancedFilters?: AdvancedFilter[];
}

class FilterStore {
    @observable currentLocationIds = [] as string[];
    @observable locationType = '';
    @observable townhallExclusive = false;
    @observable coworkingExclusive = false;
    @observable advancedFilters?: AdvancedFilter[];
    @observable defaultFilters?: AdvancedFilter[];
    @observable searchTerm = '';
    @observable startDate = '';
    @observable endDate = '';
    @observable documentStatus = DocumentStatusFilter.All;

    constructor(params?: FilterStoreArgs) {
        if (params?.advancedFilters) {
            this.advancedFilters = params.advancedFilters;
            this.defaultFilters = cloneDeep(this.advancedFilters);
        }
    }

    @action
    updateLocation(locationIds: string[]): void {
        this.currentLocationIds = locationIds;
    }

    @action
    updateLocationType(locationType: string): void {
        this.locationType = locationType;
    }

    @action
    updateTownhallExclusive(value: boolean): void {
        this.townhallExclusive = value;
    }

    @action
    updateCoworkingExclusive(value: boolean): void {
        this.coworkingExclusive = value;
    }

    @action
    updateDocumentStatus(status: DocumentStatusFilter): void {
        this.documentStatus = status;
    }

    @action
    toggleAdvancedFilter(key: string, parentKey: string): void {
        const filters = this.advancedFilters?.slice();
        const parent = filters?.find((filter) => filter.key === parentKey);
        const filterItem = parent?.items.find((item) => item.key === key);
        if (filterItem) {
            filterItem.checked = !filterItem.checked;
        }
        this.advancedFilters = filters;
    }

    @action
    toggleSection(key: string): void {
        const filters = this.advancedFilters?.slice();
        const section = filters?.find((filter) => filter.key === key);
        if (section?.items.some((item) => item.checked)) {
            section?.items.forEach((item) => (item.checked = false));
        } else {
            section?.items.forEach((item) => (item.checked = true));
        }
        this.advancedFilters = filters;
    }

    @action
    updateSearchTerm(term: string): void {
        this.searchTerm = term;
    }

    @action
    updateStartDate(date: string): void {
        this.startDate = date;
    }

    @action
    updateEndDate(date: string): void {
        this.endDate = date;
    }

    @action
    clear(): void {
        this.searchTerm = '';
        this.currentLocationIds = [];
        this.startDate = '';
        this.endDate = '';
        this.advancedFilters = cloneDeep(this.defaultFilters);
    }

    public getGroupOptions(filterGroup: string, omitAll = true): string[] {
        const types = this.advancedFilters?.find(
            (filter: AdvancedFilter) => filter.key === filterGroup
        );

        const checkedCount: number = types?.items?.filter((item) => item.checked)?.length || 0;
        const totalCount: number = types?.items?.length || 0;

        if (omitAll && checkedCount === totalCount) {
            return []; // omitAll & all options are checked, no filter will apply for this group
        }

        if (checkedCount && types?.items) {
            const checkedTypes: string[] = types?.items
                .filter((item) => item.checked)
                .map((item) => {
                    return item.key;
                });
            return checkedTypes ? checkedTypes : [];
        }

        return [];
    }

    get checkedItemsByFilterKey() {
        return (key: string): string[] => {
            const advancedFilter = this.advancedFilters?.find(
                (filter: AdvancedFilter) => filter.key === key
            );
            const checkedFilterKeys = advancedFilter?.items
                .filter((item) => item.checked)
                .map((item) => {
                    return item.key;
                });
            return checkedFilterKeys ? checkedFilterKeys : [];
        };
    }
}

export default FilterStore;
