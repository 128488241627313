import React, { FunctionComponent, ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import './select-custom.less';
import Select, { components, ValueType } from 'react-select';
import { Checkbox } from 'antd';
import { theme } from '../../variant';
import { Close } from 'Components/icons';

export interface GroupedOptions {
    value: string;
    label: string;
    isSelected: boolean;
    options: SelectCustomOption[];
}

export interface SelectCustomOption {
    value: string;
    label: string;
    content?: ReactNode;
    imageUrl?: string;
    badge?: string;
    isSelected?: boolean;
    disabled?: boolean;
}

export interface SelectCustomProps {
    className?: string;
    options?: SelectCustomOption[];
    groups?: GroupedOptions[];
    onChange?: (value: any) => void | undefined;
    onMultiChange?: (value: any[]) => void | undefined;
    onGroupsChange?: (value: string[]) => void | undefined;
    onKeywordsChange?: (value: string) => void | undefined;
    onMenuScrollToBottom?: (event: SyntheticEvent<HTMLElement>) => void | undefined;
    placeholder?: string | null;
    defaultImg?: ReactNode;
    strongLabel?: boolean;
    isMulti?: boolean;
    isClearable?: boolean;
    closeMenuOnSelect?: boolean;
    hideSelectedOptions?: boolean;
    selected?: string[] | null;
    selectedGroups?: string[] | null;
    height?: string | undefined;
    disabled?: boolean;
    checkBoxes?: boolean;
}

const SelectCustom: FunctionComponent<SelectCustomProps> = ({
    className,
    options,
    groups,
    selected,
    selectedGroups,
    onChange,
    onMultiChange,
    onGroupsChange,
    onKeywordsChange,
    onMenuScrollToBottom,
    placeholder,
    defaultImg,
    strongLabel = false,
    isMulti = false,
    isClearable = false,
    closeMenuOnSelect = true,
    hideSelectedOptions = true,
    height = '50px',
    disabled,
    checkBoxes,
}) => {
    const [keywords, setKeywords] = useState('');

    useEffect(() => {
        if (onKeywordsChange) {
            onKeywordsChange(keywords);
        }
    }, [keywords, onKeywordsChange]);
    
    const styles: any = {
        control: (styles: any) => ({
            ...styles,
            borderRadius: '0px',
            border: '1px solid ' + theme['primary-4'], // default border color
            boxShadow: 'none', // no box-shadow
            minHeight: height,
            '&:hover': {
                border: '1px solid ' + theme['primary-4'], // default border color
            },
        }),
        valueContainer: (styles: any) => ({
            ...styles,
            padding: isMulti ? '1px' : '4px 20px',
        }),
        input: (styles: any) => ({
            ...styles,
            marginLeft: '11px'
        }),
        menu: (styles: any) => ({
            ...styles,
            width: '100%',
            zIndex: '2',
        }),
        menuList: (styles: any) => ({
            ...styles,
            padding: '0',
        }),
        placeholder: (styles: any) => ({
            ...styles,
            fontSize: '12px',
            fontWeight: '500',
            letterSpacing: '0.55px',
            lineHeight: '19px',
            color: theme['primary-3'],
            marginLeft: isMulti ? '11px': undefined,
        }),
        indicatorSeparator: (styles: any) => ({
            ...styles,
            display: 'none',
        }),
        indicatorsContainer: (styles: any) => ({
            ...styles,
            marginRight: '10px',
        }),
        option: (styles: any, { isDisabled, isSelected, isFocused, isHover }: any) => ({
            ...styles,
            display: 'flex',
            alignItems: 'center',
            color: theme['black'],
            fontWeight: '500',
            fontSize: '13px',
            lineHeight: '19px',
            padding: '12px 20px',
            backgroundColor: isDisabled
                ? theme['black-5']
                : isSelected && !checkBoxes
                ? theme['primary-5']
                : (isSelected && checkBoxes) || isFocused
                ? null
                : styles.backgroundColor,
            ':hover': {
                ...styles[':hover'],
                backgroundColor: theme['black-5'],
            },
            ':active': {
                ...styles[':active'],
                backgroundColor: theme['black-5'],
            },
        }),
        group: (styles: any) => ({
            padding: '0',
        }),
        groupHeading: (styles: any) => ({
            ...styles,
            paddingRight: '9px',
            backgroundColor: theme['primary-5'],
            margin: '0',
            padding: '0',
            letterSpacing: '0.48px',
            lineHeight: '16px',
            fontSize: '12px',
            fontWeight: 'bold',
            '> div': {
                padding: '10px 20px',
            },
        }),
    };

    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: theme['black-3'],
    };

    const getOptions = (): SelectCustomOption[] => {
        if(options) {
            return options.map((option) => {
                return {
                    ...option,
                    isSelected: selected?.includes(option.value)
                }
            });
        }
        return [];
    };

    const getGroups = (): GroupedOptions[] => {
        if(groups) {
            return groups.map((group) => {
                const groupIsSelected = selectedGroups?.includes(group.value);
                return {
                    ...group,
                    isSelected: groupIsSelected,
                    options: group.options.map((option) => {
                        return {
                            ...option,
                            isSelected: selected?.includes(option.value),
                            disabled: option.disabled,
                        }
                    }),
                }
            }) as GroupedOptions[];
        }
        return [];
    };

    const getOptionsOrGroups = (): GroupedOptions[] | SelectCustomOption[] => {
        const optionsValues = getOptions();
        if(optionsValues.length) {
            return optionsValues;
        }

        const groupsValues = getGroups();
        if(groupsValues.length) {
            return groupsValues;
        }

        return [];
    };

    const getValue = (): SelectCustomOption[] => {
        let allOptions: SelectCustomOption[] = [];

        if (options) {
            allOptions = options;
        }

        if (groups) {
            groups.map((group) => group.options.map((option) => allOptions.push(option)));
        }

        return allOptions.filter((x) => {
            return selected?.includes(x.value);
        });
    };

    //If an option from a selected group has been unselected, unselect the group
    //If all options for a group are selected, select the group
    const multiChangeGroupSelection = (selectedOptions: SelectCustomOption[]): void => {
        const groups = getGroups();
        const groupsWithAllSelectedOption = groups.filter((group) =>
            group.options.length > 0 &&
            group.options.every((groupOption) =>
                selectedOptions?.map((option) => option.value).includes(groupOption.value)
            )
        );
        const groupsWithAllSelectedOptionIds = groupsWithAllSelectedOption.map((group) => group.value);

        if(selectedGroups === null || selectedGroups === undefined)
            selectedGroups = [];

        if (onGroupsChange)
            onGroupsChange(groupsWithAllSelectedOptionIds);
    };

    const triggerOnMultiChange = (options: SelectCustomOption[]): void => {
        if (onMultiChange) {
            onMultiChange(options?.map((option: SelectCustomOption) => option) || []);
            if(groups && onGroupsChange) {
                multiChangeGroupSelection(options)
            }
        }
    };

    const trimEllip = (str: string, length: number): string => {
        return str.length > length ? str.substring(0, length - 3) + '...' : str;
    };

    const handleOnInputChange = (value: string): void => {
        setKeywords(value.replace(/\W/g, ''));
    };

    const handleOnChange = (data: ValueType<any>): void => {
        if (onChange) {
            onChange(data || '');
        }
    };

    const handleMultiOnChange = (data: ValueType<any>): void => {
        triggerOnMultiChange(data);
    };

    const optionImage = (data: SelectCustomOption, selectProps: any) => {
        return (
            <>
                {data.imageUrl ? (
                    <img
                        style={{ flex: '0 0 auto' }}
                        className="dropdown-option-img"
                        width="24px"
                        height="24px"
                        src={data.imageUrl}
                        alt=""
                    />
                ) : (
                    <span className="dropdown-option-img">{selectProps.defaultImg}</span>
                )}
            </>
        );
    };

    const optionDetails = (data: SelectCustomOption, selectProps: any) => {
        return (
            <div>
                <div className="dropdown-option-label">
                    {selectProps.strongLabel ? <strong>{data.label}</strong> : data.label}
                </div>
                {data.content && <div className="dropdown-option-content">{data.content}</div>}
            </div>
        );
    };

    const Option = (props: any) => {
        return (
            <div>
                <components.Option
                    {...props}
                    className="dropdown-option-container"
                    isDisabled={props.data.disabled}
                >
                    {defaultImg && optionImage(props.data, props.selectProps)}
                    {optionDetails(props.data, props.selectProps)}
                    {props.data.badge && (
                        <div
                            className="dropdown-option-badge"
                            style={{ marginLeft: 'auto', flex: '0 0 auto' }}
                        >
                            {props.data.badge}
                        </div>
                    )}
                    {checkBoxes && (
                        <Checkbox
                            style={{ marginLeft: 'auto', flex: '0 0 auto' }}
                            checked={props.data.isSelected || props.isSelected}
                            disabled={props.data.disabled}
                        />
                    )}
                </components.Option>
            </div>
        );
    };

    const SingleValue = ({ data, selectProps }: any): any => {
        return (
            !selectProps.menuIsOpen && (
                <div className="dropdown-value-element">
                    {defaultImg && optionImage(data, selectProps)}
                    {optionDetails(data, selectProps)}
                </div>
            )
        );
    };

    const MultiValueContainer = ({ innerProps, ...props }: any): any => {
        innerProps.className += ' dropdown-multivalue-container';
        return <components.MultiValueContainer innerProps={innerProps} {...props} />;
    };

    const MultiValueLabel = ({ data, selectProps, innerProps, ...props }: any): any => {
        innerProps.className += ' dropdown-value-element';
        data.content = data.content ? trimEllip(data.content, 15) : null;
        return (
            <components.MultiValueLabel innerProps={innerProps} {...props}>
                {defaultImg && optionImage(data, selectProps)}
                {optionDetails(data, selectProps)}
            </components.MultiValueLabel>
        );
    };

    const MultiValueRemove = ({ innerProps, ...props }: any): any => {
        innerProps.className += ' dropdown-value-remove';
        return (
            <components.MultiValueRemove innerProps={innerProps} {...props}>
                <Close fill={theme['black']} />
            </components.MultiValueRemove>
        );
    };

    const onGroupClick = (groupId: string, isSelected: boolean): void => {
        const selectedGroupIds: string[] = [];
        const allOptions: SelectCustomOption[] = [];
        getGroups().forEach((group) => {
            if (group.value === groupId) {
                if(!isSelected) {
                    selectedGroupIds.push(group.value);
                    group.options.map((option) => allOptions.push(option));
                }
            } else {
                if(group.isSelected) {
                    selectedGroupIds.push(group.value);
                }
                group.options.forEach((option) => {
                    if (option.isSelected) {
                        allOptions.push(option);
                    }
                });
            }
        });
        if(onGroupsChange) {
            onGroupsChange(selectedGroupIds);
        }
        triggerOnMultiChange(allOptions);
    };

    const formatGroupLabel = (data: any) => (
        <div style={groupStyles} onClick={(): void => onGroupClick(data.value, data.isSelected)}>
            <span>{data.label}</span>
            {checkBoxes && (
                <Checkbox
                    value={data.value}
                    style={{ marginLeft: 'auto', flex: '0 0 auto' }}
                    checked={data.isSelected}
                />
            )}
        </div>
    );

    return (
        <>
            <Select
                components={{
                    Option,
                    SingleValue,
                    MultiValueContainer,
                    MultiValueLabel,
                    MultiValueRemove,
                }}
                className={`SelectCustom ${className || ''} ${isMulti ? 'multiple' : ''}`}
                styles={styles}
                options={getOptionsOrGroups()}
                onChange={isMulti ? handleMultiOnChange : handleOnChange}
                onInputChange={handleOnInputChange}
                onMenuScrollToBottom={onMenuScrollToBottom}
                isMulti={isMulti}
                isClearable={isClearable}
                closeMenuOnSelect={closeMenuOnSelect}
                hideSelectedOptions={hideSelectedOptions}
                placeholder={placeholder}
                defaultImg={defaultImg}
                strongLabel={strongLabel}
                value={getValue()}
                isDisabled={disabled}
                formatGroupLabel={formatGroupLabel}
            />
        </>
    );
};

SelectCustom.displayName = 'SelectCustom';

export default React.memo(SelectCustom);
