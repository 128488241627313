import React, { FunctionComponent } from 'react';
import { Booking } from 'Models/Bookings/Booking';
import { Avatar, Row, Col, Typography } from 'antd';
import { AngleRight, DollarBold, Amenity } from 'Components/icons';
import './upcoming-bookings.less';
import { BookingApprovalStatusDto } from 'Api/Features/Bookings/Dtos/BookingApprovalStatusDto';
import { useTranslation } from 'react-i18next';

const { Text, Paragraph } = Typography;

interface BookingsGroupedByDate {
    [key: string]: Booking[];
}

interface UpcomingBookingsProps {
    bookings: BookingsGroupedByDate | null;
    handleClickBooking: (booking: Booking) => void;
    selectedBooking?: Booking;
}

const UpcomingBookings: FunctionComponent<UpcomingBookingsProps> = ({
    bookings,
    handleClickBooking,
    selectedBooking,
}) => {
    const { t } = useTranslation();

    return (
        <div className="UpcomingBookings">
            {bookings &&
                Object.keys(bookings).length > 0 &&
                Object.keys(bookings).map((date) => {
                    return (
                        <div key={date} className="dates">
                            <Paragraph className="date">
                                <Text strong>{date}</Text>
                            </Paragraph>

                            {bookings[date].map((booking: Booking) => {
                                return (
                                    <div
                                        key={booking.id}
                                        className={`booking ${
                                            selectedBooking?.id === booking.id ? 'selected' : ''
                                        }`}
                                        onClick={(): void => handleClickBooking(booking)}
                                    >
                                        <Paragraph className="booking-time">
                                            <Text>{booking.time}</Text>
                                        </Paragraph>
                                        {booking.approvalStatus ===
                                            BookingApprovalStatusDto.WaitingForApproval && (
                                            <p className="booking-status">
                                                {t(
                                                    `Booking.booking_approvalstatus_${booking.approvalStatus}`
                                                )}
                                            </p>
                                        )}
                                        <Row gutter={12} className="amenity" align="middle">
                                            <Col>
                                                <div className="amenity-avatar">
                                                    <Avatar
                                                        size={32}
                                                        icon={
                                                            !booking.amenity?.imageUrl ? (
                                                                <Amenity />
                                                            ) : undefined
                                                        }
                                                        src={booking.amenity?.imageUrl || undefined}
                                                    />
                                                    {!!booking.cost && (
                                                        <span className="dollar-bg">
                                                            <DollarBold fill="#fff" />
                                                        </span>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col>
                                                <Text
                                                    ellipsis
                                                    strong
                                                    className="amenity-name small"
                                                >
                                                    {booking.amenity?.name}
                                                </Text>
                                            </Col>
                                        </Row>

                                        {selectedBooking?.id === booking.id && <AngleRight />}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
        </div>
    );
};

export default UpcomingBookings;
