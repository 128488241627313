import React, { FunctionComponent, ReactNode } from 'react';
import { Select } from 'antd';
import { countries } from 'appcom-globalization';
import { FormItemProps } from 'antd/es/form';
import { ValidatedFormItem } from '../validated-form-item';

interface SelectOption {
    id: string;
    name: string;
    order?: number;
}

const { Option } = Select;

interface SelectCountryProps extends FormItemProps {
    errors?: Map<string, string[]>;
}

const SelectCountry: FunctionComponent<SelectCountryProps> = ({
    children,
    name,
    label,
    errors,
    ...props
}) => {
    const countryCodeOrder = (countryCode: string): number => {
        return countryCode === 'US' ? 0 : 1;
    };

    const countrySorting = (a: any, b: any): number => {
        return a.order - b.order;
    };

    const countriesOptions: SelectOption[] = countries
        .map(
            (option) =>
                ({
                    id: option.twoLetterCode,
                    name: option.englishName,
                    order: countryCodeOrder(option.twoLetterCode),
                } as SelectOption)
        )
        .sort(countrySorting);

    const selectOptions = (options: SelectOption[]): ReactNode => {
        return options.map((option) => (
            <Option key={option.id} value={option.id}>
                {option.name}
            </Option>
        ));
    };

    return (
        <ValidatedFormItem errors={errors} name={name} label={label} {...props}>
            <Select>{selectOptions(countriesOptions)}</Select>
        </ValidatedFormItem>
    );
};

export default SelectCountry;
