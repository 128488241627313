import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import ResetPasswordForm from './reset-password-form';
import ResetPasswordSuccess from './reset-password-success';
import { useResetPassword } from './use-reset-password';

const ResetPassword: FunctionComponent = () => {
    const { t } = useTranslation();
    const [success, submit] = useResetPassword();

    return (
        <div className="ResetPassword">
            {success ? (
                <ResetPasswordSuccess />
            ) : (
                <ResetPasswordForm
                    message={t('User.reset_password_message')}
                    submitText={t('User.reset_password')}
                    onSubmit={submit}
                    isAccountConfirm={false}
                />
            )}
        </div>
    );
};

export default ResetPassword;
