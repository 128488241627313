import React, { FunctionComponent } from 'react';

import './webapp-actions-layout.less';
import { images } from 'variant';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

type WebAppActionsLayoutProps = {
    showBackToLogin?: boolean;
    showLogo?: boolean;
    children: React.ReactNode;
};

const WebAppActionsLayout: FunctionComponent<WebAppActionsLayoutProps> = ({
    showBackToLogin = false,
    showLogo = false,
    children,
}) => {
    const { t } = useTranslation();
    return (
        <div className={`WebAppActionsLayout ${process.env.REACT_APP_VARIANT}`}>
            {showBackToLogin && (
                <Link to="/" className="WebAppActionsLayout-backButton">
                    <FontAwesomeIcon icon={['fas', 'angle-left']} />{' '}
                    {t('PasswordRecovery.recover_password_back_link')}
                </Link>
            )}
            {showLogo && (
                <div className="logo">
                    <img src={images.logoColor} alt={t('app_name')} />
                </div>
            )}
            <div className="content">{children}</div>
        </div>
    );
};

export default WebAppActionsLayout;
