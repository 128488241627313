import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Key: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="60" height="54" viewBox="0 0 60 54" fill="none" {...props}>
                <path
                    d="M13.3218 38.8033L13.2361 38.8462L14.7354 40.9879L14.821 40.9451L13.3218 38.8033ZM11.18 40.3026L11.0944 40.3454L12.5936 42.4872L12.6793 42.4443L11.18 40.3026Z"
                    fill={fill}
                />
                <path
                    d="M11.18 40.3026L11.0944 40.3454L12.5936 42.4872L12.6793 42.4443L11.18 40.3026Z"
                    fill={fill}
                />
                <path
                    d="M13.3218 38.8033L13.2361 38.8462L14.7354 40.9879L14.821 40.9451L13.3218 38.8033Z"
                    fill={fill}
                />
                <path
                    d="M17.177 44.5004L22.7027 40.6453L20.2611 37.1328L38.8517 24.1537C41.7216 26.2097 45.1913 27.0665 48.7038 26.5096C52.2163 25.9527 55.3004 24.068 57.4422 21.198C58.4702 19.7416 59.2413 18.1567 59.6268 16.4861C60.0551 14.8155 60.098 13.0593 59.841 11.303C59.2841 7.79055 57.3994 4.70641 54.5294 2.56464C51.6594 0.465707 48.1469 -0.390999 44.5916 0.16586C41.0791 0.722719 37.9521 2.60747 35.8532 5.52028C33.7542 8.39025 32.8975 11.9027 33.4544 15.4152C33.5401 15.9293 33.6257 16.4433 33.7971 16.9573L0 40.4739L5.05457 47.7131L7.06783 46.2995L12.2081 53.6672L19.8756 48.3128L17.177 44.5004ZM12.8506 50.1119L7.66753 42.7014L5.65426 44.1149L3.55533 41.1164L34.6966 19.3989L36.8384 17.8997C36.4529 17.0858 36.1959 16.2291 36.0245 15.3296C35.9817 15.2011 35.9817 15.1154 35.9817 14.9869C35.5533 12.1598 36.2387 9.33262 37.9093 6.97668C39.6227 4.66357 42.1071 3.1215 44.9343 2.69314C47.7614 2.26479 50.6314 2.95016 52.9445 4.62074C53.9297 5.34894 54.7436 6.20564 55.4289 7.14802C56.3713 8.47592 56.971 10.018 57.228 11.6457C57.6564 14.4729 56.971 17.3428 55.3004 19.6559C53.6298 21.969 51.1026 23.5111 48.2754 23.9395C45.7481 24.325 43.2637 23.811 41.0791 22.5259C40.7792 22.3546 40.5222 22.1832 40.2652 21.969C39.794 21.6264 39.3657 21.2408 38.9373 20.8553L16.5773 36.5331L19.0189 40.0456L15.7206 42.3587L14.7354 40.9451L14.6497 40.9879L13.1504 38.8462L11.18 40.3026L12.6793 42.4443L12.5936 42.4872L16.2774 47.7559L12.8506 50.1119Z"
                    fill={fill}
                />
                <path
                    d="M55.3861 9.41829C55.2148 8.34741 54.6579 7.44787 53.8012 6.80534C52.9445 6.16281 51.8736 5.9058 50.8455 6.07714C48.6609 6.41982 47.1617 8.47592 47.5044 10.6177C47.5901 11.2174 47.8471 11.8171 48.1469 12.2883C48.4039 12.6309 48.7038 12.9736 49.0893 13.2306C49.946 13.8732 51.0169 14.1302 52.0449 13.9588C54.2295 13.6162 55.7288 11.6029 55.3861 9.41829ZM51.6594 11.4316C51.2739 11.4744 50.9312 11.3887 50.6314 11.1745C50.3315 10.9604 50.1173 10.6177 50.0745 10.275C49.946 9.50396 50.46 8.77576 51.2311 8.69009C51.6166 8.64726 51.9593 8.73293 52.2591 8.9471C52.3876 9.03277 52.5161 9.16128 52.6018 9.28979C52.7303 9.46113 52.816 9.67531 52.816 9.88948C52.9445 10.5748 52.4305 11.303 51.6594 11.4316Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Key;
