import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Box: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.242627 3.5573C0.337468 3.49407 0.457632 3.48242 0.562848 3.52626L8.74467 6.93535C8.87171 6.98829 8.95446 7.11241 8.95446 7.25004V16.1137C8.95446 16.2277 8.89749 16.3341 8.80265 16.3973C8.70781 16.4606 8.58765 16.4722 8.48243 16.4284L0.300611 13.0193C0.173572 12.9663 0.0908203 12.8422 0.0908203 12.7046V3.84095C0.0908203 3.72696 0.147787 3.62052 0.242627 3.5573ZM0.772639 4.35231V12.4773L8.27264 15.6023V7.47731L0.772639 4.35231Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.2984 3.55432C16.3959 3.61707 16.4548 3.72504 16.4548 3.84095V12.7046C16.4548 12.8383 16.3766 12.9596 16.2549 13.0149L8.75493 16.424C8.64941 16.472 8.52675 16.4631 8.42929 16.4003C8.33184 16.3376 8.27295 16.2296 8.27295 16.1137V7.25004C8.27295 7.11635 8.35109 6.99501 8.47279 6.93969L15.9728 3.5306C16.0783 3.48263 16.201 3.49157 16.2984 3.55432ZM8.95477 7.46955V15.5842L15.7729 12.4851V4.37038L8.95477 7.46955Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.1368 0.119222C8.22349 0.0815262 8.32195 0.0815262 8.40865 0.119222L16.2496 3.52831C16.4222 3.60338 16.5013 3.80421 16.4263 3.97688C16.3512 4.14954 16.1504 4.22866 15.9777 4.15359L8.27272 0.803596L0.567745 4.15359C0.39508 4.22866 0.19425 4.14954 0.119178 3.97688C0.0441067 3.80421 0.123222 3.60338 0.295887 3.52831L8.1368 0.119222Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.4149 5.76472L4.84668 2.16472L5.13956 1.54901L12.7077 5.14901L12.4149 5.76472Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.29791 6.61914C2.39842 6.55702 2.52392 6.55137 2.6296 6.60421L6.72051 8.64967C6.836 8.70741 6.90896 8.82546 6.90896 8.95459V12.3637C6.90896 12.4818 6.84778 12.5916 6.74727 12.6537C6.64677 12.7158 6.52127 12.7214 6.41559 12.6686L2.32468 10.6231C2.20919 10.5654 2.13623 10.4473 2.13623 10.3182V6.90913C2.13623 6.79098 2.19741 6.68125 2.29791 6.61914ZM2.81805 7.46073V10.1075L6.22714 11.8121V9.16528L2.81805 7.46073Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Box;
