import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useService, useStores } from 'Hooks';
import { LocationService } from 'Services/LocationService';
import { AmenityService } from 'Services/AmenityService';
import { AmenityCategoryService } from 'Services/AmenityCategoryService';
import { BookingService } from 'Services/BookingService';
import { Button, Checkbox, Typography } from 'antd';
import { theme } from 'variant';
import LocationHeader from 'Components/location-header/location-header';
import { BreadcrumbSegment } from 'Components/routed-breadcrumb/routed-breadcrumb';
import FlexibleBooking from 'Components/booking/flexible-booking/flexible-booking';
import DateControls from 'Components/booking/flexible-booking/date-controls';
import { Badge } from 'Components/badge';
import { ImageWithPlaceholder } from 'Components/image-with-placeholder';
import { Booking, Company, Amenity as AmenityIcon, Filter } from 'Components/icons';
import { Location } from 'Models/Location/Location';
import { LocationDto } from 'Api/Features/Locations/Dtos/LocationDto';
import { DailyBookingSummaryAmenityDto } from 'Api/Features/Bookings/Dtos/DailyBookingSummaryAmenityDto';
import { AmenityDto } from 'Api/Features/Amenities/Dtos/AmenityDto';
import { BookingDto } from 'Api/Features/Bookings/Dtos/BookingDto';
import { GetDailyBookingSummaryRequestDto } from 'Api/Features/Bookings/Dtos/GetDailyBookingSummaryRequestDto';
import BookingModal from 'Routes/console/authenticated/locations/id/booking/booking-modal';
import './index.less';
import { DATE_API_FORMAT } from 'Models/Constants';
import Icon from 'Components/icons/Icon';
import UserPermissionUtils from 'Utils/UserPermissionUtils';

interface CheckBoxElement {
    key: string;
    title: string;
    checked: boolean;
}

const breadcrumbs: BreadcrumbSegment[] = [
    {
        path: 'booking',
        nameKey: 'Location.location_bookings',
    },
];

const defaultMembershipImg = (Icon?: any) => (
    <div className="wg-6 hg-6 rounded-circle bg-light text-white align-item-center mr-1 membership-circle">
        {Icon && <Icon />}
    </div>
);

export const generateResourcePeriod = (period: any) => {
    if (period && period.booking) {
        if (period.unavailabilityReason === 'Booked') {
            return (
                <div className="booking-information d-flex align-items-center position-absolute text-white">
                    {period.booking.company && period.booking.company.imageUrl ? (
                        <div
                            className="booking-avatar"
                            style={{
                                backgroundImage: `url(${period.booking.company.imageUrl})`,
                            }}
                        />
                    ) : (
                        <ImageWithPlaceholder
                            defaultImg={defaultMembershipImg(Company)}
                            imgSrc={null}
                            className="FlexibleBooking__Booking_Image booking-avatar"
                        />
                    )}
                    <div className="booking-information-content">
                        {period.booking.company ? <p>{period.booking.company.name}</p> : <p>{'quick booking'}</p>}
                        {period.booking.organizer && (
                            <span>
                                {period.booking.organizer.firstName}{' '}
                                {period.booking.organizer.lastName}
                            </span>
                        )}
                    </div>
                </div>
            );
        } else {
            return '';
        }
    } else {
        return null;
    }
};

const LocationBooking: FunctionComponent = observer(() => {
    const { id } = useParams<{id: string}>();
    const { t } = useTranslation();
    const { bookingStore, amenityStore, navigationStore, globalLoadingStore, userStore } = useStores();
    const locationService = useService(LocationService);
    const bookingService = useService(BookingService);
    const amenityService = useService(AmenityService);
    const amenityCategoryService = useService(AmenityCategoryService);
    const [loading, setLoading] = useState(true);
    const [location, setLocation] = useState<Location | undefined>(undefined);
    const [bookingModalOpen, setBookingModalOpen] = useState(false);
    const [newBookingInfo, setNewBookingInfo] = useState(false);
    const [booking, setBooking] = useState(false);
    const [selectedResource, setSelectedResource] = useState();
    const [timezone, setTimezone] = useState<string | null>();
    const [locationAmenities, setLocationAmenities] = useState<(AmenityDto | null)[] | null>([]);
    const [filterAmount, setFilterAmount] = useState<number>(0);
    const [showFilters, setShowFilters] = useState(false);

    const [filterCheckboxesState, setFilterCheckboxesState] = useState<Array<CheckBoxElement>>([]);
    const [selectedDate, setSelectedDate] = useState(moment());
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        if (location) {
            navigationStore.setSubmenu(location);
        }
    }, [location, navigationStore]);

    useEffect(() => {
        const fetchLocation = async (): Promise<void> => {
            setLoading(true);
            globalLoadingStore.addLoading();
            try {
                // call api
                const locationData: LocationDto | null = await locationService.getById(id);

                if (locationData) {
                    const location: Location = new Location(locationData);
                    setLocation(location);

                    // set location timeZone
                    setTimezone(locationData.timeZone);
                    amenityStore.setAmenitiesForLocations(locationData.id!);
                }
            } finally {
                setLoading(false);
                globalLoadingStore.removeLoading();
            }
        };

        const fetchLocationAmenities = async (): Promise<void> => {
            const [items] = await amenityService.getAmenities({
                locationIds: [id],
            });
            setLocationAmenities(items);
        };

        const initAmenityCategoriesCheckboxes = async (): Promise<void> => {
            const [items] = await amenityCategoryService.getAmenityCategories({includeEmpty: true});
            if (items) {
                setFilterCheckboxesState(
                    items.map((x) => {
                        return { key: x?.id, title: x?.name, checked: true } as CheckBoxElement;
                    })
                );
            }
        };

        fetchLocation();
        initAmenityCategoriesCheckboxes();
        fetchLocationAmenities();
    }, [
        id,
        locationService,
        amenityCategoryService,
        amenityService,
        amenityStore,
        globalLoadingStore,
    ]);

    useEffect(() => {
        bookingStore.setDailyBookingSummary(id, {
            date: selectedDate.format(DATE_API_FORMAT),
            enableUserRestrictions: false,
            amenityIds: amenityStore.amenities?.map((x: AmenityDto) => x.id),
        } as GetDailyBookingSummaryRequestDto);
    }, [bookingStore, selectedDate, amenityStore.amenities, id]);

    useEffect(() => {
        setFilterAmount(filterCheckboxesState.filter((x) => x.checked).length);
    }, [filterCheckboxesState]);

    const getFilteredAmenities = ():
        | (DailyBookingSummaryAmenityDto | null)[]
        | null
        | undefined => {
        const activeAmenityCategories = filterCheckboxesState
            .filter((x) => x.checked)
            .map((x) => x.key);

        return bookingStore.dailyBookingSummary?.filter(function (room: any) {
            const locationAmenity = locationAmenities?.find((x) => x?.id === room.id);
            return (
                locationAmenity?.category?.id &&
                activeAmenityCategories.includes(locationAmenity.category.id)
            );
        });
    };

    const onBookingComplete = useCallback(
        (success: boolean) => {
            setBookingModalOpen(false);
            if (success) {
                bookingStore.setDailyBookingSummary(id, {
                    date: selectedDate.format(DATE_API_FORMAT),
                    enableUserRestrictions: false,
                } as GetDailyBookingSummaryRequestDto);
            }
        },
        [bookingStore, id, selectedDate]
    );

    const getBookingInfo = async (id: string): Promise<BookingDto | null> => {
        return await bookingService.getBooking(id);
    };

    const generateResourceHeader = (t: any) => (resource: any) => {
        return (
            <>
                <div className="resource-head-thumb">
                    {
                        <ImageWithPlaceholder
                            defaultImg={<AmenityIcon />}
                            imgSrc={resource.imageUrl}
                            className="FlexibleBooking__Amenity_Image mr-2"
                        />
                    }
                    {(resource.isTownhallExclusive || resource.isCoworking) && (
                        <div className="resource-type">
                            <Icon
                                iconName={resource.isTownhallExclusive ? 'Townhall' : 'Coworking'}
                                fill={theme['primary-color']}
                            />
                        </div>
                    )}
                </div>
                <h3 className="resource-title" title={resource.name}>
                    {resource.name}
                </h3>
            </>
        );
    };

    const onTimeCellClick = async (e: any, period: any, resource: any): Promise<void> => {
        if (period.bookingId !== undefined) {
            setSelectedResource(period.bookingAmenity[0]);
            const booking = await getBookingInfo(period.bookingId);
            setIsEditing(true);
            setNewBookingInfo(false);
            const amenityBooking: any = { bookingAmenity: period.bookingAmenity, ...booking };
            setBooking(amenityBooking);
        } else {
            setSelectedResource(period.bookingAmenity[0]);
            setIsEditing(false);
            setBooking(false);
            setNewBookingInfo(period);
        }

        setBookingModalOpen(true);
    };

    const handleDateChange = (selectedDate: any) => {
        setSelectedDate(moment(selectedDate));
    };

    const onAdvancedFilterClick = () => {
        setShowFilters(!showFilters);
    };

    const onCategoryFilterChange = (key: string, checked: boolean): void => {
        setFilterAmount(checked ? filterAmount + 1 : filterAmount - 1);
        const checkboxesStates = filterCheckboxesState;
        const index = checkboxesStates.findIndex((obj: CheckBoxElement) => obj.key === key);
        checkboxesStates[index].checked = checked;
        setFilterCheckboxesState(checkboxesStates);
    };

    const onClearFilters = (): void => {
        setFilterCheckboxesState(
            filterCheckboxesState.map((x) => {
                return { key: x.key, title: x.title, checked: true };
            })
        );
    };

    return (
        <div className="LocationBooking">
            <LocationHeader
                title={t('Location.location_bookings')}
                subTitle={t('Location.location_bookings_subtitle')}
                defaultImg={<Booking fill={theme['primary-color']} />}
                loading={loading}
                routes={breadcrumbs}
            />
            <div className="FlexibleBooking__actions d-flex align-items-center justify-content-between mb-1 flex-wrap mx-40 my-45">
                <div className="d-flex align-items-center">
                    <div className="pr-20">
                        <Button
                            className={`bordered-btn advanced-filters-btn ${
                                showFilters ? 'active' : ''
                            }`}
                            onClick={onAdvancedFilterClick}
                        >
                            <Filter fill={theme['black']} />
                            {t('Filter.advanced_filters')}{' '}
                            <Badge type="primary">{filterAmount}</Badge>
                        </Button>
                    </div>

                    <div onClick={onClearFilters}>
                        <Typography.Text className="clear-filters">
                            {t('Filter.clear_filters')}
                        </Typography.Text>
                    </div>
                </div>

                <DateControls
                    selectedDate={selectedDate}
                    onChange={handleDateChange}
                    showTodayButton={true}
                />

                {showFilters && (
                    <div className="advanced-filters">
                        <h4> {t('Filter.advanced_filters')}</h4>
                        <div className="filter-header">
                            <div>{t('Filter.amenity_types')}</div>
                            <Badge type="primary">{filterAmount}</Badge>
                        </div>
                        <div className="filter-section">
                            {filterCheckboxesState.map((item) => {
                                return (
                                    <Checkbox
                                        key={item.key}
                                        onChange={(e) =>
                                            onCategoryFilterChange(item.key, e.target.checked)
                                        }
                                        style={{ marginRight: '9%' }}
                                        checked={item.checked}
                                    >
                                        {item.title}
                                    </Checkbox>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
            <FlexibleBooking
                view="bookings"
                isEditing={false}
                filters={[]}
                min={0}
                max={24}
                zoom={0}
                resizable={false}
                resources={getFilteredAmenities()}
                resourceHeader={generateResourceHeader(t)}
                resourcePeriod={generateResourcePeriod}
                classNames="my-20 mx-40"
                onTimeCellClick={onTimeCellClick}
                timezone={timezone}
                selectedDate={selectedDate}
            />
            {(userStore.isAdmin || new UserPermissionUtils(userStore).UserCanViewContent([id])) && bookingModalOpen && (
                <BookingModal
                    visible={bookingModalOpen}
                    onComplete={onBookingComplete}
                    newBookingInfo={newBookingInfo}
                    resource={[selectedResource]}
                    resourcePeriod={generateResourcePeriod}
                    timezone={timezone}
                    selectedDate={selectedDate}
                    booking={booking || undefined}
                    isEditing={isEditing}
                    locationId={id}
                />
            )}
        </div>
    );
});

export default LocationBooking;
