import { Affix, Layout, Menu } from 'antd';
import { ClickParam } from 'antd/es/menu';
import { CompanyTypeDto } from 'Api/Features/Companies/Dtos/CompanyTypeDto';
import { Booking, Logout2 } from 'Components/icons';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import { WEBAPP_SIDER_MENU_INDENT, WEBAPP_SIDER_WIDTH } from 'Models/Constants';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { images, theme } from 'variant';
import './side-navigation.less';

const { Sider } = Layout;

const SideNavigation: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const {
        authenticationStore,
        confirmationModalStore,
        navigationStore,        
        userStore
    } = useStores();    

    const logo = userStore.user?.company?.type === CompanyTypeDto.Heyday?
    <div className="logo">
        <img src={images.logoSmallWhite} alt={t('brand_name')} />
    </div>:<div className="logo logo-passport">
        <img src={images.logoWhite} alt={t('brand_name')} />
    </div>;

    navigationStore.setLocation(location.pathname);

    const onMenuItemClicked = (param: ClickParam): void => {
        history.push(param.key);
    };

    const logout = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Logout2 fill={theme['primary-500']} />,
                title: t('LogOut.log_out_confirm_title'),
                message: t('LogOut.log_out_confirm_message'),
                positiveText: t('LogOut.log_out_confirm_positive'),
                negativeText: t('LogOut.log_out_confirm_negative'),
            }))
        )
            return;

        authenticationStore.clearSession();
        history.replace('/');
    };

    const selectedMenuItem = (pathname: string): string => {
        if (navigationStore.submenuPath) {
            return navigationStore.submenuPath;
        }
        return pathname;
    };

    return (
        <Sider
            className="SideNavigation no-select"
            width={WEBAPP_SIDER_WIDTH}
            style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
            }}
            theme="light"
        >
            <Link to="/">
                {logo}
            </Link>

            <Menu
                className="mainMenu"
                theme="light"
                defaultSelectedKeys={['/amenity-bookings']}
                mode="inline"
                selectedKeys={[selectedMenuItem(location.pathname)]}
                onClick={onMenuItemClicked}
                inlineIndent={WEBAPP_SIDER_MENU_INDENT}
            >
                <Menu.Item key="/amenity-bookings" icon={<Booking />}>
                    {t('Sidebar.menu_item_amenity')}
                </Menu.Item>

                {/*<Menu.Item key="/account" icon={<User />}>*/}
                {/*    {t('Sidebar.menu_item_account')}*/}
                {/*</Menu.Item>*/}
                {/*{subMenu(navigationStore.subIsAccount)}*/}

                {/*<Menu.Item key="/company" icon={<Company />}>*/}
                {/*    {t('Sidebar.menu_item_company')}*/}
                {/*</Menu.Item>*/}
                {/*{subMenu(navigationStore.subIsCompany)}*/}

            </Menu>

            <Affix offsetBottom={0.5} style={{ marginTop: 'auto' }}>
                <Menu
                    className="log-out"
                    theme="light"
                    mode="inline"
                    onSelect={logout}
                    selectedKeys={['']}
                    inlineIndent={16}
                >
                    <Menu.Item className="logout-btn" key={'logout'} icon={<Logout2 fill={theme['primary-500']} />}>
                        {t('LogOut.log_out')}
                    </Menu.Item>
                </Menu>
            </Affix>
        </Sider>
    );
});

export default SideNavigation;
