import React, { FunctionComponent } from 'react';
import { Layout, Row, Col } from 'antd';

import { images } from 'variant';
import './anonymous-layout.less';

const { Content } = Layout;

const AnonymousLayout: FunctionComponent = ({ children }) => {
    return (
        <div className="AnonymousLayout">
            <Layout
                className="container"
                style={{ backgroundImage: `url(${images.anonymousBackground})` }}
            >
                <Content>
                    <Row align="middle" className="login-row">
                        <Col
                            span={0}
                            offset={0}
                            lg={{ span: 12, offset: 0 }}
                            xl={{ span: 16, offset: 0 }}
                            className="splash-background"
                            style={{ backgroundImage: `url(${images.anonymousBackground})` }}
                            />
                        <Col
                            span={20}
                            offset={2}
                            lg={{ span: 12, offset: 0 }}
                            xl={{ span: 8, offset: 0 }}
                        >
                            <div className="AnonymousLayout-children">{children}</div>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </div>
    );
};
export default AnonymousLayout;
