import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Promotion: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.067 5.777a1.462 1.462 0 011.04-.431c.394 0 .763.153 1.042.43a1.474 1.474 0 01-1.041 2.514c-.393 0-.763-.153-1.041-.431a1.463 1.463 0 01-.432-1.041c0-.393.154-.763.432-1.041zm1.041 3.985c.787 0 1.526-.306 2.082-.863a2.948 2.948 0 000-4.163 2.924 2.924 0 00-2.082-.862c-.786 0-1.526.306-2.082.862a2.928 2.928 0 00-.862 2.082c0 .786.306 1.526.862 2.081a2.925 2.925 0 002.082.863z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27.734 12.948L12.838 27.844 2.082 17.088 16.977 2.194 28.43 1.56l-.696 11.387zM16.334.755L0 17.088l12.838 12.838 16.331-16.331L30 0 16.333.755z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Promotion;
